import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  Fragment,
  memo,
} from "react";
import { NavLink } from "react-router-dom";

// cleaned wed 24 jul 23
import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";

import axios from "axios";
import serverURL from "../serverURL";
import close from "../img/modals/close.svg";
import allowArr from "../img/general/allowArr.svg";
import greenTick from "../img/general/greenTick.svg";
import popup from "../img/general/popup.svg";
import whiteGo from "../img/general/whiteGo.svg";
import download from "../img/general/download.svg";
import info from "../img/general/info.svg";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";
import { DataContext } from "../contexts/DataContext";

import appURL from "../appURL";

const UserPayRollModal = () => {
  const { mobModal } = useContext(StyleContext);
  const { setCheckUserState, checkUserState, currencySymbol } =
    useContext(UserContext);
  const {
    setShowChecklist,
    modalOpen,
    setModalOpen,
    setShowEditUser,
    showEditUser,
    showUserPayRollModal,
    setShowUserPayRollModal,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      mobModal,
      setCheckUserState,
      checkUserState,
      setShowChecklist,
      modalOpen,
      setModalOpen,
      setShowEditUser,
      showEditUser,
      showUserPayRollModal,
      setShowUserPayRollModal,
      currencySymbol,
    }),
    [
      mobModal,
      setCheckUserState,
      checkUserState,
      setShowChecklist,
      modalOpen,
      setModalOpen,
      setShowEditUser,
      showEditUser,
      showUserPayRollModal,
      setShowUserPayRollModal,
      currencySymbol,
    ]
  );
  let [disableDownload, setDisableDownload] = useState(false);

  let [infoArr, setInfoArr] = useState([]);
  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let closeModal = () => {
    memoVals.setShowUserPayRollModal((x) => {
      return { show: false };
    });
  };

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
      closeModal();
    });
  }, []);

  const cancelSource1 = axios.CancelToken.source();
  let [clientCreated, setClientCreated] = useState(0);
  let [done, setDone] = useState([]);
  let [companyName, setCompanyName] = useState("");
  let [jt, setJt] = useState("");
  let [d, setD] = useState(1);
  let [m, setM] = useState(1);
  let [originalYearStart, setOriginalYearStart] = useState("D01M01");
  let [originalJt, setOriginalJt] = useState("");
  let [startDateInput, setStartDateInput] = useState("");
  let [originalStartDateInput, setOriginalStartDateInput] = useState("");
  let [minTs, setMinTs] = useState(0);
  let [periodStartDs, setPeriodStartDs] = useState(0);
  let [beforeToday, setBeforeToday] = useState(false);

  let [hide3, setHide3] = useState(false);

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={greenTick}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={info}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={download}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={popup}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteGo}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={allowArr}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 6) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [dataLoaded, imagesLoaded]);

  const inputRef = useRef(null);
  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.showPicker();
    }
  };

  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  let [fName, setFName] = useState("");
  let [lName, setLName] = useState("");
  let [userData, setUserData] = useState([]);

  useEffect(() => {
    axios
      .post(
        `${serverURL}/report-hours`,
        {
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          indiPayRoll: true,
          userIDs: [showUserPayRollModal.userID],
          startDs: showUserPayRollModal.startDs,
          endDs: showUserPayRollModal.endDs,
        },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("overlap")) {
          setDataLoaded(true);
          setShowOverlapYearStartModal(response.data.overlap);
          //   setNoData(true);
          setDataLoaded(true);
          if (loading) {
            setLoading(false);
          }
        }
        if (response.data.message === "success") {
          setDataLoaded(true);

          if (response.data.users) {
            setFName(response.data.users[0].fName);
            setLName(response.data.users[0].lName);

            let shTitles = [];
            let otTitles = [];
            let leaveTitles = [];
            let absTitles = [];

            setUserData(response.data.users[0].userTitleData);
          }

          //   if (response.data.users.length === 0) {
          //     setNoData(true);
          //   } else {
          //     setNoData(false);
          //   }
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  // master return
  return (
    <div
      className="clockOnModalUnderlay underlayInstall"
      onClick={() => {
        closeModal();
      }}
    >
      <div
        className={`userCheckListModal ${
          memoVals.mob ? "userCheckListModalMob" : ""
        } ${
          memoVals.mobModal ? "mobModalShoulder slideCheckUp" : "zoomIn"
        } rightAuto paddingBottom0 clientCheckListModal ${
          memoVals.mobModal ? "userpayRollModalMobile" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        ref={modalRef}
        style={memoVals.mobModal ? modalPosition : null}
      >
        {memoVals.mobModal && (
          <div className="modalSwiper modalSwiperBulkLimits"></div>
        )}
        <div
          className={`userCheckListHeader ${
            memoVals.mobModal ? "userCheckListHeaderMobModal" : ""
          }`}
          onTouchStart={handleDown}
          onTouchMove={memoVals.mobModal ? handleMove : null}
          onTouchEnd={memoVals.mobModal ? handleUp : null}
          onMouseDown={memoVals.mobModal ? handleDown : null}
          onMouseMove={memoVals.mobModal ? handleMove : null}
          onMouseUp={memoVals.mobModal ? handleUp : null}
        >
          <img
            src={close}
            alt="Close checklist"
            className="closeCheckListImg"
            onClick={() => {
              closeModal();
            }}
          />
          <p className={`userCheckListTitle ${loading ? "none" : ""}`}>
            Payroll for {fName}
          </p>
        </div>
        <p className={`userPayRollInfoTxt ${loading ? "invis" : ""}`}>
          Use the data below to determine how much {fName} is owed for the given
          period. Ensure that {dateStringer.possession(fName)} pay data is
          correct in their user profile settings.
        </p>

        {!loading ? (
          <div
            className={`clientCheckDiv payRollUserDiv ${
              memoVals.mobModal ? "payRollUserDivMob" : ""
            }`}
          >
            {userData.map((x, i) => {
              let sub;
              if (x.title === "Net pay") {
                sub = `The net amount owed to ${fName} taking into account their
                    salary with any deductions and additions.`;
              }
              if (x.title === "Gross salary") {
                sub = `${dateStringer.possession(
                  fName
                )} yearly salary pro-rated to this pay period before deductions / additions (if ${fName} is paid on a salaried basis).`;
              }
              if (x.title === "Gross non-salary") {
                sub = `If ${fName} is paid on an hourly basis, this figure is calculated from shifts, overtime, paid leave and paid absence. If they are paid on a salaried basis, this figure is made up from the hours from overtime only. `;
              }
              if (x.title === "Salary deductions") {
                sub = `Any unpaid absence, unpaid leave and unpaid lateness. This figure is deducted from ${dateStringer.possession(
                  fName
                )} salary. For salaried staff only. `;
              }
              if (x.title === "Overtime pay") {
                sub = `Hours worked as overtime that is paid for (and not added to ${dateStringer.possession(
                  fName
                )} TOIL balance).`;
              }
              if (x.title === "Total hours") {
                sub = `${dateStringer.possession(
                  fName
                )} total hours payable. If ${fName} is salaried, shifts (including overtime) are valued at their hourly rate based on their annual salary. If salaried, this figure should be not be used to determine how much they are owed (use the 'net pay' above instead).`;
              }
              if (x.title === "Total regular hours") {
                sub = `${dateStringer.possession(
                  fName
                )} hours payable from shifts only (excluding overtime, paid leave, and paid absence).`;
              }
              if (x.dynamic && x.title.includes("Regular")) {
                sub = `${dateStringer.possession(
                  fName
                )} hours payable from shifts that are paid at a rate of ${
                  x.rate
                }x.`;
              }
              if (x.title === "Total overtime hours") {
                sub = `${dateStringer.possession(
                  fName
                )} hours payable from overtime only.`;
              }
              if (x.dynamic && x.overtime) {
                sub = `${dateStringer.possession(
                  fName
                )} hours payable from overtime that is paid at a rate of ${
                  x.rate
                }x.`;
              }
              if (x.title === "Day hours") {
                sub = `${dateStringer.possession(
                  fName
                )} hours payable from shifts (& overtime) that are not paid at the night rate.`;
              }
              if (x.title === "Night hours") {
                sub = `${dateStringer.possession(
                  fName
                )} hours payable from shifts (& overtime) that are paid at the night rate.`;
              }
              if (x.title === "TOIL earned") {
                sub = `${dateStringer.possession(
                  fName
                )} hours from unpaid overtime that can be used to paid take time off.`;
              }
              if (x.title === "TOIL used") {
                sub = `${dateStringer.possession(
                  fName
                )} total amount of time off taken using their TOIL balance.`;
              }
              if (x.title === "Total leave") {
                sub = `${dateStringer.possession(
                  fName
                )} total leave from annual leave and other leave types - excluding absence.`;
              }
              if (x.title === "Paid leave") {
                sub = `${dateStringer.possession(
                  fName
                )} total leave from paid leave types (includes annual leave).`;
              }
              if (x.title === "Annual leave") {
                sub = `${dateStringer.possession(
                  fName
                )} total paid leave taken using their annual leave allowance.`;
              }
              if (x.title === "Contracted leave") {
                sub = `${dateStringer.possession(
                  fName
                )} total leave payable based on their contracted hours per week at the time of the leave period.`;
              }
              if (x.title === "Overtime leave") {
                sub = (
                  <>
                    Paid leave accrued from overtime (hours worked in excess of
                    {dateStringer.possession(fName)} contracted hours) across
                    the reference period.{" "}
                    <a
                      className="payRollLinkInfo"
                      target="_blank"
                      href="https://flowrota.com/support/pay-accrued-overtime-leave"
                    >
                      More details here
                    </a>
                    . If enabled, accrued overtime leave is paid within leave
                    periods as a supplementary amount.
                  </>
                );
              }
              if (x.title === "Unpaid leave") {
                sub = `${dateStringer.possession(
                  fName
                )} total leave taken on an unpaid basis (excludes unpaid absence).`;
              }
              if (x.title === "Total absence") {
                sub = `${dateStringer.possession(
                  fName
                )} combined absence from lateness, shifts (partially & wholly) and absent periods.`;
              }

              if (x.title === "Total paid absence") {
                sub = `${dateStringer.possession(
                  fName
                )} combined paid absence from paid lateness, paid shifts (partially & wholly) and paid absent periods.`;
              }
              if (x.title === "Total unpaid absence") {
                sub = `${dateStringer.possession(
                  fName
                )} combined unpaid absence from unpaid lateness, unpaid absent shifts (partially & wholly) and unpaid absent periods.`;
              }
              if (x.title === "Absence periods") {
                sub = `${dateStringer.possession(
                  fName
                )} hours from absence periods only (blocks of absence - excludes lateness and absent shifts).`;
              }
              if (x.title === "Shift absence") {
                sub = `${dateStringer.possession(
                  fName
                )} absence from wholly and partially absent shifts.`;
              }
              if (x.title === "Late starts") {
                sub = `The number of times ${fName} was late for a shift.`;
              }
              if (x.title === "Total late duration") {
                sub = `The total duration of ${dateStringer.possession(
                  fName
                )} late starts.`;
              }

              if (x.title === "Paid late duration") {
                sub = `The paid duration of ${dateStringer.possession(
                  fName
                )} late starts.`;
              }

              if (x.title === "Unpaid late duration") {
                sub = `The unpaid duration of ${dateStringer.possession(
                  fName
                )} late starts.`;
              }
              if (x.title === "Absence quantity") {
                sub = `The number of instances ${fName} was absent - includes absent periods, absent shifts and late starts.`;
              }
              if (x.dynamic && x.absence) {
                sub = `${dateStringer.possession(
                  fName
                )} absence duration under the absence type of '${x.title}'.`;
              }
              if (x.dynamic && x.leave) {
                sub = `${dateStringer.possession(
                  fName
                )} duration of time off taken using their '${x.title}' ${
                  x.title.includes("leave") ? "" : "leave "
                } allowance.`;
              }
              if (x.title === "Unpaid absence") {
                sub = `${dateStringer.possession(
                  fName
                )} unpaid absence - includes unpaid absent periods, unpaid shift absence and unpaid late starts.`;
              }

              return (
                <div
                  className={`userPayRollRow ${
                    i === userData.length - 1 ? "noBorder" : ""
                  } ${
                    x.title === "Net pay" ||
                    x.title === "Salary deductions" ||
                    x.title === "Night hours" ||
                    x.title === "Total leave" ||
                    x.title === "Unpaid leave" ||
                    x.title === "Total absence" ||
                    x.title === "Overtime pay"
                      ? "netPayBorder"
                      : ""
                  } ${
                    x.title === "Total leave" || x.title === "Day hours"
                      ? "netPayBorderTop"
                      : ""
                  }`}
                  key={i}
                >
                  <div className="userPayRollRowLeft">
                    <p
                      className="userPayRollRowTitle"
                      onClick={() => {
                        console.log({ x });
                      }}
                    >
                      {x.title}{" "}
                    </p>
                    {infoArr.includes(i) ? (
                      <p className="userPayRollRowTitleSub">{sub}</p>
                    ) : (
                      <p
                        className="payRollInfoTxtBtn"
                        onClick={() => {
                          setInfoArr([...infoArr, i]);
                        }}
                      >
                        Info
                      </p>
                      //   <img
                      //     src={info}
                      //     alt="Explain payroll item"
                      //     className={`payRollInfoIcon ${
                      //       infoArr.includes(i) ? "invis" : ""
                      //     }`}
                      //     onClick={() => {
                      //       setInfoArr([...infoArr, i]);
                      //     }}
                      //   />
                    )}
                  </div>
                  <div className="userPayRollRowRight">
                    <p className="userPayRollRowValue">
                      {x.cost
                        ? x.cost === 0 || isNaN(parseFloat(x.cost))
                          ? "-"
                          : `${
                              memoVals.currencySymbol
                            }${dateStringer.formatCost(x.cost)}`
                        : x.money
                        ? `${memoVals.currencySymbol}${dateStringer.formatCost(
                            x.value
                          )}`
                        : ""}
                    </p>
                    <p className="userPayRollRowValueSub">
                      {typeof x.value === 0
                        ? "-"
                        : x.hours
                        ? dateStringer.formatMinsDurationToHours(x.value)
                        : "-"}
                    </p>
                  </div>
                </div>
              );
            })}
            {/* end of new row */}
          </div>
        ) : (
          <div
            className={`clientCheckDiv payRollUserDiv ${
              !memoVals.mobModal ? "clientCheckDivDesktop" : ""
            }`}
          >
            <img
              src={horizontalBalls}
              alt="Loading"
              className={`checklistLoadImg ${
                memoVals.mobModal ? "checklistLoadImgMobModdal" : ""
              }`}
            />
          </div>
        )}
        <div className="closedDaysTimelineFooter paddingLeft4pc">
          <div
            className={`editUserMessageBtn unarchBtn reportCsvBtn reportCsvBtnDesktop ${
              loading ? "invis" : ""
            } ${disableDownload ? "disabled disablePointerEvents" : ""}`}
            onClick={() => {
              setDisableDownload(true);
              axios
                .post(
                  `${serverURL}/report-hours`,
                  {
                    csv: true,
                    nowDs: dateStringer.createStringFromTimestamp(
                      new Date().getTime()
                    ),
                    indiPayRoll: true,
                    userIDs: [showUserPayRollModal.userID],
                    startDs: showUserPayRollModal.startDs,
                    endDs: showUserPayRollModal.endDs,
                  },
                  {
                    responseType: "blob",
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  // Create a Blob from the response data
                  const blob = new Blob([response.data], {
                    type: "text/csv",
                  });

                  // Create a URL for the Blob
                  const url = window.URL.createObjectURL(blob);

                  // Create a link element to trigger the download
                  const link = document.createElement("a");
                  link.href = url;
                  link.download = `flowrota_hours_pay_data_${fName}_${lName}_${dateStringer
                    .printedDateFromDs(showUserPayRollModal.startDs)
                    .toLowerCase()}_to_${dateStringer
                    .printedDateFromDs(showUserPayRollModal.endDs)
                    .toLowerCase()}_export.csv`; // Specify the filename
                  document.body.appendChild(link);

                  // Trigger the download
                  link.click();

                  // Cleanup
                  window.URL.revokeObjectURL(url);
                  document.body.removeChild(link);
                  setTimeout(() => {
                    setDisableDownload(false);
                  }, 5000);
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            Export to CSV{" "}
            <img src={download} alt="Message" className="downloadCsvImg" />
          </div>
          <p
            className="closeReqInfo x18938311123255"
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </p>
        </div>
      </div>
      {imgPreload}
    </div>
  );
};

export default UserPayRollModal;
