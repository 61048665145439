import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// cleaned wef 24 july 23
// params cleared 10 sep 23
// images preloaded 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { UserContext } from "../../../contexts/UserContext";
import { DataContext } from "../../../contexts/DataContext";
import { StyleContext } from "../../../contexts/StyleContext";

import dateStringer from "../../../tools/dateStringer";
import serverURL from "../../../serverURL";

import "../../../styles/calendar.css";

import whitecross from "../../../img/general/whitecross.svg";

const HoursDatabox = ({ modal, my, setMy }) => {
  const {
    hoursDataLoading,
    setHoursDataLoading,
    sideBoxData,
    setSideBoxData,
    setShowStats,
    availableMonths,
    setAvailableMonths,
    setModalOpen,
    device,
  } = useContext(DataContext);
  const { checkUserState, currencySymbol } = useContext(UserContext);
  const { mob, mobModal } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      hoursDataLoading,
      setHoursDataLoading,
      sideBoxData,
      setSideBoxData,
      setShowStats,
      availableMonths,
      setAvailableMonths,
      checkUserState,
      mobModal,
      mob,
      setModalOpen,
      currencySymbol,
      device,
    }),
    [
      hoursDataLoading, //
      setHoursDataLoading, //
      sideBoxData, //
      setSideBoxData, //
      setShowStats, //
      availableMonths, //
      setAvailableMonths, //
      checkUserState, //
      mobModal, //
      mob, //
      setModalOpen,
      currencySymbol,
      device,
    ]
  );
  // let [modalMy, setModalMy] = useState(my);
  let data = memoVals.sideBoxData;
  let [showYTD, setShowYTD] = useState(false);

  useEffect(() => {
    if (!memoVals.availableMonths[0]) {
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-month-year-dropdown-options`,
          { someData: 1 },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            if (!response.data.arr.includes(my)) {
              memoVals.setAvailableMonths((x) => [my, ...response.data.arr]);
            } else {
              memoVals.setAvailableMonths((x) => response.data.arr);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, []);

  useEffect(() => {
    memoVals.setModalOpen((x) => true);
  }, []);

  let getMonth = (nnnn) => {
    let m = nnnn.substr(0, 2);
    if (m === "00") {
      return "January";
    }

    if (m === "01") {
      return "February";
    }

    if (m === "02") {
      return "March";
    }

    if (m === "03") {
      return "April";
    }

    if (m === "04") {
      return "May";
    }

    if (m === "05") {
      return "June";
    }

    if (m === "06") {
      return "July";
    }

    if (m === "07") {
      return "August";
    }

    if (m === "08") {
      return "September";
    }

    if (m === "09") {
      return "October";
    }

    if (m === "10") {
      return "November";
    }

    if (m === "11") {
      return "December";
    }
  };

  useEffect(() => {
    // clean not needed
    if (memoVals.sideBoxData.data) {
      memoVals.setHoursDataLoading((x) => false);
    } else {
      memoVals.setHoursDataLoading((x) => false);
    }
  }, [memoVals.sideBoxData]);

  useEffect(() => {
    // clean not needed
    if (modal) {
      memoVals.setHoursDataLoading((x) => false);
    }
  }, [memoVals.sideBoxData]);

  useEffect(() => {
    // clean not needed
    setShowYTD(false);
  }, [my]);

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      memoVals.setShowStats((x) => false);
      memoVals.setModalOpen((x) => false);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let generateMonthOptions = memoVals.availableMonths.map((month) => {
    let formatMonth = "Jan";
    let formatYear = month.substr(2, 2);
    if (month.substr(0, 2) === "00") {
      formatMonth = "January";
    }
    if (month.substr(0, 2) === "01") {
      formatMonth = "February";
    }
    if (month.substr(0, 2) === "02") {
      formatMonth = "March";
    }
    if (month.substr(0, 2) === "03") {
      formatMonth = "April";
    }
    if (month.substr(0, 2) === "04") {
      formatMonth = "May";
    }
    if (month.substr(0, 2) === "05") {
      formatMonth = "June";
    }
    if (month.substr(0, 2) === "06") {
      formatMonth = "July";
    }
    if (month.substr(0, 2) === "07") {
      formatMonth = "August";
    }
    if (month.substr(0, 2) === "08") {
      formatMonth = "September";
    }
    if (month.substr(0, 2) === "09") {
      formatMonth = "October";
    }
    if (month.substr(0, 2) === "10") {
      formatMonth = "November";
    }
    if (month.substr(0, 2) === "11") {
      formatMonth = "December";
    }

    return (
      <option key={month} value={month}>
        {formatMonth} {formatYear}
      </option>
    );
  });

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      memoVals.setShowStats((x) => false);
      memoVals.setModalOpen((x) => false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };
  // master return

  return (
    <div
      className={`desktopDataBox ${
        modal
          ? `desktopDataBoxNoMargin ${
              memoVals.device.ios ? "paddingBottom20" : ""
            }`
          : ""
      }`}
      style={modalPosition}
      ref={modalRef}
      onTouchStart={handleDown}
      onTouchMove={window.innerWidth <= 550 ? handleMove : null}
      onTouchEnd={window.innerWidth <= 550 ? handleUp : null}
      onMouseDown={window.innerWidth <= 550 ? handleDown : null}
      onMouseMove={window.innerWidth <= 550 ? handleMove : null}
      onMouseUp={window.innerWidth <= 550 ? handleUp : null}
    >
      <div
        className={`${
          modal
            ? `showMyToggler ${!memoVals.mobModal ? "x198349811" : ""}`
            : "none"
        }`}
        onClick={() => {
          if (modal) {
            memoVals.setModalOpen((x) => false);
            memoVals.setShowStats((x) => false);
          }
        }}
      >
        <img
          src={whitecross}
          alt="Close"
          className="closeStatsModalCrossImg"
          onClick={() => {
            memoVals.setShowStats((x) => false);
            memoVals.setModalOpen((x) => false);
          }}
        />
        <p className="myHoursDataBoxTitle">My hours statistics</p>
      </div>
      {window.innerWidth <= 550 && (
        <div className="modalSwiper modalSwiperMyHoursDataBox"></div>
      )}
      <div className="desktopDataBoxHeader">
        {!showYTD ? (
          <select
            value={my}
            className="shiftsTilMonthDropdown2"
            onChange={(e) => {
              setMy(e.target.value);

              memoVals.setHoursDataLoading((x) => true);
              axios
                .post(
                  `${serverURL}/get-my-hours-data`,
                  {
                    my: e.target.value,
                    ytd: false,
                    nowDs: dateStringer.createStringFromTimestamp(
                      new Date().getTime()
                    ),
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    console.log(response.data.data);
                    memoVals.setSideBoxData((x) => response.data.data);
                    memoVals.setHoursDataLoading((x) => false);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            {generateMonthOptions}
          </select>
        ) : (
          <span
            className={`dataBoxHeading2 ${
              memoVals.mob ? "txtFontSize16px_" : ""
            } ${showYTD ? "none" : ""}`}
          >
            {" "}
            {dateStringer.printMonthFromMy(my.substr(0, 2))} '{my.substr(2, 2)}{" "}
          </span>
        )}

        <span className={`${showYTD ? "dataBoxHeading2" : "none"} `}>
          {" "}
          Year-to-date data
        </span>
        <div
          className={`dataBoxYTDbtn  ${memoVals.mob ? "txtFontSize16px_" : ""}`}
          onClick={() => {
            memoVals.setHoursDataLoading((x) => true);
            setShowYTD(!showYTD);
            axios
              .post(
                `${serverURL}/get-my-hours-data`,
                {
                  my: showYTD ? my : false,
                  ytdNowDs: showYTD
                    ? false
                    : dateStringer.createStringFromTimestamp(
                        new Date().getTime()
                      ),
                  nowDs: dateStringer.createStringFromTimestamp(
                    new Date().getTime()
                  ),
                },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((response) => {
                if (response.data.message === "success") {
                  console.log(response.data.data);
                  memoVals.setSideBoxData((x) => response.data.data);
                  memoVals.setHoursDataLoading((x) => false);
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        >
          {showYTD ? "Month" : "Year to date"}{" "}
          {/* <span className="ytdTri">▸</span> */}
        </div>
      </div>
      <div
        className={`desktopDataRow desktopRowThickBorder ${
          memoVals.mob ? "mobHoursBarHeight" : ""
        }`}
      >
        <p
          className={`desktopDataTitle colorWhite boldDataVal ${
            memoVals.mob ? "txtFontSize16px_" : ""
          } colourNavy_`}
        >
          {showYTD ? "" : "Total "}
          {showYTD ? (
            memoVals.checkUserState.currClientYear
          ) : (
            <span className="x08282">{getMonth(my)}</span>
          )}{" "}
          hours {showYTD ? "to date" : ""}
        </p>
        {!memoVals.hoursDataLoading ? (
          <p
            className={`desktopDataValue boldDataVal  ${
              memoVals.mob ? "txtFontSize16px_" : ""
            }`}
          >
            {dateStringer.formatMinsDurationToHours(data.totalMins || 0)}
          </p>
        ) : (
          ""
        )}
        <p
          className={`${
            memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
          }`}
        >
          -
        </p>
      </div>
      <div className="desktopDataRow paddingR8px">
        <p
          className={`desktopDataTitle payableExpandedTitle2 ${
            memoVals.mob ? "txtFontSize16px_" : ""
          }`}
          onClick={() => {
            console.log({ my });
          }}
        >
          Regular hours
          {showYTD ? " to date" : ` in ${getMonth(my).substr(0, 3)}`}
        </p>
        <p
          className={`${
            memoVals.hoursDataLoading ? "none" : ""
          } desktopDataValue boldDataVal  ${
            memoVals.mob ? "txtFontSize16px_" : ""
          }`}
        >
          {dateStringer.formatMinsDurationToHours(data.regularMins)}
        </p>{" "}
        <p
          className={`${
            memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
          }`}
        >
          -
        </p>
      </div>
      <div className="desktopDataRow paddingR8px">
        <p
          className={`desktopDataTitle payableExpandedTitle2 ${
            memoVals.mob ? "txtFontSize16px_" : ""
          }`}
        >
          Overtime hours
          {showYTD ? " to date" : ` in ${getMonth(my).substr(0, 3)}`}
        </p>
        <p
          className={`${
            memoVals.hoursDataLoading ? "none" : ""
          } desktopDataValue boldDataVal  ${
            memoVals.mob ? "txtFontSize16px_" : ""
          }`}
        >
          {" "}
          {dateStringer.formatMinsDurationToHours(data.overtimeMins)}
        </p>{" "}
        <p
          className={`${
            memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
          }`}
        >
          -
        </p>
      </div>

      <div className="desktopDataRow paddingR8px">
        <p
          className={`desktopDataTitle payableExpandedTitle2 ${
            memoVals.mob ? "txtFontSize16px_" : ""
          }`}
        >
          Absence
          {showYTD ? " to date" : ` in ${getMonth(my).substr(0, 3)}`}
        </p>
        <p
          className={`${
            memoVals.hoursDataLoading ? "none" : ""
          } desktopDataValue boldDataVal ${
            memoVals.mob ? "txtFontSize16px_" : ""
          }`}
        >
          {" "}
          {dateStringer.formatMinsDurationToHours(data.absenceMins)}
        </p>{" "}
        <p
          className={`${
            memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
          }`}
        >
          -
        </p>
      </div>
      <div className="desktopDataRow paddingR8px">
        <p
          className={`desktopDataTitle payableExpandedTitle2 ${
            memoVals.mob ? "txtFontSize16px_" : ""
          }`}
        >
          Leave{showYTD ? " to date" : ` in ${getMonth(my).substr(0, 3)}`}
        </p>
        <p
          className={`${
            memoVals.hoursDataLoading ? "none" : ""
          } desktopDataValue boldDataVal  ${
            memoVals.mob ? "txtFontSize16px_" : ""
          }`}
        >
          {" "}
          {dateStringer.formatMinsDurationToHours(data.leaveMins)}
        </p>{" "}
        <p
          className={`${
            memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
          }`}
        >
          -
        </p>
      </div>
      <div className="desktopDataRow noBorder paddingR8px">
        <div className="netPayHoursDivRow">
          <p
            className={`desktopDataTitle payableExpandedTitle2 ${
              memoVals.mob ? "txtFontSize16px_" : ""
            }`}
          >
            Net pay*
          </p>
          <div className="usrPayAsterixSalaryInfo x32189824211">
            *Includes salary, overtime, unpaid deductions & paid leave.
          </div>
        </div>
        <p
          className={`${
            memoVals.hoursDataLoading ? "none" : ""
          } desktopDataValue boldDataVal  ${
            memoVals.mob ? "txtFontSize16px_" : ""
          }`}
        >
          {" "}
          {memoVals.currencySymbol}
          {dateStringer.formatCost(isNaN(data.netPay) ? 0 : data.netPay)}
        </p>{" "}
        <p
          className={`${
            memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
          }`}
        >
          -
        </p>
      </div>
      {modal ? (
        <div className="mgrAllowsFooter">
          <p></p>{" "}
          <p
            className={`closeEditShiftBtn x4289489231 `}
            onClick={() => {
              memoVals.setShowStats((x) => false);
              memoVals.setModalOpen((x) => false);
            }}
          >
            Close
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default HoursDatabox;
