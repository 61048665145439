import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 8 sep 24
import axios from "axios";
import { CalendarContext } from "../contexts/CalendarContext";
import { UserContext } from "../contexts/UserContext";
import { StyleContext } from "../contexts/StyleContext";
import { DataContext } from "../contexts/DataContext";

import dateStringer from "../tools/dateStringer";
import serverURL from "../serverURL";

import navyClose from "../img/general/navyClose.svg";
import whitebin from "../img/general/whitebin.svg";
import edit from "../img/general/edit.svg";

import tickWhite from "../img/general/tickWhite.svg";

import blueLoader from "../img/general/blueLoader.gif";

import ManagerShiftModal from "../components/calendar/shifts/ManagerShiftModal";

import "../styles/calendar.css";
import { create } from "domain";

const FormModal = () => {
  const {
    setIndicate,
    showForm,
    setShowForm,
    setPushForm,
    setShowUserReqFromNav,
    setShowMyShiftModal,
    setShowReqMgrModal,
    modalOpen,
    setModalOpen,
    device,
  } = useContext(DataContext);
  const { mobModal } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setIndicate,
      showForm,
      setShowForm,
      setPushForm,
      setShowUserReqFromNav,
      setShowMyShiftModal,
      setShowReqMgrModal,
      mobModal,
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      setIndicate, //
      showForm, //
      setShowForm, //
      setPushForm, //
      setShowUserReqFromNav, //
      setShowMyShiftModal, //
      setShowReqMgrModal, //
      mobModal,
      modalOpen,
      setModalOpen,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [loaded, setLoaded] = useState(false);
  let [saveLoading, setSaveLoading] = useState(false);

  let shortenAbsName = (val) => {
    if (val) {
      if (val.length > 13) {
        return `${val.substr(0, 11)}...`;
      } else {
        return val;
      }
    }
  };
  let [page, setPage] = useState(1);
  let [title, setTitle] = useState("");
  let [items, setItems] = useState([]);
  let [note, setNote] = useState("");
  let [completedDs, setCompletedDs] = useState("");
  let [questions, setQuestions] = useState([]);
  let [answer0, setAnswer0] = useState("");
  let [answer1, setAnswer1] = useState("");
  let [answer2, setAnswer2] = useState("");
  let [answer3, setAnswer3] = useState("");
  let [answer4, setAnswer4] = useState("");
  let [answer5, setAnswer5] = useState("");
  let [answer6, setAnswer6] = useState("");
  let [answer7, setAnswer7] = useState("");
  let [answer8, setAnswer8] = useState("");
  let [answer9, setAnswer9] = useState("");
  let [answer10, setAnswer10] = useState("");
  let [answer11, setAnswer11] = useState("");
  let [answer12, setAnswer12] = useState("");
  let [answer13, setAnswer13] = useState("");
  let [answer14, setAnswer14] = useState("");
  let [answer15, setAnswer15] = useState("");
  let [answer16, setAnswer16] = useState("");
  let [answer17, setAnswer17] = useState("");
  let [answer18, setAnswer18] = useState("");
  let [answer19, setAnswer19] = useState("");
  let [answer20, setAnswer20] = useState("");
  let [answer21, setAnswer21] = useState("");
  let [answer22, setAnswer22] = useState("");
  let [answer23, setAnswer23] = useState("");
  let [answer24, setAnswer24] = useState("");
  let [answer25, setAnswer25] = useState("");
  let [answer26, setAnswer26] = useState("");
  let [answer27, setAnswer27] = useState("");
  let [answer28, setAnswer28] = useState("");
  let [answer29, setAnswer29] = useState("");

  let [showSignOff, setShowSignOff] = useState(false);

  let [showIncompleteModal, setShowIncompleteModal] = useState(0);
  let [sureDelete, setSureDelete] = useState(false);
  let [responses, setResponses] = useState([]);
  let [issuedBy, setIssuedBy] = useState("");
  let [createdDs, setCreatedDs] = useState("");
  let [signedByMgr, setSignedbyMgr] = useState("");

  let [fName, setFName] = useState("");
  let [lName, setLName] = useState("");
  let [showEditShiftModal, setShowEditShiftModal] = useState(false);
  let [itemType, setItemType] = useState("");
  let [itemID, setItemID] = useState("");

  let renderEditShiftModal = () => {
    if (showEditShiftModal) {
      if (itemType !== "shift") {
      }

      return (
        <ManagerShiftModal
          getFromServerID={itemID}
          unitType={itemType}
          setShowEditShiftModalParent={setShowEditShiftModal}

          // unitFName={itemFName}
          // unitLName={itemLName}
        />
      );
    }
  };

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (memoVals.showForm.formID) {
      axios
        .post(
          `${serverURL}/get-user-form`,
          {
            formID: memoVals.showForm.formID,
            isMgr: memoVals.showForm.isMgr,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            console.log(response.data);
            setTitle(response.data.form.title || " ");
            setItems(response.data.form.items || []);
            setNote(response.data.form.info || "");
            setCompletedDs(response.data.form.completedDs);
            setQuestions(response.data.form.questions || []);
            setIssuedBy(response.data.createdByName || "");
            setCreatedDs(response.data.form.createdDs);
            setLoaded(true);
            setResponses(response.data.form.response || []);
            setSignedbyMgr(response.data.signedByName);
            setFName(response.data.fName);
            setLName(response.data.lName);
            if (
              !response.data.signedByName &&
              response.data.form.response &&
              response.data.form.response[0]
            ) {
              setShowSignOff(true);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let closeModal = () => {
    setShowForm((x) => false);
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };
  // mster return
  return (
    <div
      className="bulkEditLimitUnderlay"
      onClick={() => {
        closeModal();
      }}
    >
      {loaded ? (
        <div
          style={modalPosition}
          ref={modalRef}
          className={`addLeaveModalBox ${
            memoVals.mobModal
              ? "addLeaveModalBoxMob mobModalShoulder slideUp"
              : "zoomIn"
          } ${memoVals.device.ios ? "paddingBottom20" : ""} ${
            memoVals.showForm.isMgr ? "mgrModal" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {memoVals.mobModal && (
            <div className="modalSwiper modalSwiperFormModal"></div>
          )}
          <div
            className="addLeaveModalBoxHeader"
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
            onClick={() => {
              if (memoVals.mobModal) {
                closeModal();
              }
            }}
          >
            <img
              src={navyClose}
              alt="Close"
              className="addLeaveModalHeaderboxCloseImg"
              onClick={() => {
                closeModal();
              }}
            />
            <p className="formHeaderTitle" onClick={() => {}}>
              {memoVals.showForm.isMgr
                ? `${fName}${
                    fName[fName.length - 1] === "s" ? "'" : "'s"
                  } absence form`
                : "Absence form"}
            </p>
            <img
              src={navyClose}
              alt="Close"
              className="addLeaveModalHeaderboxCloseImg invis"
            />{" "}
          </div>
          <div
            className={`formKey ${memoVals.showForm.isMgr ? "mgrFormKey" : ""}`}
          >
            <div
              className={`formKeyTab ${
                memoVals.showForm.isMgr && page === 1 ? "purpleFormTab" : ""
              } 
              ${page === 2 ? "formKeyTabUnselected" : "formKeyTabUnselected"}
              `}
              onClick={() => {
                setPage(1);
              }}
            >
              <p
                className={`formTabTitle ${
                  page === 1 ? "formTabTitleSel" : ""
                }`}
              >
                Form details
              </p>
              <div className={`formTabSel ${page === 2 ? "invis" : ""}`}></div>
            </div>
            <p
              className={`formKeyTab  ${
                memoVals.showForm.isMgr && page === 2 ? "purpleFormTab" : ""
              } ${
                page === 1 ? "formKeyTabUnselected" : "formKeyTabUnselected"
              } ${
                page === 1 && !memoVals.showForm.isMgr && !completedDs
                  ? "respHighlight"
                  : ""
              }`}
              onClick={() => {
                setPage(2);
              }}
            >
              {" "}
              <p
                className={`formTabTitle ${
                  page === 2 ? "formTabTitleSel" : ""
                }`}
              >
                {!completedDs
                  ? memoVals.showForm.isMgr
                    ? "Questions"
                    : "Respond"
                  : "Response"}{" "}
                <span
                  className={`formPageQty ${
                    completedDs ? "formPageQtyFilledIn" : ""
                  }`}
                >
                  {completedDs ? responses.length : questions.length}
                </span>
              </p>
              <div className={`formTabSel ${page === 1 ? "invis" : ""}`}></div>
            </p>
          </div>

          <div
            className={`editShiftModalBody ${
              !memoVals.mobModal ? "desktopAddLeaveModalBody formRespBody" : ""
            } ${page === 2 ? "editShiftModalBodyPage2" : ""}`}
          >
            {" "}
            {title && page === 1 ? (
              <div className={`addLeaveDatesRow`}>
                <p className="addLeaveDatesTitle">Title</p>
                <p className="formNote">{title}</p>
              </div>
            ) : (
              ""
            )}
            {fName && lName && page === 1 ? (
              <div className={`addLeaveDatesRow`}>
                <p className="addLeaveDatesTitle">Employee</p>
                <p className="formNote">
                  {fName} {lName}
                </p>
              </div>
            ) : (
              ""
            )}
            {page === 1 ? (
              <div className={`addLeaveDatesRow`}>
                <p className="addLeaveDatesTitle">Absent shifts and periods</p>
                <div className="formItemsDivMyForm">
                  {items[0] ? (
                    items.map((item) => {
                      return (
                        <div
                          className={`addAbsItemUnitForm2 ${
                            item.type === "shift"
                              ? "extendBlueLeftBorder"
                              : item.type === "period"
                              ? "extendPurpleLeftBorder"
                              : "extendGreyLeftBorder"
                          }`}
                          onClick={() => {
                            if (memoVals.showForm.isMgr) {
                              if (item.type === "period") {
                                memoVals.setShowReqMgrModal((x) => item.reqID);
                              } else {
                                setItemType(item.type);
                                setItemID(
                                  item.type === "shift"
                                    ? item.shiftID
                                    : item.tilID
                                );
                                setShowEditShiftModal(true);
                              }
                            } else {
                              if (item.type === "period") {
                                memoVals.setShowUserReqFromNav(
                                  (x) => item.reqID
                                );
                              } else {
                                memoVals.setShowMyShiftModal((x) => {
                                  return {
                                    unitType:
                                      item.type === "shift" ? "shift" : "til",
                                    unitID:
                                      item.type === "shift"
                                        ? item.shiftID
                                        : item.tilID,
                                  };
                                });
                              }
                            }
                          }}
                        >
                          <div className="addAbsItemLeft">
                            <p className={`addAbsDate2`}>
                              {dateStringer.printedDateWithYrFromDWithoutDay(
                                item.startDs
                              )}{" "}
                              {item.endDs.substr(0, 11) !==
                              item.startDs.substr(0, 11)
                                ? `- ${dateStringer.printedDateWithYrFromDWithoutDay(
                                    item.endDs
                                  )}`
                                : ""}
                            </p>
                            <p className="addAbsPeriod2">
                              {item.type === "period"
                                ? `${item.days} day${
                                    item.days === 1 ? "" : "s"
                                  }`
                                : `${dateStringer.dsToTimeStrip(
                                    item.startDs
                                  )} - ${dateStringer.dsToTimeStrip(
                                    item.endDs
                                  )}`}
                            </p>
                          </div>
                          <div className="addAbsItemRight">
                            <p className={`addAbsTitle2  `}>
                              {item.type === "shift"
                                ? "Shift"
                                : item.type === "til"
                                ? "Time in Lieu"
                                : item.type === "overtime"
                                ? "Overtime"
                                : "Period"}
                            </p>
                            <p className="addAbsName2">
                              {item.type === "period"
                                ? shortenAbsName(item.absenceName)
                                : dateStringer.formatMinsDurationToHours(
                                    item.mins
                                  )}
                            </p>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="noFormItems">
                      No items of absence attached to this form
                    </p>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {renderEditShiftModal()}
            {issuedBy && createdDs && page === 1 ? (
              <div className={`addLeaveDatesRow ${!note ? "noBorder" : ""}`}>
                {/* <p className="addLeaveDatesTitle">Issued</p> */}
                <p className="formNote issuedByForm">
                  Issued by {issuedBy} on <br />
                  {dateStringer.printedDateWithYrFromDWithoutDay(createdDs)}
                </p>
              </div>
            ) : (
              ""
            )}
            {page === 1 && !memoVals.showForm.isMgr && !completedDs ? (
              <div
                className="completeFormNowBtn blueColours"
                onClick={() => {
                  setPage(2);
                }}
              >
                Complete form now{" "}
                <img src={edit} alt="Form" className="competeFormNowImg" />
              </div>
            ) : (
              ""
            )}
            {note && page === 1 ? (
              <div className={`addLeaveDatesRow noBorder`}>
                <p className="addLeaveDatesTitle">Form note</p>
                <p className="formNote">{note}</p>
              </div>
            ) : (
              ""
            )}
            {page === 2 ? (
              <div className={`addLeaveDatesRow noBorder`}>
                <p
                  className="addLeaveDatesTitle"
                  onClick={() => {
                    console.log(responses);
                  }}
                >
                  Form {completedDs ? "responses" : "questions"}
                </p>

                {questions && questions[0] && !completedDs ? (
                  !completedDs && memoVals.showForm.isMgr ? (
                    questions.map((q, i) => {
                      return (
                        <div className="respUnit respUnit2">
                          <p className="responseQ">
                            <span className="qIndex">{i + 1}.</span>
                            {"  "}
                            {q.question}
                          </p>
                          {/* <p className="responseA">{resp.answer}</p> */}
                        </div>
                      );
                    })
                  ) : (
                    <div className="questionsContainer">
                      {questions.map((q, i) => {
                        let val = answer0;
                        if (i === 1) {
                          val = answer1;
                        }
                        if (i === 2) {
                          val = answer2;
                        }
                        if (i === 3) {
                          val = answer3;
                        }
                        if (i === 4) {
                          val = answer4;
                        }
                        if (i === 5) {
                          val = answer5;
                        }
                        if (i === 6) {
                          val = answer6;
                        }

                        if (i === 7) {
                          val = answer7;
                        }
                        if (i === 8) {
                          val = answer8;
                        }
                        if (i === 9) {
                          val = answer9;
                        }
                        if (i === 10) {
                          val = answer10;
                        }
                        if (i === 11) {
                          val = answer11;
                        }
                        if (i === 12) {
                          val = answer12;
                        }
                        if (i === 13) {
                          val = answer13;
                        }
                        if (i === 14) {
                          val = answer14;
                        }
                        if (i === 15) {
                          val = answer15;
                        }
                        if (i === 16) {
                          val = answer16;
                        }
                        if (i === 17) {
                          val = answer17;
                        }
                        if (i === 18) {
                          val = answer18;
                        }
                        if (i === 19) {
                          val = answer19;
                        }
                        if (i === 20) {
                          val = answer20;
                        }
                        if (i === 21) {
                          val = answer21;
                        }
                        if (i === 22) {
                          val = answer22;
                        }
                        if (i === 23) {
                          val = answer23;
                        }
                        if (i === 24) {
                          val = answer24;
                        }
                        if (i === 25) {
                          val = answer25;
                        }
                        if (i === 26) {
                          val = answer26;
                        }
                        if (i === 27) {
                          val = answer27;
                        }
                        if (i === 28) {
                          val = answer28;
                        }
                        if (i === 29) {
                          val = answer29;
                        }
                        if (i === 30) {
                          val = answer30;
                        }

                        return (
                          <div className="rtwQitemToAnswer">
                            <span className="qIndex">{i + 1}.</span>{" "}
                            {q.question}
                            <br />
                            <textarea
                              className="rtwQanswerInput"
                              value={val}
                              onChange={(e) => {
                                if (i === 0) {
                                  setAnswer0(e.target.value);
                                }
                                if (i === 1) {
                                  setAnswer1(e.target.value);
                                }
                                if (i === 2) {
                                  setAnswer2(e.target.value);
                                }
                                if (i === 3) {
                                  setAnswer3(e.target.value);
                                }
                                if (i === 4) {
                                  setAnswer4(e.target.value);
                                }
                                if (i === 5) {
                                  setAnswer5(e.target.value);
                                }
                                if (i === 6) {
                                  setAnswer6(e.target.value);
                                }

                                if (i === 7) {
                                  setAnswer7(e.target.value);
                                }
                                if (i === 8) {
                                  setAnswer8(e.target.value);
                                }
                                if (i === 9) {
                                  setAnswer9(e.target.value);
                                }
                                if (i === 10) {
                                  setAnswer10(e.target.value);
                                }
                                if (i === 11) {
                                  setAnswer11(e.target.value);
                                }
                                if (i === 12) {
                                  setAnswer12(e.target.value);
                                }
                                if (i === 13) {
                                  setAnswer13(e.target.value);
                                }
                                if (i === 14) {
                                  setAnswer14(e.target.value);
                                }
                                if (i === 15) {
                                  setAnswer15(e.target.value);
                                }
                                if (i === 16) {
                                  setAnswer16(e.target.value);
                                }
                                if (i === 17) {
                                  setAnswer17(e.target.value);
                                }
                                if (i === 18) {
                                  setAnswer18(e.target.value);
                                }
                                if (i === 19) {
                                  setAnswer19(e.target.value);
                                }
                                if (i === 20) {
                                  setAnswer20(e.target.value);
                                }
                                if (i === 21) {
                                  setAnswer21(e.target.value);
                                }
                                if (i === 22) {
                                  setAnswer22(e.target.value);
                                }
                                if (i === 23) {
                                  setAnswer23(e.target.value);
                                }
                                if (i === 24) {
                                  setAnswer24(e.target.value);
                                }
                                if (i === 25) {
                                  setAnswer25(e.target.value);
                                }
                                if (i === 26) {
                                  setAnswer26(e.target.value);
                                }
                                if (i === 27) {
                                  setAnswer27(e.target.value);
                                }
                                if (i === 28) {
                                  setAnswer28(e.target.value);
                                }
                                if (i === 29) {
                                  setAnswer29(e.target.value);
                                }
                              }}
                            ></textarea>
                          </div>
                        );
                      })}
                    </div>
                  )
                ) : (
                  responses.map((resp, i) => {
                    return (
                      <div className="respUnit">
                        <p className="responseQ">
                          <span className="qIndex">{i + 1}.</span>
                          {"  "}
                          {resp.question}
                        </p>
                        <p className="responseA">{resp.answer}</p>
                      </div>
                    );
                  })
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="formModalFooterMain spaceBetween">
            {saveLoading ? (
              <img
                src={blueLoader}
                alt="Loading"
                className="addLeaveLoadingImg"
              />
            ) : (
              ""
            )}
            <div className="absenceRTWFooterLeft">
              {showSignOff && memoVals.showForm.isMgr ? (
                <p
                  className="signOffRTWbtn mgrCtaColours_"
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/sign-off-form`,
                        {
                          formID: memoVals.showForm.formID,

                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setShowSignOff(false);
                          setSignedbyMgr(
                            `${response.data.fName} ${
                              response.data.lName && response.data.lName[0]
                            }`
                          );

                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Form signed off",
                              colour: "blue",
                              duration: 4000,
                            };
                          });

                          let mins = 0;
                          items.forEach((item) => {
                            mins += item.mins;
                          });
                          memoVals.setPushForm((x) => {
                            return {
                              isMgr: true,
                              fName,
                              lName,
                              formID: memoVals.showForm.formID,
                              questionsLength: questions.length,
                              responsesLength: responses.length,
                              title,
                              itemsLength: items.length,
                              completedDs,
                              createdDs: createdDs,
                              filledIn: true,
                              signedByMgr: true,
                              mins,
                              deleting: false,
                              updating: true,
                              creating: false,
                            };
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Sign off
                </p>
              ) : (
                ""
              )}
              {signedByMgr ? (
                <div className="formCompletedByYouDiv marginRight8">
                  Signed off by
                  <br />
                  {signedByMgr}
                </div>
              ) : (
                ""
              )}
              {completedDs ? (
                <div
                  className={`formCompletedByYouDiv ${
                    signedByMgr || showSignOff ? "filledInMarginLeft_" : ""
                  }`}
                >
                  {/* <img
                  src={tickWhite}
                  alt="Completed"
                  className="formCompByYouTick"
                /> */}
                  Filled in {memoVals.showForm.isMgr ? `on ` : "by you on "}
                  {memoVals.showForm.isMgr ? <br /> : ""}
                  {dateStringer.printedDateWithYrFromDs(completedDs)}
                </div>
              ) : answer0 ||
                answer1 ||
                answer2 ||
                answer3 ||
                answer4 ||
                answer5 ||
                answer6 ||
                answer7 ||
                answer8 ||
                answer9 ||
                answer10 ||
                answer11 ||
                answer12 ||
                answer13 ||
                answer14 ||
                answer15 ||
                answer16 ||
                answer17 ||
                answer18 ||
                answer19 ||
                answer20 ||
                answer21 ||
                answer22 ||
                answer23 ||
                answer24 ||
                answer25 ||
                answer26 ||
                answer27 ||
                answer28 ||
                answer29 ? (
                <p
                  className={`saveRtwBtn ${saveLoading ? "none" : ""}`}
                  onClick={() => {
                    let output = [];
                    let count = 0;

                    if (answer0) {
                      count++;
                      let qFilt = questions.filter((item, i) => {
                        return i === 0;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer0,
                        });
                      }
                    }

                    if (answer1) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 1;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer1,
                        });
                      }
                    }

                    if (answer2) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 2;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer2,
                        });
                      }
                    }

                    if (answer3) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 3;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer3,
                        });
                      }
                    }

                    if (answer4) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 4;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer4,
                        });
                      }
                    }

                    if (answer5) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 5;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer5,
                        });
                      }
                    }

                    if (answer6) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 6;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer6,
                        });
                      }
                    }

                    if (answer7) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 7;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer7,
                        });
                      }
                    }

                    if (answer8) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 8;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer8,
                        });
                      }
                    }

                    if (answer9) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 9;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer9,
                        });
                      }
                    }

                    if (answer10) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 10;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer10,
                        });
                      }
                    }

                    if (answer11) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 11;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer11,
                        });
                      }
                    }

                    if (answer12) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 12;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer12,
                        });
                      }
                    }

                    if (answer13) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 13;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer13,
                        });
                      }
                    }

                    if (answer14) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 14;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer14,
                        });
                      }
                    }

                    if (answer15) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 15;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer15,
                        });
                      }
                    }

                    if (answer16) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 16;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer16,
                        });
                      }
                    }

                    if (answer17) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 17;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer17,
                        });
                      }
                    }

                    if (answer18) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 18;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer18,
                        });
                      }
                    }

                    if (answer19) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 19;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer19,
                        });
                      }
                    }

                    if (answer20) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 20;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer20,
                        });
                      }
                    }

                    if (answer21) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 21;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer21,
                        });
                      }
                    }

                    if (answer22) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 22;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer22,
                        });
                      }
                    }

                    if (answer23) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 23;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer23,
                        });
                      }
                    }

                    if (answer24) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 24;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer24,
                        });
                      }
                    }

                    if (answer25) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 25;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer25,
                        });
                      }
                    }

                    if (answer26) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 26;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer26,
                        });
                      }
                    }

                    if (answer27) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 27;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer27,
                        });
                      }
                    }

                    if (answer28) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 28;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer28,
                        });
                      }
                    }

                    if (answer29) {
                      count++;

                      let qFilt = questions.filter((item, i) => {
                        return i === 29;
                      });

                      if (qFilt[0]) {
                        output.push({
                          question: qFilt[0].question,
                          questionID: qFilt[0].questionID,
                          answer: answer29,
                        });
                      }
                    }

                    {
                      signedByMgr ? (
                        <div className="formCompletedByYouDiv">
                          <img
                            src={tickWhite}
                            alt="Completed"
                            className="formCompByYouTick"
                          />
                          Signed by {signedByMgr}
                        </div>
                      ) : (
                        ""
                      );
                    }

                    console.log({ output });
                    if (count === questions.length) {
                      console.log("ALL COMPLETE");

                      axios
                        .post(
                          `${serverURL}/submit-form`,
                          {
                            formID: memoVals.showForm.formID,
                            responses: output,
                            nowDs: dateStringer.createStringFromTimestamp(
                              new Date().getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            console.log("DONE!");

                            setCompletedDs(response.data.completedDs);
                            setResponses(response.data.responses);

                            memoVals.setIndicate((x) => {
                              return {
                                show: true,
                                message: "Form completed",
                                colour: "blue",
                                duration: 4000,
                              };
                            });

                            let mins = 0;
                            items.forEach((item) => {
                              mins += item.mins;
                            });
                            memoVals.setPushForm((x) => {
                              return {
                                isMgr: false,
                                fName,
                                lName,
                                formID: memoVals.showForm.formID,
                                questionsLength: questions.length,
                                responsesLength: responses.length,
                                title,
                                itemsLength: items.length,
                                completedDs: response.data.nowDs,
                                createdDs: createdDs,
                                filledIn: true,
                                signedByMgr,
                                mins,
                                deleting: false,
                                updating: true,
                                creating: false,
                              };
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    } else {
                      console.log(
                        `${questions.length - count} questions not filled in`
                      );
                      setShowIncompleteModal(questions.length - count);
                    }
                  }}
                >
                  Submit
                </p>
              ) : (
                <p
                  className={`yetToCompleteForm ${
                    !memoVals.showForm.isMgr && page === 1
                      ? "yetToCompleteFormUsrOnly"
                      : ""
                  }`}
                  onClick={() => {
                    if (!memoVals.showForm.isMgr && page === 1) {
                      setPage(2);
                    }
                  }}
                >
                  Not yet completed
                </p>
              )}
            </div>
            <div className="formModalFooterRight">
              {memoVals.showForm.isMgr ? (
                <img
                  src={whitebin}
                  className="deleteFormBtn"
                  onClick={() => {
                    if (memoVals.showForm.isMgr) {
                      setSureDelete(true);
                    }
                  }}
                />
              ) : (
                ""
              )}

              <p
                className="myShiftModalFooterRight"
                onClick={() => {
                  closeModal();
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {sureDelete ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setShowIncompleteModal(0);
          }}
        >
          {" "}
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              Are you sure you want to
              <br />
              delete this form?
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  // axios here -- delete form
                  if (memoVals.showForm.isMgr) {
                    axios
                      .post(
                        `${serverURL}/delete-form`,
                        {
                          formID: memoVals.showForm.formID,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Form deleted",
                              colour: "blue",
                              duration: 4000,
                            };
                          });

                          memoVals.setPushForm((x) => {
                            return {
                              isMgr: true,
                              fName,
                              lName,
                              formID: memoVals.showForm.formID,
                              questionsLength: questions.length,
                              responsesLength: responses.length,
                              title,
                              itemsLength: items.length,
                              completedDs,
                              createdDs: createdDs,
                              filledIn: true,
                              signedByMgr: true,
                              mins: 0,
                              deleting: true,
                              updating: false,
                              creating: false,
                            };
                          });
                          setShowForm((x) => false);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDelete(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showIncompleteModal ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setShowIncompleteModal(0);
          }}
        >
          {" "}
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            You have only answered {questions.length - showIncompleteModal}{" "}
            question{questions.length - showIncompleteModal === 1 ? "" : "s"}{" "}
            out of {questions.length}.
            <br />
            <br />
            Answers to all questions are required.
            <p
              className="formCreatedModalClose"
              onClick={() => {
                setShowIncompleteModal(0);
              }}
            >
              Understood
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
  // } else {
  //   return "";
  // }
};

export default FormModal;
