// INSTRUCTIONS TO LOAD MODAL
// use props
// show modal with showEditMyShiftModal stored in CalendarContext

import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
// clean not needed
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import axios from "axios";
import DatePicker from "react-datepicker";

import { DataContext } from "../../../contexts/DataContext";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";

import { NavLink } from "react-router-dom";

import serverURL from "../../../serverURL";
import { StyleContext } from "../../../contexts/StyleContext";
import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

import downRightArrow2 from "../../../img/general/downRightArrow2.svg";

import close from "../../../img/modals/close.svg";
import shiftChangeArrow from "../../../img/general/shiftChangeArrow.svg";
import blueLoader from "../../../img/general/blueLoader.gif";

import dateStringer, { tsToHHMM } from "../../../tools/dateStringer";

// type = til, overtime, shift
const TilTrimModal = ({
  unitType,
  unitID,
  unitDate,
  unitShiftMins,
  unitStart,
  unitEnd,
  unitTeamID,
  unitBrkMins,
  isMgr,
  setShowTilTrimModalParent,
  userID,
  fName,
  setReload,
  reload,
  editing,
  editStart,
  editEnd,
  setSureBinTil,
  setChangesMade,
}) => {
  const { mob, mobModal } = useContext(StyleContext);

  const { setShowTilTrimModal, setUpdateTilData, updateTilData } =
    useContext(CalendarContext);
  const {
    setTilReqMade,
    tilReqMade,
    modalOpen,
    setModalOpen,
    device,
    countData,
    setCountData,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      mob,
      mobModal,
      setTilReqMade,
      tilReqMade,
      setShowTilTrimModal,
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      mob, //
      mobModal, //
      setTilReqMade, //
      tilReqMade, //
      setShowTilTrimModal,
      modalOpen,
      setModalOpen,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);
  let [type, setType] = useState("shift");
  let [id, setId] = useState("");

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 3) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftChangeArrow}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={blueLoader}
        alt="Unclocked Check"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );
  let [shiftMins, setShiftMins] = useState(0);

  let [trimMins, setTrimMins] = useState(60);
  let [start, setStart] = useState(unitStart);
  let [end, setEnd] = useState(unitEnd);
  let [ds, setDs] = useState("");
  let [note, setNote] = useState("");
  let [teamID, setTeamID] = useState(unitTeamID);
  let [previewStart1, setPreviewStart1] = useState(unitStart);

  let [whole, setWhole] = useState(false);

  let tempEndMs = dateStringer.HHMMToMsToday(unitEnd) - 610000;

  let [previewEnd1, setPreviewEnd1] = useState(
    dateStringer.tsToHHMM(tempEndMs)
  );
  let [previewStart2, setPreviewStart2] = useState("00:00");
  let [previewEnd2, setPreviewEnd2] = useState("00:00");

  let [invalidModal, setInvalidModal] = useState(false);

  let [accruedTil, setAccruedTil] = useState(0);
  let [takenTil, setTakenTil] = useState(0);
  let [yearStartDs, setYearStartDs] = useState("");
  let [yearEndDs, setYearEndDs] = useState("");
  let [remainingTil, setRemainingTil] = useState(0);

  let [notEnoughModal, setNotEnoughModal] = useState(false);

  // // try {
  // const toilStart = document.getElementById("toilStart");
  // if (toilStart) {
  //   toilStart.addEventListener("focus", function (evt) {
  //     try {
  //       // if (this.getAttribute("type") === "time") {
  //       this.showPicker();
  //       // }
  //     } catch (error) {
  //       console.error("Error in toilStart focus event listener:", error);
  //     }
  //   });
  // }
  // // } catch (error) {
  // //   console.error("Error in setting up toilStart:", error);
  // // }

  // // try {
  // const toilEnd = document.getElementById("toilEnd");
  // if (toilEnd) {
  //   toilEnd.addEventListener("focus", function (evt) {
  //     try {
  //       // if (this.getAttribute("type") === "time") {
  //       this.showPicker();
  //       // }
  //     } catch (error) {
  //       console.error("Error in toilEnd focus event listener:", error);
  //     }
  //   });
  // }
  // // } catch (error) {
  // //   console.error("Error in setting up toilEnd:", error);
  // // }

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-til-allowance`,
        {
          ds: unitDate,
          userID,
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          console.log(response.data);
          setYearStartDs(response.data.forceStartDs);
          // setYearEndDs(response.data.forceEndDs);

          // setAccruedTil(response.data.tilMinsWorked);
          // setTakenTil(response.data.tilTaken);
          setDataLoaded(true);
          setRemainingTil(response.data.tilBalance);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let [split, setSplit] = useState(false);
  let updateTimes = (newWhen) => {
    if (newWhen === "start") {
      let date = new Date();
      date.setHours(unitStart.substr(0, 2), unitStart.substr(3, 2), 0, 0);
      let tenMinsMs = 1000 * 60 * 10;
      let newTsStart = date.getTime() + tenMinsMs;
      let startDateObj = new Date(newTsStart);

      setStart(unitStart);
      setEnd(dateStringer.tsToHHMM(startDateObj.getTime()));

      let tsStart = dateStringer.HHMMToMsToday(unitStart);
      let tsEnd = dateStringer.HHMMToMsToday(
        dateStringer.tsToHHMM(startDateObj.getTime())
      );
      let msDiff = tsEnd - tsStart;
      let minsDiff = msDiff / 1000 / 60;

      if (minsDiff > shiftMins) {
        setTrimMins(shiftMins);
        setWhole(true);
      } else {
        setTrimMins(minsDiff);
        setWhole(false);
      }
    }

    if (newWhen === "end") {
      let date = new Date();
      date.setHours(unitEnd.substr(0, 2), unitEnd.substr(3, 2), 0, 0);
      let tenMinsMs = 1000 * 60 * 10;
      let newTsStart = date.getTime() - tenMinsMs;
      let startDateObj = new Date(newTsStart);

      setStart(dateStringer.tsToHHMM(startDateObj.getTime()));
      setEnd(unitEnd);

      let tsStart = dateStringer.HHMMToMsToday(
        dateStringer.tsToHHMM(startDateObj.getTime())
      );
      let tsEnd = dateStringer.HHMMToMsToday(unitEnd);
      let msDiff = tsEnd - tsStart;
      let minsDiff = msDiff / 1000 / 60;
      // if (minsDiff > shiftMins) {
      //   setTrimMins(shiftMins);
      //   setWhole(true);
      // } else {
      setTrimMins(minsDiff);
      setWhole(false);
      // }
    }
  };

  console.log({ start });
  useEffect(() => {
    // clean not needed
    if (editStart && editEnd) {
      setStart(editStart);
      setEnd(editEnd);

      updateTimes("start");
      updateTimes("end");
    }
  }, []);

  let [endValid, setEndValid] = useState(true);
  let [startValid, setStartValid] = useState(true);

  let checkTimes = (startOrEnd, newTime) => {
    if (startOrEnd === "end") {
      let ts = dateStringer.HHMMToMsToday(newTime);
      let tsMax = dateStringer.HHMMToMsToday(unitEnd);
      let tsMin = dateStringer.HHMMToMsToday(unitStart);

      if (ts > tsMax || ts < tsMin) {
        setEndValid(false);
      } else {
        setEndValid(true);
      }

      // if (ts < tsMin) {
      //   setStartValid(false);
      // } else {
      //   setStartValid(true);
      // }
    }
    if (startOrEnd === "start") {
      let ts = dateStringer.HHMMToMsToday(newTime);
      let tsMin = dateStringer.HHMMToMsToday(unitStart);
      let tsMax = dateStringer.HHMMToMsToday(unitEnd);

      if (ts < tsMin || ts > tsMax) {
        setStartValid(false);
      } else {
        setStartValid(true);
      }

      // if (ts > tsMax) {
      //   setEndValid(false);
      // } else {
      //   setEndValid(true);
      // }
    }
  };

  let updateData = (shiftStart, shiftEnd, tilStart, tilEnd) => {
    // split
    let splitted = false;
    let endOrStart = "end";
    if (shiftStart !== tilStart && shiftEnd !== tilEnd) {
      setSplit(true);
      splitted = true;
    } else {
      setSplit(false);
      if (shiftStart !== tilStart && shiftEnd === tilEnd) {
        endOrStart = "end";
      }
      if (shiftStart === tilStart && shiftEnd !== tilEnd) {
        endOrStart = "start";
      }
    }

    if (splitted) {
      setPreviewStart1(shiftStart);
      let ts = dateStringer.HHMMToMsToday(tilStart) - 60000;
      setPreviewEnd1(dateStringer.tsToHHMM(ts));

      setPreviewEnd2(shiftEnd);
      let ts2 = dateStringer.HHMMToMsToday(tilEnd) + 60000;
      setPreviewStart2(dateStringer.tsToHHMM(ts2));
    } else {
      if (endOrStart === "start") {
        setPreviewEnd1(shiftEnd);
        let ts = dateStringer.HHMMToMsToday(tilEnd) + 60000;
        setPreviewStart1(dateStringer.tsToHHMM(ts));
      }

      if (endOrStart === "end") {
        setPreviewStart1(shiftStart);
        let ts = dateStringer.HHMMToMsToday(tilStart) - 60000;
        setPreviewEnd1(dateStringer.tsToHHMM(ts));
      }
    }

    // if (shiftStart !== tilStart) {
    //   setPreviewStart1(shiftStart);
    // } else {
    //   let ts = dateStringer.HHMMToMsToday(tilEnd) + 60000;
    //   setPreviewStart1(dateStringer.tsToHHMM(ts));
    // }

    // if (shiftEnd !== tilEnd) {
    //   setPreviewEnd1(shiftEnd);
    // } else {
    //   let ts = dateStringer.HHMMToMsToday(tilStart) - 60000;
    //   setPreviewEnd1(dateStringer.tsToHHMM(ts));
    // }
  };

  useEffect(() => {
    // clean not needed
    setType(unitType);
    setId(unitID);
    setDs(unitDate);
    setShiftMins(unitShiftMins);
    updateTimes("end");
  }, []);

  // LOAD EXISTING
  useEffect(() => {
    // clean not needed
    if (editing) {
      // start:
      setStart(editStart);

      let tsStart = dateStringer.HHMMToMsToday(editStart);
      let tsEnd = dateStringer.HHMMToMsToday(editEnd);
      let msDiff = tsEnd - tsStart;
      let minsDiff = msDiff / 1000 / 60;
      if (minsDiff >= unitShiftMins) {
        setTrimMins(unitShiftMins);
        setWhole(true);
      } else {
        setTrimMins(minsDiff);
        setWhole(false);
      }
      checkTimes("start", editStart);

      // end:
      setEnd(editEnd);

      checkTimes("end", editEnd);
      updateData(unitStart, unitEnd, editStart, editEnd);
    }
  }, []);

  let closeModal = () => {
    if (setShowTilTrimModalParent) {
      // parent from the manager shift
      setShowTilTrimModalParent(false);
    } else {
      // from the my shift
      memoVals.setShowTilTrimModal((x) => false);
    }
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return
  return (
    <div
      className={`editShiftRequestModalUnderlay ${isMgr ? "x13984191" : ""}`}
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`editShiftRequestModalBox ${
            memoVals.mobModal
              ? `mobModalShoulder ${
                  memoVals.device.ios ? "paddingBottom20" : ""
                }`
              : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperTilTrim"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className={`toilHeader ${
              isMgr ? "marginTop6px marginBottomMinus20" : ""
            }`}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            {/* <div
            className="editMyShiftCloseHolder"
            onClick={() => {
              closeModal();
            }}
          > */}
            <img
              src={close}
              onClick={() => {
                closeModal();
              }}
              alt="Close"
              className="closeMyShiftModalImg"
            />
            {/* </div> */}
            <p
              className="toilHeaderTxt"
              onClick={() => {
                if (memoVals.mobModal) {
                  closeModal();
                }
              }}
            >
              {isMgr ? `${editing ? "Edit" : "Add"} TOIL` : "Request TOIL"}
            </p>
            {/* <div className="myShiftModalHeaderRight">&nbsp;</div> */}
          </div>

          {!isMgr ? (
            <p className="toilInstructInfo">
              Use your TOIL balance to request to shorten this {unitType} or
              take the whole {unitType} off.
            </p>
          ) : (
            ""
          )}
          {/* <div className="firstBarTil"></div> */}
          <div className={`editMyShiftRow ${whole ? "noBorder" : ""}`}>
            <p className="toilRowTitle">Date</p>

            <p className="takeTilDsTxt">
              {dateStringer.printedDayWithYrFromTs(
                dateStringer.createTimestampFromString(ds)
              )}
              &nbsp;
              {dateStringer.createShortStripFromDateString(ds)}
            </p>
          </div>

          {!whole ? (
            <div className="editMyShiftRow flexEnd_ noBorder">
              {/* <p className="toilRowTitle">Whole {unitType}</p> */}
              <p className="wholeShiftToilInfo">
                {isMgr
                  ? `Apply TOIL to the entire duration?`
                  : `Take the entire duration off as TOIL?`}
              </p>
              <p
                className="makeTilWholeBtn"
                onClick={() => {
                  setStart(unitStart);
                  setEnd(unitEnd);

                  let tsStart = dateStringer.HHMMToMsToday(unitStart);
                  let tsEnd = dateStringer.HHMMToMsToday(unitEnd);
                  let msDiff = tsEnd - tsStart;
                  let minsDiff = msDiff / 1000 / 60;
                  if (minsDiff >= shiftMins) {
                    setTrimMins(shiftMins);
                    setWhole(true);
                  } else {
                    setTrimMins(minsDiff);
                    setWhole(false);
                  }

                  updateData(unitStart, unitEnd, unitStart, unitEnd);
                }}
              >
                Whole {unitType}
                <img
                  src={downRightArrow2}
                  alt="Whole"
                  className="wholeDownRightArrImg"
                />
              </p>
            </div>
          ) : (
            ""
          )}

          <div className="editMyShiftRow highlightedTilRow1">
            <p className="toilRowTitle weight600">TOIL start</p>

            <input
              className={`shiftTimeInput tilTrimTimeInput ${
                startValid ? "" : "tilInvalidTimeInput"
              }`}
              type="time"
              id="toilStart"
              value={start}
              onChange={(e) => {
                // setChangeMade(true);

                setStart(e.target.value);

                let tsStart = dateStringer.HHMMToMsToday(e.target.value);
                let tsEnd = dateStringer.HHMMToMsToday(end);
                let msDiff = tsEnd - tsStart;
                let minsDiff = msDiff / 1000 / 60;
                if (minsDiff >= shiftMins) {
                  setTrimMins(shiftMins);
                  setWhole(true);
                } else {
                  setTrimMins(minsDiff);
                  setWhole(false);
                }
                checkTimes("start", e.target.value);

                updateData(unitStart, unitEnd, e.target.value, end);
              }}
            />
          </div>
          <div className="editMyShiftRow highlightedTilRow2">
            <p className="toilRowTitle weight600">TOIL end</p>

            <input
              className={`shiftTimeInput tilTrimTimeInput ${
                endValid ? "" : "tilInvalidTimeInput"
              }`}
              type="time"
              id="toilEnd"
              value={end}
              onChange={(e) => {
                setEnd(e.target.value);

                let tsStart = dateStringer.HHMMToMsToday(start);
                let tsEnd = dateStringer.HHMMToMsToday(e.target.value);
                let msDiff = tsEnd - tsStart;
                let minsDiff = msDiff / 1000 / 60;
                if (minsDiff > shiftMins) {
                  setTrimMins(shiftMins);
                  setWhole(true);
                } else {
                  setTrimMins(minsDiff);
                  setWhole(false);
                }
                checkTimes("end", e.target.value);

                updateData(unitStart, unitEnd, start, e.target.value);
              }}
            />
          </div>
          <div className="editMyShiftRow">
            <p className="toilRowTitle">TOIL duration</p>

            <p className="takeTilDsTxt">
              <span
                className={`boldTrimSpan ${
                  trimMins < 0 ? "tilInvalidTime" : ""
                }`}
              >
                {dateStringer.formatMinsDurationToHours(trimMins)}
              </span>{" "}
              out of {/* <span className="boldTrimSpan"> */}
              {dateStringer.formatMinsDurationToHours(shiftMins)}
              {/* </span> */}
            </p>
          </div>

          <div className={`editMyShiftRow ${isMgr ? "noBorder_" : ""}`}>
            <div className="availToilTitleDiv">
              <p className="toilRowTitle">
                {isMgr ? dateStringer.possession(fName) : "Your"} available TOIL
              </p>
              {/* <p
                className={`availTilBrackets ${
                  isMgr ? "marginTopMinus28" : ""
                }`}
              >
                between{" "}
                {dateStringer.printedDateWithYrFromDWithoutDay(yearStartDs)} -{" "}
                {dateStringer.printedDateWithYrFromDWithoutDay(yearEndDs)}
              </p> */}
            </div>

            <p className="takeTilDsTxt">
              <span
                className={`boldTrimSpan ${
                  trimMins > remainingTil ? "tilInvalidTime" : ""
                }`}
              >
                {dateStringer.formatMinsDurationToHours(remainingTil)}
              </span>{" "}
            </p>
          </div>
          {/* <div
          className={`${
            itemType === "til" || itemType === "overtime"
              ? "editMyShiftRow"
              : "none"
          }`}
        >
          <p className='editMyShiftRowTitle'>Type</p>

          <select
            className='editMyShiftTypeDropdown'
            value={selectedType}
            onChange={(e) => {
              setChangeMade(true);

              setSelectedType(e.target.value);
            }}
          >
            <option value='til'>Time in lieu</option>
            <option value='overtime'>Overtime</option>
          </select>
        </div> */}
          {!whole ? (
            <div className={`toilNewOldTimesBar ${isMgr ? "x923111831" : ""}`}>
              <p className="toilNewOldTitle">Original times</p>{" "}
              <p className="toilNewOldTitle2">Proposed times</p>
            </div>
          ) : (
            ""
          )}
          <div
            className={`${whole ? "tilChangePreviewHeaderWhole" : "none"} ${
              isMgr ? "noBorder" : ""
            }`}
          >
            <div className="tilPreviewShiftContainerWhole">
              <p className="wholeOff">Whole {unitType} off</p>
            </div>
          </div>
          <div
            className={`${whole ? "none" : "tilChangePreviewHeader"}  ${
              isMgr ? "noBorder" : ""
            }`}
          >
            <div className="tilPreviewShiftContainer">
              <p className="originalTilShiftTxt">
                <span className={startValid ? "" : "tilInvalidTime"}>
                  {unitStart}
                </span>{" "}
                -{" "}
                <span className={endValid ? "" : "tilInvalidTime"}>
                  {unitEnd}
                </span>
              </p>
            </div>
            <img src={shiftChangeArrow} className="shiftChangeArrowTil" />
            <div className="tilPreviewShiftContainer">
              <div className={`${split ? "none" : "notSplitTilShiftPreview"}`}>
                <p className={`${!whole ? "originalTilShiftTxt" : "none"}`}>
                  <span className={startValid ? "" : ""}>{previewStart1}</span>{" "}
                  - <span className={endValid ? "" : ""}>{previewEnd1}</span>
                </p>
              </div>
              <div className={`${split ? "splitTilShiftPreview" : "none"}`}>
                <p className="splitPreviewTilShift">
                  <span className={startValid ? "" : ""}>{previewStart1}</span>{" "}
                  - <span className={endValid ? "" : ""}>{previewEnd1}</span>
                </p>

                <p className="splitPreviewTilShift">
                  {previewStart2} - {previewEnd2}
                </p>
              </div>
            </div>
          </div>
          {isMgr ? (
            ""
          ) : (
            <div className="editMyShiftRow noEditShiftBorder">
              <p className="editMyShiftRowTitle toilRowTitle toilNoteTitle">
                Note
              </p>

              <textarea
                className="EditMyShiftNoteTextBox"
                // type=''
                id="addShiftDateInput"
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
              />
            </div>
          )}

          <div className="toilFooter">
            <div className="tilFooterLeft">
              <div className="submitContainerEditMyShift">
                <p
                  className={`${
                    loading
                      ? "none"
                      : `requestEditMyShiftBtn ${isMgr ? "mgrCtaColours" : ""}`
                  } ${startValid && endValid ? "" : "disableAbsenceElement"}  ${
                    trimMins < 1 ? "disableAbsenceElement" : ""
                  }`}
                  onClick={() => {
                    if (!startValid || !endValid || trimMins < 1) {
                      setInvalidModal(true);
                    } else {
                      if (trimMins > remainingTil) {
                        setNotEnoughModal(true);
                      } else {
                        setLoading(true);

                        if (isMgr) {
                          // add toil req here
                          axios
                            .post(
                              `${serverURL}/add-til-to-shift-or-til`,
                              {
                                type: type,
                                start: start,
                                end: end,
                                tilMins: trimMins,
                                shiftID: id,
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setTimeout(() => {
                                  setUpdateTilData(!updateTilData);
                                }, 100);
                                if (setChangesMade && isMgr) {
                                  setChangesMade(true);
                                }
                                setLoading(false);
                                closeModal();
                                setReload(!reload);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        } else {
                          axios
                            .post(
                              `${serverURL}/take-til-request`,
                              {
                                type: type,
                                start: start,
                                end: end,
                                tilMins: trimMins,
                                note: note,
                                shiftID: id,
                                teamID: teamID,
                                whole: whole,
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                if (setChangesMade && isMgr) {
                                  setChangesMade(true);
                                }

                                if (!response.data.existed) {
                                  setCountData({
                                    outstandingChanges:
                                      countData.outstandingChanges + 1,
                                  });
                                }
                                setTimeout(() => {
                                  setUpdateTilData(!updateTilData);
                                }, 100);
                                setLoading(false);
                                closeModal();
                                memoVals.setTilReqMade(
                                  (x) => !memoVals.tilReqMade
                                );
                                console.log("SETTILREQMADE1");
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }
                    }
                  }}
                >
                  {isMgr ? (editing ? "Save" : "Add") : "Request"} TOIL
                </p>
                <img
                  src={blueLoader}
                  className={`${loading ? "blueLoaderEditMyShiftImg" : "none"}`}
                  alt="Loading"
                  onClick={() => {
                    setLoading(false);
                  }}
                />
              </div>
              {editing ? (
                <p
                  className="removeTilFooterBtn"
                  onClick={() => {
                    setSureBinTil(true);
                    // closeModal();
                  }}
                >
                  Remove
                </p>
              ) : (
                ""
              )}
            </div>

            <p
              className="closeToilBtn"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>

            {notEnoughModal ? (
              <div className="addAbsenceModalUnderlay">
                <div className="formCreatedModal">
                  <p className="overlapsRenTxt">
                    {isMgr ? `${fName} does` : "You do"} not have enough TOIL
                    accrued since{" "}
                    {dateStringer.printedDateWithYrFromDWithoutDay(yearStartDs)}{" "}
                    to fulfill this {isMgr ? "time off" : "request"}.
                    <br />
                    <br />
                    {isMgr ? "They" : "You"} only have{" "}
                    {dateStringer.formatMinsDurationToHours(remainingTil)} and
                    this request requires{" "}
                    {dateStringer.formatMinsDurationToHours(trimMins)}.
                    <br />
                    <br />
                    Do you want to {isMgr ? "apply" : "request"} it anyway?
                  </p>
                  <div className="areYouModalBtnsDiv">
                    <p
                      className="areYouSureModalYesBtn"
                      onClick={() => {
                        setLoading(true);
                        setNotEnoughModal(false);

                        if (isMgr) {
                          // add til req here
                          axios
                            .post(
                              `${serverURL}/add-til-to-shift-or-til`,
                              {
                                type: type,
                                start: start,
                                end: end,
                                tilMins: trimMins,
                                shiftID: id,
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                if (setChangesMade && isMgr) {
                                  setChangesMade(true);
                                }
                                setTimeout(() => {
                                  setUpdateTilData(!updateTilData);
                                }, 100);
                                setLoading(false);
                                closeModal();
                                setReload(!reload);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        } else {
                          axios
                            .post(
                              `${serverURL}/take-til-request`,
                              {
                                type: type,
                                start: start,
                                end: end,
                                tilMins: trimMins,
                                note: note,
                                shiftID: id,
                                teamID: teamID,
                                whole: whole,
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                if (setChangesMade && isMgr) {
                                  setChangesMade(true);
                                }
                                if (!response.data.existed) {
                                  setCountData({
                                    outstandingChanges:
                                      countData.outstandingChanges + 1,
                                  });
                                }
                                setTimeout(() => {
                                  setUpdateTilData(!updateTilData);
                                }, 100);
                                setLoading(false);
                                closeModal();
                                setReload(!reload);
                                memoVals.setTilReqMade(
                                  (x) => !memoVals.tilReqMade
                                );
                                console.log("SETTILREQMADE2");
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      Yes
                    </p>
                    <p
                      className="areYouModalNoBtn"
                      onClick={() => {
                        setNotEnoughModal(false);
                      }}
                    >
                      Cancel
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {invalidModal ? (
              <div className="addAbsenceModalUnderlay">
                <div className="formCreatedModal">
                  {trimMins < 1 ? (
                    <p className="overlapsRenTxt">
                      Your flex time cannot end before it begins.
                      <br />
                      <br />
                      Please ensure that your end time is after the start time.
                    </p>
                  ) : (
                    <p className="overlapsRenTxt">
                      Please ensure {isMgr ? "the" : "your requested"} TOIL{" "}
                      {!startValid ? "start time" : ""}{" "}
                      {!startValid && !endValid ? "and" : ""}{" "}
                      {!endValid ? "end time" : ""}{" "}
                      {!startValid && !endValid ? "are" : "is"} within the{" "}
                      {type === "shift" ? "shift" : "overtime"}'s scheduled
                      start and end times.
                    </p>
                  )}
                  <div className="areYouModalBtnsDiv">
                    <p
                      className="closeSwapsNoLongerBtn"
                      onClick={() => {
                        setInvalidModal(false);
                      }}
                    >
                      Understood
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      {imgPreload}
    </div>
  );
};

export default TilTrimModal;
