import React, { useState, useContext, useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { StyleContext } from "../../contexts/StyleContext";

import axios from "axios";

import logoicon from "../../img/signIn/smalllogo.png";
import download from "../../img/signIn/download.svg";
import eye from "../../img/signIn/eye.svg";
import logo from "../../img/general/logo.svg";
import signUpMob from "../../img/general/signup/mob.png";
import tick from "../../img/general/signup/tick.svg";

import "../../styles/signup.css";

import { UserContext } from "../../contexts/UserContext";

import serverURL from "../../serverURL";
import appURL from "../../appURL";

import dateStringer from "../../tools/dateStringer";

const SignUpForm = () => {
  const { setCheckUserState } = useContext(UserContext);
  const { mob, setMob } = useContext(StyleContext);

  let [page, setPage] = useState(0);

  // is the form filled?
  const [formFilled, setFormFilled] = useState(false);

  // value of email input field
  const [emailInput, setEmailInput] = useState("");
  let [companyName, setCompanyName] = useState("");
  let [fName, setFName] = useState("");
  let [lName, setLName] = useState("");
  let [securityQuestion, setSecurityQuestion] = useState(
    "What is your mother's maiden name?"
  );
  let [securityAnswer, setSecurityAnswer] = useState("");
  // value of password input field
  const [passInput, setPassInput] = useState("");

  // check all fields are filled

  // state for the show password button to change the password input type
  const [showPassword, setShowPassword] = useState(false);

  // state to show invalid signin texts
  const [wrongEmail, setWrongEmail] = useState(false);
  const [wrongPass, setWrongPass] = useState(false);
  let [emailInUse, setEmailInUse] = useState(false);
  let [unsafePassword, setUnsafePassword] = useState(false);
  let [invalidEmail, setInvalidEmail] = useState(false);
  let [invalidPassword, setInvalidPassword] = useState(false);
  let [invalidCompanyName, setInvalidCompanyName] = useState(false);
  let [invalidFName, setInvalidFName] = useState(false);
  let [invalidLName, setInvalidLName] = useState(false);
  let [invalidSecurityQuestion, setInvalidSecurityQuestion] = useState("");
  let [invalidSecurityAnswer, setInvalidSecurityAnswer] = useState("");
  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    var elements = document.getElementsByClassName("flowWidget");

    if (elements) {
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.visibility = "hidden";
      }
    }
  }, []);

  useEffect(() => {
    // Function to fetch IP address
    const fetchIPAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip);

        const locationApi = await fetch(`https://ip-api.com/json/${data.ip}`);
        const data2 = await locationApi.json();

        setLocation(data2.city);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIPAddress();
  }, []);

  let checkPage = (pageNum) => {
    let valid = false;
    if (pageNum === 0) {
      if (
        emailInput &&
        emailInput.includes("@") &&
        emailInput.includes(".") &&
        emailInput.length < 150 &&
        passInput &&
        passInput.length >= 8 &&
        companyName &&
        companyName.length < 150
      ) {
        valid = true;
      }
    }

    if (pageNum === 1) {
      if (
        fName &&
        typeof fName === "string" &&
        fName.length < 200 &&
        lName &&
        typeof lName === "string" &&
        lName.length < 200 &&
        securityQuestion &&
        typeof securityQuestion === "string" &&
        securityQuestion.length < 200 &&
        securityAnswer &&
        typeof securityAnswer === "string" &&
        securityAnswer.length < 200
      ) {
        valid = true;
      }
    }

    return valid;
  };

  let enterFct = (e, btn) => {
    if (page === 0) {
      if (e.key === "Enter" || btn) {
        axios
          .post(
            `${serverURL}/is-email-unique`,
            {
              email: emailInput,
              password: passInput,
              companyName,
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              if (response.data.unique) {
                if (response.data.unsafePassword) {
                  setUnsafePassword(true);
                }
                if (!response.data.passwordValid) {
                  setInvalidPassword(true);
                }
                if (!response.data.companyNameValid) {
                  setInvalidCompanyName(true);
                }

                if (
                  response.data.passwordValid &&
                  response.data.companyNameValid
                ) {
                  setPage(1); // fill in name, lName, sec q & a
                }
              } else {
                setEmailInUse(true);
                setInvalidEmail(true);
              }
            } else {
              if (response.data.message) {
                if (
                  response.data.message.includes("invalid") ||
                  response.data.message.includes("500") ||
                  response.data.message.includes("passed")
                ) {
                  setInvalidEmail(true);
                }
              }
            }
          });
      }
    }

    if (page === 1) {
      if (e.key === "Enter" || btn) {
        axios
          .post(
            `${serverURL}/init-user-and-client`,
            {
              email: emailInput,
              password: passInput,
              companyName,
              securityAnswer,
              securityQuestion,
              fName,
              lName,
              nowDs: dateStringer.createStringFromTimestamp(
                new Date().getTime()
              ),
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message === "password unsafe") {
              setPage(0);
              setUnsafePassword(true);
            } else if (response.data.message === "fail") {
              if (
                response.data.invalidValues &&
                response.data.invalidValues[0]
              ) {
                if (response.data.invalidValues.includes("email")) {
                  setInvalidEmail(true);
                }
                if (response.data.invalidValues.includes("password")) {
                  setInvalidPassword(true);
                }
                if (response.data.invalidValues.includes("companyName")) {
                  setInvalidCompanyName(true);
                }
                if (response.data.invalidValues.includes("fName")) {
                  setInvalidFName(true);
                }

                if (response.data.invalidValues.includes("lName")) {
                  setInvalidLName(true);
                }
                if (response.data.invalidValues.includes("securityQuestion")) {
                  setInvalidSecurityQuestion(true);
                }
                if (response.data.invalidValues.includes("securityAnswer")) {
                  setInvalidSecurityAnswer(true);
                }
                if (page === 1) {
                  if (
                    response.data.invalidValues.includes("email") ||
                    response.data.invalidValues.includes("password") ||
                    response.data.invalidValues.includes("companyName")
                  ) {
                    setPage(0);
                  }
                }
              }
            } else if (response.data.message === "success") {
              function getDeviceName() {
                const userAgent = navigator.userAgent;
                if (/Android/i.test(userAgent)) {
                  return "Android Device";
                } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
                  return "iOS Device";
                } else if (/Windows Phone/i.test(userAgent)) {
                  return "Windows Phone";
                } else if (/Windows/i.test(userAgent)) {
                  return "Windows PC";
                } else if (
                  /Macintosh|MacIntel|MacPPC|Mac68K/i.test(userAgent)
                ) {
                  return "Macintosh";
                } else if (/Linux|X11/i.test(userAgent)) {
                  return "Linux";
                } else {
                  return "Unknown Device";
                }
              }
              axios
                .post(
                  `${serverURL}/signin`,
                  {
                    email: emailInput,
                    password: passInput,
                    client: "React",
                    nowDs: dateStringer.createStringFromTimestamp(
                      new Date().getTime()
                    ),
                    deviceName: getDeviceName(),
                    ip: ipAddress,
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.loggedIn) {
                    setCheckUserState({ validUser: true });

                    if (response.data.user.permissions === 1) {
                      // window.location.href = "/team-rota";
                      window.location.href = "/team-rota";
                    } else {
                      window.location.href = "/calendar";
                    }
                  }
                });
            }
          });
      }
    }
  };

  console.log("height:", window.innerHeight);
  function handleWindowSizeChange() {
    let widthBrk = 820;

    if (window.innerWidth < widthBrk || window.innerHeight < 683) {
      setMob((x) => true);
    } else {
      setMob((x) => false);
    }
  }

  useEffect(() => {
    // clean not needed
    handleWindowSizeChange();

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // master return
  return (
    <div className="signUpBackground">
      {/* <div className="downloadBar">
        <div className="downloadBarContainer">
          <img src={download} alt="download pwa" id="mobIcon" />
          <p className="downloadBarText">Install FlowRota on your smartphone</p>
        </div>
      </div> */}

      {!mob && (
        <div className="signUpLeft">
          {/* <img src={signUpMob} alt="FlowRota" className="signUpMobImg" /> */}
          {/* <div className="signUpLeftBar"></div> */}
          <p className="signUpLeftHeadline">
            <span className="frBoldSpan">FlowRota</span> is the UK's most
            affordable rota software
          </p>
          {/* <p className="signUpTrialStrap">30 days free trial</p>
          <p className="signUpNoCard">No card required</p> */}
          <div className="signUpLeftItemRow">
            <img
              src={tick}
              alt="FlowRota tick"
              className="signUpLeftItemRowTickImg"
            />
            <p className="signUpLeftItemRowTxt">30 days free trial</p>
          </div>{" "}
          <div className="signUpLeftItemRow noCardReqRow">
            <img
              src={tick}
              alt="FlowRota tick"
              className="signUpLeftItemRowTickImg invis"
            />
            <p className="signUpLeftItemRowTxt">(No card required)</p>
          </div>
          <div className="signUpLeftItemRow">
            <img
              src={tick}
              alt="FlowRota tick"
              className="signUpLeftItemRowTickImg"
            />
            <p className="signUpLeftItemRowTxt">Shift-based rotas</p>
          </div>
          <div className="signUpLeftItemRow">
            <img
              src={tick}
              alt="FlowRota tick"
              className="signUpLeftItemRowTickImg"
            />
            <p className="signUpLeftItemRowTxt">UK-based live chat support</p>
          </div>{" "}
          <div className="signUpLeftItemRow">
            <img
              src={tick}
              alt="FlowRota tick"
              className="signUpLeftItemRowTickImg"
            />
            <p className="signUpLeftItemRowTxt">Wage spend tracking</p>
          </div>
          <div className="signUpLeftItemRow">
            <img
              src={tick}
              alt="FlowRota tick"
              className="signUpLeftItemRowTickImg"
            />
            <p className="signUpLeftItemRowTxt">
              Leave requests with leave planning
            </p>
          </div>
          <div className="signUpLeftItemRow">
            <img
              src={tick}
              alt="FlowRota tick"
              className="signUpLeftItemRowTickImg"
            />
            <p className="signUpLeftItemRowTxt">
              Track overtime + time in lieu
            </p>
          </div>
          <div className="signUpLeftItemRow">
            <img
              src={tick}
              alt="FlowRota tick"
              className="signUpLeftItemRowTickImg"
            />
            <p className="signUpLeftItemRowTxt">
              Shift swaps amongst employees
            </p>
          </div>
          <div className="signUpLeftItemRow">
            <img
              src={tick}
              alt="FlowRota tick"
              className="signUpLeftItemRowTickImg"
            />
            <p className="signUpLeftItemRowTxt">Clock on / off with GPS</p>
          </div>
          <div className="signUpLeftItemRow">
            <img
              src={tick}
              alt="FlowRota tick"
              className="signUpLeftItemRowTickImg"
            />
            <p className="signUpLeftItemRowTxt">
              Absence monitoring with ranking
            </p>
          </div>
          <div className="signUpLeftItemRow">
            <img
              src={tick}
              alt="FlowRota tick"
              className="signUpLeftItemRowTickImg"
            />
            <p className="signUpLeftItemRowTxt">Return to work forms</p>
          </div>{" "}
          <div className="signUpLeftItemRow">
            <img
              src={tick}
              alt="FlowRota tick"
              className="signUpLeftItemRowTickImg"
            />
            <p className="signUpLeftItemRowTxt">Payroll</p>
          </div>{" "}
          <div className="signUpLeftItemRow">
            <img
              src={tick}
              alt="FlowRota tick"
              className="signUpLeftItemRowTickImg"
            />
            <p className="signUpLeftItemRowTxt">& much more</p>
          </div>{" "}
        </div>
      )}
      <div className="signUpContainer2">
        <div className="signinUpper">
          <p className="signInTitle">
            Create an account{" "}
            <img src={logo} alt="FlowRota" className="signInLogoImg" />
          </p>
          {/* <img className="signUpLogo" alt="FlowRota logo" src={logoicon} /> */}
          {page === 0 ? (
            <form className="signUpForm">
              <p className="signInInputTitle">Email</p>
              <input
                type="email"
                placeholder="email"
                id="email"
                name="email"
                className={`signinInput signUpEmailInput ${
                  invalidEmail ? "invalidFormInput" : ""
                }`}
                value={emailInput}
                onKeyPress={(e) => {
                  enterFct(e, false);
                }}
                onChange={(e) => {
                  if (invalidEmail) {
                    if (
                      e.target.value.includes("@") &&
                      e.target.value.includes(".") &&
                      e.target.value.length < 150
                    ) {
                      setInvalidEmail(false);
                    }
                  }
                  if (emailInUse) {
                    setEmailInUse(false);
                  }
                  setEmailInput(e.target.value);
                }}
              />
              <br />
              <p className={`${emailInUse ? "wrongSignInPass" : "none"}`}>
                Email already in use
              </p>
              <p className="signInInputTitle x20483085">Password</p>
              <input
                type={`${showPassword ? "text" : "password"}`}
                id="password"
                placeholder="password"
                name="password"
                className={invalidPassword ? "invalidFormInput" : ""}
                value={passInput}
                onKeyPress={(e) => {
                  enterFct(e, false);
                }}
                onChange={(e) => {
                  if (invalidPassword && e.target.value.length >= 8) {
                    setInvalidPassword(false);
                  }
                  if (unsafePassword) {
                    setUnsafePassword(false);
                  }
                  setPassInput(e.target.value);
                }}
              ></input>
              <img
                className="showPassword"
                src={eye}
                alt="show password"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
              <div
                className={`forgotContainer min8charBox" ${
                  unsafePassword ? "none" : ""
                }`}
              >
                {/* <NavLink to="/reset-password"> */}
                <p className="min8char marginTop20">Minimum of 8 characters</p>
                {/* </NavLink> */}
              </div>
              <p
                className={`${
                  unsafePassword ? "wrongSignInPass x3274328748211" : "none"
                }`}
              >
                Unsafe password
              </p>
              {/* <br /> */}
              <p className="signInInputTitle">Company name</p>
              <input
                type="text"
                placeholder="company name"
                id="email"
                name="companyName"
                className={`signinInput signUpEmailInput ${
                  invalidCompanyName ? "invalidFormInput" : ""
                }`}
                onKeyPress={(e) => {
                  enterFct(e, false);
                }}
                onChange={(e) => {
                  if (invalidCompanyName && e.target.value.length < 200) {
                    setInvalidCompanyName(false);
                  }
                  setCompanyName(e.target.value);
                }}
              />
              <br />
              {/* <NavLink to='/account-admin-general'> */} {/* </NavLink> */}
            </form>
          ) : page === 1 ? (
            <form className="signUpForm">
              <p className="signInInputTitle">First name</p>
              <input
                type="text"
                placeholder="First name"
                id="email"
                name="email"
                className={`signinInput signUpEmailInput ${
                  invalidFName ? "invalidFormInput" : ""
                }`}
                value={fName}
                onKeyPress={(e) => {
                  enterFct(e, false);
                }}
                onChange={(e) => {
                  setFName(e.target.value);
                  if (invalidFName) {
                    setInvalidFName(false);
                  }
                }}
              />
              <br />
              <p className="signInInputTitle x20483085">Last name</p>
              <input
                type="text"
                placeholder="Last name"
                id="email"
                name="email"
                className={`signinInput signUpEmailInput ${
                  invalidLName ? "invalidFormInput" : ""
                }`}
                value={lName}
                onKeyPress={(e) => {
                  enterFct(e, false);
                }}
                onChange={(e) => {
                  setLName(e.target.value);
                  if (invalidLName) {
                    setInvalidLName(false);
                  }
                }}
              />
              <br />
              <p className="signInInputTitle x20483085">Security question</p>
              <select
                className={`secQdrop ${
                  invalidSecurityQuestion ? "invalidFormInput" : ""
                }`}
                onChange={(e) => {
                  setSecurityQuestion(e.target.value);
                  if (invalidSecurityQuestion) {
                    setInvalidSecurityQuestion(false);
                  }
                }}
              >
                <option value="What is your mother's maiden name?">
                  What is your mother's maiden name?
                </option>
                <option value="What was the name of your first pet?">
                  What was the name of your first pet?
                </option>
                <option value="What is your place of birth?">
                  What is your place of birth?
                </option>
                <option value="What was the model of your first car?">
                  What was the model of your first car?
                </option>
              </select>
              <br />
              <p className="signInInputTitle x20483085">Answer</p>
              <input
                type="text"
                placeholder="The answer to your security question"
                id="text"
                name="text"
                className={`signinInput signUpEmailInput ${
                  invalidSecurityAnswer ? "invalidFormInput" : ""
                }`}
                value={securityAnswer}
                onKeyPress={(e) => {
                  enterFct(e, false);
                }}
                onChange={(e) => {
                  setSecurityAnswer(e.target.value);
                  if (invalidSecurityAnswer) {
                    setInvalidSecurityAnswer(false);
                  }
                }}
              />
              {/* <NavLink to='/account-admin-general'> */} {/* </NavLink> */}
            </form>
          ) : (
            "Other page"
          )}

          <div className={`signUpBtnRow ${page === 0 ? "signUpBtnRow0" : ""}`}>
            <p
              className={`${
                page === 0 || page === 1 ? "signUpNext" : "signinBtn2"
              } ${checkPage(page) ? "" : "disabled"}`}
              onClick={(event) => {
                enterFct("", true);
              }}
            >
              {page === 0 ? "Next" : "Finish"}
            </p>
            {page === 1 && (
              <p className="termsSignUpDisclaim">
                By clicking 'Finish', you agree to our{" "}
                <a
                  href="https://flowrota.com/terms"
                  target="_blank"
                  className="termsLink_"
                >
                  terms
                </a>
                . You can find out how we use your data in our{" "}
                <a
                  href="https://flowrota.com/privacy"
                  target="_blank"
                  className="termsLink_"
                >
                  Privacy Policy
                </a>{" "}
                and how we use tracking technology in our{" "}
                <a
                  href="https://flowrota.com/cookies-policy"
                  target="_blank"
                  className="termsLink_"
                >
                  Cookies Policy
                </a>
                .
              </p>
            )}
          </div>
        </div>
        <div className="signinLower">
          <div className="signinDivider"></div>
          <div className="signinFooter">
            <NavLink to="/signin">
              <p className="signInPrivacy">Sign in</p>
            </NavLink>
            <a
              href="https://flowrota.com/privacy"
              className="signInPrivacy"
              target="_blank"
            >
              Privacy & Terms
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
