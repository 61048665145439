// Full screen modal for making a leave request - generic use
import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 7 sep 24

import axios from "axios";
import serverURL from "../serverURL";

import ReqDateInputter from "./ReqDateInputter2";

import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";
import { StyleContext } from "../contexts/StyleContext";
import { RequestContext } from "../contexts/RequestContext";

import "../styles/reqModals.css";

import navycross from "../img/general/navycross.svg";
import darkRedCross from "../img/general/darkRedCross.svg";
import greenTick from "../img/general/greenTick.svg";
import edit from "../img/general/edit.svg";
import profilePicturePlaceholder from "../img/general/profilePicturePlaceholder.svg";

import blueLoader from "../img/loaders/horizontalBalls.svg";

import dateStringer from "../tools/dateStringer";

import "../styles/menu.css"; // also contains css for request fullscreen modal

const MakeLeaveReq = ({ ds }) => {
  // if reqID is passed, the user is editing an existing request
  // if leaveTypeID is passed, the user is editing an existing request

  const {
    setShowUserReqFromNav,
    updateUserReqs,
    setUpdateUserReqs,
    setLoadUserReqOnceMade,
    modalOpen,
    setModalOpen,
    device,
    countData,
    setCountData,
  } = useContext(DataContext);
  const { userID, workingPattern, setCheckUserState, checkUserState } =
    useContext(UserContext);

  const { mob, mobModal } = useContext(StyleContext);
  const {
    setShowReqModal,
    availableLeaveTypes,
    setAvailableLeaveTypes,
    setTilAvailable,
    setShowAdd,
  } = useContext(RequestContext);

  const memoVals = useMemo(
    () => ({
      setShowUserReqFromNav,
      updateUserReqs,
      setUpdateUserReqs,
      setLoadUserReqOnceMade,
      userID,
      workingPattern,
      setCheckUserState,
      checkUserState,
      mob,
      mobModal,
      setShowReqModal,
      availableLeaveTypes,
      setAvailableLeaveTypes,
      setTilAvailable,
      setShowAdd,
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      setShowUserReqFromNav,
      updateUserReqs,
      setUpdateUserReqs,
      setLoadUserReqOnceMade,
      userID,
      workingPattern,
      setCheckUserState,
      checkUserState,
      mob,
      setShowReqModal,
      availableLeaveTypes,
      setAvailableLeaveTypes,
      setTilAvailable,
      setShowAdd,
      modalOpen,
      setModalOpen,
      mobModal,
      device,
    ]
  );
  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [loading, setLoading] = useState(true);
  let [daysOrHours, setDaysOrHours] = useState("hours");
  let [endDate, setEndDate] = useState("");
  let [excessMpdInternal, setExcessMpdInternal] = useState(0);
  let [reqLeaveTypeID, setReqLeaveTypeID] = useState("annLeave");

  let [renewalOverlap, setRenewalOverlap] = useState(false);
  let [tooFarAhead, setTooFarAhead] = useState(false);

  let [note, setNote] = useState("");
  let [showNote, setShowNote] = useState(false);

  let [valid, setValid] = useState(false);
  // let [additionalExcess, setAdditionalExcess] = useState(0);
  let [overlapsWithOtherReqs, setOverlapsWithOtherReqs] = useState([]);
  let [leaveTypeName, setLeaveTypeName] = useState("Annual leave");
  let [reqWorkingPattern, setReqWorkingPattern] = useState("");
  // let [dayable, setDayable] = useState(false); // can the allowance be shown in days as well as hours?
  let [dailyMins, setDailyMins] = useState(0);
  let [allowanceObject, setAllowanceObject] = useState({});
  let [daysLength, setDaysLength] = useState(0);
  // let [excessMinsExtendsAllowance, setExcessMinsExtendsAllowance] = useState(0);
  let [allowanceYear, setAllowanceYear] = useState(0);
  let [notYetAvailable, setNotYetAvailable] = useState(false);

  let [blockSubmit, setBlockSubmit] = useState(false);

  let [localSunSchedTimes, setLocalSunSchedTimes] = useState("");
  let [localMonSchedTimes, setLocalMonSchedTimes] = useState("");
  let [localTueSchedTimes, setLocalTueSchedTimes] = useState("");
  let [localWedSchedTimes, setLocalWedSchedTimes] = useState("");
  let [localThuSchedTimes, setLocalThuSchedTimes] = useState("");
  let [localFriSchedTimes, setLocalFriSchedTimes] = useState("");
  let [localSatSchedTimes, setLocalSatSchedTimes] = useState("");
  let [alReqStartTs, setAlReqStartTs] = useState(
    ds ? dateStringer.createTimestampFromString(ds) : 0
  );
  let [alReqEndTs, setAlReqEndTs] = useState(0);
  let [limitsArr, setLimitsArr] = useState([]);
  let [maxDs, setMaxDs] = useState("");

  let generateLimitsArr = useMemo(() => {
    return limitsArr.map((lim, i) => {
      return (
        <div className="reqMakerLimitrRow">
          <p className="limRowDs">
            <img
              src={darkRedCross}
              alt="Limits exceeded"
              className="limExceedRedX"
            />
            {dateStringer.printedDateFromDs(lim.ds)}
          </p>
          <div className="limRowFlexBox">
            {lim.jtOrTeam === "jt" ? (
              <div className="limRowLeft">
                <p className="limRowTitle">Limit</p>
                <p className="limRowValue">{lim.maxOff}</p>
              </div>
            ) : (
              <div className="limRowLeft">
                <p className="limRowTitle">Limit</p>
                <p className="limRowValue">{lim.maxOff}</p>
              </div>
            )}

            {lim.jtOrTeam === "jt" ? (
              <div className="limRowMiddle">
                <p className="limRowTitle">Already off</p>
                <p className="limRowValue">{lim.currOff}</p>
              </div>
            ) : (
              <div className="limRowMiddle">
                <p className="limRowTitle">Already off</p>
                <p className="limRowValue">{lim.currOff}</p>
              </div>
            )}

            {lim.jtOrTeam === "jt" ? (
              <div className="limRowRight">
                <p className="limRowTitle">Limit by</p>
                <p className="limRowValue minHeightFitContent">
                  {lim.jobTitle}
                  {lim.jobTitle[lim.jobTitle.length - 1] === "s"
                    ? "es"
                    : "s"}{" "}
                  in {lim.teamName}
                </p>
              </div>
            ) : (
              <div className="limRowRight">
                <p className="limRowTitle">Limit by</p>
                <p className="limRowValue">All staff in {lim.teamName}</p>
              </div>
            )}
          </div>
        </div>
      );
    });
  }, [limitsArr]);

  let [approvers, setApprovers] = useState(false);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      setHalfEnd(false);
      setHalfStart(false);
      setAlReqEndTs();
      setAlReqStartTs();
      memoVals.setShowAdd((x) => false);
      memoVals.setShowReqModal((x) => false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  let [halfStart, setHalfStart] = useState(false);
  let [halfEnd, setHalfEnd] = useState(false);

  let [reqDur, setReqDur] = useState(0);
  let [reqDur2, setReqDur2] = useState(0);

  let [zMins, setZMins] = useState(0);

  let [startHalfString, setStartHalfString] = useState("");
  let [endHalfString, setEndHalfString] = useState("");

  let [startDate, setStartDate] = useState(
    ds ? dateStringer.createDateInputFromDateString(ds) : ""
  );

  let generateTypes3 =
    memoVals.availableLeaveTypes &&
    memoVals.availableLeaveTypes[0] &&
    memoVals.availableLeaveTypes.map((type) => {
      return (
        <option key={type.leaveTypeID} value={type.leaveTypeID}>
          {type.name}
        </option>
      );
    });

  let [showHalfTimeModal, setShowHalfTimeModal] = useState(false);

  // if start and end dates are the same and both start + end half is true, the request will be invalid as the user will have no leave
  let checkValid = (alReqStartTs, alReqEndTs, reqStartHalf, reqEndHalf) => {
    if (alReqStartTs === alReqEndTs && reqStartHalf && reqEndHalf) {
      return false;
    } else {
      if (alReqEndTs < alReqStartTs) {
        return false;
      } else {
        return true;
      }
    }
  };

  let generateHalfTimeDateStrings = (ts) => {
    let dateObj = new Date(ts);
    let d = dateObj.getDay();
    let m = dateObj.getMonth();
    let month;
    if (m === 0) {
      month = "Jan";
    } else if (m === 1) {
      month = "Feb";
    } else if (m === 2) {
      month = "Mar";
    } else if (m === 3) {
      month = "Apr";
    } else if (m === 4) {
      month = "May";
    } else if (m === 5) {
      month = "Jun";
    } else if (m === 6) {
      month = "Jul";
    } else if (m === 7) {
      month = "Aug";
    } else if (m === 8) {
      month = "Sep";
    } else if (m === 9) {
      month = "Oct";
    } else if (m === 10) {
      month = "Nov";
    } else if (m === 11) {
      month = "Dec";
    }
    let day;
    if (d === 0) {
      day = "Sun";
    } else if (d === 1) {
      day = "Mon";
    } else if (d === 2) {
      day = "Tue";
    } else if (d === 3) {
      day = "Wed";
    } else if (d === 4) {
      day = "Thu";
    } else if (d === 5) {
      day = "Fri";
    } else if (d === 6) {
      day = "Sat";
    }
    return `${day} ${dateObj.getDate()} ${month}`;
  };

  let [reqPage, setReqPage] = useState(1);

  let generateDateString = (ts) => {
    let dateObj = new Date(ts);
    let y = JSON.stringify(dateObj.getFullYear()).substr(2, 2);
    let d = dateObj.getDay();
    let m = dateObj.getMonth();
    let date = dateObj.getDate();
    let day;
    if (d === 0) {
      day = "Sun";
    } else if (d === 1) {
      day = "Mon";
    } else if (d === 2) {
      day = "Tue";
    } else if (d === 3) {
      day = "Wed";
    } else if (d === 4) {
      day = "Thu";
    } else if (d === 5) {
      day = "Fri";
    } else if (d === 6) {
      day = "Sat";
    }
    let month;
    if (m === 0) {
      month = "Jan";
    } else if (m === 1) {
      month = "Feb";
    } else if (m === 2) {
      month = "Mar";
    } else if (m === 3) {
      month = "Apr";
    } else if (m === 4) {
      month = "May";
    } else if (m === 5) {
      month = "Jun";
    } else if (m === 6) {
      month = "Jul";
    } else if (m === 7) {
      month = "Aug";
    } else if (m === 8) {
      month = "Sep";
    } else if (m === 9) {
      month = "Oct";
    } else if (m === 10) {
      month = "Nov";
    } else if (m === 11) {
      month = "Dec";
    }

    return `${day} ${date} ${month}`;
  };

  useEffect(() => {
    setAlReqEndTs();
    setEndDate("");
    setHalfStart(false);
    setHalfEnd(false);
  }, []);

  let getHalfStartTime = (number, ts) => {
    let dateObj = new Date(ts);
    let dayNumber = dateObj.getDay();
    if (number === 1) {
      if (dayNumber === 0) {
        if (localSunSchedTimes) {
          return `${localSunSchedTimes.start.substr(
            0,
            2
          )}:${localSunSchedTimes.start.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 1) {
        if (localMonSchedTimes) {
          return `${localMonSchedTimes.start.substr(
            0,
            2
          )}:${localMonSchedTimes.start.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 2) {
        if (localTueSchedTimes) {
          return `${localTueSchedTimes.start.substr(
            0,
            2
          )}:${localTueSchedTimes.start.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 3) {
        if (localWedSchedTimes) {
          return `${localWedSchedTimes.start.substr(
            0,
            2
          )}:${localWedSchedTimes.start.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 4) {
        if (localThuSchedTimes) {
          return `${localThuSchedTimes.start.substr(
            0,
            2
          )}:${localThuSchedTimes.start.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 5) {
        if (localFriSchedTimes) {
          return `${localFriSchedTimes.start.substr(
            0,
            2
          )}:${localFriSchedTimes.start.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 6) {
        if (localSatSchedTimes) {
          return `${localSatSchedTimes.start.substr(
            0,
            2
          )}:${localSatSchedTimes.start.substr(2, 2)}`;
        } else {
          return "½";
        }
      }
    } else if (number === 2) {
      if (dayNumber === 0) {
        if (localSunSchedTimes) {
          return `${localSunSchedTimes.firstHalfEnd.substr(
            0,
            2
          )}:${localSunSchedTimes.firstHalfEnd.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 1) {
        if (localMonSchedTimes) {
          return `${localMonSchedTimes.firstHalfEnd.substr(
            0,
            2
          )}:${localMonSchedTimes.firstHalfEnd.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 2) {
        if (localTueSchedTimes) {
          return `${localTueSchedTimes.firstHalfEnd.substr(
            0,
            2
          )}:${localTueSchedTimes.firstHalfEnd.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 3) {
        if (localWedSchedTimes) {
          return `${localWedSchedTimes.firstHalfEnd.substr(
            0,
            2
          )}:${localWedSchedTimes.firstHalfEnd.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 4) {
        if (localThuSchedTimes) {
          return `${localThuSchedTimes.firstHalfEnd.substr(
            0,
            2
          )}:${localThuSchedTimes.firstHalfEnd.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 5) {
        if (localFriSchedTimes) {
          return `${localFriSchedTimes.firstHalfEnd.substr(
            0,
            2
          )}:${localFriSchedTimes.firstHalfEnd.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 6) {
        if (localSatSchedTimes) {
          return `${localSatSchedTimes.firstHalfEnd.substr(
            0,
            2
          )}:${localSatSchedTimes.firstHalfEnd.substr(2, 2)}`;
        } else {
          return "½";
        }
      }
    } else if (number === 3) {
      if (dayNumber === 0) {
        if (localSunSchedTimes) {
          return `${localSunSchedTimes.end.substr(
            0,
            2
          )}:${localSunSchedTimes.end.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 1) {
        if (localMonSchedTimes) {
          return `${localMonSchedTimes.end.substr(
            0,
            2
          )}:${localMonSchedTimes.end.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 2) {
        if (localTueSchedTimes) {
          return `${localTueSchedTimes.end.substr(
            0,
            2
          )}:${localTueSchedTimes.end.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 3) {
        if (localWedSchedTimes) {
          return `${localWedSchedTimes.end.substr(
            0,
            2
          )}:${localWedSchedTimes.end.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 4) {
        if (localThuSchedTimes) {
          return `${localThuSchedTimes.end.substr(
            0,
            2
          )}:${localThuSchedTimes.end.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 5) {
        if (localFriSchedTimes) {
          return `${localFriSchedTimes.end.substr(
            0,
            2
          )}:${localFriSchedTimes.end.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 6) {
        if (localSatSchedTimes) {
          return `${localSatSchedTimes.end.substr(
            0,
            2
          )}:${localSatSchedTimes.end.substr(2, 2)}`;
        } else {
          return "½";
        }
      }
    } else if (number === 4) {
      if (dayNumber === 0) {
        if (localSunSchedTimes) {
          return `${localSunSchedTimes.secondHalfStart.substr(
            0,
            2
          )}:${localSunSchedTimes.secondHalfStart.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 1) {
        if (localMonSchedTimes) {
          return `${localMonSchedTimes.secondHalfStart.substr(
            0,
            2
          )}:${localMonSchedTimes.secondHalfStart.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 2) {
        if (localTueSchedTimes) {
          return `${localTueSchedTimes.secondHalfStart.substr(
            0,
            2
          )}:${localTueSchedTimes.secondHalfStart.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 3) {
        if (localWedSchedTimes) {
          return `${localWedSchedTimes.secondHalfStart.substr(
            0,
            2
          )}:${localWedSchedTimes.secondHalfStart.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 4) {
        if (localThuSchedTimes) {
          return `${localThuSchedTimes.secondHalfStart.substr(
            0,
            2
          )}:${localThuSchedTimes.secondHalfStart.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 5) {
        if (localFriSchedTimes) {
          return `${localFriSchedTimes.secondHalfStart.substr(
            0,
            2
          )}:${localFriSchedTimes.secondHalfStart.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 6) {
        if (localSatSchedTimes) {
          return `${localSatSchedTimes.secondHalfStart.substr(
            0,
            2
          )}:${localSatSchedTimes.secondHalfStart.substr(2, 2)}`;
        } else {
          return "½";
        }
      }
    }
  };

  let generateOverlapDays = overlapsWithOtherReqs.map((item) => {
    return (
      <p className="overlappingDayOff">
        {dateStringer.printedDateFromDs(item.ds, true)}
      </p>
    );
  });

  useEffect(() => {
    if (halfStart) {
      setStartHalfString(getHalfStartTime(2, alReqStartTs));
    } else {
      setStartHalfString();
    }
    if (halfEnd) {
      setEndHalfString(getHalfStartTime(4, alReqEndTs));
    } else {
      setEndHalfString();
    }
  }, [halfStart, halfEnd]);

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);

      // Add the event listener for "popstate" event
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", handlePopstate);

      // Cleanup function to remove the event listener when the component unmounts
      return () => {
        window.removeEventListener("popstate", handlePopstate);
      };
    };
  }, []);

  let requestStart = dateStringer.createStringFromTimestamp(alReqStartTs);
  let requestEnd = dateStringer.createStringFromTimestamp(alReqEndTs);

  let formatZMinsDisplay = (zMins) => {
    let hours = Math.floor(zMins / 60);
    let mins = zMins % 60;
    if (mins !== 0) {
      if (zMins < 60) {
        return `${Math.round(mins)} minutes`;
      } else if (zMins < 120) {
        return `${hours} hour & ${Math.round(mins)} mins`;
      } else {
        return `${hours} hours & ${Math.round(mins)} mins`;
      }
    } else {
      if (hours === 1) {
        return `${hours} hour`;
      } else {
        return `${hours} hours`;
      }
    }
  };

  const cancelSource1 = axios.CancelToken.source();
  const cancelSource2 = axios.CancelToken.source();
  // const cancelSource3 = axios.CancelToken.source();

  // let [myFromReqID, setMyFromReqID] = useState("");

  useEffect(() => {
    // if (reqID) {
    //   axios
    //     .post(
    //       `${serverURL}/get-req-details`,
    //       { reqID },

    //       {
    //         withCredentials: true,
    //         credentials: "include",
    //         cancelToken: cancelSource3.token,
    //       }
    //     )
    //     .then((response) => {
    //       if (response.data.message === "success") {
    //         setAlReqStartTs(
    //           dateStringer.createTimestampFromString(response.data.startDs)
    //         );

    //         setMyFromReqID(
    //           dateStringer.getMyFromTs(
    //             dateStringer.createTimestampFromString(response.data.startDs)
    //           )
    //         );
    //         setAlReqEndTs(
    //           dateStringer.createTimestampFromString(response.data.endDs)
    //         );
    //         setHalfStart(response.data.reqStartHalf);
    //         setHalfEnd(response.data.reqEndHalf);
    //       }
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     });
    // }

    axios
      .post(
        `${serverURL}/get-available-request-types`,
        { requester: true },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (
            response.data.workingPattern === "fixedScheduled"
            // && !response.data.fixedSchedOdd
          ) {
            response.data.schedule.forEach((item) => {
              if (item.day === "sun") {
                setLocalSunSchedTimes(item);
              } else if (item.day === "mon") {
                setLocalMonSchedTimes(item);
              } else if (item.day === "tues") {
                setLocalTueSchedTimes(item);
              } else if (item.day === "wed") {
                setLocalWedSchedTimes(item);
              } else if (item.day === "thu") {
                setLocalThuSchedTimes(item);
              } else if (item.day === "fri") {
                setLocalFriSchedTimes(item);
              } else if (item.day === "sat") {
                setLocalSatSchedTimes(item);
              }
            });
          }
          // setShowAnnLeave(response.data.annualLeave);
          memoVals.setTilAvailable((x) => response.data.til);
          memoVals.setAvailableLeaveTypes((x) => response.data.custom);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .post(
        `${serverURL}/how-many-months`,
        { someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource2.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setMaxDs(response.data.maxDs);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
      cancelSource2.cancel("Component unmounted");
      // cancelSource3.cancel("Component unmounted");
    };
  }, []);

  return (
    <div
      className={`reqModalContainer ${
        memoVals.mobModal
          ? `mobReqModalContainer ${
              memoVals.device.ios ? "mobReqModalContainerIos" : ""
            }`
          : ""
      }`}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={modalPosition}
      ref={modalRef}
    >
      {memoVals.mobModal && (
        <div className="modalSwiper modalSwiperMakeLeaveReq"></div>
      )}
      {/* header */}
      <div
        className="reqModalHeader"
        onTouchStart={handleDown}
        onTouchMove={memoVals.mob ? handleMove : null}
        onTouchEnd={memoVals.mob ? handleUp : null}
        onMouseDown={memoVals.mob ? handleDown : null}
        onMouseMove={memoVals.mob ? handleMove : null}
        onMouseUp={memoVals.mob ? handleUp : null}
      >
        <img
          src={navycross}
          className="closeReqModalImg"
          onClick={() => {
            setHalfEnd(false);
            setHalfStart(false);
            setAlReqEndTs();
            setAlReqStartTs();
            memoVals.setShowAdd((x) => false);
            memoVals.setShowReqModal((x) => false);
          }}
        />
        <p className="reqLeaveTitle">New request</p>
        &nbsp;
      </div>
      {/* end of header */}

      {/* dates and likeliness */}
      <div
        className="previewContainer"
        onTouchStart={handleDown}
        onTouchMove={memoVals.mob ? handleMove : null}
        onTouchEnd={memoVals.mob ? handleUp : null}
        onMouseDown={memoVals.mob ? handleDown : null}
        onMouseMove={memoVals.mob ? handleMove : null}
        onMouseUp={memoVals.mob ? handleUp : null}
      >
        <p
          className={`${
            alReqStartTs || alReqEndTs ? "reqDateRangeTxt" : "none"
          }`}
        >
          <span className={`${alReqStartTs ? "startD" : "none"}`}>
            {generateDateString(alReqStartTs)}
          </span>{" "}
          <span className={`${startHalfString ? "halfStartTime" : "none"}`}>
            {startHalfString}
          </span>
          &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;
          <span className={`${alReqEndTs ? "endD" : "none"}`}>
            {generateDateString(alReqEndTs) || "no end"}
          </span>{" "}
          <span className={`${!alReqEndTs ? "startD x98298131133" : "none"}`}>
            Select an end date
          </span>
          <span className={`${endHalfString ? "halfStartTime" : "none"}`}>
            {endHalfString}
          </span>
        </p>
        <p
          className={`${
            alReqStartTs || alReqEndTs ? "none" : "selectDatesTxt"
          }`}
        >
          Select your first and last day of leave.
        </p>
        <p
          className={`${reqDur === 0 ? "alreadyOffTxt" : "none"} ${
            alReqStartTs || alReqEndTs ? "" : "none_"
          }`}
        >
          No leave is required
        </p>
        <div className={`${alReqEndTs && reqDur !== 0 ? "" : "none"}`}>
          <p className="reqLikelyTxt">
            {reqDur} day{`${reqDur > 1 ? "s" : ""}`} to be requested
          </p>
        </div>
      </div>
      {/* end of dates and likeliness */}

      <div className="reqPageKey">
        <div
          className={`page1Bullet ${reqPage === 1 ? "pageBulletSelected" : ""}`}
        ></div>
        <div
          className={`page2Bullet ${reqPage === 2 ? "pageBulletSelected" : ""}`}
        ></div>
      </div>

      {/* P A G E  1 */}
      <div className={` ${reqPage === 1 ? "requestPage1" : "none"} `}>
        {/* type */}
        <div className="typeContainer">
          <p className="typeTxt typeOnlyTxt">Type</p>

          <select
            className="desktopTypesDropdown"
            value={reqLeaveTypeID}
            onChange={(e) => {
              setReqLeaveTypeID(e.target.value);
            }}
          >
            <option value="annLeave">Annual Leave</option>
            {generateTypes3}
          </select>
          {/* <div className={`${memoVals.mob ? "typesBox" : "none"}`}>
            <div className="typesInnerContainer">
              <div
                className={`${
                  reqLeaveTypeID === "annLeave"
                    ? "reqTypeItem selTypeItem"
                    : "reqTypeItem"
                }`}
                onClick={() => {
                  setReqLeaveTypeID("annLeave");
                }}
              >
                Annual Leave
              </div>
              {generateTypes2}
            </div>
          </div> */}
        </div>
        {/* end of type */}

        {/* calendar section */}

        <div className="datePickerContainer">
          <div className="reqScrollableContainer">
            <ReqDateInputter
              setTooFarAhead={setTooFarAhead}
              setRenewalOverlap={setRenewalOverlap}
              localSunSchedTimes={localSunSchedTimes}
              localMonSchedTimes={localMonSchedTimes}
              localTueSchedTimes={localTueSchedTimes}
              localWedSchedTimes={localWedSchedTimes}
              localThuSchedTimes={localThuSchedTimes}
              localFriSchedTimes={localFriSchedTimes}
              localSatSchedTimes={localSatSchedTimes}
              endDate={endDate}
              setEndDate={setEndDate}
              alReqStartTs={alReqStartTs}
              alReqEndTs={alReqEndTs}
              setAlReqStartTs={setAlReqStartTs}
              setAlReqEndTs={setAlReqEndTs}
              setHalfStart={setHalfStart}
              setHalfEnd={setHalfEnd}
              halfStart={halfStart}
              halfEnd={halfEnd}
              reqDur={reqDur}
              setReqDur={setReqDur}
              setReqDur2={setReqDur2}
              setStartDate={setStartDate}
              startDate={startDate}
              // setOverlappingDs={setOverlappingDs}
              maxDs={maxDs}
              loadedFromCal={ds ? ds : false}
              // myFromReqID={myFromReqID}
              // reqID={reqID}
            />
          </div>
          {/* end of calendar section */}
        </div>
      </div>

      <div
        className={`${
          reqPage === 2 && !loading ? "addNoteToReqContainer" : "none"
        }`}
      >
        <p></p>
        <div
          className={`addNoteToReqBtn ${
            overlapsWithOtherReqs.length > 0 || notYetAvailable ? "none" : ""
          }`}
          onClick={() => {
            setShowNote(!showNote);
          }}
        >
          {note ? "Edit" : "Add"} note
          <img src={edit} alt="Add note" className="addNoteToReqBtnImg" />
        </div>
      </div>
      {/* P A G E  2 */}
      <img
        className={`${loading && reqPage === 2 ? "reqLoadingGifImg" : "none"}`}
        src={blueLoader}
      />
      <p
        className={`${
          reqPage === 2 && !loading ? "reqMakerLeaveTypeName" : "none"
        }`}
      >
        {leaveTypeName}
      </p>
      <div
        className={`${
          reqPage === 2 ? `requestPage2 ${loading ? "invis" : ""}` : "none"
        }`}
      >
        <div className={`${loading ? "none" : "allowanceInfoContainer"}`}>
          <div
            className={`${
              overlapsWithOtherReqs.length > 0
                ? "zMinsExplainTxt moveIfApprovedTxtDown"
                : "none"
            }
            `}
          >
            Unable to make this request. You have already requested the
            following days off:
          </div>{" "}
          <div
            className={`${
              notYetAvailable ? "zMinsExplainTxt moveIfApprovedTxtDown" : "none"
            }
            }`}
          >
            The dates you have selected are not yet available to be requested.
          </div>{" "}
          <br />
          <div
            className={`${
              !notYetAvailable && overlapsWithOtherReqs.length > 0
                ? "overlappingDsArr"
                : "none"
            }
              }`}
          >
            {overlapsWithOtherReqs.length > 0 ? generateOverlapDays : ""}
          </div>
          <div
            className={`${
              (!notYetAvailable && overlapsWithOtherReqs.length > 0) ||
              memoVals.checkUserState.workingPattern === "zero"
                ? "zMinsSplitter overlapsSplitter none"
                : "none"
            }`}
          ></div>
          <p
            className={`${
              overlapsWithOtherReqs.length > 0
                ? "cannotOverlapTxt none"
                : "none"
            }`}
          >
            Requests cannot overlap other requests. Please amend or cancel the
            other request first.
          </p>
          <div className={`${overlapsWithOtherReqs.length > 0 ? "none" : ""}`}>
            <div
              className={`${
                reqWorkingPattern === "zero" && !notYetAvailable
                  ? "zMinsExplainTxt zeroZMinsExplainer"
                  : "none"
              }`}
            >
              You have worked an average of{" "}
              <span className="avgMpdBoldSpan">
                {dateStringer.formatMinsDurationToHours(excessMpdInternal)}
              </span>{" "}
              per workable day, so of your {leaveTypeName.toLowerCase()}{" "}
              allowance in {allowanceYear}, this request will use:
              <br />
              {/* <br /> */}
              <div className="zeroTxtSpacerMakeReq"></div>
            </div>
            {limitsArr[0] ? (
              <div className="reqMakerLimitsBox">
                <p className="zMinsExplainTxt likeliHoodTxt">
                  The likelihood of your request being approved might be
                  affected because the following leave limits will be exceeded:
                </p>
                {generateLimitsArr}
              </div>
            ) : (
              ""
            )}
            <p
              className={`${
                memoVals.checkUserState.workingPattern !== "zero" &&
                !notYetAvailable
                  ? `zMinsExplainTxt`
                  : "none"
              }`}
            >
              Of your {leaveTypeName.toLowerCase()} allowance in{" "}
              <span className="allowanceYrBold">{allowanceYear}</span>,
              <br />
              this request will use:
            </p>
            <div
              className={`${
                //   daysOrHours !== "hours" || workingPatternChangedMidTerm
                (daysOrHours === "hours" ||
                  memoVals.checkUserState.workingPattern === "zero") &&
                !notYetAvailable
                  ? "zMinsDisplayContainer"
                  : "none"
              } ${
                memoVals.checkUserState.workingPattern === "zero" &&
                "zeroDisplayContainer"
              }`}
            >
              {allowanceObject.daysOrHours === "days" &&
              reqLeaveTypeID !== "annLeave"
                ? `${daysLength} day${daysLength === 1 ? "" : "s"}`
                : formatZMinsDisplay(zMins)}
              {allowanceObject.daysOrHours === "days" &&
              reqLeaveTypeID !== "annLeave" ? (
                ""
              ) : (
                <br />
              )}
              {(allowanceObject.daysOrHours === "days" &&
                reqLeaveTypeID !== "annLeave") ||
              reqWorkingPattern === "zero" ||
              allowanceObject.remainingAllowanceMinsPost <= 0 ? (
                ""
              ) : (
                <span
                  className="reqConversionSpan"
                  onClick={() => {
                    console.log(zMins, dailyMins);
                  }}
                >
                  {dateStringer.convertMinsToDays(zMins, dailyMins, true)}
                </span>
              )}
            </div>

            {allowanceObject.remainingAllowanceMinsPost < 0 ? (
              <p className="insufficAllow">Not enough allowance left</p>
            ) : (
              <p className="sufficAllow">
                Sufficient allowance left{" "}
                <img
                  src={greenTick}
                  alt="Enough allowance"
                  className="sufficTickImg"
                />
              </p>
            )}
            <div
              className={`${
                !notYetAvailable ? "reqMakerAllowanceBox" : "none"
              }`}
            >
              <div className="reqMakerAllowRow">
                <p
                  className="reqMakerAllowRowTitle"
                  onClick={() => {
                    console.log({ allowanceObject });
                  }}
                >
                  Currently remaining:
                </p>
                <div className="reqMakerRowValueBox">
                  <p className="reqMakerAllowRowValue">
                    {allowanceObject.daysOrHours === "hours" ||
                    (memoVals.checkUserState.workingPattern !== "zero" &&
                      allowanceObject.daysOrHours === "days" &&
                      reqLeaveTypeID === "annLeave")
                      ? dateStringer.formatMinsDurationToHours(
                          allowanceObject.remainingAlowanceMinsPre,
                          false,
                          true
                        )
                      : `${allowanceObject.remainingAlowanceMinsPre} days`}
                  </p>

                  <p
                    className={`${
                      allowanceObject.daysOrHours === "hours" ||
                      (memoVals.checkUserState.workingPattern !== "zero" &&
                        allowanceObject.daysOrHours === "days" &&
                        reqLeaveTypeID === "annLeave")
                        ? "reqMakerAllowRowValue extendAllowanceTxtReq"
                        : "none"
                    } ${
                      memoVals.checkUserState.workingPattern === "zero" ||
                      allowanceObject.remainingAllowanceMinsPost <= 0
                        ? "none"
                        : ""
                    }`}
                  >
                    {dateStringer.convertMinsToDays(
                      allowanceObject.remainingAlowanceMinsPre,
                      dailyMins,
                      true
                    )}
                  </p>
                </div>
              </div>
              <div className="reqMakerAllowRow">
                <p className="reqMakerAllowRowTitle">Remaining if approved:</p>
                <div className="reqMakerRowValueBox">
                  <p className="reqMakerAllowRowValue">
                    {" "}
                    {allowanceObject.daysOrHours === "hours" ||
                    (memoVals.checkUserState.workingPattern !== "zero" &&
                      allowanceObject.daysOrHours === "days" &&
                      reqLeaveTypeID === "annLeave")
                      ? dateStringer.formatMinsDurationToHours(
                          allowanceObject.remainingAllowanceMinsPost,
                          false,
                          true
                        )
                      : `${allowanceObject.remainingAllowanceMinsPost} days`}
                  </p>
                  <p
                    className={`${
                      allowanceObject.daysOrHours === "hours" ||
                      (memoVals.checkUserState.workingPattern !== "zero" &&
                        allowanceObject.daysOrHours === "days" &&
                        reqLeaveTypeID === "annLeave")
                        ? "reqMakerAllowRowValue extendAllowanceTxtReq"
                        : "none"
                    } ${
                      memoVals.checkUserState.workingPattern === "zero" ||
                      allowanceObject.remainingAllowanceMinsPost <= 0
                        ? "none"
                        : ""
                    }`}
                  >
                    {dateStringer.convertMinsToDays(
                      allowanceObject.remainingAllowanceMinsPost,
                      dailyMins,
                      true
                    )}
                  </p>
                </div>{" "}
              </div>
            </div>
            {note ? <p className="noteTitleX">Your note</p> : ""}
            {note ? <p className="noteTitleXval">{note}</p> : ""}
            {!notYetAvailable && (
              <div className="reqApproversDiv">
                <p className="approversTitle">
                  Staff who can approve this request:
                </p>

                {Array.isArray(approvers) &&
                  approvers.map((user) => {
                    return (
                      <div key={user.userID} className="approverUserRow">
                        <img
                          src={user.picUrl || profilePicturePlaceholder}
                          alt={user.fName}
                          className="approverRowImg"
                        />
                        <div className="approverUserRowRight">
                          <p className="approverUserName">
                            {user.fName} {user.lName}
                            {user.you ? (
                              <span className="youSpan">(You)</span>
                            ) : (
                              ""
                            )}
                          </p>
                          <p className="approverAdminOrMgr">
                            {user.admin ? (
                              <span className="approverSpan">Admin</span>
                            ) : (
                              ""
                            )}{" "}
                            {user.mgr ? (
                              <span className="approverSpan approverSpanMgr">
                                Manager
                              </span>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end of pages */}

      <div
        className={`${showNote ? "halfTimeModalUnderlay" : "none"}`}
        onClick={() => {
          setShowNote(false);
        }}
      >
        <div
          className="halfTimeModalBox"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <p className="halfTimeModal">Add a note</p>

          <textarea
            className="addReqTextBox"
            placeholder="Leave a note for your manager"
            onChange={(e) => {
              setNote(e.target.value);
            }}
          ></textarea>
          <div className="halfModalFooter">
            <p
              className={`saveBtnHalfModal`}
              onClick={() => {
                setShowNote(false);
              }}
            >
              Save
            </p>
            <p
              className={note ? "clearReqNoteBtn" : "invis"}
              onClick={() => {
                setNote("");
                setShowNote(false);
              }}
            >
              Remove
            </p>
            {/* <p
              className="closeReqInfo"
              onClick={() => {
                setShowNote(false);
              }}
            >
              Close
            </p> */}
          </div>
        </div>
      </div>

      {/* footer */}
      <div className={`reqModalFooterContainer`}>
        <div
          className={`reqAndCloseBtns ${
            reqPage === 2 ? "reqAndCloseBtnsPage2" : ""
          }`}
        >
          <div className="makeReqFooterLeft">
            <p
              className={`${reqPage === 2 ? "reqBtn" : "none"} ${
                valid && overlapsWithOtherReqs.length === 0 && !notYetAvailable
                  ? ""
                  : "invalidReqSubmitBtnFade"
              } ${loading ? "invalidReqSubmitBtnFade" : ""} ${
                overlapsWithOtherReqs.length > 0 ? "none" : ""
              }`}
              onClick={() => {
                if (
                  alReqEndTs &&
                  alReqStartTs &&
                  valid &&
                  !blockSubmit &&
                  !notYetAvailable
                ) {
                  setBlockSubmit(true);

                  axios
                    .post(
                      `${serverURL}/make-new-request`,
                      {
                        leaveTypeID: reqLeaveTypeID,
                        isUser: true,
                        startDs: requestStart,
                        endDs: requestEnd,
                        startHalf:
                          memoVals.workingPattern === "zero"
                            ? false
                            : halfStart,
                        endHalf:
                          memoVals.workingPattern === "zero" ? false : halfEnd,

                        note: note,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                        // deleteReqID: reqID,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setCountData({
                          userLeave: countData.userLeave + 1,
                        });
                        memoVals.setShowReqModal((x) => false);
                        memoVals.setShowUserReqFromNav(
                          (x) => response.data.reqID.newReqID
                        );
                        memoVals.setLoadUserReqOnceMade(
                          (x) => response.data.reqID.newReqID
                        );
                        console.log(
                          "response.data.reqID.newReqID: ",
                          response.data.reqID.newReqID
                        );
                        memoVals.setUpdateUserReqs(
                          (x) => !memoVals.updateUserReqs
                        );
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }}
            >
              Submit
            </p>
            <p
              className={`${reqPage === 2 ? "reqBackBtn" : "none"} ${
                loading && reqPage === 2 ? "none" : ""
              } ${overlapsWithOtherReqs.length > 0 ? "marginLeft0" : ""}`}
              onClick={() => {
                setReqPage(1);
                setShowNote(false);
              }}
            >
              Back
            </p>
          </div>

          <p
            className={`${reqPage === 1 ? "reqBtnNext" : "none"} ${
              alReqEndTs &&
              alReqStartTs &&
              checkValid(alReqStartTs, alReqEndTs, halfStart, halfEnd) &&
              !renewalOverlap &&
              !tooFarAhead
                ? ""
                : "nextDisabled"
            }`}
            onClick={() => {
              setLoading(true);
              setReqPage(2);

              axios
                .post(
                  `${serverURL}/get-leave-request-pre-data`,
                  {
                    userID: memoVals.userID,
                    leaveTypeID: reqLeaveTypeID,
                    startDs: requestStart,
                    endDs: requestEnd,
                    startHalf:
                      memoVals.workingPattern === "zero" ? false : halfStart,
                    endHalf:
                      memoVals.workingPattern === "zero" ? false : halfEnd,
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    console.log(response.data);
                    let existingReqMins = response.data.existingReqMins || 0;

                    setZMins(response.data.totalMinsWithoutExcess);
                    // setAdditionalExcess(response.data.additionalExcess);
                    setValid(response.data.valid);
                    setExcessMpdInternal(response.data.avgMpd);
                    setAllowanceYear(response.data.cliYr);
                    setLeaveTypeName(
                      response.data.leaveTypeName || "Annual leave"
                    );
                    setNotYetAvailable(response.data.notYetAvailable);
                    setOverlapsWithOtherReqs(
                      response.data.overlapsWithOtherReqs
                    );
                    setLoading(false);
                    setReqWorkingPattern(response.data.workingPattern);
                    memoVals.setCheckUserState((x) => {
                      return {
                        workingPattern: response.data.workingPattern,
                      };
                    });
                    // setDayable(response.data.dayable);
                    setDailyMins(response.data.dailyMins);
                    setAllowanceObject(response.data.allowanceObject);
                    let fulls = 0;
                    let halfs = 0;
                    response.data.fulls.forEach((ds) => {
                      fulls++;
                    });
                    response.data.halfs.forEach((ds) => {
                      halfs++;
                    });
                    setDaysLength(fulls + halfs);

                    setLimitsArr(response.data.limitsArr);
                    setApprovers(response.data.approvers);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            Next
          </p>
        </div>
        <p
          className={`closeReqInfo`}
          onClick={() => {
            setHalfEnd(false);
            setHalfStart(false);
            setAlReqEndTs();
            setAlReqStartTs();
            memoVals.setShowAdd((x) => false);
            memoVals.setShowReqModal((x) => false);
          }}
        >
          Close
        </p>
      </div>
      {/* end of footer */}
    </div>
  );
};

export default MakeLeaveReq;
