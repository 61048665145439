import React, { useContext, useEffect, useState, useRef, useMemo } from "react";

import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";
import { CalendarContext } from "../contexts/CalendarContext";
// ios done 8 sep 24
import axios from "axios";
import serverURL from "../serverURL";
import appURL from "../appURL";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import navyClose from "../img/general/navycross.svg";

import popup2 from "../img/general/popup2.svg";
import unclockCal from "../img/general/rotaDate.svg";
import unclockTime from "../img/general/rotaClock.svg";
import teamIcon from "../img/general/rotaTeam.svg";
import openShiftBlock from "../img/general/openShiftBlock.svg";

const MgrOpenShiftsModal = () => {
  const { mobModal } = useContext(StyleContext);
  const { checkUserState } = useContext(UserContext);
  const { setShowOpenShiftModal } = useContext(CalendarContext);

  const {
    setIndicate,
    setShowOpenShiftsMgrModal,
    updateOpensModal,
    modalOpen,
    setModalOpen,
    device,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setIndicate,
      setShowOpenShiftsMgrModal,
      checkUserState,
      mobModal,
      setShowOpenShiftModal,
      updateOpensModal,
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      setIndicate, //
      setShowOpenShiftsMgrModal,
      checkUserState, //
      mobModal, //
      setShowOpenShiftModal,
      updateOpensModal,
      modalOpen,
      setModalOpen,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 6) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={unclockCal}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={openShiftBlock}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={navyClose}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup2}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unclockTime}
        alt="Unit Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={teamIcon}
        alt="Grey Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let closeModal = () => {
    memoVals.setShowOpenShiftsMgrModal((x) => false);
  };

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      closeModal(false);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let [loaded, setLoaded] = useState(false);

  let [opens, setOpens] = useState([]);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-open-shifts-mgr`,
        {
          someData: 1,
        },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setDataLoaded(true);
          setLoaded(true);
          setOpens(response.data.opens || []);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [updateOpensModal]);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };
  // master return
  return (
    <div
      className={`clockOnModalUnderlay `}
      onClick={() => {
        closeModal();
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`mySwapsModal ${
            memoVals.mobModal ? "mobModalShoulder" : "x209dw022"
          } ${memoVals.device.ios ? "paddingBottom35" : ""} mgrModal`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperTeamSwaps"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className="mySwapsHeader"
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <img
              src={memoVals.mobModal ? navyClose : navyClose}
              alt="Close"
              className="closeMySwapsModalImg"
              onClick={() => {
                closeModal();
              }}
            />
            <p>Manage open shifts</p>

            <p></p>
          </div>

          <div
            className={`mySwapsContainer ${
              memoVals.mobModal ? "mgrAcceptanceContainer" : ""
            }`}
          >
            {!loaded ? (
              <img
                src={horizontalBalls}
                alt="Loading"
                className="loadingSwaps"
              />
            ) : (
              ""
            )}
            {!loaded ? (
              ""
            ) : opens.length === 0 ? (
              <p className="noSwapsTxt">
                Open shifts across teams you manage will be displayed here
              </p>
            ) : (
              opens.map((unit, i) => {
                return (
                  <div
                    className={`acceptUnit ${
                      i === 0 ? "acceptUnitFirst" : ""
                    } ${!memoVals.mobModal ? "acceptUnitDesktop" : ""} ${
                      unit.declined ? "acceptUnitDeclinedItem" : "cursorPointer"
                    }`}
                    onClick={() => {
                      memoVals.setShowOpenShiftModal((x) => {
                        return {
                          ds: unit.ds,
                          openID: unit.openID,
                          new: false,
                          teamID: unit.teamID,
                        };
                      });
                    }}
                  >
                    <div className="acceptUnitInner">
                      <div className="acceptUnitLeft">
                        <p className="acceptUnitName mgrOpensTitle">
                          <img
                            src={openShiftBlock}
                            alt="Open shift"
                            className="openShBlockIcon"
                          />
                          Open {unit.type === "til" ? "overtime" : unit.type}
                        </p>

                        <div className="acceptTypeAndStrip">
                          <p
                            className={`openTimesBox ${
                              unit.declined ? "declinedAccUnitBorder" : ""
                            }`}
                          >
                            <img
                              src={unclockCal}
                              alt="Time"
                              className="accTimeCalIcon"
                            />{" "}
                            {dateStringer.printedDateWithYrFromDs(unit.startDs)}{" "}
                            {unit.declined && (
                              <img
                                src={popup2}
                                alt="View rota"
                                className="acceptanceDsPopupImg"
                              />
                            )}
                            <br />
                            <span className="acceptUnitDsSpan">
                              <img
                                src={unclockTime}
                                alt="Time"
                                className="accTimeCalIcon accTimeCalIcon1"
                              />{" "}
                              {dateStringer.dsToTimeStrip(unit.startDs, true)} -{" "}
                              {dateStringer.dsToTimeStrip(unit.endDs, true)}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="acceptUnitRight">
                        <p className="acceptUnitType">
                          {dateStringer.formatMinsDurationToHours(
                            unit.durMins || 0
                          )}
                        </p>
                        {/* <p className="acceptUnitDsMade">Created</p> */}

                        {/* <p
                          className={`acceptUnitString acceptUnitStringAddedBy `}
                        >
                          {dateStringer.printedDateFromDs(unit.createdDs)}{" "}
                        </p> */}
                      </div>
                    </div>
                    <div className="openShiftsRowTeamAndBids">
                      <p className="acceptDeclineTeamName openTeamNameMgr">
                        <img
                          src={teamIcon}
                          alt="Team"
                          className="teamIconAccept"
                        />
                        {unit.teamName || "Teamless"}
                      </p>
                      <p
                        className={`opensUnitBids ${
                          !unit.bids ? "bbcada" : "greenBids"
                        } `}
                      >
                        {unit.bids === 0 ? "No" : unit.bids} bid
                        {unit.bids === 1 ? "" : "s"}
                      </p>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <div className="mySwapsModalFooter">
            <p></p>

            <p
              className="closeSeeUserIDBtn"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}

      {imgPreload}
    </div>
  );
};

export default MgrOpenShiftsModal;
