import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useMemo,
  useRef,
  Fragment,
} from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { UserContext } from "../../contexts/UserContext";
import { StyleContext } from "../../contexts/StyleContext";
import { DataContext } from "../../contexts/DataContext";

import { NavLink } from "react-router-dom";
import imageCompression from "browser-image-compression";

import serverURL from "../../serverURL";

import Navbar from "../Navbar";
import CheckUser from "../../tools/CheckUser";
import MgrAccountNavbar from "../account/MgrAccountNavbar";

import "../../styles/settings.css";
import horizontalBalls from "../../img/loaders/horizontalBalls.svg";

import close from "../../img/modals/close.svg";
import whiteDown from "../../img/general/whiteDown.svg";
import popup3 from "../../img/general/popup3.svg";
import popup2 from "../../img/general/popup2.svg";
import profilePicturePlaceholder from "../../img/general/profilePicturePlaceholder.svg";
import editUserEdit1 from "../../img/general/editUserEdit1.svg";
import userIcon from "../../img/general/userIcon.svg";
import teamIcon from "../../img/general/teamIcon.svg";
import teamIcon2 from "../../img/general/teamIcon2.svg";
import editGrey from "../../img/general/editGrey.svg";
import signout from "../../img/general/signout.svg";
import install from "../../img/general/install.svg";
import edit from "../../img/general/edit.svg";
import bin1 from "../../img/general/bin1.svg";
import threeGreyDots from "../../img/general/threeGreyDots.svg";
import adminBurgerMob from "../../img/general/adminBurgerMob.svg";
import whiteAddThin from "../../img/general/whiteAddThin.svg";
import allowDropNavy2 from "../../img/general/allowDropNavy2.svg";

import whiteTriangle from "../../img/general/whiteTriangle.svg";
import up from "../../img/general/up.svg";
import plane2 from "../../img/general/plane2.svg";
import toilImg from "../../img/general/toil.svg";

import mgrIcon2 from "../../img/general/mgrIcon2.svg";
import clock from "../../img/general/rotaClock2.svg";
import code from "../../img/general/fileTypes/code.svg";
import doc from "../../img/general/fileTypes/doc.svg";
import file from "../../img/general/fileTypes/file.svg";
import img from "../../img/general/fileTypes/img.svg";
import music from "../../img/general/fileTypes/music.svg";
import pdf from "../../img/general/fileTypes/pdf.svg";
import ppt from "../../img/general/fileTypes/ppt.svg";
import spreadsheet from "../../img/general/fileTypes/spreadsheet.svg";
import zip from "../../img/general/fileTypes/zip.svg";
import video from "../../img/general/fileTypes/video.svg";
import folder from "../../img/general/fileTypes/folder.svg";
import folderIcon from "../../img/general/fileTypes/folderIcon.svg";
import folderIconSelf from "../../img/general/fileTypes/folderIconSelf.svg";
import folderHidden from "../../img/general/fileTypes/folderHidden.svg";
import privateIcon from "../../img/general/fileTypes/private.svg";

import convArr from "../../img/general/navySwap.svg";
import convert from "../../img/general/convert.svg";

let getFileImg = (type) => {
  if (type.startsWith("image/")) {
    return img;
  } else if (
    type.startsWith("text/") &&
    !type.includes("html") &&
    !type.includes("css") &&
    !type.includes("javascript")
  ) {
    if (type.includes("xls") || type.includes("csv")) {
      return spreadsheet;
    }
    return doc;
  } else if (type.startsWith("audio/")) {
    return music;
  } else if (type.startsWith("video/")) {
    return video;
  } else if (type.startsWith("application/") && type.includes("pdf")) {
    return pdf;
  } else if (
    type.startsWith(
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) ||
    type === "application/vnd.ms-powerpoint" ||
    type === "application/keynote"
  ) {
    return ppt;
  } else if (type.startsWith("application/zip")) {
    return zip;
  } else if (type.includes("xls") || type.includes("csv")) {
    return spreadsheet;
  } else {
    return code;
  }
};

import cal from "../../img/general/unclockCal.svg";
import download from "../../img/general/download.svg";
import greenTick from "../../img/general/greenTick.svg";
import darkRedCross from "../../img/general/darkRedCross.svg";
import unclockedCheck from "../../img/general/unclockedCheck.svg";

import TopBar from "../../components/TopBar";
// import NotifSettings from "./NotifSettings";

import dateStringer from "../../tools/dateStringer";

import MyReqsDataBox from "../requests/MyReqsDataBox";

import HoursDataBox from "../calendar/shifts/HoursDataBox";
import appURL from "../../appURL";
import DashboardPayRoll from "./DashboardPayRoll";
import DashboardAllowances from "./DashboardAllowances";
import DashboardTOIL from "./DashboardTOIL";
import DashboardDocs from "./DashboardDocs";
import DashboardDash from "./DashboardDash";

const Dashboard = ({
  contact,
  notifications,
  allowances,
  employees,
  toil,
  docs,
  dashboard,
}) => {
  // check the user is valid - this fct will update the UserContext
  CheckUser();
  const { checkUserState, currencySymbol } = useContext(UserContext);

  const { mob, full, mobModal } = useContext(StyleContext);
  const {
    setShowViewUserTeamModal,
    setShowEditUser,
    updatedDashData,
    setShowEditShiftModal,
    setShowReqMgrModal,
    setShowViewTimesheet,
    setClockOnID,
    setShowForm,
    setShowAddUserModal,
    addNewUserDynamic,
    setAddNewUserDynamic,
    updateSingleUserInUsers,
    setUpdateSingleUserInUsers,
    setIndicate,
    device,
    setShowConvertToilModal,
    setUpdateSingleUserAllowances,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setUpdateSingleUserInUsers,
      updateSingleUserInUsers,
      setShowViewUserTeamModal,
      setShowEditUser, //
      updatedDashData,
      setShowEditShiftModal,
      setShowReqMgrModal,
      setShowViewTimesheet,
      setClockOnID,
      setShowForm,
      mob,
      full,
      mobModal,
      checkUserState,
      currencySymbol, //
      setShowAddUserModal,
      addNewUserDynamic,
      setIndicate,
      setAddNewUserDynamic,
      device,
      setShowConvertToilModal,
      setUpdateSingleUserAllowances,
    }),
    [
      setUpdateSingleUserInUsers,
      updateSingleUserInUsers,
      addNewUserDynamic,
      setAddNewUserDynamic,
      setShowViewUserTeamModal,
      setShowEditUser, //
      updatedDashData,
      setShowEditShiftModal,
      setShowReqMgrModal,
      setShowViewTimesheet,
      setClockOnID,
      setShowForm,
      mob,
      full,
      mobModal,
      checkUserState,
      currencySymbol, //
      setShowAddUserModal,
      setIndicate,
      device,
      setShowConvertToilModal,
      setUpdateSingleUserAllowances,
    ]
  );

  let [sureDeleteFile, setSureDeleteFile] = useState({ show: false });

  let [availableYears, setAvailableYears] = useState([
    new Date().getFullYear(),
  ]);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  let [dashPage, setDashPage] = useState(
    params && params.userID && params.allowances
      ? "employees"
      : dashboard
      ? "dashboard"
      : docs
      ? "documents"
      : toil
      ? "toil"
      : employees
      ? "employees"
      : allowances
      ? "allowances"
      : "employees"
  );

  let [subPage, setSubPage] = useState("details");
  let [selectedUser, setSelectedUser] = useState(
    params && params.allowances && params.userID
      ? dateStringer.cleanParam(params.userID)
      : ""
  );
  let [selectedYear, setSelectedYear] = useState(
    memoVals.checkUserState.currClientYear
  );
  let [showMobMenu, setShowMobMenu] = useState(false);
  let [showUserHistory, setShowUserHistory] = useState(false);
  let [excessArr, setExcessArr] = useState([]);
  let [userTeams, setUserTeams] = useState([]);
  // let [availableYears, setAvailableYears] = useState([]);
  let [maxDs, setMaxDs] = useState("");
  let [monthArr, setMonthArr] = useState([]);
  let [selectedStartTs, setSelectedStartTs] = useState(0);
  let [minDateInput, setMinDateInput] = useState("");
  let [maxDateInput, setMaxDateInput] = useState("");
  let [dataMinTs, setDataMinTs] = useState(0);
  let [dataMaxTs, setDataMaxTs] = useState(0);
  let [hoursLoading, setHoursLoading] = useState(false);
  let [docsLoading, setDocsLoading] = useState(true);
  let [hoursDataArr, setHoursDataArr] = useState([]);
  let [userLoading, setUserLoading] = useState(true);
  let [absPage, setAbsPage] = useState("info"); // info, forms, instances
  let [formsLoading, setFormsLoading] = useState(false);
  let [excHistoryLoading, setExcHistoryLoading] = useState(true);
  let [timesheetsArr, setTimesheetsArr] = useState([]);
  let [clockCards, setClockCards] = useState([]);
  let [forms, setForms] = useState([]);
  let [lates, setLates] = useState([]);
  let [lateDurMins, setLateDurMins] = useState(0);
  let [lateQty, setLateQty] = useState(0);
  let [lateCost, setLateCost] = useState(0);
  let [unpaidLateDurMins, setUnpaidLateDurMins] = useState(0);
  let [paidLateDurMins, setPaidLateDurMins] = useState(0);
  let [toilBalance, setToilBalance] = useState(0);
  let [userBlockTil, setUserBlockTil] = useState(false);
  let [clientTilEnabled, setClientTilEnabled] = useState(false);
  let [toilConversionArr, setToilConversionArr] = useState([]);
  let [toilToAnnLeave, setToilToAnnLeave] = useState(0);
  let [annLeaveToToil, setAnnLeaveToToil] = useState(0);
  let [showConversions, setShowConversions] = useState(false);
  let [exceedSizeLimit, setExceedSizeLimit] = useState(false);
  let [showUploadModal, setShowUploadModal] = useState(false);
  let [uploadLoading, setUploadLoading] = useState(false);

  let [files, setFiles] = useState([]);
  let [totBytes, setTotBytes] = useState(0);
  let [inFolderID, setInFolderID] = useState("");
  let [inFolderName, setInFolderName] = useState("");
  let [loadingFiles, setLoadingFiles] = useState(true);
  let [renameFolderID, setRenameFolderID] = useState("");
  let [newFolderNameValue, setNewFolderNameValue] = useState("");
  let [hoveredFolderID, setHoveredFolderID] = useState("");

  let folderNameInput = useRef();
  useEffect(() => {
    if (
      renameFolderID &&
      folderNameInput &&
      folderNameInput.current &&
      !memoVals.mob
    ) {
      folderNameInput.current.focus();
    }
  }, [renameFolderID]);

  let enterFct = (e) => {
    if (e.key === "Enter" || e === "create") {
      // create folder here
      if (newFolderNameValue) {
        axios
          .post(
            `${serverURL}/new-folder`,
            {
              name: newFolderNameValue,
              userID: selectedUser,
              parentFolderID: inFolderID ? inFolderID : "",
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              let newArr = [];
              files.forEach((x) => {
                if (x.new) {
                  newArr.push({
                    folderID: response.data.folderID,
                    fileName: response.data.name,
                    folder: true,
                  });
                } else {
                  newArr.push(x);
                }
              });

              setFiles(newArr);
            }
          });
      }
    }
  };

  let countBytes = (arr, returnBytesOnly) => {
    let bytes = 0;
    arr.forEach((x) => {
      if (x.bytes) {
        bytes += x.bytes;
      }
    });

    if (returnBytesOnly) {
      return bytes;
    }
    return dateStringer.bytes(bytes);
  };
  useEffect(() => {
    if (lates && lates[0]) {
      setLates([]);
    }

    if (lateDurMins) {
      setLateDurMins(0);
    }

    if (lateQty) {
      setLateQty(0);
    }

    if (lateCost) {
      setLateCost(0);
    }

    if (unpaidLateDurMins) {
      setUnpaidLateDurMins(0);
    }

    if (paidLateDurMins) {
      setPaidLateDurMins(0);
    }

    if (!docsLoading) {
      setDocsLoading(true);
    }
    if (files[0]) {
      setFiles([]);
    }

    setInFolderID("");
    setDraggedItem({});
  }, [subPage, selectedUser]);

  useEffect(() => {
    if (
      subPage !== "details" &&
      (!params || (params && !params.userID && !params.allowances))
    ) {
      setSubPage("details");
    }
  }, [selectedUser]);

  let [tsFilter, setTsFilter] = useState("all");

  let countData = (costOrHours) => {
    if (hoursDataArr[0]) {
      let cost = 0;
      let mins = 0;
      hoursDataArr.forEach((x) => {
        if (typeof x.cost === "number") {
          cost += x.cost;
        }
        if (typeof x.durMins === "number") {
          mins += x.durMins;
        }
      });

      if (costOrHours === "hours") {
        return dateStringer.formatMinsDurationToHours(mins);
      }
      if (costOrHours === "cost") {
        return `${memoVals.currencySymbol}${cost > 0 ? cost.toFixed(2) : cost}`;
      }
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (availableYears[0]) {
      setAvailableYears([]);
    }
    if (timesheetsArr[0]) {
      setTimesheetsArr([]);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (!tsFilter !== "all") {
      setTsFilter("all");
    }
    if (timesheetsArr[0]) {
      setTimesheetsArr([]);
    }

    if (clockCards[0]) {
      setClockCards([]);
    }

    if (hoursDataArr[0]) {
      setHoursDataArr([]);
    }
    if (forms[0]) {
      setForms([]);
    }
  }, [subPage, selectedUser]);

  let [proPicLoading, setProPicLoading] = useState(false);

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={toilImg}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={whiteAddThin}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={adminBurgerMob}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteDown}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={edit}
        alt="3"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup3}
        alt="3"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup2}
        alt="4"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={profilePicturePlaceholder}
        alt="5"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editUserEdit1}
        alt="6"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={userIcon}
        alt="7"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={teamIcon}
        alt="8"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={teamIcon2}
        alt="9"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editGrey}
        alt="10"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={signout}
        alt="11"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={install}
        alt="12"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 16) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      fName: "",
      lName: "",
      jobTitle: "",
      profPicUrl: "",
      primaryTeamName: "",
      refPeriod: 0,
      startDate: "",
      avgMpd: 0,
      refPeriod: 0,
      email: "",
      contractedMpd: 0,
      payExcess: false,
      admin: false,
      salary: 0,
      salaried: false,
      hourlyRate: 0,
      hourlyRateTimeline: [],
      customs: [],
    }
  );

  let getPageName = (sub) => {
    if (sub === "details") {
      return "Details";
    }
    if (sub === "teams") {
      return "Teams";
    }
    if (sub === "salary") {
      return "Salary";
    }
    if (sub === "allowances") {
      return "Allowances";
    }
    if (sub === "absence") {
      return "Absence";
    }
    if (sub === "late") {
      return "Late starts";
    }
    if (sub === "documents") {
      return "Documents";
    }
    if (sub === "payroll") {
      return "Payroll";
    }
    if (sub === "hours") {
      return "Worked hours";
    }
    if (sub === "leave") {
      return "Leave periods";
    }
    if (sub === "timesheets") {
      return "Timesheets";
    }
    if (sub === "clockcards") {
      return "Clock cards";
    }
  };

  let [loadingAbsence, setLoadingAbsence] = useState(false);
  useEffect(() => {
    if (subPage === "absence") {
      setLoadingAbsence(true);
    }
    if (subPage === "late") {
      setLoadingAbsence(true);
    }
    setSelectedYear(memoVals.checkUserState.currClientYear);
    if (hoursDataArr[0]) {
      setHoursDataArr([]);
    }
    if (absPage !== "info") {
      setAbsPage("info");
    }

    if (timesheetsArr[0] && subPage !== "timesheets") {
      setTimesheetsArr([]);
    }
  }, [subPage]);

  let [leaveChartData, setLeaveChartData] = useState({
    taken: 0,
    booked: 0,
    remaining: 0,
    takenVal: 0,
    bookedVal: 0,
    remainingVal: 0,
    daysOrHours: "days",
  });
  let [leaveChartDataLoading, setLeaveChartDataLoading] = useState(true);

  useEffect(() => {
    if (selectedUser) {
      // console.log({ subPage });

      if (!tsFilter !== "all") {
        setTsFilter("all");
      }
      if (showUserHistory) {
        setShowUserHistory(false);
      }

      if (excessArr[0]) {
        setExcessArr([]);
      }
      if (userTeams[0]) {
        setUserTeams([]);
      }
      // reset state
      setState({
        // fName: "",
        // lName: "",
        // profPicUrl: "",
        refPeriod: 0,

        primaryTeamName: "",
        startDate: "",
        avgMpd: 0,
        refPeriod: 0,
        email: "",
        admin: false,
      });
      setUserLoading(true);

      if (subPage === "details") {
        setUserLoading(true);
        const cancelSource1 = axios.CancelToken.source();
        axios
          .post(
            `${serverURL}/get-user-dash-details`,
            {
              userID: selectedUser,
            },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource1.token,
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              // message: 'success',
              // fName: getUser[0].fName,
              // lName: getUser[0].lName,
              // startDate: getUser[0].startDate,
              // avgMpd,
              // refPeriod: getClient[0].refPeriod || 0,
              // profPicUrl: getUser[0].profPicUrl,
              // email: getUser[0].email,
              setMaxDs(response.data.maxDs);

              setState({
                fName: response.data.fName,
                lName: response.data.lName,
                jobTitle: response.data.jobTitle,
                startDate: response.data.startDate,
                avgMpd: response.data.avgMpd,
                primaryTeamName: response.data.primaryTeamName,
                refPeriod: response.data.refPeriod,
                profPicUrl: response.data.profPicUrl,
                email: response.data.email,
                excessDataArr: [],
                contractedMpd: response.data.contractedMpd,
                payExcess: response.data.payExcess,
                admin: response.data.admin,
              });

              // ---- - - - - - - - -  calc months arr

              let startDs = `${response.data.startDate.substr(0, 8)}D01H00M00`;

              let endDs = response.data.maxDs;

              if (startDs && endDs && startDs[0] === "Y" && endDs[0] === "Y") {
                let minTs = dateStringer.createTimestampFromString(startDs);
                let maxTs = dateStringer.createTimestampFromString(endDs);
                // console.log({ minTs, maxTs });

                let monthsArray = [];
                let currentDate = new Date(minTs);

                // Loop through each month until currentDate is after maxTs
                while (currentDate <= maxTs) {
                  // Get the start and end timestamps for the current month
                  const startOfMonth = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    1
                  );
                  const endOfMonth = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth() + 1,
                    0,
                    23,
                    59,
                    59
                  );

                  // Create the month object with the desired format
                  let nowTs = new Date().getTime();
                  const monthObject = {
                    startTs: startOfMonth.getTime(),
                    endTs: endOfMonth.getTime(),
                    current:
                      nowTs >= startOfMonth.getTime() &&
                      nowTs <= endOfMonth.getTime(),
                    startDs: dateStringer.createStringFromTimestamp(
                      startOfMonth.getTime()
                    ),
                    endDs: dateStringer.createStringFromTimestamp(
                      endOfMonth.getTime()
                    ),

                    title: `${startOfMonth
                      .toLocaleString("default", {
                        month: "short",
                      })
                      .substr(0, 3)} ${startOfMonth.getFullYear()}`,
                  };

                  // Push the month object into the monthsArray
                  monthsArray.push(monthObject);

                  // Move to the next month
                  currentDate.setMonth(currentDate.getMonth() + 1);
                }
                setMonthArr(monthsArray);

                let filt = monthsArray.filter((x) => {
                  return x.current;
                });

                setSelectedStartTs(filt[0] ? filt[0].startTs : 0);

                setMinDateInput(
                  dateStringer.createDateInputFromDateString(
                    filt[0] ? filt[0].startDs : ""
                  )
                );
                setMaxDateInput(
                  dateStringer.createDateInputFromDateString(
                    filt[0] ? filt[0].endDs : ""
                  )
                );
                if (params && params.userID && params.allowances) {
                  setSubPage("allowances");
                } else {
                  setUserLoading(false);
                }
              }

              // ----- --- - - - - - - -
            }
          })
          .catch((err) => {
            console.error(err);
          });
        return () => {
          cancelSource1.cancel("Component unmounted");
        };
      }
      if (subPage === "teams") {
        setUserLoading(true);

        const cancelSource1 = axios.CancelToken.source();
        axios
          .post(
            `${serverURL}/get-user-dash-teams`,
            {
              userID: selectedUser,
            },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource1.token,
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setTimeout(() => {
                setUserLoading(false);
              }, 0);

              setUserTeams(response.data.teams);
            }
          })
          .catch((err) => {
            console.error(err);
          });
        return () => {
          cancelSource1.cancel("Component unmounted");
        };
      }
      if (subPage === "salary") {
        setUserLoading(true);

        const cancelSource1 = axios.CancelToken.source();
        axios
          .post(
            `${serverURL}/get-user-dash-salary`,
            {
              userID: selectedUser,
            },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource1.token,
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setTimeout(() => {
                setUserLoading(false);
              }, 0);

              setState({
                salary: response.data.salary,
                salaried: response.data.salaried,
                hourlyRate: response.data.hourlyRate,
                hourlyRateTimeline: response.data.hourlyRateTimeline,
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
        return () => {
          cancelSource1.cancel("Component unmounted");
        };
      }
      if (
        (subPage === "allowances" && !availableYears[0]) ||
        (subPage === "absence" && !availableYears[0]) ||
        (subPage === "timesheets" && !availableYears[0]) ||
        (subPage === "clockcards" && !availableYears[0]) ||
        (subPage === "late" && !availableYears[0])
      ) {
        setUserLoading(true);

        const cancelSource2 = axios.CancelToken.source();
        axios
          .post(
            `${serverURL}/get-available-years`,
            {
              userID: selectedUser,
              userOnly: true,
            },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource2.token,
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setAvailableYears(
                response.data.availableYears || [
                  memoVals.checkUserState.currClientYear,
                ]
              );
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
      if (
        // subPage !== "absence" &&
        // subPage !== "timesheets" &&
        // subPage !== "clockcards" &&
        // subPage !== "late"
        subPage === "allowances"
      ) {
        const cancelSourceXCF = axios.CancelToken.source();
        axios
          .post(
            `${serverURL}/get-user-dash-allowances`,
            {
              userID: selectedUser,
              year: parseInt(selectedYear),
              nowDs: dateStringer.nowDs(),
            },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSourceXCF.token,
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setTimeout(() => {
                setUserLoading(false);
              }, 0);

              setTimeout(() => {
                setLeaveChartDataLoading(false);
              }, 250);

              setLeaveChartData(response.data.leaveChartData);

              let annLeaveFilt = response.data.output.filter((x) => {
                return x.leaveTypeID === "annLeave";
              });

              let customs = response.data.output.filter((x) => {
                return x.leaveTypeID !== "annLeave";
              });
              if (params && params.allowances && params.userID) {
                let currentUrl = new URL(window.location.href);

                // Remove query parameters by setting search to an empty string
                currentUrl.search = "";

                // Update the URL without the query parameters
                window.history.replaceState(
                  {},
                  document.title,
                  currentUrl.href
                );

                axios
                  .post(
                    `${serverURL}/get-available-years`,
                    {
                      userID: selectedUser,
                      userOnly: true,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      setAvailableYears(
                        response.data.availableYears || [
                          memoVals.checkUserState.currClientYear,
                        ]
                      );
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }

              // "leaveTypeName": "Annual leave",
              // "leaveTypeID": "annLeave",
              // "totMins": 10500,
              // "usedMins": 2310,
              // "totDays": 25,
              // "usedDays": 5.5,
              // "remainingMins": 8190,
              // "mode": "based on contracted hours only"
              setAnnLeaveToToil(response.data.annLeaveToToil);
              setToilToAnnLeave(response.data.toilToAnnLeave);
              setToilBalance(response.data.toilBalance);
              setUserBlockTil(response.data.userBlockTil);
              setClientTilEnabled(response.data.clientTilEnabled);
              setToilConversionArr(response.data.conversions);
              setState({
                dailyMins: response.data.dailyMins,
                percentage: response.data.annLeavePercentage,
                totMins: annLeaveFilt[0].totMins,
                usedMins: annLeaveFilt[0].usedMins,
                totDays: annLeaveFilt[0].totDays,
                usedDays: annLeaveFilt[0].usedDays,
                remainingMins: annLeaveFilt[0].remainingMins,
                mode: annLeaveFilt[0].mode,
                customs,

                // salary: response.data.salary,
                // salaried: response.data.salaried,
                // hourlyRate: response.data.hourlyRate,
                // hourlyRateTimeline: response.data.hourlyRateTimeline,
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
        return () => {
          cancelSourceXCF.cancel("Component unmounted");
        };
      }
      if (subPage === "clockcards") {
        setUserLoading(true);

        const cancelSource1 = axios.CancelToken.source();
        axios
          .post(
            `${serverURL}/get-user-dash-clock-cards`,
            {
              userID: selectedUser,
              year: parseInt(selectedYear),
              nowDs: dateStringer.createStringFromTimestamp(
                new Date().getTime()
              ),
            },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource1.token,
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setTimeout(() => {
                setUserLoading(false);
              }, 0);

              setClockCards(response.data.clockCards);
            }
          })
          .catch((err) => {
            console.error(err);
          });
        return () => {
          cancelSource1.cancel("Component unmounted");
        };
      }

      if (
        (subPage === "leave" || subPage === "hours" || subPage === "absence") &&
        minDateInput.length === 10 &&
        maxDateInput.length === 10
      ) {
        // setUserLoading(true);
        setHoursLoading(true);
        const cancelSource1 = axios.CancelToken.source();
        //     userID,
        // startDs,
        // endDs,
        // shifts,
        // leave,
        // absence,
        // downloadCsv,
        // csvShifts,
        // csvTils,
        // csvOvertimes,
        // csvLeave,
        // csvAbsence,
        axios
          .post(
            `${serverURL}/get-user-tab-data`,
            {
              userID: selectedUser,
              startDs: dateStringer.createStringFromDateInput(minDateInput),
              endDs: dateStringer.createStringFromDateInput(maxDateInput),
              shifts: subPage == "hours",
              leave: subPage === "leave" || subPage === "absence",
              absence: subPage === "absence",
              combineWithAbsentShifts: subPage === "absence",
            },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource1.token,
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setHoursLoading(false);
              setUserLoading(false);
              if (subPage === "absence") {
                let filt = response.data.output.filter((x) => {
                  return (
                    x.type === "absence" ||
                    x.type === "shift" ||
                    x.type === "overtime" ||
                    x.type === "til"
                  );
                });

                setHoursDataArr(filt);
              } else {
                setHoursDataArr(response.data.output);
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });

        return () => {
          cancelSource1.cancel("Component unmounted");
        };
      }

      if (subPage === "timesheets") {
        setUserLoading(true);

        const cancelSource1 = axios.CancelToken.source();
        axios
          .post(
            `${serverURL}/get-user-dash-timesheets`,
            {
              userID: selectedUser,
              year: parseInt(selectedYear),
            },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource1.token,
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setTimeout(() => {
                setUserLoading(false);
              }, 0);

              setTimesheetsArr(response.data.timesheetsArr);
            }
          })
          .catch((err) => {
            console.error(err);
          });
        return () => {
          cancelSource1.cancel("Component unmounted");
        };
      }
    }
  }, [
    selectedUser,
    memoVals.updatedDashData,
    dashPage,
    subPage,
    selectedYear,
    minDateInput,
    maxDateInput,
  ]);

  let [showEditProPicModal, setShowEditProPicModal] = useState(false);

  let [users, setUsers] = useState([]);
  useEffect(() => {
    if (memoVals.addNewUserDynamic) {
      setUsers([
        ...users,
        {
          fName: memoVals.addNewUserDynamic.fName,
          lName: memoVals.addNewUserDynamic.lName,
          userID: memoVals.addNewUserDynamic.userID,
        },
      ]);

      memoVals.setAddNewUserDynamic((x) => false);
    }

    if (updateSingleUserInUsers && updateSingleUserInUsers.userID) {
      setUsers(
        users.filter((x) => {
          return x.userID !== updateSingleUserInUsers.userID;
        })
      );
      setSelectedUser("");
      setUpdateSingleUserInUsers(false);
    }
  }, [memoVals.addNewUserDynamic, memoVals.updateSingleUserInUsers]);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();
    if (!users[0]) {
      // to run only when selectedYear is changed - the initial one is ran in the useEffect above
      axios
        .post(
          `${serverURL}/get-users-user-can-manage`,
          {
            getProPics: 1,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setUsers(response.data.usersArr);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [memoVals.updateDashUsers]);

  let [emailAlreadyExists, setEmailAlreadyExists] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);

  const handleDragStart = (event, item) => {
    setDraggedItem(item);
  };

  const handleTouchStart = (event, item) => {
    setDraggedItem(item);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, targetFolder, folderID) => {
    console.log({ draggedItem }, { folderID });
    event.preventDefault();
    if (!draggedItem || !targetFolder) return;

    // Check if dragged item is a file and target folder is a folder
    console.log({ draggedItem, targetFolder });

    axios
      .post(
        `${serverURL}/move-file-or-folder`,
        {
          fileID: draggedItem.folder ? "" : draggedItem.fileID,
          folderID: draggedItem.folder ? draggedItem.folderID : "",
          intoFolderID: targetFolder.folderID,
        },

        {
          withCredentials: true,
          credentials: "include",
          // cancelToken: cancelSource1.token,
        }
      )
      .then((response1) => {
        if (response1.data.message === "success") {
          axios
            .post(
              `${serverURL}/get-dash-files`,
              {
                userID: selectedUser,
                folderID: response1.data.currentFolderID,
                // fromFolderID: inFolderID,
              },

              {
                withCredentials: true,
                credentials: "include",
              }
            )
            .then((response) => {
              if (response.data.message === "success") {
                setFiles(response.data.files);
                setLoadingFiles(false);
                setTotBytes(response.data.bytes);
              }
            });
        } else {
          console.log("Error moving file or folder");
        }
      });

    if (!draggedItem.folder && targetFolder.folder) {
      // Do something to move the file into the folder
      console.log(
        `Moving file "${draggedItem.fileName}" into folder "${targetFolder.fileName}"`
      );
    } else if (draggedItem.folder && targetFolder.folder) {
      // Do something to move the folder into the folder
      console.log(
        `Moving folder "${draggedItem.fileName}" into folder "${targetFolder.fileName}"`
      );
    } else if (!draggedItem.folder && targetFolder.upRoot) {
      console.log(
        `Moving file "${draggedItem.fileName}" into parent folder "${targetFolder.folderName} which is ${targetFolder.folderID}"`
      );
    } else if (draggedItem.folder && targetFolder.upRoot) {
      console.log(
        `Moving file "${draggedItem.fileName}" into parent folder "${targetFolder.folderName} which is ${targetFolder.folderID}"`
      );
    } else if (!draggedItem.folder && folderID) {
      `Moving file "${draggedItem.fileName}" into folder "${folderID} x17171"`;
    }
    setDraggedItem(null);
  };

  let [folders, setFolders] = useState([]);
  let [moveToFolder, setMoveToFolder] = useState("");
  useEffect(() => {
    if (renameFolderID.show) {
      axios
        .post(
          `${serverURL}/get-available-folders`,
          {
            userID: selectedUser,
          },

          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setFolders(response.data.folders);
          }
        });
    }
  }, [renameFolderID]);

  let [exportDisable, setExportDisable] = useState(false);

  // master return
  return (
    <div className={`settingsBg `}>
      <div className={` ${!memoVals.mob ? "pageLeft240pxDesktop" : "whiteBg"}`}>
        {!memoVals.mob ? <TopBar /> : ""}

        <div
          className={`shiftsHeaderContentContainer myAccountHeader ${
            memoVals.mob ? "noBorderBottom" : ""
          }`}
        >
          <div
            className={`shiftsHeaderLeft ${
              memoVals.mob ? "mobMyAccHeader" : "myAccHeaderCorrector"
            }`}
          >
            <div
              className={`shiftHeaderLeftTitle myHoursTitle
              ${
                !memoVals.mob
                  ? "headerTitleSize hoursSizer myAccTitleDesktop"
                  : "myHoursTitleMob x13211231"
              } 
             `}
            >
              Dashboard
            </div>

            <div
              className={`shiftHeaderMenuRow myHoursMenuRow dashMenuRow  ${
                !memoVals.full && !memoVals.mob ? "x23982983" : ""
              } ${memoVals.mob ? "dashMenuRowMob" : ""}`}
            >
              {" "}
              <NavLink
                to="/staff-dashboard"
                className={`shiftHeaderMenuItem ${
                  dashPage === "dashboard" ? `shiftHeaderMenuItemSelect` : ""
                }`}
                onClick={() => {
                  setDashPage("dashboard");
                }}
              >
                <p className="headerMenuTopRow">Today</p>

                <p
                  className={`headerMenuBottomRow ${
                    dashPage === "dashboard" ? "none" : "x198389191"
                  } `}
                ></p>
                <p
                  className={`headerMenuBottomRow ${
                    dashPage === "dashboard" ? "" : "none"
                  }  `}
                ></p>
              </NavLink>{" "}
              <div className="subHeadSplit"></div>
              <NavLink
                to="/staff-dashboard/employees"
                className={`shiftHeaderMenuItem ${
                  dashPage === "employees" ? `shiftHeaderMenuItemSelect` : ""
                }`}
                onClick={() => {
                  setDashPage("employees");
                }}
              >
                <p className="headerMenuTopRow">Employees</p>

                <p
                  className={`headerMenuBottomRow ${
                    dashPage === "employees" ? "none" : "x198389191"
                  } `}
                ></p>
                <p
                  className={`headerMenuBottomRow ${
                    dashPage === "employees" ? "" : "none"
                  }  `}
                ></p>
                {/* </div>{" "} */}
              </NavLink>
              <div className="subHeadSplit"></div>
              <NavLink
                to="/staff-dashboard/allowances"
                className={`shiftHeaderMenuItem ${
                  dashPage === "allowances" || dashPage === "toil"
                    ? `shiftHeaderMenuItemSelect`
                    : ""
                }`}
                onClick={() => {
                  setDashPage("allowances");
                }}
              >
                <p className="headerMenuTopRow">Allowances</p>

                <p
                  className={`headerMenuBottomRow ${
                    dashPage === "allowances" || dashPage === "toil"
                      ? "none"
                      : "x198389191"
                  } `}
                ></p>
                <p
                  className={`headerMenuBottomRow ${
                    dashPage === "allowances" || dashPage === "toil"
                      ? ""
                      : "none"
                  }  `}
                ></p>
              </NavLink>
              {/* <div className="subHeadSplit"></div>
              <NavLink
                to="/staff-dashboard/documents"
                className={`shiftHeaderMenuItem ${
                  dashPage === "documents" ? `shiftHeaderMenuItemSelect` : ""
                }`}
                onClick={() => {
                  setDashPage("documents");

                  // Get doc list
                }}
              >
                <p className="headerMenuTopRow">Documents</p>

                <p
                  className={`headerMenuBottomRow ${
                    dashPage === "documents" ? "none" : "x198389191"
                  } `}
                ></p>
                <p
                  className={`headerMenuBottomRow ${
                    dashPage === "documents" ? "" : "none"
                  }  `}
                ></p>
              </NavLink>{" "} */}
            </div>
          </div>
        </div>
        <div className="accountBody">
          <div className={`desktopMaster ${memoVals.mob ? "whiteBg" : ""}`}>
            {dashPage === "employees" ? (
              <div
                className={`dashboardMasterMain  ${
                  memoVals.mob ? "dashMasterMob" : ""
                } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""}`}
              >
                {!memoVals.mob && (
                  <div
                    className={`dashboardLeftDiv ${
                      !memoVals.full && !memoVals.mob
                        ? "dashboardLeftDivMid"
                        : ""
                    }`}
                  >
                    {!memoVals.mob &&
                      memoVals.checkUserState.permissions === 1 && (
                        <p
                          className="dashAddUserBtn lilacColours"
                          onClick={() => {
                            memoVals.setShowAddUserModal((x) => true);
                          }}
                        >
                          <span className="dashAddEmpPlus">+</span>Add employee
                        </p>
                      )}

                    {loading ? (
                      <img
                        src={horizontalBalls}
                        alt="Loading"
                        className="loadingProfileBallsImg"
                      />
                    ) : (
                      users
                        .sort((a, b) => a.fName.localeCompare(b.fName))
                        .map((user, i) => {
                          return (
                            <div
                              key={user.userID}
                              className={`dashUserRow ${
                                i === 0 ? "dashUserRowFirst" : ""
                              } ${
                                i === users.length - 1 ? "dashUserRowLast" : ""
                              } ${users.length === 1 ? "dashRowSingle" : ""} ${
                                selectedUser === user.userID
                                  ? "selectedUserDash"
                                  : ""
                              } `}
                              onClick={() => {
                                if (selectedUser === user.userID) {
                                  setSelectedUser("");
                                } else {
                                  setSelectedUser(user.userID);
                                  if (subPage !== "details") {
                                    // setSubPage("details");
                                  }
                                }
                              }}
                            >
                              {user.fName} {user.lName}
                            </div>
                          );
                        })
                    )}
                    {memoVals.mob &&
                      memoVals.checkUserState.permissions === 1 && (
                        <p
                          className="dashAddUserBtn dashAddUserBtnMob lilacColours"
                          onClick={() => {
                            memoVals.setShowAddUserModal((x) => true);
                          }}
                        >
                          <span className="dashAddEmpPlus">+</span>Add employee
                        </p>
                      )}
                  </div>
                )}
                {!loading ? (
                  <div
                    className={`dashboardRightDiv ${
                      !memoVals.full && !memoVals.mob
                        ? "dashboardRightDivMid"
                        : ""
                    } ${memoVals.mob ? "mobDashContent" : ""}`}
                  >
                    {memoVals.mob && (
                      <div className="dashSelectorMobBar">
                        <select
                          className="mobDashUserSelectDropDown"
                          value={selectedUser}
                          onChange={(e) => {
                            // change user here
                            if (e.target.value === "*") {
                              memoVals.setShowAddUserModal((x) => true);
                            } else {
                              setSelectedUser(e.target.value);
                            }
                          }}
                        >
                          {" "}
                          <option key="00" value="">
                            Select employee
                          </option>
                          <option key="*" value="*">
                            + Add new employee
                          </option>
                          {users
                            .sort((a, b) => {
                              return a.fName.localeCompare(b.fName);
                            })
                            .map((user) => {
                              return (
                                <option key={user.userID} value={user.userID}>
                                  {user.fName} {user.lName}
                                </option>
                              );
                            })}
                        </select>

                        {selectedUser ? (
                          <div
                            className={`mobAdminMenuDiv mobDashMenu ${
                              showMobMenu
                                ? "mobAdminMenuDivActive mobAdminMenuDivActiveDash"
                                : ""
                            } ${!selectedUser ? "invis" : ""}`}
                            onClick={() => {
                              setShowMobMenu(!showMobMenu);
                            }}
                          >
                            <p className="mobAdminPageTitle">
                              {getPageName(subPage)}
                            </p>
                            <img
                              src={adminBurgerMob}
                              alt="Admin menu"
                              className="adminBurger2"
                            />
                          </div>
                        ) : memoVals.mob &&
                          memoVals.checkUserState.permissions === 1 ? (
                          <p
                            className="dashAddedUserBtn lilacColours"
                            onClick={() => {
                              memoVals.setShowAddUserModal((x) => true);
                            }}
                          >
                            Add employee{" "}
                            <img
                              src={whiteAddThin}
                              alt="Add employee"
                              className="dashAddEmpMobWhiteAddThinImg"
                            />
                          </p>
                        ) : (
                          ""
                        )}
                        {memoVals.mob && showMobMenu ? (
                          <div
                            className="adminMenuUnderlayDash"
                            onClick={() => {
                              setShowMobMenu(false);
                            }}
                          >
                            <div className="mobAdminOptions dashMenuDropper">
                              <p
                                className={`mobAdminOption mobDashMenuOption detailsNoBorder ${
                                  subPage === "details"
                                    ? "mobAdminOptionSelectedDash"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSubPage((x) => "details");
                                  setShowMobMenu(false);
                                }}
                              >
                                Details
                              </p>
                              <p
                                className={`mobAdminOption mobDashMenuOption ${
                                  subPage === "teams"
                                    ? "mobAdminOptionSelectedDash"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSubPage((x) => "teams");
                                  setShowMobMenu(false);
                                }}
                              >
                                Teams
                              </p>
                              <p
                                className={`mobAdminOption mobDashMenuOption ${
                                  subPage === "salary"
                                    ? "mobAdminOptionSelectedDash"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSubPage((x) => "salary");
                                  setShowMobMenu(false);
                                }}
                              >
                                Salary
                              </p>{" "}
                              <p
                                className={`mobAdminOption mobDashMenuOption ${
                                  subPage === "allowances"
                                    ? "mobAdminOptionSelectedDash"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSubPage((x) => "allowances");
                                  setShowMobMenu(false);
                                }}
                              >
                                Allowances
                              </p>{" "}
                              <p
                                className={`mobAdminOption mobDashMenuOption ${
                                  subPage === "absence"
                                    ? "mobAdminOptionSelectedDash"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSubPage((x) => "absence");
                                  setShowMobMenu(false);
                                  setSubPage("absence");
                                  setUserLoading(true);

                                  const cancelSource1 =
                                    axios.CancelToken.source();
                                  axios
                                    .post(
                                      `${serverURL}/get-user-dash-absence`,
                                      {
                                        userID: selectedUser,
                                        year: selectedYear,
                                        nowDs:
                                          dateStringer.createStringFromTimestamp(
                                            new Date().getTime()
                                          ),
                                      },

                                      {
                                        withCredentials: true,
                                        credentials: "include",
                                        cancelToken: cancelSource1.token,
                                      }
                                    )
                                    .then((response) => {
                                      if (response.data.message === "success") {
                                        setTimeout(() => {
                                          setUserLoading(false);
                                          setLoadingAbsence(false);
                                        }, 0);
                                        // lilly
                                        setState({
                                          absentInstances:
                                            response.data.instances,
                                          absentDurMins: response.data.durMins,
                                          paidAbsentDurMins:
                                            response.data.paidDurMins,
                                          unpaidAbsentDurMins:
                                            response.data.unpaidDurMins,
                                          whollyAbsentShiftsQty:
                                            response.data.whollyAbsentShiftsQty,
                                          partiallyAbsentShiftsQty:
                                            response.data
                                              .partiallyAbsentShiftsQty,
                                          absentShiftDurMins:
                                            response.data.shiftDurMins,
                                          absentPeriodDurMins:
                                            response.data.periodDurMins,
                                          absentPercentage:
                                            response.data.percentage,
                                          primaryTeamName:
                                            response.data.primaryTeamName,
                                          companyRankNum:
                                            response.data.companyRankNum,
                                          teamRankNum:
                                            response.data.teamRankNum,
                                          teamRankOutOf:
                                            response.data.teamRankOutOf,
                                          companyRankOutOf:
                                            response.data.companyRankOutOf,
                                        });
                                      }
                                    })
                                    .catch((err) => {
                                      console.error(err);
                                    });
                                  return () => {
                                    cancelSource1.cancel("Component unmounted");
                                  };
                                }}
                              >
                                Absence
                              </p>{" "}
                              <p
                                className={`mobAdminOption mobDashMenuOption ${
                                  subPage === "late"
                                    ? "mobAdminOptionSelectedDash"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSubPage((x) => "late");
                                  setShowMobMenu(false);

                                  axios
                                    .post(
                                      `${serverURL}/get-user-dash-late`,
                                      {
                                        userID: selectedUser,
                                        year: selectedYear,
                                      },

                                      {
                                        withCredentials: true,
                                        credentials: "include",
                                      }
                                    )
                                    .then((response) => {
                                      if (response.data.message === "success") {
                                        setLoadingAbsence(false);

                                        setLateCost(response.data.lateCost);
                                        setLateQty(response.data.lateQty);
                                        setLateDurMins(
                                          response.data.lateDurMins
                                        );
                                        setUnpaidLateDurMins(
                                          response.data.unpaidLateDurMins
                                        );
                                        setPaidLateDurMins(
                                          response.data.paidLateDurMins
                                        );
                                        setLates(response.data.lates || []);
                                        setUserLoading(false);
                                        setHoursLoading(false);
                                      }
                                    })
                                    .catch((err) => {
                                      console.error(err);
                                    });
                                }}
                              >
                                Late starts
                              </p>{" "}
                              {/* <p
                                className={`mobAdminOption mobDashMenuOption ${
                                  subPage === "documents"
                                    ? "mobAdminOptionSelectedDash"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSubPage((x) => "documents");
                                  setShowMobMenu(false);
                                  setFiles([]);
                                  setTotBytes(0);
                                  setLoadingFiles(true);
                                  axios
                                    .post(
                                      `${serverURL}/get-dash-files`,
                                      {
                                        userID: selectedUser,
                                        folderID: false,
                                        // fromFolderID: inFolderID,
                                      },

                                      {
                                        withCredentials: true,
                                        credentials: "include",
                                      }
                                    )
                                    .then((response) => {
                                      if (response.data.message === "success") {
                                        setFiles(response.data.files);
                                        setLoadingFiles(false);
                                        setTotBytes(response.data.bytes);
                                        setInFolderName("Root directory");
                                      }
                                    });
                                }}
                              >
                                Documents
                              </p>{" "} */}
                              <p
                                className={`mobAdminOption mobDashMenuOption ${
                                  subPage === "payroll"
                                    ? "mobAdminOptionSelectedDash"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSubPage((x) => "payroll");
                                  setShowMobMenu(false);
                                }}
                              >
                                Payroll
                              </p>{" "}
                              <p
                                className={`mobAdminOption mobDashMenuOption ${
                                  subPage === "hours"
                                    ? "mobAdminOptionSelectedDash"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSubPage((x) => "hours");
                                  setShowMobMenu(false);
                                }}
                              >
                                Worked hours
                              </p>{" "}
                              <p
                                className={`mobAdminOption mobDashMenuOption ${
                                  subPage === "leave"
                                    ? "mobAdminOptionSelectedDash"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSubPage((x) => "leave");
                                  setShowMobMenu(false);
                                }}
                              >
                                Leave periods
                              </p>{" "}
                              <p
                                className={`mobAdminOption mobDashMenuOption ${
                                  subPage === "timesheets"
                                    ? "mobAdminOptionSelectedDash"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSubPage((x) => "timesheets");
                                  setShowMobMenu(false);
                                }}
                              >
                                Timesheets
                              </p>{" "}
                              <p
                                className={`mobAdminOption mobDashMenuOption noBorder ${
                                  subPage === "clockcards"
                                    ? "mobAdminOptionSelectedDash"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSubPage((x) => "clockcards");
                                  setShowMobMenu(false);
                                }}
                              >
                                Clock cards
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    <div
                      className={`dashSubPage ${
                        !selectedUser ? "unselUsrSubPage" : ""
                      } ${memoVals.mob ? "dashSubPageMob" : ""}`}
                    >
                      {!selectedUser ? (
                        <p className="selectColl">Select an employee</p>
                      ) : (
                        <Fragment>
                          {!memoVals.mob && (
                            <div className="userDashLeft">
                              <div
                                className={`userDashLeftBtn ${
                                  subPage === "details" ? "userDashBtnSel" : ""
                                }`}
                                onClick={() => {
                                  setSubPage("details");
                                }}
                              >
                                Details
                              </div>
                              <div
                                className={`userDashLeftBtn ${
                                  subPage === "teams" ? "userDashBtnSel" : ""
                                }`}
                                onClick={() => {
                                  setSubPage("teams");
                                }}
                              >
                                Teams
                              </div>
                              <div
                                className={`userDashLeftBtn ${
                                  subPage === "salary" ? "userDashBtnSel" : ""
                                }`}
                                onClick={() => {
                                  setSubPage("salary");
                                }}
                              >
                                Salary
                              </div>
                              <div
                                className={`userDashLeftBtn ${
                                  subPage === "allowances"
                                    ? "userDashBtnSel"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSubPage("allowances");
                                }}
                              >
                                Allowances
                              </div>
                              <div
                                className={`userDashLeftBtn ${
                                  subPage === "absence" ? "userDashBtnSel" : ""
                                }`}
                                onClick={() => {
                                  setSubPage("absence");
                                  setUserLoading(true);

                                  const cancelSource1 =
                                    axios.CancelToken.source();
                                  axios
                                    .post(
                                      `${serverURL}/get-user-dash-absence`,
                                      {
                                        userID: selectedUser,
                                        year: selectedYear,
                                        nowDs:
                                          dateStringer.createStringFromTimestamp(
                                            new Date().getTime()
                                          ),
                                      },

                                      {
                                        withCredentials: true,
                                        credentials: "include",
                                        cancelToken: cancelSource1.token,
                                      }
                                    )
                                    .then((response) => {
                                      if (response.data.message === "success") {
                                        setTimeout(() => {
                                          setUserLoading(false);
                                          setLoadingAbsence(false);
                                        }, 0);
                                        // lilly
                                        setState({
                                          absentInstances:
                                            response.data.instances,
                                          absentDurMins: response.data.durMins,
                                          paidAbsentDurMins:
                                            response.data.paidDurMins,
                                          unpaidAbsentDurMins:
                                            response.data.unpaidDurMins,
                                          whollyAbsentShiftsQty:
                                            response.data.whollyAbsentShiftsQty,
                                          partiallyAbsentShiftsQty:
                                            response.data
                                              .partiallyAbsentShiftsQty,
                                          absentShiftDurMins:
                                            response.data.shiftDurMins,
                                          absentPeriodDurMins:
                                            response.data.periodDurMins,
                                          absentPercentage:
                                            response.data.percentage,
                                          primaryTeamName:
                                            response.data.primaryTeamName,
                                          companyRankNum:
                                            response.data.companyRankNum,
                                          teamRankNum:
                                            response.data.teamRankNum,
                                          teamRankOutOf:
                                            response.data.teamRankOutOf,
                                          companyRankOutOf:
                                            response.data.companyRankOutOf,
                                        });
                                      }
                                    })
                                    .catch((err) => {
                                      console.error(err);
                                    });
                                  return () => {
                                    cancelSource1.cancel("Component unmounted");
                                  };
                                }}
                              >
                                Absence
                              </div>
                              <div
                                className={`userDashLeftBtn ${
                                  subPage === "late" ? "userDashBtnSel" : ""
                                }`}
                                onClick={() => {
                                  setSubPage("late");
                                  axios
                                    .post(
                                      `${serverURL}/get-user-dash-late`,
                                      {
                                        userID: selectedUser,
                                        year: selectedYear,
                                      },

                                      {
                                        withCredentials: true,
                                        credentials: "include",
                                      }
                                    )
                                    .then((response) => {
                                      if (response.data.message === "success") {
                                        setLoadingAbsence(false);

                                        setLateCost(response.data.lateCost);
                                        setLateQty(response.data.lateQty);
                                        setLateDurMins(
                                          response.data.lateDurMins
                                        );
                                        setUnpaidLateDurMins(
                                          response.data.unpaidLateDurMins
                                        );
                                        setPaidLateDurMins(
                                          response.data.paidLateDurMins
                                        );
                                        setLates(response.data.lates || []);
                                        setUserLoading(false);
                                        setHoursLoading(false);
                                      }
                                    })
                                    .catch((err) => {
                                      console.error(err);
                                    });
                                }}
                              >
                                Late starts
                              </div>
                              {/* <div
                                className={`userDashLeftBtn ${
                                  subPage === "documents"
                                    ? "userDashBtnSel"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSubPage("documents");
                                  setFiles([]);
                                  setTotBytes(0);
                                  setLoadingFiles(true);
                                  axios
                                    .post(
                                      `${serverURL}/get-dash-files`,
                                      {
                                        userID: selectedUser,
                                        folderID: false,
                                        // fromFolderID: inFolderID,
                                      },

                                      {
                                        withCredentials: true,
                                        credentials: "include",
                                      }
                                    )
                                    .then((response) => {
                                      if (response.data.message === "success") {
                                        setFiles(response.data.files);
                                        setLoadingFiles(false);
                                        setTotBytes(response.data.bytes);
                                        setInFolderName("Root directory");
                                      }
                                    });
                                }}
                              >
                                Documents
                              </div>{" "} */}
                              <div
                                className={`userDashLeftBtn ${
                                  subPage === "payroll" ? "userDashBtnSel" : ""
                                }`}
                                onClick={() => {
                                  setSubPage("payroll");
                                }}
                              >
                                Payroll
                              </div>
                              <div
                                className={`userDashLeftBtn ${
                                  subPage === "hours" ? "userDashBtnSel" : ""
                                }`}
                                onClick={() => {
                                  setSubPage("hours");
                                }}
                              >
                                Worked hours
                              </div>
                              <div
                                className={`userDashLeftBtn ${
                                  subPage === "leave" ? "userDashBtnSel" : ""
                                }`}
                                onClick={() => {
                                  setSubPage("leave");
                                }}
                              >
                                Leave periods
                              </div>
                              <div
                                className={`userDashLeftBtn ${
                                  subPage === "timesheets"
                                    ? "userDashBtnSel"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSubPage("timesheets");
                                }}
                              >
                                Timesheets
                              </div>
                              <div
                                className={`userDashLeftBtn lateStartsDash noBorder ${
                                  subPage === "clockcards"
                                    ? "userDashBtnSel"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSubPage("clockcards");
                                }}
                              >
                                Clock cards
                              </div>
                            </div>
                          )}
                          {subPage === "details" ? (
                            userLoading ? (
                              <img
                                src={horizontalBalls}
                                alt="Loading user"
                                className="dashUserLoadingimg"
                              />
                            ) : (
                              <div
                                className={`userDashRight scrollDashRight ${
                                  memoVals.mob
                                    ? `mobDash ${
                                        memoVals.device.ios ? "mobDashIos" : ""
                                      }`
                                    : ""
                                }`}
                              >
                                <div className="userDashDetailsTop">
                                  <div className="userDashDetailsLeft">
                                    <div className="userDashDetailsLeftRow">
                                      <p className="userDashDetailsLeftRowTitle">
                                        Name
                                      </p>
                                      <p className="userDashDetailsLeftRowValue">
                                        {userLoading ? "-" : state.fName}{" "}
                                        {userLoading ? "" : state.lName}{" "}
                                      </p>
                                      {state.admin ? (
                                        <p className="dashAdmin">
                                          Administrator
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="userDashDetailsLeftRow">
                                      <p className="userDashDetailsLeftRowTitle">
                                        Job title
                                      </p>
                                      <p className="userDashDetailsLeftRowValue">
                                        {userLoading
                                          ? "-"
                                          : state.jobTitle || "No job title"}
                                      </p>
                                    </div>
                                    <div className="userDashDetailsLeftRow">
                                      <p className="userDashDetailsLeftRowTitle">
                                        Primary team
                                      </p>
                                      <p className="userDashDetailsLeftRowValue">
                                        {userLoading
                                          ? "-"
                                          : state.primaryTeamName ||
                                            "No primary team"}
                                      </p>
                                    </div>
                                    <div className="userDashDetailsLeftRow">
                                      <p className="userDashDetailsLeftRowTitle">
                                        Start date
                                      </p>
                                      <p className="userDashDetailsLeftRowValue">
                                        {dateStringer.printedDateFromDs(
                                          state.startDate,
                                          true,
                                          true
                                        )}
                                      </p>
                                    </div>
                                    <div className="userDashDetailsLeftRow noBorder">
                                      <p className="userDashDetailsLeftRowTitle">
                                        Email
                                      </p>
                                      <p class="userDashDetailsLeftRowValue dashEmail">
                                        <a href={`mailto:${state.email}`}>
                                          {state.email}
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="userDashDetailsRight">
                                    <p
                                      className={`editProfileBtnDash ${
                                        !memoVals.full && !memoVals.mob
                                          ? "editProfileBtnDashMid"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        memoVals.setShowEditUser(
                                          (x) => selectedUser
                                        );
                                      }}
                                    >
                                      Profile{" "}
                                      <img
                                        src={edit}
                                        alt="Edit profile"
                                        className={`editProfilePenIcon ${
                                          !memoVals.full && !memoVals.mob
                                            ? "editProfilePenIconMid"
                                            : ""
                                        }`}
                                      />
                                    </p>
                                    <img
                                      src={
                                        state.profPicUrl ||
                                        profilePicturePlaceholder
                                      }
                                      alt={state.fName}
                                      className={`userDashProPicImg ${
                                        !memoVals.full && !memoVals.mob
                                          ? "userDashProPicImgMid"
                                          : ""
                                      }`}
                                    />
                                  </div>
                                </div>
                                <div className="avgMpwDashRow">
                                  <div className="avgMpwDashLeft">
                                    <p className="userDashDetailsLeftRowTitle">
                                      Average hours per day (paid for):
                                    </p>
                                    <p className="userDashDetailsLeftRowValue">
                                      {dateStringer.formatMinsDurationToHours(
                                        state.avgMpd || 0
                                      )}
                                    </p>
                                  </div>{" "}
                                  <div className="avgMpwDashRight">
                                    <p
                                      className="avgMpdHistBtn"
                                      onClick={() => {
                                        setShowUserHistory(!showUserHistory);
                                        if (!showUserHistory) {
                                          if (!excHistoryLoading) {
                                            setExcHistoryLoading(true);
                                          }
                                          axios
                                            .post(
                                              `${serverURL}/get-user-dash-details`,
                                              {
                                                userID: selectedUser,
                                                getExcessHistory: true,
                                              },

                                              {
                                                withCredentials: true,
                                                credentials: "include",
                                              }
                                            )
                                            .then((response) => {
                                              if (
                                                response.data.message ===
                                                "success"
                                              ) {
                                                console.log(response);
                                                setExcHistoryLoading(false);
                                                setExcessArr(
                                                  response.data.excessData || []
                                                );
                                              }
                                            })
                                            .catch((err) => {
                                              console.error(err);
                                            });
                                        }
                                      }}
                                    >
                                      History{" "}
                                      <img
                                        src={whiteTriangle}
                                        alt="History"
                                        className={`avgHistTriangle ${
                                          showUserHistory
                                            ? "avgHistTriangleActive"
                                            : ""
                                        }`}
                                      />
                                    </p>
                                  </div>
                                </div>
                                {showUserHistory ? (
                                  <div
                                    className={`dashHistoryArr ${
                                      memoVals.device.ios
                                        ? "dashHistoryArrIos"
                                        : ""
                                    }`}
                                  >
                                    {excHistoryLoading ? (
                                      ""
                                    ) : excessArr[0] ? (
                                      excessArr
                                        .sort((a, b) => {
                                          return b.startTs - a.startTs;
                                        })
                                        .map((x) => {
                                          return (
                                            <div className="dashExcessArrItem">
                                              <div className="dashExcRow">
                                                <p className="dashExcTitle">
                                                  Week
                                                </p>
                                                <p className="dashExcValue">
                                                  {dateStringer.printedDateFromDs(
                                                    x.startDs
                                                  )}{" "}
                                                  -{" "}
                                                  {dateStringer.printedDateFromDs(
                                                    x.endDs,
                                                    true
                                                  )}
                                                </p>
                                              </div>
                                              <div className="dashExcRow">
                                                <p className="dashExcTitle">
                                                  Total paid hours
                                                </p>
                                                <p className="dashExcValue">
                                                  {dateStringer.formatMinsDurationToHours(
                                                    x.minsFromShifts +
                                                      x.minsFromOvertimes +
                                                      x.minsFromReqs +
                                                      x.minsFromHistory
                                                  )}
                                                </p>
                                              </div>
                                              <div className="dashExcRow">
                                                <p className="dashExcTitle">
                                                  Paid hours from shifts
                                                </p>
                                                <p className="dashExcValue x19101133">
                                                  {dateStringer.formatMinsDurationToHours(
                                                    x.minsFromShifts
                                                  )}
                                                </p>
                                              </div>
                                              <div className="dashExcRow">
                                                <p className="dashExcTitle">
                                                  Paid hours from overtime
                                                </p>
                                                <p className="dashExcValue x19101133">
                                                  {dateStringer.formatMinsDurationToHours(
                                                    x.minsFromOvertimes
                                                  )}
                                                </p>
                                              </div>
                                              <div
                                                className={`dashExcRow ${
                                                  !x.minsFromHistory
                                                    ? "noBorder"
                                                    : ""
                                                }`}
                                              >
                                                <p className="dashExcTitle">
                                                  Paid hours from leave &
                                                  absence periods
                                                </p>
                                                <p className="dashExcValue x19101133">
                                                  {dateStringer.formatMinsDurationToHours(
                                                    x.minsFromReqs
                                                  )}
                                                </p>
                                              </div>
                                              {x.minsFromHistory ? (
                                                <div className="dashExcRow noBorder">
                                                  <p className="dashExcTitle">
                                                    Paid hours from before{" "}
                                                    {state.fName} was added to
                                                    FlowRota
                                                  </p>
                                                  <p className="dashExcValue x19101133">
                                                    {dateStringer.formatMinsDurationToHours(
                                                      x.minsFromHistory
                                                    )}
                                                  </p>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          );
                                        })
                                    ) : (
                                      <p className="noExcHist">
                                        No historical contracted hours data
                                      </p>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="avgMpwDashRow x10910">
                                  <div className="userDashDetailsLeftRow x1910911">
                                    <p className="userDashDetailsLeftRowTitle">
                                      Contracted hours per day:
                                    </p>
                                    <p className="userDashDetailsLeftRowValue">
                                      {dateStringer.formatMinsDurationToHours(
                                        state.contractedMpd || 0
                                      )}
                                    </p>
                                  </div>

                                  <div className="userDashDetailsLeftRow x1910911 x392842911 noBorder">
                                    <p className="userDashDetailsLeftRowTitle">
                                      Daily variation (average):
                                    </p>
                                    <p className="userDashDetailsLeftRowValue x920842">
                                      <span>
                                        {state.avgMpd - state.contractedMpd >
                                          0 &&
                                        !dateStringer
                                          .formatMinsDurationToHours(
                                            state.avgMpd - state.contractedMpd,
                                            false,
                                            true
                                          )
                                          .includes("N")
                                          ? "+"
                                          : ""}
                                        {dateStringer
                                          .formatMinsDurationToHours(
                                            state.avgMpd - state.contractedMpd,
                                            false,
                                            true
                                          )
                                          .includes("N")
                                          ? "0m"
                                          : dateStringer.formatMinsDurationToHours(
                                              state.avgMpd -
                                                state.contractedMpd,
                                              false,
                                              true
                                            )}{" "}
                                      </span>
                                      <p
                                        className={`dashPayExcess ${
                                          state.avgMpd - state.contractedMpd <=
                                          0
                                            ? "none"
                                            : ""
                                        }`}
                                      >
                                        {state.payExcess
                                          ? "Variation included within leave and absence pay"
                                          : "Variation not accounted for within leave and absence pay"}
                                      </p>
                                    </p>
                                    <p className="variationExplain">
                                      Variation is the difference between the
                                      average hours per week {state.fName} was
                                      paid for across the reference period and
                                      the current hours per week they are
                                      contracted for.
                                    </p>
                                  </div>
                                </div>
                                <div className="avgMpwDashRow x10910">
                                  <div className="userDashDetailsLeftRow x1910911 x139849111 noBorder">
                                    <div className="">
                                      <p className="userDashDetailsLeftRowTitle">
                                        1 day of leave is worth:
                                      </p>
                                      <p className="userDashDetailsLeftRowValue">
                                        {dateStringer
                                          .formatMinsDurationToHours(
                                            state.payExcess
                                              ? state.avgMpd <
                                                state.contractedMpd
                                                ? state.contractedMpd
                                                : state.avgMpd
                                              : state.contractedMpd
                                          )
                                          .includes("N")
                                          ? "0m"
                                          : dateStringer.formatMinsDurationToHours(
                                              state.payExcess
                                                ? state.avgMpd <
                                                  state.contractedMpd
                                                  ? state.contractedMpd
                                                  : state.avgMpd
                                                : state.contractedMpd
                                            )}
                                      </p>
                                    </div>
                                    <img
                                      src={plane2}
                                      alt="Leave"
                                      className="worthPlaneImg"
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                          ) : (
                            ""
                          )}
                          {subPage === "teams" ? (
                            userLoading ? (
                              <img
                                src={horizontalBalls}
                                alt="Loading user"
                                className="dashUserLoadingimg"
                              />
                            ) : (
                              <div
                                className={`userDashRight userDashRightTeams x992c ${
                                  memoVals.device.ios ? "x992cIos" : ""
                                }`}
                              >
                                {userTeams.map((team) => {
                                  let mgrs = team.otherUsers.filter((x) => {
                                    return x.mgr;
                                  });
                                  let users = team.otherUsers.filter((x) => {
                                    return x.usr;
                                  });
                                  return (
                                    <div className="dashTeamItem">
                                      <div className="dasthTeamItemUpper">
                                        <div className="userDashDetailsLeft">
                                          <div className="userDashDetailsLeftRow">
                                            <p className="userDashDetailsLeftRowTitle">
                                              Team
                                            </p>
                                            <p className="userDashDetailsLeftRowValue">
                                              {team.teamName}
                                            </p>
                                          </div>
                                          <div className="userDashDetailsLeftRow">
                                            <p className="userDashDetailsLeftRowTitle">
                                              Member or manager
                                            </p>
                                            <p className="userDashDetailsLeftRowValue">
                                              {team.usr && team.mgr
                                                ? "Both"
                                                : team.usr
                                                ? "Member"
                                                : "Manager"}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="userDashDetailsRight">
                                          {team.mgr ||
                                          memoVals.checkUserState
                                            .permissions === 1 ? (
                                            <p
                                              className={`editProfileBtnDash teamDashBtn `}
                                              onClick={() => {
                                                memoVals.setShowViewUserTeamModal(
                                                  (x) => team.teamID
                                                );
                                              }}
                                            >
                                              Team{" "}
                                              <img
                                                src={edit}
                                                alt="Edit profile"
                                                className={`editProfilePenIcon ${
                                                  !memoVals.full &&
                                                  !memoVals.mob
                                                    ? "editProfilePenIconMid"
                                                    : ""
                                                }`}
                                              />
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>{" "}
                                      <div className="dashTeamUserSection">
                                        <div className="dashTeamMgrs">
                                          <p className="dashTeamMgrTitle">
                                            Managers
                                          </p>
                                          {mgrs.map((mgr) => {
                                            return (
                                              <p
                                                className={`dashTeamPerson defaultCursor ${
                                                  selectedUser === mgr.userID
                                                    ? "selectedDashTeamMgr"
                                                    : ""
                                                }`}
                                              >
                                                <img
                                                  src={mgrIcon2}
                                                  alt="Manager"
                                                  className="dashMgrIcon"
                                                />{" "}
                                                {mgr.fName} {mgr.lName}
                                              </p>
                                            );
                                          })}
                                        </div>
                                        <div className="dashTeamMgrs dashTeamMgrs2">
                                          <p className="dashTeamMgrTitle">
                                            Members
                                          </p>
                                          {users
                                            .sort((a, b) => {
                                              return a.fName.localeCompare(
                                                b.fName
                                              );
                                            })
                                            .map((usr) => {
                                              return (
                                                <p
                                                  className={`dashTeamPerson defaultCursor ${
                                                    selectedUser === usr.userID
                                                      ? "selectedDashTeamUsr"
                                                      : ""
                                                  }`}
                                                >
                                                  <img
                                                    src={userIcon}
                                                    alt="Manager"
                                                    className="dashMgrIcon"
                                                  />{" "}
                                                  {usr.fName} {usr.lName}
                                                </p>
                                              );
                                            })}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                                <p
                                  className="dashManageTeamsBtn"
                                  onClick={() => {
                                    memoVals.setShowEditUser((x) => {
                                      return {
                                        userID: selectedUser,
                                        teams: true,
                                      };
                                    });
                                  }}
                                >
                                  Add {state.fName} to a team
                                </p>
                              </div>
                            )
                          ) : (
                            ""
                          )}
                          {subPage === "salary" ? (
                            userLoading ? (
                              <img
                                src={horizontalBalls}
                                alt="Loading user"
                                className="dashUserLoadingimg"
                              />
                            ) : (
                              <div className="userDashRight userDashRightSal">
                                {/* --- -- -- - -- -  */}
                                <div className="avgMpwDashRow avgMpwDashRowSal">
                                  <div className="avgMpwDashLeft">
                                    <p className="userDashDetailsLeftRowTitle">
                                      {state.salaried
                                        ? "Salary"
                                        : "Hourly rate"}{" "}
                                    </p>
                                    <p className="userDashDetailsLeftRowValue">
                                      {state.salaried
                                        ? `${memoVals.currencySymbol}${state.salary}`
                                        : `${memoVals.currencySymbol}${state.hourlyRate} / hour`}
                                    </p>
                                  </div>{" "}
                                  <div className="avgMpwDashRight">
                                    <p
                                      className={`avgMpdHistBtn ${
                                        !state.hourlyRateTimeline[0]
                                          ? "invis"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        setShowUserHistory(!showUserHistory);
                                      }}
                                    >
                                      History{" "}
                                      <img
                                        src={whiteTriangle}
                                        alt="History"
                                        className={`avgHistTriangle ${
                                          showUserHistory
                                            ? "avgHistTriangleActive"
                                            : ""
                                        }`}
                                      />
                                    </p>
                                  </div>
                                </div>

                                {showUserHistory ? (
                                  <div
                                    className={`dashHistoryArr ${
                                      memoVals.device.ios
                                        ? "dashHistoryArrIos"
                                        : ""
                                    }`}
                                  >
                                    {state.hourlyRateTimeline
                                      .sort((a, b) => {
                                        return b.startTs - a.startTs;
                                      })
                                      .map((x) => {
                                        return (
                                          <div className="dashExcessArrItem">
                                            <div className="dashExcRow">
                                              <p className="dashExcTitle">
                                                Period
                                              </p>
                                              <p className="dashExcValue">
                                                {dateStringer.printedDateFromDs(
                                                  x.startDate,
                                                  true
                                                )}{" "}
                                                -{" "}
                                                {dateStringer.printedDateFromDs(
                                                  x.endDate,
                                                  true
                                                )}
                                              </p>
                                            </div>
                                            <div className="dashExcRow">
                                              <p className="dashExcTitle">
                                                Pay type
                                              </p>
                                              <p className="dashExcValue">
                                                {x.salaried
                                                  ? "Salary"
                                                  : "Hourly rate"}
                                              </p>
                                            </div>
                                            <div className="dashExcRow noBorder">
                                              <p className="dashExcTitle">
                                                {x.salaried
                                                  ? "Salary"
                                                  : "Hourly rate"}
                                              </p>
                                              <p className="dashExcValue x19101133">
                                                {memoVals.currencySymbol}
                                                {x.salaried
                                                  ? x.salary || 0
                                                  : x.hourlyRate || 0}{" "}
                                                {x.salaried
                                                  ? "per year"
                                                  : "per hour"}
                                              </p>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <p
                                  className="dashManageTeamsBtn x99j"
                                  onClick={() => {
                                    memoVals.setShowEditUser((x) => {
                                      return {
                                        userID: selectedUser,
                                      };
                                    });
                                  }}
                                >
                                  Amend {dateStringer.possession(state.fName)}{" "}
                                  pay details{" "}
                                  <img
                                    src={editGrey}
                                    alt="Edit"
                                    className="editDashPencil"
                                  />
                                </p>
                              </div>
                            )
                          ) : (
                            ""
                          )}
                          {subPage === "allowances" ? (
                            <div className="userDashRight userDashRightAllows">
                              <div className="avgMpwDashRow x10910 x183981">
                                <div className="userDashDetailsLeftRow x1910911 x139849111 noBorder">
                                  <div className="">
                                    {userLoading ? (
                                      <img
                                        src={horizontalBalls}
                                        alt="Loading user"
                                        className="dashUserLoadingimg allowsLoadDash"
                                      />
                                    ) : (
                                      <p className="userDashDetailsLeftRowTitle"></p>
                                    )}
                                    {/* <p className="userDashDetailsLeftRowValue">
                                        {dateStringer.formatMinsDurationToHours(
                                          state.payExcess
                                            ? state.avgMpd < state.contractedMpd
                                              ? state.contractedMpd
                                              : state.avgMpd
                                            : state.contractedMpd
                                        )}
                                      </p> */}
                                    {userLoading ? (
                                      ""
                                    ) : (
                                      <p
                                        className="dashManageTeamsBtn"
                                        onClick={() => {
                                          memoVals.setShowEditUser((x) => {
                                            return {
                                              userID: selectedUser,
                                              allows: true,
                                            };
                                          });
                                        }}
                                      >
                                        Amend{" "}
                                        {dateStringer.possession(state.fName)}{" "}
                                        allowances{" "}
                                        <img
                                          src={editGrey}
                                          alt="Edit"
                                          className="editDashPencil x23984984324"
                                        />
                                      </p>
                                    )}
                                  </div>
                                  <select
                                    className="dashYrSelectorDropdown"
                                    value={selectedYear}
                                    onChange={(e) => {
                                      setSelectedYear(e.target.value);
                                    }}
                                  >
                                    {availableYears.map((yr) => {
                                      return (
                                        <option value={yr} key={yr}>
                                          {yr}
                                        </option>
                                      );
                                    })}
                                  </select>

                                  {/* <img
                                      src={plane2}
                                      alt="Leave"
                                      className="worthPlaneImg"
                                    /> */}
                                </div>
                              </div>

                              <div
                                className={`dashAllowsScroll ${
                                  memoVals.device.ios
                                    ? "dashAllowsScrollIos"
                                    : ""
                                }`}
                              >
                                <div
                                  className={`avgMpwDashRow x10910 ${
                                    userLoading ? "invis" : ""
                                  }`}
                                >
                                  <div className="accountSettingsRow x239494255 x678123 x23847824423">
                                    <div className="myAccLeaveChartRow marginTop5">
                                      <div
                                        className={`myAccLeaveChartTaken ${
                                          leaveChartData.taken === 0
                                            ? "char0"
                                            : leaveChartData.taken === 10
                                            ? "char10"
                                            : leaveChartData.taken === 20
                                            ? "char20"
                                            : leaveChartData.taken === 30
                                            ? "char30"
                                            : leaveChartData.taken === 40
                                            ? "char40"
                                            : leaveChartData.taken === 50
                                            ? "char50"
                                            : leaveChartData.taken === 60
                                            ? "char60"
                                            : leaveChartData.taken === 70
                                            ? "char70"
                                            : leaveChartData.taken === 80
                                            ? "char80"
                                            : leaveChartData.taken === 90
                                            ? "char90"
                                            : "char100"
                                        }`}
                                      ></div>
                                      <div
                                        className={`myAccLeaveChartBooked ${
                                          leaveChartData.booked === 0 &&
                                          leaveChartData.taken === 0
                                            ? "myAccLeaveChartBookedMinus5Left"
                                            : ""
                                        }  ${
                                          leaveChartData.booked === 0
                                            ? "char0"
                                            : leaveChartData.booked === 10
                                            ? "char10"
                                            : leaveChartData.booked === 20
                                            ? "char20"
                                            : leaveChartData.booked === 30
                                            ? "char30"
                                            : leaveChartData.booked === 40
                                            ? "char40"
                                            : leaveChartData.booked === 50
                                            ? "char50"
                                            : leaveChartData.booked === 60
                                            ? "char60"
                                            : leaveChartData.booked === 70
                                            ? "char70"
                                            : leaveChartData.booked === 80
                                            ? "char80"
                                            : leaveChartData.booked === 90
                                            ? "char90"
                                            : "char100"
                                        }
`}
                                      ></div>

                                      <div
                                        className={`myAccLeaveChartRemaining ${
                                          leaveChartData.remaining === 0
                                            ? "char0"
                                            : leaveChartData.remaining === 10
                                            ? "char10"
                                            : leaveChartData.remaining === 20
                                            ? "char20"
                                            : leaveChartData.remaining === 30
                                            ? "char30"
                                            : leaveChartData.remaining === 40
                                            ? "char40"
                                            : leaveChartData.remaining === 50
                                            ? "char50"
                                            : leaveChartData.remaining === 60
                                            ? "char60"
                                            : leaveChartData.remaining === 70
                                            ? "char70"
                                            : leaveChartData.remaining === 80
                                            ? "char80"
                                            : leaveChartData.remaining === 90
                                            ? "char90"
                                            : "char100"
                                        }
`}
                                      ></div>
                                    </div>

                                    <div className="myAccLeaveChartKey">
                                      <div className="myAccLeaveChartKeyItem keyItem1">
                                        <div className="myAccLeaveChartKeyBlob"></div>
                                        <div className="myAccLCKeyTxtDiv">
                                          <p className="myAccLCkeyVal">
                                            {leaveChartData.daysOrHours ===
                                            "hours"
                                              ? dateStringer.formatMinsDurationToShortHours(
                                                  leaveChartData.takenVal || 0
                                                )
                                              : leaveChartData.takenVal ||
                                                0}{" "}
                                            {leaveChartData.daysOrHours ===
                                            "days"
                                              ? "day"
                                              : ""}
                                            {leaveChartData.takenVal === 1
                                              ? ""
                                              : leaveChartData.daysOrHours ===
                                                "days"
                                              ? "s"
                                              : ""}
                                          </p>
                                          <p className="myAccLCkeyValTxt">
                                            taken
                                          </p>
                                        </div>
                                      </div>{" "}
                                      {parseInt(selectedYear) >=
                                        new Date().getFullYear() && (
                                        <div className="myAccLeaveChartKeyItem keyItem2">
                                          <div className="myAccLeaveChartKeyBlob myAccLeaveChartKeyBlobBooked"></div>
                                          <div className="myAccLCKeyTxtDiv">
                                            <p className="myAccLCkeyVal">
                                              {leaveChartData.daysOrHours ===
                                              "hours"
                                                ? dateStringer.formatMinsDurationToShortHours(
                                                    leaveChartData.bookedVal ||
                                                      0
                                                  )
                                                : leaveChartData.bookedVal ||
                                                  0}{" "}
                                              {leaveChartData.daysOrHours ===
                                              "days"
                                                ? "day"
                                                : ""}
                                              {leaveChartData.bookedVal === 1
                                                ? ""
                                                : leaveChartData.daysOrHours ===
                                                  "days"
                                                ? "s"
                                                : ""}
                                            </p>
                                            <p className="myAccLCkeyValTxt">
                                              booked
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                      <div className="myAccLeaveChartKeyItem keyItem3">
                                        <div className="myAccLeaveChartKeyBlob myAccLeaveChartKeyBlobRemaining"></div>
                                        <div className="myAccLCKeyTxtDiv">
                                          <p className="myAccLCkeyVal">
                                            {leaveChartData.daysOrHours ===
                                            "hours"
                                              ? dateStringer.formatMinsDurationToShortHours(
                                                  leaveChartData.remainingVal ||
                                                    0
                                                )
                                              : leaveChartData.remainingVal ||
                                                0}{" "}
                                            {leaveChartData.daysOrHours ===
                                            "days"
                                              ? "day"
                                              : ""}
                                            {leaveChartData.remainingVal === 1
                                              ? ""
                                              : leaveChartData.daysOrHours ===
                                                "days"
                                              ? "s"
                                              : ""}
                                          </p>
                                          <p className="myAccLCkeyValTxt">
                                            remaining
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <p
                                      className={`myAccLeaveAnnLeaveTitle dashLeaveAnnLeaveTitle ${
                                        leaveChartDataLoading ? "invis" : ""
                                      }`}
                                    >
                                      Annual leave
                                    </p>
                                  </div>
                                  <div className="userDashDetailsLeftRow x1910911 x392842911">
                                    <p className="userDashDetailsLeftRowTitle">
                                      Total allowance
                                    </p>
                                    <p className="userDashDetailsLeftRowValue">
                                      {selectedYear <
                                      memoVals.checkUserState.currClientYear
                                        ? `${dateStringer.formatMinsDurationToHours(
                                            state.totMins
                                          )}`
                                        : `${
                                            state.totDays === null
                                              ? 0
                                              : state.totDays
                                          } day${
                                            state.totDays === 1 ? "" : "s"
                                          }`}{" "}
                                      {selectedYear >=
                                      memoVals.checkUserState.currClientYear ? (
                                        <span className="dashAllowPercentSpan">
                                          {" "}
                                          |{" "}
                                          {dateStringer.formatMinsDurationToHours(
                                            state.totMins
                                          )}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </div>
                                  <div className="userDashDetailsLeftRow x1910911 x392842911">
                                    <p className="userDashDetailsLeftRowTitle">
                                      Used:
                                    </p>
                                    <p className="userDashDetailsLeftRowValue">
                                      {selectedYear <
                                      memoVals.checkUserState.currClientYear
                                        ? `${dateStringer.formatMinsDurationToHours(
                                            state.usedMins
                                          )}`
                                        : `${
                                            state.usedDays === null
                                              ? 0
                                              : state.usedDays
                                          } day${
                                            state.usedDays === 1 ? "" : "s"
                                          }`}{" "}
                                      {selectedYear >=
                                      memoVals.checkUserState.currClientYear ? (
                                        <span className="dashAllowPercentSpan">
                                          {" "}
                                          |{" "}
                                          {dateStringer.formatMinsDurationToHours(
                                            state.usedMins
                                          )}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </div>
                                  <div className="userDashDetailsLeftRow x1910911 x392842911 noBorder">
                                    <p className="userDashDetailsLeftRowTitle">
                                      Remaining:
                                    </p>
                                    <p
                                      className={`userDashDetailsLeftRowValue ${
                                        state.remainingMins < 0
                                          ? "redRemaining"
                                          : "remainingColour"
                                      }`}
                                    >
                                      {selectedYear <
                                      memoVals.checkUserState.currClientYear
                                        ? `${dateStringer.formatMinsDurationToHours(
                                            state.remainingMins
                                          )}`
                                        : `${dateStringer.convertMinsToDays(
                                            state.remainingMins,
                                            state.dailyMins,
                                            true
                                          )}`}{" "}
                                      {selectedYear >=
                                        memoVals.checkUserState
                                          .currClientYear &&
                                      state.remainingMins > 0 ? (
                                        <span className="dashAllowPercentSpan">
                                          {" "}
                                          |{" "}
                                          {dateStringer.formatMinsDurationToHours(
                                            state.remainingMins
                                          )}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className={`avgMpwDashRow x10910 ${
                                    userLoading ? "invis" : ""
                                  }`}
                                >
                                  <div className="userDashDetailsLeftRow x1910911">
                                    <p
                                      className="userDashDetailsLeftRowTitle"
                                      onClick={() => {
                                        console.log({ state });
                                      }}
                                    >
                                      Annual leave allowance
                                    </p>
                                    <p className="userDashDetailsLeftRowValue">
                                      {dateStringer.convertAnnLeavePercentToWeeks(
                                        state.percentage
                                      )}{" "}
                                      weeks{" "}
                                      <span className="dashAllowPercentSpan">
                                        {" "}
                                        |{"   "} {state.percentage}%
                                      </span>
                                    </p>
                                  </div>

                                  <div className="userDashDetailsLeftRow x1910911 x392842911">
                                    <p className="userDashDetailsLeftRowTitle">
                                      Total allowance
                                    </p>
                                    <p className="userDashDetailsLeftRowValue">
                                      {selectedYear <
                                      memoVals.checkUserState.currClientYear
                                        ? `${dateStringer.formatMinsDurationToHours(
                                            state.totMins
                                          )}`
                                        : `${
                                            state.totDays === null
                                              ? 0
                                              : state.totDays
                                          } day${
                                            state.totDays === 1 ? "" : "s"
                                          }`}{" "}
                                      {selectedYear >=
                                      memoVals.checkUserState.currClientYear ? (
                                        <span className="dashAllowPercentSpan">
                                          {" "}
                                          |{" "}
                                          {dateStringer.formatMinsDurationToHours(
                                            state.totMins
                                          )}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </div>

                                  <div className="userDashDetailsLeftRow x1910911 x392842911">
                                    <p className="userDashDetailsLeftRowTitle">
                                      Used:
                                    </p>
                                    <p className="userDashDetailsLeftRowValue">
                                      {selectedYear <
                                      memoVals.checkUserState.currClientYear
                                        ? `${dateStringer.formatMinsDurationToHours(
                                            state.usedMins
                                          )}`
                                        : `${
                                            state.usedDays === null
                                              ? 0
                                              : state.usedDays
                                          } day${
                                            state.usedDays === 1 ? "" : "s"
                                          }`}{" "}
                                      {selectedYear >=
                                      memoVals.checkUserState.currClientYear ? (
                                        <span className="dashAllowPercentSpan">
                                          {" "}
                                          |{" "}
                                          {dateStringer.formatMinsDurationToHours(
                                            state.usedMins
                                          )}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </div>

                                  <div className="userDashDetailsLeftRow x1910911 x392842911 noBorder">
                                    <p className="userDashDetailsLeftRowTitle">
                                      Remaining:
                                    </p>
                                    <p
                                      className={`userDashDetailsLeftRowValue ${
                                        state.remainingMins < 0
                                          ? "redRemaining"
                                          : "remainingColour"
                                      }`}
                                    >
                                      {selectedYear <
                                      memoVals.checkUserState.currClientYear
                                        ? `${dateStringer.formatMinsDurationToHours(
                                            state.remainingMins
                                          )}`
                                        : `${dateStringer.convertMinsToDays(
                                            state.remainingMins,
                                            state.dailyMins,
                                            true
                                          )}`}{" "}
                                      {selectedYear >=
                                        memoVals.checkUserState
                                          .currClientYear &&
                                      state.remainingMins > 0 ? (
                                        <span className="dashAllowPercentSpan">
                                          {" "}
                                          |{" "}
                                          {dateStringer.formatMinsDurationToHours(
                                            state.remainingMins
                                          )}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </div>
                                </div>
                                {selectedYear >=
                                memoVals.checkUserState.currClientYear ? (
                                  <div
                                    className={`avgMpwDashRow x10910  ${
                                      userLoading ? "invis" : ""
                                    }`}
                                  >
                                    <div className="userDashDetailsLeftRow x1910911 x139849111 noBorder">
                                      <div className="">
                                        <p className="userDashDetailsLeftRowTitle">
                                          1 day of leave is worth:
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {dateStringer.formatMinsDurationToHours(
                                            state.payExcess
                                              ? state.avgMpd <
                                                state.contractedMpd
                                                ? state.contractedMpd
                                                : state.avgMpd
                                              : state.contractedMpd
                                          )}
                                        </p>
                                      </div>
                                      <img
                                        src={plane2}
                                        alt="Leave"
                                        className="worthPlaneImg"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div
                                  className={`avgMpwDashRow x10910  ${
                                    userLoading ? "invis" : ""
                                  }`}
                                >
                                  <div className="userDashDetailsLeftRow x1910911 x139849111 noBorder">
                                    <div className="">
                                      <p className="userDashDetailsLeftRowTitle">
                                        Time off in lieu balance
                                      </p>
                                      <p
                                        className={`userDashDetailsLeftRowValue ${
                                          !clientTilEnabled || userBlockTil
                                            ? "tilDisabledDashTxt"
                                            : "remainingColour"
                                        } ${
                                          !clientTilEnabled || userBlockTil
                                            ? ""
                                            : "ba0000"
                                        }`}
                                      >
                                        {!clientTilEnabled
                                          ? "TOIL is disabled in the FlowRota Admin settings"
                                          : userBlockTil
                                          ? `TOIL is disabled in ${dateStringer.possession(
                                              state.fName
                                            )} allowances settings. You can amend these using the 'Amend' button above`
                                          : `${
                                              toilBalance < 0 ? "-" : ""
                                            }${dateStringer.formatMinsDurationToHours(
                                              toilBalance
                                            )}`}
                                      </p>
                                    </div>
                                    <img
                                      src={toilImg}
                                      alt="Leave"
                                      className="worthPlaneImg"
                                    />
                                  </div>
                                  <div
                                    className={`allowanceAdditionsContainer additionsExtended maxWidth320 ${
                                      userBlockTil || !clientTilEnabled
                                        ? "none"
                                        : ""
                                    }`}
                                    onClick={(e) => {
                                      setShowConversions(!showConversions);
                                    }}
                                  >
                                    <div className="showMetaToggle">
                                      <img
                                        src={allowDropNavy2}
                                        alt="Show meta info"
                                        className={`showAccrualDataArrowImg ${
                                          showConversions
                                            ? "dataArrowShowMetaActive"
                                            : ""
                                        }`}
                                      />
                                      <p className="accruInfoTxt">
                                        {selectedYear} TOIL conversions (
                                        {toilConversionArr.length})
                                      </p>
                                    </div>

                                    <div
                                      className={`${
                                        showConversions
                                          ? `${
                                              memoVals.mobModal
                                                ? "x38437849274"
                                                : "addOrDeductContent"
                                            }`
                                          : "none"
                                      }`}
                                    >
                                      {toilToAnnLeave > 0 ? (
                                        <p
                                          className={`additionalMinsTxt ${
                                            window.innerWidth < 820
                                              ? "additionalMinsTxtMobLieu"
                                              : ""
                                          }`}
                                        >
                                          TOIL converted to Annual Leave:{" "}
                                          <span className="addedMinsSpan manipulatedAddedMinsSpan">
                                            {dateStringer.formatMinsDurationToHours(
                                              toilToAnnLeave
                                            )}
                                          </span>{" "}
                                          {/* {dateStringer
                                          .convertMinsToDays(
                                            toilToAnnLeave,
                                            dailyMins,
                                            true
                                          )
                                          .includes("minute") ||
                                        dailyMins <= 0 ? (
                                          ""
                                        ) : (
                                          <span className="x9hh">
                                            (
                                            {dateStringer.convertMinsToDays(
                                              toilToAnnLeave,
                                              dailyMins,
                                              true
                                            )}
                                            )
                                          </span>
                                        )} */}
                                        </p>
                                      ) : (
                                        <p className="dashNoConvArr">
                                          No conversions in {selectedYear}
                                        </p>
                                      )}
                                      {annLeaveToToil ? (
                                        <p
                                          className={`additionalMinsTxt ${
                                            window.innerWidth < 820
                                              ? "additionalMinsTxtMobLieu"
                                              : ""
                                          }`}
                                        >
                                          Annual leave converted to TOIL:{" "}
                                          <span className="addedMinsSpan manipulatedAddedMinsSpan">
                                            {dateStringer.formatMinsDurationToHours(
                                              annLeaveToToil
                                            )}
                                          </span>{" "}
                                          {/* {dateStringer
                                          .convertMinsToDays(
                                            annLeaveToToil,
                                            dailyMins,
                                            true
                                          )
                                          .includes("minute") ||
                                        dailyMins <= 0 ? (
                                          ""
                                        ) : (
                                          <span className="x9hh">
                                            (
                                            {dateStringer.convertMinsToDays(
                                              annLeaveToToil,
                                              dailyMins,
                                              true
                                            )}
                                            )
                                          </span>
                                        )} */}
                                        </p>
                                      ) : (
                                        ""
                                      )}

                                      <div
                                        className={`userAllowsConversionsArr ${
                                          window.innerWidth < 820
                                            ? "noBorder"
                                            : ""
                                        }`}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        {toilConversionArr
                                          .sort((a, b) => {
                                            return b.ts - a.ts;
                                          })
                                          .map((item, i) => {
                                            return (
                                              <div
                                                className={`dashTilListItem defaultCursor x248924894 userAllowToilConvRow userAllowToilConvRowDash
                        `}
                                                key={i}
                                              >
                                                <div className="dashTilListItemLeft">
                                                  <p className="dashTilType">
                                                    Conversion
                                                  </p>
                                                  <p className="dashTilListItemDs">
                                                    <img
                                                      src={convArr}
                                                      alt="Conversion"
                                                      className="convArrinline"
                                                    />

                                                    {dateStringer.printedDateFromDs(
                                                      item.ds,
                                                      true
                                                    )}
                                                  </p>
                                                  <p
                                                    className={`dashTilListItemTimeStrip ${
                                                      item.toLeave
                                                        ? ""
                                                        : "colour00ccff"
                                                    }`}
                                                  >
                                                    {item.toLeave
                                                      ? "TOIL to Annual Leave"
                                                      : "Annual Leave to TOIL"}
                                                  </p>
                                                  {item.addedByFName &&
                                                  item.addedByLName ? (
                                                    <p className="itemconvAddedBy">
                                                      By {item.addedByFName}{" "}
                                                      {item.addedByLName[0]}
                                                    </p>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                                <div className="dashTilListItemRight">
                                                  <p
                                                    className={`dashTilListItemDurMins ${
                                                      item.toLeave
                                                        ? "dashTilListItemDurMins"
                                                        : "colour00ccff"
                                                    }`}
                                                  >
                                                    {!item.toLeave ? "-" : "+"}
                                                    {dateStringer.formatMinsDurationToHours(
                                                      item.mins
                                                    )}
                                                  </p>
                                                  <p className="dashTilListItemType">
                                                    {item.toLeave
                                                      ? `added to annual leave`
                                                      : "deducted from annual leave"}
                                                  </p>
                                                  <p
                                                    className="deleteToilConvBtn"
                                                    onClick={() => {
                                                      setSureDeleteConversion({
                                                        show: true,
                                                        conversionID:
                                                          item.conversionID,
                                                        userID: userID,
                                                        toLeave: item.toLeave,
                                                        mins: item.mins,
                                                      });
                                                    }}
                                                  >
                                                    Remove
                                                  </p>
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>
                                      <div
                                        className={`convToilToLeaveEditUserBtn ${
                                          window.innerWidth < 820
                                            ? "allLeaveDashControllerMobClosed"
                                            : ""
                                        } convToilDashBtn ${
                                          toilConversionArr.length === 0
                                            ? "toilConversionArrEmpty"
                                            : ""
                                        }`}
                                        onClick={(e) => {
                                          e.stopPropagation();

                                          memoVals.setShowConvertToilModal(
                                            (x) => selectedUser
                                          );
                                          // console.log("!", user.userID);
                                        }}
                                      >
                                        <div className="dashAddDeductTitle colourWhite">
                                          Convert TOIL to leave
                                          <img
                                            src={convert}
                                            alt="Edit"
                                            className="convertImg"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {state.customs.map((custom, i) => {
                                  return (
                                    <div
                                      className={`avgMpwDashRow x10910 ${
                                        userLoading ? "invis" : ""
                                      } ${
                                        selectedYear >
                                          memoVals.checkUserState
                                            .currClientYear && custom.removed
                                          ? "none"
                                          : ""
                                      }`}
                                    >
                                      <div
                                        className="userDashDetailsLeftRow x1910911"
                                        key={i}
                                      >
                                        <p className="userDashDetailsLeftRowTitle">
                                          Leave type
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {custom.leaveTypeName}{" "}
                                          {custom.removed ? <br /> : ""}
                                          {custom.removed ? (
                                            <span className="dashAllowPercentSpan noLongerAvailAllowDash">
                                              No longer available
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </p>
                                      </div>
                                      <div
                                        className="userDashDetailsLeftRow x1910911"
                                        key={i}
                                      >
                                        <p className="userDashDetailsLeftRowTitle">
                                          Allowance
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {custom.totAllow === 366
                                            ? "Unlimited"
                                            : custom.daysOrHours === "hours"
                                            ? dateStringer.formatMinsDurationToHours(
                                                custom.totAllow
                                              )
                                            : `${custom.totAllow} day${
                                                custom.totAllow === 1 ? "" : "s"
                                              }`}
                                        </p>
                                      </div>
                                      <div
                                        className="userDashDetailsLeftRow x1910911 noBorder"
                                        key={i}
                                      >
                                        <p className="userDashDetailsLeftRowTitle">
                                          Remaining
                                        </p>
                                        <p
                                          className={`userDashDetailsLeftRowValue remainingColour ${
                                            custom.remaining < 0
                                              ? "redRemaining"
                                              : ""
                                          } `}
                                        >
                                          {custom.totAllow === 366
                                            ? "-"
                                            : custom.daysOrHours === "hours"
                                            ? dateStringer.formatMinsDurationToHours(
                                                custom.remaining
                                              )
                                            : `${custom.remaining} day${
                                                custom.remaining === 1
                                                  ? ""
                                                  : "s"
                                              }`}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                              {/* {userLoading ? (
                                ""
                              ) : (
                                <p
                                  className="dashManageTeamsBtn x99j"
                                  onClick={() => {
                                    memoVals.setShowEditUser(x => {return{
                                      userID: selectedUser,
                                      allows: true,
                                    });
                                  }}
                                >
                                  Amend {dateStringer.possession(state.fName)}{" "}
                                  allowances
                                </p>
                              )} */}
                            </div>
                          ) : (
                            ""
                          )}

                          {subPage === "hours" ? (
                            <div className="userDashRight noScroll">
                              <div className="avgMpwDashRow x10910 hoursDashTopBar">
                                <div className="userDashDetailsLeftRow x1910911 x139849111 noBorder">
                                  <div
                                    className={
                                      hoursLoading || userLoading
                                        ? ""
                                        : "x1093901111a"
                                    }
                                  >
                                    {hoursLoading || userLoading ? (
                                      <img
                                        src={horizontalBalls}
                                        alt="Loading user"
                                        className="dashUserLoadingimg allowsLoadDash"
                                      />
                                    ) : (
                                      <div
                                        className="editUserMessageBtn unarchBtn x190390111"
                                        onClick={() => {
                                          axios
                                            .post(
                                              `${serverURL}/get-user-tab-data`,
                                              {
                                                downloadCsv: true,
                                                userID: selectedUser,
                                                shifts: true,

                                                csvShifts: true,
                                                csvTil: true,
                                                csvOverime: true,

                                                startDs:
                                                  dateStringer.createStringFromDateInput(
                                                    minDateInput
                                                  ),
                                                endDs:
                                                  dateStringer.createStringFromDateInput(
                                                    maxDateInput
                                                  ),
                                                nowDs:
                                                  dateStringer.createStringFromTimestamp(
                                                    new Date().getTime()
                                                  ),
                                              },

                                              {
                                                responseType: "blob",
                                                withCredentials: true,
                                                credentials: "include",
                                              }
                                            )
                                            .then((response) => {
                                              // Create a Blob from the response data
                                              const blob = new Blob(
                                                [response.data],
                                                {
                                                  type: "text/csv",
                                                }
                                              );

                                              // Create a URL for the Blob
                                              const url =
                                                window.URL.createObjectURL(
                                                  blob
                                                );

                                              // Create a link element to trigger the download
                                              const link =
                                                document.createElement("a");
                                              link.href = url;
                                              link.download = `${state.fName.toLowerCase()}_${state.lName.toLowerCase()}_tab_data.csv`; // Specify the filename
                                              document.body.appendChild(link);

                                              // Trigger the download
                                              link.click();

                                              // Cleanup
                                              window.URL.revokeObjectURL(url);
                                              document.body.removeChild(link);
                                            })
                                            .catch((error) => {
                                              console.error(
                                                "Error downloading CSV:",
                                                error
                                              );
                                            });
                                        }}
                                      >
                                        Export to CSV{" "}
                                        <img
                                          src={download}
                                          alt="Message"
                                          className="downloadCsvImg"
                                        />
                                      </div>
                                    )}
                                    {/* <p className="userDashDetailsLeftRowValue">
                                        {dateStringer.formatMinsDurationToHours(
                                          state.payExcess
                                            ? state.avgMpd < state.contractedMpd
                                              ? state.contractedMpd
                                              : state.avgMpd
                                            : state.contractedMpd
                                        )}
                                      </p> */}
                                  </div>
                                  <div className="dashHoursTopRight">
                                    <select
                                      className="dashYrSelectorDropdown dashMonthSel"
                                      value={selectedStartTs}
                                      onChange={(e) => {
                                        let val = parseInt(e.target.value);
                                        setSelectedStartTs(val);
                                        let dateObj = new Date(val);
                                        dateObj.setMonth(
                                          dateObj.getMonth() + 1
                                        );
                                        dateObj.setDate(dateObj.getDate() - 1);
                                        dateObj.setHours(23, 59, 0, 0);
                                        setDataMaxTs(dateObj.getTime());
                                        setDataMinTs(val);
                                        setMinDateInput(
                                          dateStringer.createDateInputFromDateString(
                                            dateStringer.createStringFromTimestamp(
                                              val
                                            )
                                          )
                                        );
                                        setMaxDateInput(
                                          dateStringer.createDateInputFromDateString(
                                            dateStringer.createStringFromTimestamp(
                                              dateObj.getTime()
                                            )
                                          )
                                        );
                                      }}
                                    >
                                      {monthArr.map((x, i) => {
                                        return (
                                          <option key={i} value={x.startTs}>
                                            {x.title}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <p
                                      className="openDataTabOnUser"
                                      onClick={() => {
                                        memoVals.setShowEditUser((x) => {
                                          return {
                                            userID: selectedUser,
                                            tab: true,
                                            monthArr: monthArr,
                                            selectedStartTs: selectedStartTs,
                                            minDateInput,
                                            maxDateInput,
                                          };
                                        });
                                      }}
                                    >
                                      Set specific dates
                                    </p>
                                  </div>

                                  {/* <img
                                      src={plane2}
                                      alt="Leave"
                                      className="worthPlaneImg"
                                    /> */}
                                </div>
                              </div>

                              <div
                                className={`dashTabHours dashTabHoursWithBottomBar ${
                                  !memoVals.mobModal
                                    ? "dashTabHoursWithBottomBarDesktop"
                                    : ""
                                } ${
                                  memoVals.device.ios
                                    ? "dashTabHoursWithBottomBarIos"
                                    : ""
                                }`}
                              >
                                {hoursDataArr.length === 0 &&
                                !userLoading &&
                                !hoursLoading ? (
                                  <p className="noDashHours">No hours</p>
                                ) : (
                                  ""
                                )}
                                {hoursDataArr.map((item, i) => {
                                  let title = "Shift";
                                  if (item.shiftID || item.tilID) {
                                    if (item.tilID) {
                                      if (item.type === "til") {
                                        title = "Shift in lieu";
                                      } else {
                                        title = "Overtime";
                                      }
                                    }
                                  } else {
                                    title = item.leaveTypeName;
                                  }
                                  return (
                                    <div
                                      className={`dataTabUserShift dashItem ${
                                        i === hoursDataArr.length - 1 &&
                                        hoursDataArr.length !== 1
                                          ? "noBorder"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        if (item.type === "shift") {
                                          memoVals.setShowEditShiftModal(
                                            (x) => {
                                              return {
                                                itemID: item.shiftID,
                                                itemType: "shift",

                                                itemIsNew: false,
                                                userID: selectedUser,
                                              };
                                            }
                                          );
                                        }
                                        if (
                                          item.type === "til" ||
                                          item.type === "overtime"
                                        ) {
                                          memoVals.setShowEditShiftModal(
                                            (x) => {
                                              return {
                                                itemID: item.tilID,
                                                itemType: item.type,
                                                // itemFName: editUserState.editFName,
                                                // itemLName: editUserState.editLName,
                                                itemIsNew: false,
                                                userID: selectedUser,
                                              };
                                            }
                                          );
                                        }
                                      }}
                                    >
                                      <div className="dataTabUserShiftTop">
                                        <div className="dataTabUserShiftLeft">
                                          <div className="dataTabBlock dataTabBlockFirst">
                                            <p className="dataTabBlockTitle">
                                              Type
                                            </p>
                                            <p className={`dataTabBlockValue`}>
                                              {title}
                                            </p>
                                            <p className="dataTabBlockTeamName">
                                              {item.teamName}
                                            </p>
                                          </div>
                                          <div className="dataTabBlock dataTabTimesBlock">
                                            {item.reqID ? (
                                              <p
                                                className={`dataTabBlockValueTimes ${
                                                  item.startDs === item.endDs
                                                    ? "sameDataPushDown"
                                                    : ""
                                                }`}
                                              >
                                                <img
                                                  src={cal}
                                                  className="dataTabClockIcon"
                                                  alt="Times"
                                                />
                                                {!item.startDs
                                                  ? "No leave"
                                                  : dateStringer.printedDateFromDs(
                                                      item.startDs,
                                                      false
                                                    )}{" "}
                                              </p>
                                            ) : (
                                              <p className="dataTabBlockValueTimes dataTabBlockValueTimesTimes">
                                                <img
                                                  src={clock}
                                                  className="dataTabClockIcon"
                                                  alt="Times"
                                                />
                                                {dateStringer.dsToTimeStrip(
                                                  item.startDs,
                                                  true
                                                )}{" "}
                                                -{" "}
                                                {dateStringer.dsToTimeStrip(
                                                  item.endDs,
                                                  true
                                                )}
                                              </p>
                                            )}
                                            {item.reqID ? (
                                              <p
                                                className={`dataTabBlockValueTimes ${
                                                  item.startDs === item.endDs
                                                    ? "none"
                                                    : ""
                                                }`}
                                              >
                                                <img
                                                  src={cal}
                                                  className="dataTabClockIcon"
                                                  alt="Times"
                                                />
                                                {dateStringer.printedDateFromDs(
                                                  item.endDs,
                                                  false
                                                )}{" "}
                                              </p>
                                            ) : (
                                              <p className="dataTabBlockValueTimes ">
                                                <img
                                                  src={cal}
                                                  className="dataTabClockIcon"
                                                  alt="Date"
                                                />
                                                {dateStringer.printedDateFromDs(
                                                  item.startDs,
                                                  true,
                                                  false,
                                                  false,
                                                  true
                                                )}
                                              </p>
                                            )}
                                          </div>
                                          <div className="dataTabBlock dataTabDurMins">
                                            <p className="dataTabBlockTitle">
                                              {item.unpaid
                                                ? "Duration"
                                                : "Payable"}
                                            </p>
                                            <p
                                              className={`dataTabBlockValue dataTabBlockValueDurMins `}
                                            >
                                              {dateStringer.formatMinsDurationToHours(
                                                item.durMins || 0,
                                                memoVals.mobModal ? true : false
                                              )}
                                            </p>
                                          </div>
                                        </div>{" "}
                                        <div className="dataTabUserShiftRight">
                                          <div className="dataTabBlock flexEnd2">
                                            <p className="dataTabBlockTitle textAlignRight">
                                              {item.cost < 0
                                                ? "Deduction"
                                                : "Cost"}
                                            </p>
                                            <p className="dataTabBlockValue textAlignRight">
                                              {memoVals.currencySymbol}
                                              {item.cost === 0 ||
                                              item.type === "til"
                                                ? ""
                                                : item.cost <= 0
                                                ? ""
                                                : "+"}
                                              {Number(
                                                item.cost
                                                  ? item.cost.toFixed(2)
                                                  : 0
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {item.ghost ||
                                      item.inLieu ||
                                      item.payClocked ||
                                      item.absent ||
                                      item.lateDurMins > 0 ||
                                      item.reqID ? (
                                        <div
                                          className={`dataTabUserShiftBottom ${
                                            item.reqID
                                              ? "dataTabUserShiftBottomReq"
                                              : ""
                                          }`}
                                        >
                                          <div className="tabRow">
                                            {item.reqID ? (
                                              <p
                                                className={`tabDays ${
                                                  item.type === "leave"
                                                    ? "tabDaysLeave"
                                                    : ""
                                                }`}
                                              >
                                                {item.days} day
                                                {item.days === 1 ? "" : "s"}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            {item.ghost ? (
                                              <p className={`tabDays tabGhost`}>
                                                Paid closed day
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            {item.inLieu ? (
                                              <p className={`tabDays tabGhost`}>
                                                Paid & added to TOIL balance
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            {item.lateDurMins ? (
                                              <p className="tabLate">
                                                {dateStringer.formatMinsDurationToHours(
                                                  item.lateDurMins,
                                                  true
                                                )}{" "}
                                                late -{" "}
                                                {item.unpaidLate
                                                  ? item.type === "til"
                                                    ? "Not earned"
                                                    : "Unpaid"
                                                  : item.type === "til"
                                                  ? "Still earned"
                                                  : "Paid"}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            {item.absent &&
                                            !item.partialAbsent ? (
                                              <p className="tabLate">
                                                Absent -{" "}
                                                {item.unpaidAbsent
                                                  ? item.type === "til"
                                                    ? "Not earned"
                                                    : "Unpaid"
                                                  : item.type === "til"
                                                  ? "Still earned"
                                                  : "Paid"}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            {item.absent &&
                                            item.partialAbsent ? (
                                              <p className="tabLate">
                                                {dateStringer.formatMinsDurationToHours(
                                                  item.partialAbsentDuration /
                                                    1000 /
                                                    60,
                                                  false
                                                )}{" "}
                                                absence -{" "}
                                                {item.unpaidAbsent
                                                  ? item.type === "til"
                                                    ? "Not earned"
                                                    : "Unpaid"
                                                  : item.type === "til"
                                                  ? "Still earned"
                                                  : "Paid"}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                              <div
                                className={`dashTabHoursTotals ${
                                  userLoading || hoursLoading ? "invis" : ""
                                }`}
                              >
                                <p className="dashTabHoursTotTitle">
                                  Total hours:{" "}
                                  <span className="dashTabHoursTotValue">
                                    {countData("hours")}
                                  </span>
                                </p>
                                <p
                                  className="dashTabHoursTotTitle"
                                  // onClick={() => {
                                  //   console.log({ hoursDataArr });
                                  // }}
                                >
                                  Total cost:{" "}
                                  <span className="dashTabHoursTotValue">
                                    {countData("cost")}
                                  </span>
                                </p>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {subPage === "leave" ? (
                            <div className="userDashRight noScroll">
                              <div className="avgMpwDashRow x10910 hoursDashTopBar">
                                <div className="userDashDetailsLeftRow x1910911 x139849111 noBorder">
                                  <div
                                    className={
                                      hoursLoading || userLoading
                                        ? ""
                                        : "x1093901111a"
                                    }
                                  >
                                    {hoursLoading || userLoading ? (
                                      <img
                                        src={horizontalBalls}
                                        alt="Loading user"
                                        className="dashUserLoadingimg allowsLoadDash"
                                      />
                                    ) : (
                                      <div
                                        className={`editUserMessageBtn unarchBtn x190390111 ${
                                          exportDisable ? "disable" : ""
                                        }`}
                                        onClick={() => {
                                          setExportDisable(true);

                                          axios
                                            .post(
                                              `${serverURL}/get-user-tab-data`,
                                              {
                                                downloadCsv: true,
                                                userID: selectedUser,
                                                leave: true,

                                                csvLeave: true,

                                                startDs:
                                                  dateStringer.createStringFromDateInput(
                                                    minDateInput
                                                  ),
                                                endDs:
                                                  dateStringer.createStringFromDateInput(
                                                    maxDateInput
                                                  ),
                                                nowDs:
                                                  dateStringer.createStringFromTimestamp(
                                                    new Date().getTime()
                                                  ),
                                              },

                                              {
                                                responseType: "blob",
                                                withCredentials: true,
                                                credentials: "include",
                                              }
                                            )
                                            .then((response) => {
                                              setTimeout(() => {
                                                setExportDisable(false);
                                              }, 5000);
                                              // Create a Blob from the response data
                                              const blob = new Blob(
                                                [response.data],
                                                {
                                                  type: "text/csv",
                                                }
                                              );

                                              // Create a URL for the Blob
                                              const url =
                                                window.URL.createObjectURL(
                                                  blob
                                                );

                                              // Create a link element to trigger the download
                                              const link =
                                                document.createElement("a");
                                              link.href = url;
                                              link.download = `${state.fName.toLowerCase()}_${state.lName.toLowerCase()}_tab_data.csv`; // Specify the filename
                                              document.body.appendChild(link);

                                              // Trigger the download
                                              link.click();

                                              // Cleanup
                                              window.URL.revokeObjectURL(url);
                                              document.body.removeChild(link);
                                            })
                                            .catch((error) => {
                                              console.error(
                                                "Error downloading CSV:",
                                                error
                                              );
                                            });
                                        }}
                                      >
                                        Export to CSV{" "}
                                        <img
                                          src={download}
                                          alt="Message"
                                          className="downloadCsvImg"
                                        />
                                      </div>
                                    )}
                                    {/* <p className="userDashDetailsLeftRowValue">
                                        {dateStringer.formatMinsDurationToHours(
                                          state.payExcess
                                            ? state.avgMpd < state.contractedMpd
                                              ? state.contractedMpd
                                              : state.avgMpd
                                            : state.contractedMpd
                                        )}
                                      </p> */}
                                  </div>
                                  <div className="dashHoursTopRight">
                                    <select
                                      className="dashYrSelectorDropdown dashMonthSel"
                                      value={selectedStartTs}
                                      onChange={(e) => {
                                        let val = parseInt(e.target.value);
                                        setSelectedStartTs(val);
                                        let dateObj = new Date(val);
                                        dateObj.setMonth(
                                          dateObj.getMonth() + 1
                                        );
                                        dateObj.setDate(dateObj.getDate() - 1);
                                        dateObj.setHours(23, 59, 0, 0);
                                        setDataMaxTs(dateObj.getTime());
                                        setDataMinTs(val);
                                        setMinDateInput(
                                          dateStringer.createDateInputFromDateString(
                                            dateStringer.createStringFromTimestamp(
                                              val
                                            )
                                          )
                                        );
                                        setMaxDateInput(
                                          dateStringer.createDateInputFromDateString(
                                            dateStringer.createStringFromTimestamp(
                                              dateObj.getTime()
                                            )
                                          )
                                        );
                                      }}
                                    >
                                      {monthArr.map((x, i) => {
                                        return (
                                          <option key={i} value={x.startTs}>
                                            {x.title}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <p
                                      className="openDataTabOnUser"
                                      onClick={() => {
                                        memoVals.setShowEditUser((x) => {
                                          return {
                                            userID: selectedUser,
                                            tab: true,
                                            monthArr: monthArr,
                                            selectedStartTs: selectedStartTs,
                                            minDateInput,
                                            maxDateInput,
                                          };
                                        });
                                      }}
                                    >
                                      Set specific dates
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="dashTabHours dashTabHoursTimesheets">
                                {hoursDataArr.length === 0 &&
                                !userLoading &&
                                !hoursLoading ? (
                                  <p className="noDashHours">No leave</p>
                                ) : (
                                  ""
                                )}
                                {hoursDataArr
                                  .filter((x) => {
                                    return x.type === "leave";
                                  })
                                  .map((item, i) => {
                                    return (
                                      <div
                                        className={`dataTabUserShift dashItem ${
                                          i ===
                                            hoursDataArr.filter((x) => {
                                              return x.type === "leave";
                                            }).length -
                                              1 &&
                                          hoursDataArr.filter((x) => {
                                            return x.type === "leave";
                                          }).length !== 1
                                            ? "noBorder"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          memoVals.setShowReqMgrModal(
                                            (x) => item.reqID
                                          );
                                        }}
                                      >
                                        <div className="dataTabUserShiftTop">
                                          <div className="dataTabUserShiftLeft">
                                            <div className="dataTabBlock dataTabBlockFirst x21989111342">
                                              <p className="dataTabBlockTitle">
                                                Type
                                              </p>
                                              <p
                                                className={`dataTabBlockValue`}
                                              >
                                                {item.leaveTypeName}
                                              </p>
                                            </div>
                                            <div className="dataTabBlock dataTabTimesBlock x18191x">
                                              <p
                                                className={`dataTabBlockValueTimes ${
                                                  item.startDs === item.endDs
                                                    ? "sameDataPushDown"
                                                    : ""
                                                }`}
                                              >
                                                <img
                                                  src={cal}
                                                  className="dataTabClockIcon"
                                                  alt="Times"
                                                />
                                                {!item.startDs
                                                  ? "No leave"
                                                  : dateStringer.printedDateFromDs(
                                                      item.startDs,
                                                      false
                                                    )}{" "}
                                              </p>

                                              <p
                                                className={`dataTabBlockValueTimes ${
                                                  item.startDs === item.endDs
                                                    ? "none"
                                                    : ""
                                                }`}
                                              >
                                                <img
                                                  src={cal}
                                                  className="dataTabClockIcon"
                                                  alt="Times"
                                                />
                                                {dateStringer.printedDateFromDs(
                                                  item.endDs,
                                                  false
                                                )}{" "}
                                              </p>
                                            </div>
                                            <div className="dataTabBlock dataTabDurMins">
                                              <p className="dataTabBlockTitle">
                                                {item.unpaid
                                                  ? "Duration"
                                                  : "Payable"}
                                              </p>
                                              <p
                                                className={`dataTabBlockValue dataTabBlockValueDurMins `}
                                              >
                                                {dateStringer.formatMinsDurationToHours(
                                                  item.durMins || 0,
                                                  memoVals.mobModal
                                                    ? true
                                                    : false
                                                )}
                                              </p>
                                            </div>
                                          </div>{" "}
                                          <div className="dataTabUserShiftRight">
                                            <div className="dataTabBlock flexEnd2">
                                              <p className="dataTabBlockTitle textAlignRight">
                                                {item.cost < 0
                                                  ? "Deduction"
                                                  : "Cost"}
                                              </p>
                                              <p className="dataTabBlockValue textAlignRight">
                                                {memoVals.currencySymbol}
                                                {item.cost === 0 ||
                                                item.type === "til"
                                                  ? ""
                                                  : item.cost <= 0
                                                  ? ""
                                                  : "+"}
                                                {Number(
                                                  item.cost
                                                    ? item.cost.toFixed(2)
                                                    : 0
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className={`dataTabUserShiftBottom ${
                                            item.reqID
                                              ? "dataTabUserShiftBottomReq"
                                              : ""
                                          }`}
                                        >
                                          <div className="tabRow">
                                            {item.reqID ? (
                                              <p
                                                className={`tabDays ${
                                                  item.type === "leave"
                                                    ? "tabDaysLeave"
                                                    : ""
                                                }`}
                                              >
                                                {item.days} day
                                                {item.days === 1 ? "" : "s"}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            {item.ghost ? (
                                              <p className={`tabDays tabGhost`}>
                                                Paid closed day
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            {item.inLieu ? (
                                              <p className={`tabDays tabGhost`}>
                                                Paid & added to TOIL balance
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            {item.lateDurMins ? (
                                              <p className="tabLate">
                                                {dateStringer.formatMinsDurationToHours(
                                                  item.lateDurMins,
                                                  true
                                                )}{" "}
                                                late -{" "}
                                                {item.unpaidLate
                                                  ? item.type === "til"
                                                    ? "Not earned"
                                                    : "Unpaid"
                                                  : item.type === "til"
                                                  ? "Still earned"
                                                  : "Paid"}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            {item.absent &&
                                            !item.partialAbsent ? (
                                              <p className="tabLate">
                                                Absent -{" "}
                                                {item.unpaidAbsent
                                                  ? item.type === "til"
                                                    ? "Not earned"
                                                    : "Unpaid"
                                                  : item.type === "til"
                                                  ? "Still earned"
                                                  : "Paid"}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            {item.absent &&
                                            item.partialAbsent ? (
                                              <p className="tabLate">
                                                {dateStringer.formatMinsDurationToHours(
                                                  item.partialAbsentDuration /
                                                    1000 /
                                                    60,
                                                  false
                                                )}{" "}
                                                absence -{" "}
                                                {item.unpaidAbsent
                                                  ? item.type === "til"
                                                    ? "Not earned"
                                                    : "Unpaid"
                                                  : item.type === "til"
                                                  ? "Still earned"
                                                  : "Paid"}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {subPage === "absence" ? (
                            <div
                              className={`userDashRight userDashRightTeams ${
                                absPage !== "info"
                                  ? "noScroll"
                                  : memoVals.mob
                                  ? "noScroll paddingBottom20"
                                  : ""
                              } `}
                            >
                              <div
                                className={`avgMpwDashRow x10910 ${
                                  absPage === "info" || absPage === "forms"
                                    ? "absPage1Height"
                                    : ""
                                } absrowMob`}
                              >
                                <div className="userDashDetailsLeftRow x1910911 x139849111 noBorder">
                                  <div
                                    className={
                                      hoursLoading || userLoading
                                        ? ""
                                        : "x1093901111a"
                                    }
                                  >
                                    {hoursLoading || userLoading ? (
                                      <img
                                        src={horizontalBalls}
                                        alt="Loading user"
                                        className="dashUserLoadingimg allowsLoadDash"
                                      />
                                    ) : (
                                      <div
                                        className={`dashAbsMenu ${
                                          absPage !== "info"
                                            ? ""
                                            : "dashAbsMenuSel"
                                        }`}
                                      >
                                        <div
                                          className={`dashAbsMenuDiv ${
                                            absPage !== "info"
                                              ? `dashAbsMenuDiv2 ${
                                                  absPage === "forms"
                                                    ? "x29484298422"
                                                    : ""
                                                }`
                                              : ""
                                          }`}
                                        >
                                          <p
                                            className={`dashAbsInfo ${
                                              absPage !== "info"
                                                ? ""
                                                : "dashAbsInfoSel"
                                            }`}
                                            onClick={() => {
                                              setAbsPage("info");
                                            }}
                                          >
                                            Info
                                          </p>
                                          <p
                                            className={`dashAbsInfo ${
                                              absPage === "forms"
                                                ? "dashAbsInfoSel"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              setAbsPage("forms");

                                              if (!forms[0]) {
                                                setFormsLoading(true);
                                                axios
                                                  .post(
                                                    `${serverURL}/get-user-dash-forms`,
                                                    {
                                                      userID: selectedUser,
                                                    },

                                                    {
                                                      withCredentials: true,
                                                      credentials: "include",
                                                    }
                                                  )
                                                  .then((response) => {
                                                    if (
                                                      response.data.message ===
                                                      "success"
                                                    ) {
                                                      setTimeout(() => {
                                                        setFormsLoading(false);
                                                      }, 0);

                                                      setForms(
                                                        response.data.forms
                                                      );
                                                    }
                                                  })
                                                  .catch((err) => {
                                                    console.error(err);
                                                  });
                                              }
                                            }}
                                          >
                                            Forms
                                          </p>
                                          <p
                                            className={`dashAbsInfo ${
                                              absPage === "instances"
                                                ? "dashAbsInfoSel"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              setAbsPage("instances");
                                            }}
                                          >
                                            Instances
                                          </p>
                                        </div>
                                        <div
                                          className={`editUserMessageBtn unarchBtn marginTop20 ${
                                            absPage === "info" ||
                                            absPage === "forms"
                                              ? "invis"
                                              : ""
                                          } x190390111`}
                                          onClick={() => {
                                            axios
                                              .post(
                                                `${serverURL}/get-user-tab-data`,
                                                {
                                                  downloadCsv: true,
                                                  userID: selectedUser,
                                                  absence: true,
                                                  leave: true,
                                                  combineWithAbsentShifts: true,
                                                  csvAbsence: true,

                                                  startDs:
                                                    dateStringer.createStringFromDateInput(
                                                      minDateInput
                                                    ),
                                                  endDs:
                                                    dateStringer.createStringFromDateInput(
                                                      maxDateInput
                                                    ),
                                                  nowDs:
                                                    dateStringer.createStringFromTimestamp(
                                                      new Date().getTime()
                                                    ),
                                                },

                                                {
                                                  responseType: "blob",
                                                  withCredentials: true,
                                                  credentials: "include",
                                                }
                                              )
                                              .then((response) => {
                                                // Create a Blob from the response data
                                                const blob = new Blob(
                                                  [response.data],
                                                  {
                                                    type: "text/csv",
                                                  }
                                                );

                                                // Create a URL for the Blob
                                                const url =
                                                  window.URL.createObjectURL(
                                                    blob
                                                  );

                                                // Create a link element to trigger the download
                                                const link =
                                                  document.createElement("a");
                                                link.href = url;
                                                link.download = `${state.fName.toLowerCase()}_${state.lName.toLowerCase()}_tab_data.csv`; // Specify the filename
                                                document.body.appendChild(link);

                                                // Trigger the download
                                                link.click();

                                                // Cleanup
                                                window.URL.revokeObjectURL(url);
                                                document.body.removeChild(link);
                                              })
                                              .catch((error) => {
                                                console.error(
                                                  "Error downloading CSV:",
                                                  error
                                                );
                                              });
                                          }}
                                        >
                                          Export to CSV{" "}
                                          <img
                                            src={download}
                                            alt="Message"
                                            className="downloadCsvImg"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {/* <p className="userDashDetailsLeftRowValue">
                                        {dateStringer.formatMinsDurationToHours(
                                          state.payExcess
                                            ? state.avgMpd < state.contractedMpd
                                              ? state.contractedMpd
                                              : state.avgMpd
                                            : state.contractedMpd
                                        )}
                                      </p> */}
                                  </div>
                                  {absPage === "info" ? (
                                    <select
                                      className="dashYrSelectorDropdown x910930112"
                                      value={selectedYear}
                                      onChange={(e) => {
                                        let val = e.target.value;
                                        setSelectedYear(val);
                                        setUserLoading(true);
                                        setLoadingAbsence(true);
                                        const cancelSource1 =
                                          axios.CancelToken.source();
                                        axios
                                          .post(
                                            `${serverURL}/get-user-dash-absence`,
                                            {
                                              userID: selectedUser,
                                              year: parseInt(val),
                                              nowDs:
                                                dateStringer.createStringFromTimestamp(
                                                  new Date().getTime()
                                                ),
                                            },

                                            {
                                              withCredentials: true,
                                              credentials: "include",
                                              cancelToken: cancelSource1.token,
                                            }
                                          )
                                          .then((response) => {
                                            if (
                                              response.data.message ===
                                              "success"
                                            ) {
                                              setTimeout(() => {
                                                setUserLoading(false);
                                                setLoadingAbsence(false);
                                              }, 0);
                                              // lilly
                                              setState({
                                                absentInstances:
                                                  response.data.instances,
                                                absentDurMins:
                                                  response.data.durMins,
                                                paidAbsentDurMins:
                                                  response.data.paidDurMins,
                                                unpaidAbsentDurMins:
                                                  response.data.unpaidDurMins,
                                                whollyAbsentShiftsQty:
                                                  response.data
                                                    .whollyAbsentShiftsQty,
                                                partiallyAbsentShiftsQty:
                                                  response.data
                                                    .partiallyAbsentShiftsQty,
                                                absentShiftDurMins:
                                                  response.data.shiftDurMins,
                                                absentPeriodDurMins:
                                                  response.data.periodDurMins,
                                                absentPercentage:
                                                  response.data.percentage,
                                                primaryTeamName:
                                                  response.data.primaryTeamName,
                                                companyRankNum:
                                                  response.data.companyRankNum,
                                                teamRankNum:
                                                  response.data.teamRankNum,
                                                teamRankOutOf:
                                                  response.data.teamRankOutOf,
                                                companyRankOutOf:
                                                  response.data
                                                    .companyRankOutOf,
                                              });
                                            }
                                          })
                                          .catch((err) => {
                                            console.error(err);
                                          });
                                        return () => {
                                          cancelSource1.cancel(
                                            "Component unmounted"
                                          );
                                        };
                                      }}
                                    >
                                      {availableYears.map((yr) => {
                                        return (
                                          <option value={yr} key={yr}>
                                            {yr}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  ) : (
                                    <div
                                      className={`dashHoursTopRight ${
                                        absPage === "forms" ? "none" : ""
                                      }`}
                                    >
                                      <select
                                        className="dashYrSelectorDropdown dashMonthSel"
                                        value={selectedStartTs}
                                        onChange={(e) => {
                                          let val = parseInt(e.target.value);
                                          setSelectedStartTs(val);
                                          let dateObj = new Date(val);
                                          dateObj.setMonth(
                                            dateObj.getMonth() + 1
                                          );
                                          dateObj.setDate(
                                            dateObj.getDate() - 1
                                          );
                                          dateObj.setHours(23, 59, 0, 0);
                                          setDataMaxTs(dateObj.getTime());
                                          setDataMinTs(val);
                                          setMinDateInput(
                                            dateStringer.createDateInputFromDateString(
                                              dateStringer.createStringFromTimestamp(
                                                val
                                              )
                                            )
                                          );
                                          setMaxDateInput(
                                            dateStringer.createDateInputFromDateString(
                                              dateStringer.createStringFromTimestamp(
                                                dateObj.getTime()
                                              )
                                            )
                                          );
                                        }}
                                      >
                                        {monthArr.map((x, i) => {
                                          return (
                                            <option key={i} value={x.startTs}>
                                              {x.title}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      <p
                                        className="openDataTabOnUser"
                                        onClick={() => {
                                          memoVals.setShowEditUser((x) => {
                                            return {
                                              userID: selectedUser,
                                              tab: true,
                                              monthArr: monthArr,
                                              selectedStartTs: selectedStartTs,
                                              minDateInput,
                                              maxDateInput,
                                            };
                                          });
                                        }}
                                      >
                                        Set specific dates
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>

                              {absPage === "info" && subPage === "absence" ? (
                                loadingAbsence ? (
                                  <img
                                    src={horizontalBalls}
                                    alt="Loading absence"
                                    className="loadingDashAbsImg"
                                  />
                                ) : (
                                  <div className="x398428924892">
                                    <div
                                      className={`avgMpwDashRow x10910 absenceDashDataBoard ${
                                        loadingAbsence ? "invis" : ""
                                      } ${
                                        memoVals.device.ios
                                          ? "absenceDashDataBoardIos"
                                          : ""
                                      } marginTop0`}
                                    >
                                      <div className="userDashDetailsLeftRow x1910911">
                                        <p className="userDashDetailsLeftRowTitle">
                                          Absent instances
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {hoursLoading || userLoading
                                            ? "-"
                                            : state.absentInstances || 0}
                                        </p>
                                      </div>

                                      <div className="userDashDetailsLeftRow x1910911 x392842911">
                                        <p className="userDashDetailsLeftRowTitle">
                                          Total absence duration
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {hoursLoading || userLoading
                                            ? "-"
                                            : dateStringer.formatMinsDurationToHours(
                                                state.absentDurMins || 0
                                              )}
                                        </p>
                                      </div>
                                      <div className="userDashDetailsLeftRow x1910911 x392842911 ">
                                        <p className="userDashDetailsLeftRowTitle">
                                          Absence rank in{" "}
                                          {state.primaryTeamName ||
                                            "their team"}
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {hoursLoading || userLoading
                                            ? "-"
                                            : `#${state.teamRankNum} - out of ${state.teamRankOutOf}`}
                                        </p>
                                      </div>
                                      <div className="userDashDetailsLeftRow x1910911 x392842911 ">
                                        <p className="userDashDetailsLeftRowTitle">
                                          Absence rank in the company
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {hoursLoading || userLoading
                                            ? "-"
                                            : `#${state.companyRankNum} - out of ${state.companyRankOutOf}`}
                                        </p>
                                      </div>

                                      <div className="userDashDetailsLeftRow x1910911 x392842911">
                                        <p className="userDashDetailsLeftRowTitle">
                                          Absence percentage
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {hoursLoading || userLoading
                                            ? "-"
                                            : `${
                                                state.absentPercentage
                                                  ? state.absentPercentage ===
                                                    "0.00"
                                                    ? 0
                                                    : state.absentPercentage
                                                  : 0
                                              }%`}
                                        </p>
                                      </div>
                                      <div className="userDashDetailsLeftRow x1910911 x392842911">
                                        <p className="userDashDetailsLeftRowTitle">
                                          Paid absence
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {hoursLoading || userLoading
                                            ? "-"
                                            : dateStringer.formatMinsDurationToHours(
                                                state.paidAbsentDurMins || 0
                                              )}
                                        </p>
                                      </div>
                                      <div className="userDashDetailsLeftRow x1910911 x392842911">
                                        <p className="userDashDetailsLeftRowTitle">
                                          Unpaid absence
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {hoursLoading || userLoading
                                            ? "-"
                                            : dateStringer.formatMinsDurationToHours(
                                                state.unpaidAbsentDurMins || 0
                                              )}
                                        </p>
                                      </div>
                                      <div className="userDashDetailsLeftRow x1910911 x392842911">
                                        <p className="userDashDetailsLeftRowTitle">
                                          Wholly-absent shifts
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {hoursLoading || userLoading
                                            ? "-"
                                            : state.whollyAbsentShiftsQty || 0}
                                        </p>
                                      </div>
                                      <div className="userDashDetailsLeftRow x1910911 x392842911">
                                        <p className="userDashDetailsLeftRowTitle">
                                          Partially-absent shifts
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {hoursLoading || userLoading
                                            ? "-"
                                            : state.partiallyAbsentShiftsQty ||
                                              0}
                                        </p>
                                      </div>
                                      <div className="userDashDetailsLeftRow x1910911 x392842911">
                                        <p className="userDashDetailsLeftRowTitle">
                                          Absence duration from shifts
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {hoursLoading || userLoading
                                            ? "-"
                                            : dateStringer.formatMinsDurationToHours(
                                                state.absentShiftDurMins || 0
                                              )}
                                        </p>
                                      </div>

                                      <div className="userDashDetailsLeftRow x1910911 x392842911 noBorder">
                                        <p className="userDashDetailsLeftRowTitle">
                                          Absence duration from absence periods
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {hoursLoading || userLoading
                                            ? "-"
                                            : dateStringer.formatMinsDurationToHours(
                                                state.absentPeriodDurMins || 0
                                              )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )
                              ) : absPage === "forms" ? (
                                <div className="dashTabHours dashFormsContainer">
                                  {/* FORMS HERE */}
                                  {formsLoading ? (
                                    <img
                                      src={horizontalBalls}
                                      alt="Loading absence"
                                      className="loadingDashAbsImg"
                                    />
                                  ) : forms.length === 0 &&
                                    !userLoading &&
                                    !hoursLoading ? (
                                    <p className="noDashHours">
                                      No return-to-work forms
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {formsLoading
                                    ? ""
                                    : forms.map((item, i) => {
                                        return (
                                          <div
                                            className={`dataTabUserShift dashItem ${
                                              i === forms.length - 1 &&
                                              forms.length !== 1
                                                ? "noBorder"
                                                : ""
                                            } ${
                                              !item.canManage
                                                ? "defaultCursor"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              if (item.canManage) {
                                                memoVals.setShowForm((x) => {
                                                  return {
                                                    isMgr: true,
                                                    formID: item.formID,
                                                  };
                                                });
                                              }
                                            }}
                                          >
                                            <div className="dataTabUserShiftTop">
                                              <div className="dataTabUserShiftLeft">
                                                <div className="dataTabBlock dataTabBlockFirst x21989111342">
                                                  <p className="dataTabBlockTitle">
                                                    Title
                                                  </p>
                                                  <p
                                                    className={`dataTabBlockValue`}
                                                  >
                                                    {item.title}
                                                  </p>
                                                </div>
                                                <div className="dataTabBlock dataTabTimesBlock x317931781">
                                                  <div
                                                    className={`dataTabBlockValueTimes`}
                                                  >
                                                    {item.completedDs
                                                      ? `Completed by ${state.fName}`
                                                      : "Not yet completed"}{" "}
                                                    {item.completedDs ? (
                                                      <img
                                                        src={greenTick}
                                                        alt="Completed"
                                                        className="completedFormDashTickImg"
                                                      />
                                                    ) : (
                                                      <div className="notYetCompDashForm"></div>
                                                    )}
                                                  </div>
                                                  <div
                                                    className={`dataTabBlockValueTimes`}
                                                  >
                                                    {item.signedOff
                                                      ? "Signed off by mgr"
                                                      : "Not yet signed off by mgr"}
                                                    {item.signedOff ? (
                                                      <img
                                                        src={greenTick}
                                                        alt="Completed"
                                                        className="completedFormDashTickImg"
                                                      />
                                                    ) : (
                                                      <div className="notYetCompDashForm"></div>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="dataTabBlock dataTabDurMins">
                                                  <p className="dataTabBlockTitle">
                                                    Issued
                                                  </p>
                                                  <p
                                                    className={`dataTabBlockValue dataTabBlockValueDurMins `}
                                                  >
                                                    {dateStringer.printedDateFromDs(
                                                      item.createdDs
                                                    )}
                                                  </p>
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        );
                                      })}
                                </div>
                              ) : (
                                <div className="dashTabHours dashTabHoursAbsInstances ">
                                  {hoursDataArr.length === 0 &&
                                  !userLoading &&
                                  !hoursLoading ? (
                                    <p className="noDashHours">No absence</p>
                                  ) : (
                                    ""
                                  )}
                                  {hoursDataArr.map((item, i) => {
                                    if (
                                      item.type === "absence" ||
                                      item.type === "shift" ||
                                      item.type === "til" ||
                                      item.type === "overtime"
                                    ) {
                                      return (
                                        <div
                                          className={`dataTabUserShift dashItem ${
                                            i === hoursDataArr.length - 1 &&
                                            hoursDataArr.length !== 1
                                              ? "noBorder"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (
                                              item.type === "shift" ||
                                              item.type === "til" ||
                                              item.type === "overtime"
                                            ) {
                                              memoVals.setShowEditShiftModal(
                                                (x) => {
                                                  return {
                                                    itemID:
                                                      item.shiftID ||
                                                      item.tilID,
                                                    itemType: item.type,

                                                    itemIsNew: false,
                                                    userID: selectedUser,
                                                  };
                                                }
                                              );
                                            } else {
                                              memoVals.setShowReqMgrModal(
                                                (x) => item.reqID
                                              );
                                            }
                                          }}
                                        >
                                          <div className="dataTabUserShiftTop">
                                            <div className="dataTabUserShiftLeft">
                                              <div className="dataTabBlock dataTabBlockFirst x21989111342">
                                                <p className="dataTabBlockTitle">
                                                  Type
                                                </p>
                                                <p
                                                  className={`dataTabBlockValue`}
                                                >
                                                  {item.reqID
                                                    ? item.leaveTypeName
                                                    : item.type === "til"
                                                    ? "Shift in lieu"
                                                    : item.type === "shift"
                                                    ? "Shift"
                                                    : "Overtime"}
                                                </p>
                                              </div>
                                              <div className="dataTabBlock dataTabTimesBlock x18191x">
                                                <p
                                                  className={`dataTabBlockValueTimes ${
                                                    item.startDs === item.endDs
                                                      ? "sameDataPushDown"
                                                      : ""
                                                  } ${
                                                    item.type === "shift" ||
                                                    item.type === "til" ||
                                                    item.type === "overtime"
                                                      ? "dataTabBlockValueTimesTimes"
                                                      : ""
                                                  }`}
                                                >
                                                  <img
                                                    src={
                                                      item.type === "shift" ||
                                                      item.type === "til" ||
                                                      item.type === "overtime"
                                                        ? clock
                                                        : cal
                                                    }
                                                    className="dataTabClockIcon"
                                                    alt="Times"
                                                  />
                                                  {item.type === "shift" ||
                                                  item.type === "til" ||
                                                  item.type === "overtime"
                                                    ? `${dateStringer.dsToTimeStrip(
                                                        item.startDs
                                                      )} - ${dateStringer.dsToTimeStrip(
                                                        item.endDs
                                                      )}`
                                                    : !item.startDs
                                                    ? "No leave"
                                                    : dateStringer.printedDateFromDs(
                                                        item.startDs,
                                                        false
                                                      )}{" "}
                                                </p>

                                                <p
                                                  className={`dataTabBlockValueTimes ${
                                                    item.startDs === item.endDs
                                                      ? "none"
                                                      : ""
                                                  }`}
                                                >
                                                  <img
                                                    src={cal}
                                                    className="dataTabClockIcon"
                                                    alt="Times"
                                                  />
                                                  {dateStringer.printedDateFromDs(
                                                    item.endDs,
                                                    false
                                                  )}{" "}
                                                </p>
                                              </div>
                                              <div className="dataTabBlock dataTabDurMins">
                                                <p className="dataTabBlockTitle">
                                                  {item.unpaid
                                                    ? "Duration"
                                                    : "Payable"}
                                                </p>
                                                <p
                                                  className={`dataTabBlockValue dataTabBlockValueDurMins `}
                                                >
                                                  {dateStringer.formatMinsDurationToHours(
                                                    item.durMins || 0,
                                                    memoVals.mobModal
                                                      ? true
                                                      : false
                                                  )}
                                                </p>
                                              </div>
                                            </div>{" "}
                                            <div className="dataTabUserShiftRight">
                                              <div className="dataTabBlock flexEnd2">
                                                <p className="dataTabBlockTitle textAlignRight">
                                                  {item.cost < 0
                                                    ? "Deduction"
                                                    : "Cost"}
                                                </p>
                                                <p className="dataTabBlockValue textAlignRight">
                                                  {memoVals.currencySymbol}
                                                  {item.cost === 0 ||
                                                  item.type === "til"
                                                    ? ""
                                                    : item.cost <= 0
                                                    ? ""
                                                    : "+"}
                                                  {Number(
                                                    item.cost
                                                      ? item.cost.toFixed(2)
                                                      : 0
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className={`dataTabUserShiftBottom ${
                                              item.reqID
                                                ? "dataTabUserShiftBottomReq"
                                                : ""
                                            }`}
                                          >
                                            <div className="tabRow">
                                              {item.reqID ? (
                                                <p
                                                  className={`tabDays ${
                                                    item.type === "leave"
                                                      ? "tabDaysLeave"
                                                      : ""
                                                  }`}
                                                >
                                                  {item.days} day
                                                  {item.days === 1 ? "" : "s"}
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                              {item.ghost ? (
                                                <p
                                                  className={`tabDays tabGhost`}
                                                >
                                                  Paid closed day
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                              {item.inLieu ? (
                                                <p
                                                  className={`tabDays tabGhost`}
                                                >
                                                  Paid & added to TOIL balance
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                              {item.lateDurMins ? (
                                                <p className="tabLate">
                                                  {dateStringer.formatMinsDurationToHours(
                                                    item.lateDurMins,
                                                    true
                                                  )}{" "}
                                                  late -{" "}
                                                  {item.unpaidLate
                                                    ? item.type === "til"
                                                      ? "Not earned"
                                                      : "Unpaid"
                                                    : item.type === "til"
                                                    ? "Still earned"
                                                    : "Paid"}
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                              {item.absent &&
                                              !item.partialAbsent ? (
                                                <p className="tabLate">
                                                  Absent -{" "}
                                                  {item.unpaidAbsent
                                                    ? item.type === "til"
                                                      ? "Not earned"
                                                      : "Unpaid"
                                                    : item.type === "til"
                                                    ? "Still earned"
                                                    : "Paid"}
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                              {item.absent &&
                                              item.partialAbsent ? (
                                                <p className="tabLate">
                                                  {dateStringer.formatMinsDurationToHours(
                                                    item.partialAbsentDuration /
                                                      1000 /
                                                      60,
                                                    false
                                                  )}{" "}
                                                  absence -{" "}
                                                  {item.unpaidAbsent
                                                    ? item.type === "til"
                                                      ? "Not earned"
                                                      : "Unpaid"
                                                    : item.type === "til"
                                                    ? "Still earned"
                                                    : "Paid"}
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              )}
                            </div>
                          ) : (
                            ""
                          )}

                          {subPage === "timesheets" ? (
                            <div className="userDashRight noScroll">
                              <div className="avgMpwDashRow x10910 hoursDashTopBar">
                                <div className="userDashDetailsLeftRow x1910911 x139849111 noBorder">
                                  <div
                                    className={
                                      hoursLoading || userLoading
                                        ? ""
                                        : "g89492"
                                    }
                                  >
                                    {hoursLoading || userLoading ? (
                                      <img
                                        src={horizontalBalls}
                                        alt="Loading user"
                                        className="dashUserLoadingimg allowsLoadDash"
                                      />
                                    ) : (
                                      <div className="tsKeyHolder">
                                        <p className="tsDashKeyTitle">
                                          Filter timesheets
                                        </p>
                                        <div className="tsDashKey">
                                          <p
                                            className={`dataTypeTxt ${
                                              tsFilter !== "approved"
                                                ? "unselDataTypeTxt"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              if (tsFilter === "approved") {
                                                setTsFilter("all");
                                              } else {
                                                setTsFilter("approved");
                                              }
                                            }}
                                          >
                                            Approved{" "}
                                          </p>
                                          <p
                                            className={`dataTypeTxt ${
                                              tsFilter !== "declined"
                                                ? "unselDataTypeTxt"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              if (tsFilter === "declined") {
                                                setTsFilter("all");
                                              } else {
                                                setTsFilter("declined");
                                              }
                                            }}
                                          >
                                            Declined{" "}
                                          </p>{" "}
                                          <p
                                            className={`dataTypeTxt ${
                                              tsFilter !== "pending"
                                                ? "unselDataTypeTxt"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              if (tsFilter === "pending") {
                                                setTsFilter("all");
                                              } else {
                                                setTsFilter("pending");
                                              }
                                            }}
                                          >
                                            Pending{" "}
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                    {/* <p className="userDashDetailsLeftRowValue">
                                        {dateStringer.formatMinsDurationToHours(
                                          state.payExcess
                                            ? state.avgMpd < state.contractedMpd
                                              ? state.contractedMpd
                                              : state.avgMpd
                                            : state.contractedMpd
                                        )}
                                      </p> */}
                                  </div>
                                  <div className="dashHoursTopRight">
                                    <select
                                      className="dashYrSelectorDropdown"
                                      value={selectedYear}
                                      onChange={(e) => {
                                        setSelectedYear(e.target.value);
                                      }}
                                    >
                                      {availableYears.map((yr) => {
                                        return (
                                          <option value={yr} key={yr}>
                                            {yr}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>

                                  {/* <img
                                      src={plane2}
                                      alt="Leave"
                                      className="worthPlaneImg"
                                    /> */}
                                </div>
                              </div>

                              <div
                                className={`dashTabHours dashTabHoursTimesheets ${
                                  memoVals.device.ios
                                    ? "dashTabHoursTimesheetsIos"
                                    : ""
                                }`}
                              >
                                {timesheetsArr.length === 0 &&
                                !userLoading &&
                                !hoursLoading ? (
                                  <p className="noDashHours">No timesheets</p>
                                ) : (
                                  ""
                                )}
                                {timesheetsArr
                                  .sort((a, b) => {
                                    return a.startTs - b.startTs;
                                  })
                                  .map((item, i) => {
                                    if (
                                      tsFilter === "all" ||
                                      (tsFilter === "approved" &&
                                        item.approved) ||
                                      (tsFilter === "declined" &&
                                        item.declined) ||
                                      (tsFilter === "pending" &&
                                        !item.approved &&
                                        !item.declined)
                                    ) {
                                      return (
                                        <div
                                          className={`dataTabUserShift dashItem ${
                                            i === timesheetsArr.length - 1 &&
                                            timesheetsArr.length !== 1
                                              ? "noBorder"
                                              : ""
                                          } ${
                                            item.canManage
                                              ? ""
                                              : "cursorDefault x23894398423"
                                          } ${
                                            item.declined
                                              ? "backgroundf6f7f9"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (item.canManage) {
                                              memoVals.setShowViewTimesheet(
                                                (x) => {
                                                  return {
                                                    show: true,
                                                    timesheetID:
                                                      item.timesheetID,
                                                    isMgr: true,
                                                  };
                                                }
                                              );
                                            }
                                          }}
                                        >
                                          <div className="dataTabUserShiftTop">
                                            <div className="dataTabUserShiftLeft">
                                              <div className="dataTabBlock dataTabBlockFirst">
                                                <p className="dataTabBlockTitle">
                                                  Type
                                                </p>
                                                <p
                                                  className={`dataTabBlockValue`}
                                                >
                                                  Timesheet
                                                </p>
                                                <p className="dataTabBlockTeamName">
                                                  {item.teamName}
                                                </p>
                                              </div>
                                              <div className="dataTabBlock dataTabTimesBlock">
                                                <p
                                                  className={`dataTabBlockValueTimes ${
                                                    item.startDs.substr(
                                                      0,
                                                      11
                                                    ) ===
                                                    item.endDs.substr(0, 11)
                                                      ? "sameDataPushDown none"
                                                      : ""
                                                  }`}
                                                >
                                                  <img
                                                    src={cal}
                                                    className="dataTabClockIcon"
                                                    alt="Times"
                                                  />
                                                  {!item.startDs
                                                    ? "No leave"
                                                    : dateStringer.printedDateFromDs(
                                                        item.startDs,
                                                        false
                                                      )}{" "}
                                                </p>

                                                <p className="dataTabBlockValueTimes ">
                                                  <img
                                                    src={cal}
                                                    className="dataTabClockIcon"
                                                    alt="Date"
                                                  />
                                                  {dateStringer.printedDateFromDs(
                                                    item.endDs,
                                                    true,
                                                    false,
                                                    false,
                                                    true
                                                  )}
                                                </p>
                                              </div>
                                              <div className="dataTabBlock dataTabDurMins">
                                                <p className="dataTabBlockTitle">
                                                  Items
                                                </p>
                                                <p
                                                  className={`dataTabBlockValue dataTabBlockValueDurMins `}
                                                >
                                                  {item.itemsQty}
                                                </p>
                                              </div>
                                            </div>{" "}
                                            <div className="dataTabUserShiftRight">
                                              <div className="dataTabBlock flexEnd2">
                                                <p className="dataTabBlockTitle textAlignRight">
                                                  {item.approved
                                                    ? "Approved by"
                                                    : item.declined
                                                    ? `Declined by`
                                                    : "Submitted"}
                                                </p>
                                                <p className="dataTabBlockValue textAlignRight noWrap">
                                                  {item.approved ||
                                                  item.declined
                                                    ? item.decidedByName
                                                    : dateStringer.printedDateFromDs(
                                                        item.submitted
                                                      )}{" "}
                                                  {item.approved ||
                                                  item.declined ? (
                                                    <img
                                                      src={
                                                        item.approved
                                                          ? greenTick
                                                          : darkRedCross
                                                      }
                                                      alt="Status"
                                                      className="dashTsApproveImg"
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          {item.ghost ||
                                          item.inLieu ||
                                          item.payClocked ||
                                          item.absent ||
                                          item.lateDurMins > 0 ||
                                          item.reqID ? (
                                            <div
                                              className={`dataTabUserShiftBottom ${
                                                item.reqID
                                                  ? "dataTabUserShiftBottomReq"
                                                  : ""
                                              }`}
                                            >
                                              <div className="tabRow">
                                                {item.reqID ? (
                                                  <p
                                                    className={`tabDays ${
                                                      item.type === "leave"
                                                        ? "tabDaysLeave"
                                                        : ""
                                                    }`}
                                                  >
                                                    {item.days} day
                                                    {item.days === 1 ? "" : "s"}
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                                {item.ghost ? (
                                                  <p
                                                    className={`tabDays tabGhost`}
                                                  >
                                                    Paid closed day
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                                {item.inLieu ? (
                                                  <p
                                                    className={`tabDays tabGhost`}
                                                  >
                                                    Paid & added to TOIL balance
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                                {item.lateDurMins ? (
                                                  <p className="tabLate">
                                                    {dateStringer.formatMinsDurationToHours(
                                                      item.lateDurMins,
                                                      true
                                                    )}{" "}
                                                    late -{" "}
                                                    {item.unpaidLate
                                                      ? item.type === "til"
                                                        ? "Not earned"
                                                        : "Unpaid"
                                                      : item.type === "til"
                                                      ? "Still earned"
                                                      : "Paid"}
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                                {item.absent &&
                                                !item.partialAbsent ? (
                                                  <p className="tabLate">
                                                    Absent -{" "}
                                                    {item.unpaidAbsent
                                                      ? item.type === "til"
                                                        ? "Not earned"
                                                        : "Unpaid"
                                                      : item.type === "til"
                                                      ? "Still earned"
                                                      : "Paid"}
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                                {item.absent &&
                                                item.partialAbsent ? (
                                                  <p className="tabLate">
                                                    {dateStringer.formatMinsDurationToHours(
                                                      item.partialAbsentDuration /
                                                        1000 /
                                                        60,
                                                      false
                                                    )}{" "}
                                                    absence -{" "}
                                                    {item.unpaidAbsent
                                                      ? item.type === "til"
                                                        ? "Not earned"
                                                        : "Unpaid"
                                                      : item.type === "til"
                                                      ? "Still earned"
                                                      : "Paid"}
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      );
                                    }
                                  })}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {subPage === "clockcards" ? (
                            <div className="userDashRight noScroll">
                              <div className="avgMpwDashRow x10910 hoursDashTopBar">
                                <div className="userDashDetailsLeftRow x1910911 x139849111 noBorder">
                                  <div
                                    className={
                                      hoursLoading || userLoading
                                        ? ""
                                        : "g89492"
                                    }
                                  >
                                    {hoursLoading || userLoading ? (
                                      <img
                                        src={horizontalBalls}
                                        alt="Loading user"
                                        className="dashUserLoadingimg allowsLoadDash"
                                      />
                                    ) : (
                                      <div className="tsKeyHolder">
                                        <p className="tsDashKeyTitle">
                                          Filter clock cards
                                        </p>
                                        <div className="tsDashKey">
                                          <p
                                            className={`dataTypeTxt ${
                                              tsFilter !== "approved"
                                                ? "unselDataTypeTxt"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              if (tsFilter === "approved") {
                                                setTsFilter("all");
                                              } else {
                                                setTsFilter("approved");
                                              }
                                            }}
                                          >
                                            Approved{" "}
                                          </p>
                                          <p
                                            className={`dataTypeTxt ${
                                              tsFilter !== "declined"
                                                ? "unselDataTypeTxt"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              if (tsFilter === "declined") {
                                                setTsFilter("all");
                                              } else {
                                                setTsFilter("declined");
                                              }
                                            }}
                                          >
                                            Declined{" "}
                                          </p>{" "}
                                          <p
                                            className={`dataTypeTxt ${
                                              tsFilter !== "pending"
                                                ? "unselDataTypeTxt"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              if (tsFilter === "pending") {
                                                setTsFilter("all");
                                              } else {
                                                setTsFilter("pending");
                                              }
                                            }}
                                          >
                                            Pending{" "}
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                    {/* <p className="userDashDetailsLeftRowValue">
                                        {dateStringer.formatMinsDurationToHours(
                                          state.payExcess
                                            ? state.avgMpd < state.contractedMpd
                                              ? state.contractedMpd
                                              : state.avgMpd
                                            : state.contractedMpd
                                        )}
                                      </p> */}
                                  </div>
                                  <div className="dashHoursTopRight">
                                    <select
                                      className="dashYrSelectorDropdown"
                                      value={selectedYear}
                                      onChange={(e) => {
                                        setSelectedYear(e.target.value);
                                      }}
                                    >
                                      {availableYears.map((yr) => {
                                        return (
                                          <option value={yr} key={yr}>
                                            {yr}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>

                                  {/* <img
                                      src={plane2}
                                      alt="Leave"
                                      className="worthPlaneImg"
                                    /> */}
                                </div>
                              </div>

                              <div
                                className={`dashTabHours dashTabHoursTimesheets ${
                                  memoVals.device.ios
                                    ? "dashTabHoursTimesheetsIos"
                                    : ""
                                }`}
                              >
                                {clockCards.length === 0 &&
                                !userLoading &&
                                !hoursLoading ? (
                                  <p className="noDashHours">No clock cards</p>
                                ) : (
                                  ""
                                )}
                                {clockCards
                                  .sort((a, b) => {
                                    return a.startTs - b.startTs;
                                  })
                                  .map((item, i) => {
                                    if (
                                      tsFilter === "all" ||
                                      (tsFilter === "approved" &&
                                        item.approved) ||
                                      (tsFilter === "declined" &&
                                        item.declined) ||
                                      (tsFilter === "pending" &&
                                        !item.approved &&
                                        !item.declined)
                                    ) {
                                      return (
                                        <div
                                          className={`dataTabUserShift dashItem ${
                                            i === clockCards.length - 1 &&
                                            clockCards.length !== 1
                                              ? "noBorder"
                                              : ""
                                          } ${
                                            item.canManage
                                              ? ""
                                              : "cursorDefault x23894398423"
                                          } ${
                                            item.declined
                                              ? "backgroundf6f7f9"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (item.canManage) {
                                              memoVals.setClockOnID((x) => {
                                                return {
                                                  clockOnID: item.clockOnID,
                                                  isMgr: true,
                                                };
                                              });
                                            }
                                          }}
                                        >
                                          <div className="dataTabUserShiftTop">
                                            <div className="dataTabUserShiftLeft">
                                              <div className="dataTabBlock dataTabBlockFirst">
                                                <p className="dataTabBlockTitle">
                                                  Type
                                                </p>
                                                <p
                                                  className={`dataTabBlockValue`}
                                                >
                                                  Clock card
                                                </p>
                                                <p className="dataTabBlockTeamName">
                                                  {item.teamName}
                                                </p>
                                              </div>
                                              <div className="dataTabBlock dataTabTimesBlock">
                                                <p
                                                  className={`dataTabBlockValueTimes`}
                                                >
                                                  <img
                                                    src={cal}
                                                    className="dataTabClockIcon"
                                                    alt="Times"
                                                  />

                                                  {dateStringer.printedDateFromDs(
                                                    item.startDs
                                                  )}
                                                </p>
                                                <p
                                                  className={`dataTabBlockValueTimes dashClockTimeStrip`}
                                                >
                                                  <img
                                                    src={clock}
                                                    className="dataTabClockIcon"
                                                    alt="Times"
                                                  />

                                                  {dateStringer.dsToTimeStrip(
                                                    item.startDs
                                                  )}
                                                </p>

                                                <p className="dataTabBlockValueTimes dashClockTimeStrip">
                                                  <img
                                                    src={clock}
                                                    className="dataTabClockIcon"
                                                    alt="Date"
                                                  />
                                                  {!item.endDs
                                                    ? "Still clocked on"
                                                    : dateStringer.dsToTimeStrip(
                                                        item.endDs
                                                      )}
                                                </p>
                                                {/* <p className="dataTabBlockValueTimes">
                                                  <img
                                                    src={rotaCoffee2}
                                                    className="dataTabClockIcon dashClockCardBreakIcon"
                                                    alt="Date"
                                                  />
                                                  {dateStringer.formatMinsDurationToHours(
                                                    item.brk
                                                  )}
                                                </p> */}
                                              </div>
                                              <div className="dataTabBlock dataTabDurMins">
                                                <p className="dataTabBlockTitle">
                                                  Duration
                                                </p>
                                                <p
                                                  className={`dataTabBlockValue dataTabBlockValueDurMins `}
                                                >
                                                  {dateStringer.formatMinsDurationToHours(
                                                    item.durMins
                                                  )}
                                                </p>
                                              </div>
                                            </div>{" "}
                                            <div className="dataTabUserShiftRight">
                                              <div className="dataTabBlock flexEnd2">
                                                <p className="dataTabBlockTitle textAlignRight">
                                                  {item.approved
                                                    ? "Approved by"
                                                    : item.declined
                                                    ? `Declined by`
                                                    : "Not yet approved"}
                                                </p>
                                                <p className="dataTabBlockValue textAlignRight noWrap">
                                                  {item.approved ||
                                                  item.declined
                                                    ? item.decidedBy
                                                    : ""}{" "}
                                                  {item.approved ||
                                                  item.declined ? (
                                                    <img
                                                      src={
                                                        item.approved
                                                          ? greenTick
                                                          : darkRedCross
                                                      }
                                                      alt="Status"
                                                      className="dashTsApproveImg"
                                                    />
                                                  ) : (
                                                    ""
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          {item.ghost ||
                                          item.inLieu ||
                                          item.payClocked ||
                                          item.absent ||
                                          item.lateDurMins > 0 ||
                                          item.reqID ? (
                                            <div
                                              className={`dataTabUserShiftBottom ${
                                                item.reqID
                                                  ? "dataTabUserShiftBottomReq"
                                                  : ""
                                              }`}
                                            >
                                              <div className="tabRow">
                                                {item.reqID ? (
                                                  <p
                                                    className={`tabDays ${
                                                      item.type === "leave"
                                                        ? "tabDaysLeave"
                                                        : ""
                                                    }`}
                                                  >
                                                    {item.days} day
                                                    {item.days === 1 ? "" : "s"}
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                                {item.ghost ? (
                                                  <p
                                                    className={`tabDays tabGhost`}
                                                  >
                                                    Paid closed day
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                                {item.inLieu ? (
                                                  <p
                                                    className={`tabDays tabGhost`}
                                                  >
                                                    Paid & added to TOIL balance
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                                {item.lateDurMins ? (
                                                  <p className="tabLate">
                                                    {dateStringer.formatMinsDurationToHours(
                                                      item.lateDurMins,
                                                      true
                                                    )}{" "}
                                                    late -{" "}
                                                    {item.unpaidLate
                                                      ? item.type === "til"
                                                        ? "Not earned"
                                                        : "Unpaid"
                                                      : item.type === "til"
                                                      ? "Still earned"
                                                      : "Paid"}
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                                {item.absent &&
                                                !item.partialAbsent ? (
                                                  <p className="tabLate">
                                                    Absent -{" "}
                                                    {item.unpaidAbsent
                                                      ? item.type === "til"
                                                        ? "Not earned"
                                                        : "Unpaid"
                                                      : item.type === "til"
                                                      ? "Still earned"
                                                      : "Paid"}
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                                {item.absent &&
                                                item.partialAbsent ? (
                                                  <p className="tabLate">
                                                    {dateStringer.formatMinsDurationToHours(
                                                      item.partialAbsentDuration /
                                                        1000 /
                                                        60,
                                                      false
                                                    )}{" "}
                                                    absence -{" "}
                                                    {item.unpaidAbsent
                                                      ? item.type === "til"
                                                        ? "Not earned"
                                                        : "Unpaid"
                                                      : item.type === "til"
                                                      ? "Still earned"
                                                      : "Paid"}
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      );
                                    }
                                  })}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          {subPage === "late" ? (
                            <div
                              className={`userDashRight noScroll dashRightMob100w`}
                            >
                              <div
                                className={`avgMpwDashRow x10910 absPage1Height mobDashLateHeader`}
                              >
                                <div className="userDashDetailsLeftRow x1910911 x139849111 noBorder">
                                  <div
                                    className={
                                      hoursLoading || userLoading
                                        ? ""
                                        : "x1093901111a"
                                    }
                                  >
                                    {hoursLoading || userLoading ? (
                                      <img
                                        src={horizontalBalls}
                                        alt="Loading user"
                                        className="dashUserLoadingimg allowsLoadDash"
                                      />
                                    ) : (
                                      <div
                                        className={`dashAbsMenu dashAbsMenuSel`}
                                      >
                                        <div
                                          className={`dashAbsMenuDiv lateDashMenu ${
                                            absPage === "forms"
                                              ? "x29484298422"
                                              : ""
                                          } `}
                                        >
                                          <p
                                            className={`dashAbsInfo ${
                                              absPage === "instances"
                                                ? ""
                                                : "dashAbsInfoSel"
                                            }`}
                                            onClick={() => {
                                              setAbsPage("info");
                                            }}
                                          >
                                            Info
                                          </p>

                                          <p
                                            className={`dashAbsInfo ${
                                              absPage === "instances"
                                                ? "dashAbsInfoSel"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              setAbsPage("instances");
                                            }}
                                          >
                                            Instances
                                          </p>
                                        </div>
                                        {/* <div
                                          className={`editUserMessageBtn unarchBtn marginTop20 ${
                                            absPage === "info" ||
                                            absPage === "forms"
                                              ? "invis"
                                              : ""
                                          } x190390111`}
                                          onClick={() => {
                                            axios
                                              .post(
                                                `${serverURL}/get-user-tab-data`,
                                                {
                                                  downloadCsv: true,
                                                  userID: selectedUser,
                                                  absence: true,
                                                  leave: true,
                                                  combineWithAbsentShifts: true,
                                                  csvAbsence: true,

                                                  startDs:
                                                    dateStringer.createStringFromDateInput(
                                                      minDateInput
                                                    ),
                                                  endDs:
                                                    dateStringer.createStringFromDateInput(
                                                      maxDateInput
                                                    ),
                                                  nowDs:
                                                    dateStringer.createStringFromTimestamp(
                                                      new Date().getTime()
                                                    ),
                                                },

                                                {
                                                  responseType: "blob",
                                                  withCredentials: true,
                                                  credentials: "include",
                                                }
                                              )
                                              .then((response) => {
                                                // Create a Blob from the response data
                                                const blob = new Blob(
                                                  [response.data],
                                                  {
                                                    type: "text/csv",
                                                  }
                                                );

                                                // Create a URL for the Blob
                                                const url =
                                                  window.URL.createObjectURL(
                                                    blob
                                                  );

                                                // Create a link element to trigger the download
                                                const link =
                                                  document.createElement("a");
                                                link.href = url;
                                                link.download = `${state.fName.toLowerCase()}_${state.lName.toLowerCase()}_tab_data.csv`; // Specify the filename
                                                document.body.appendChild(link);

                                                // Trigger the download
                                                link.click();

                                                // Cleanup
                                                window.URL.revokeObjectURL(url);
                                                document.body.removeChild(link);
                                              })
                                              .catch((error) => {
                                                console.error(
                                                  "Error downloading CSV:",
                                                  error
                                                );
                                              });
                                          }}
                                        >
                                          Export to CSV{" "}
                                          <img
                                            src={download}
                                            alt="Message"
                                            className="downloadCsvImg"
                                          />
                                        </div> */}
                                      </div>
                                    )}
                                    {/* <p className="userDashDetailsLeftRowValue">
                                        {dateStringer.formatMinsDurationToHours(
                                          state.payExcess
                                            ? state.avgMpd < state.contractedMpd
                                              ? state.contractedMpd
                                              : state.avgMpd
                                            : state.contractedMpd
                                        )}
                                      </p> */}
                                  </div>

                                  <select
                                    className="dashYrSelectorDropdown x910930112"
                                    value={selectedYear}
                                    onChange={(e) => {
                                      let val = e.target.value;
                                      setSelectedYear(val);
                                      setUserLoading(true);

                                      axios
                                        .post(
                                          `${serverURL}/get-user-dash-late`,
                                          {
                                            userID: selectedUser,
                                            year: parseInt(val),
                                          },

                                          {
                                            withCredentials: true,
                                            credentials: "include",
                                          }
                                        )
                                        .then((response) => {
                                          if (
                                            response.data.message === "success"
                                          ) {
                                            setLoadingAbsence(false);

                                            setLateCost(response.data.lateCost);
                                            setLateQty(response.data.lateQty);
                                            setLateDurMins(
                                              response.data.lateDurMins
                                            );
                                            setUnpaidLateDurMins(
                                              response.data.unpaidLateDurMins
                                            );
                                            setPaidLateDurMins(
                                              response.data.paidLateDurMins
                                            );
                                            setLates(response.data.lates || []);
                                            setUserLoading(false);
                                            setHoursLoading(false);
                                          }
                                        })
                                        .catch((err) => {
                                          console.error(err);
                                        });
                                    }}
                                  >
                                    {availableYears.map((yr) => {
                                      return (
                                        <option value={yr} key={yr}>
                                          {yr}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>

                              {absPage === "info" && subPage === "late" ? (
                                loadingAbsence ? (
                                  <img
                                    src={horizontalBalls}
                                    alt="Loading absence"
                                    className="loadingDashAbsImg"
                                  />
                                ) : (
                                  <div className="x398428924892">
                                    <div
                                      className={`avgMpwDashRow x10910 absenceDashDataBoard ${
                                        loadingAbsence ? "invis" : ""
                                      } marginTop0`}
                                    >
                                      <div className="userDashDetailsLeftRow x1910911">
                                        <p className="userDashDetailsLeftRowTitle">
                                          Late instances
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {hoursLoading || userLoading
                                            ? "-"
                                            : lateQty || 0}
                                        </p>
                                      </div>

                                      <div className="userDashDetailsLeftRow x1910911 x392842911">
                                        <p className="userDashDetailsLeftRowTitle">
                                          Total late duration
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {hoursLoading || userLoading
                                            ? "-"
                                            : dateStringer.formatMinsDurationToHours(
                                                lateDurMins || 0
                                              )}
                                        </p>
                                      </div>

                                      <div className="userDashDetailsLeftRow x1910911 x392842911">
                                        <p className="userDashDetailsLeftRowTitle">
                                          Late cost
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {hoursLoading || userLoading
                                            ? "-"
                                            : `${memoVals.currencySymbol}${lateCost}`}
                                        </p>
                                      </div>
                                      <div className="userDashDetailsLeftRow x1910911 x392842911">
                                        <p className="userDashDetailsLeftRowTitle">
                                          Unpaid late duration
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {hoursLoading || userLoading
                                            ? "-"
                                            : dateStringer.formatMinsDurationToHours(
                                                unpaidLateDurMins || 0
                                              )}
                                        </p>
                                      </div>
                                      <div className="userDashDetailsLeftRow x1910911 x392842911 noBorder">
                                        <p className="userDashDetailsLeftRowTitle">
                                          Paid late duration
                                        </p>
                                        <p className="userDashDetailsLeftRowValue">
                                          {hoursLoading || userLoading
                                            ? "-"
                                            : dateStringer.formatMinsDurationToHours(
                                                paidLateDurMins || 0
                                              )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )
                              ) : absPage === "forms" ? (
                                <div className="dashTabHours dashFormsContainer">
                                  {/* FORMS HERE */}
                                  {formsLoading ? (
                                    <img
                                      src={horizontalBalls}
                                      alt="Loading absence"
                                      className="loadingDashAbsImg"
                                    />
                                  ) : forms.length === 0 &&
                                    !userLoading &&
                                    !hoursLoading ? (
                                    <p className="noDashHours">
                                      No return-to-work forms
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {formsLoading
                                    ? ""
                                    : forms.map((item, i) => {
                                        return (
                                          <div
                                            className={`dataTabUserShift dashItem ${
                                              i === forms.length - 1 &&
                                              forms.length !== 1
                                                ? "noBorder"
                                                : ""
                                            } ${
                                              !item.canManage
                                                ? "defaultCursor"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              if (item.canManage) {
                                                memoVals.setShowForm((x) => {
                                                  return {
                                                    isMgr: true,
                                                    formID: item.formID,
                                                  };
                                                });
                                              }
                                            }}
                                          >
                                            <div className="dataTabUserShiftTop">
                                              <div className="dataTabUserShiftLeft">
                                                <div className="dataTabBlock dataTabBlockFirst x21989111342">
                                                  <p className="dataTabBlockTitle">
                                                    Title
                                                  </p>
                                                  <p
                                                    className={`dataTabBlockValue`}
                                                  >
                                                    {item.title}
                                                  </p>
                                                </div>
                                                <div className="dataTabBlock dataTabTimesBlock x317931781">
                                                  <div
                                                    className={`dataTabBlockValueTimes`}
                                                  >
                                                    {item.completedDs
                                                      ? `Completed by ${state.fName}`
                                                      : "Not yet completed"}{" "}
                                                    {item.completedDs ? (
                                                      <img
                                                        src={greenTick}
                                                        alt="Completed"
                                                        className="completedFormDashTickImg"
                                                      />
                                                    ) : (
                                                      <div className="notYetCompDashForm"></div>
                                                    )}
                                                  </div>
                                                  <div
                                                    className={`dataTabBlockValueTimes`}
                                                  >
                                                    {item.signedOff
                                                      ? "Signed off by mgr"
                                                      : "Not yet signed off by mgr"}
                                                    {item.signedOff ? (
                                                      <img
                                                        src={greenTick}
                                                        alt="Completed"
                                                        className="completedFormDashTickImg"
                                                      />
                                                    ) : (
                                                      <div className="notYetCompDashForm"></div>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="dataTabBlock dataTabDurMins">
                                                  <p className="dataTabBlockTitle">
                                                    Issued
                                                  </p>
                                                  <p
                                                    className={`dataTabBlockValue dataTabBlockValueDurMins `}
                                                  >
                                                    {dateStringer.printedDateFromDs(
                                                      item.createdDs
                                                    )}
                                                  </p>
                                                </div>
                                              </div>{" "}
                                            </div>

                                            {/* <div
                                              className={`dataTabUserShiftBottom ${
                                                item.reqID
                                                  ? "dataTabUserShiftBottomReq"
                                                  : ""
                                              }`}
                                            >
                                              <div className="tabRow">
                                                {item.reqID ? (
                                                  <p
                                                    className={`tabDays ${
                                                      item.type === "leave"
                                                        ? "tabDaysLeave"
                                                        : ""
                                                    }`}
                                                  >
                                                    {item.days} day
                                                    {item.days === 1 ? "" : "s"}
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                                {item.ghost ? (
                                                  <p
                                                    className={`tabDays tabGhost`}
                                                  >
                                                    Paid closed day
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                                {item.inLieu ? (
                                                  <p
                                                    className={`tabDays tabGhost`}
                                                  >
                                                    Paid & added to TOIL balance
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                                {item.lateDurMins ? (
                                                  <p className="tabLate">
                                                    {dateStringer.formatMinsDurationToHours(
                                                      item.lateDurMins,
                                                      true
                                                    )}{" "}
                                                    late -{" "}
                                                    {item.unpaidLate
                                                      ? item.type === "til"
                                                        ? "Not earned"
                                                        : "Unpaid"
                                                      : item.type === "til"
                                                      ? "Still earned"
                                                      : "Paid"}
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                                {item.absent &&
                                                !item.partialAbsent ? (
                                                  <p className="tabLate">
                                                    Absent -{" "}
                                                    {item.unpaidAbsent
                                                      ? item.type === "til"
                                                        ? "Not earned"
                                                        : "Unpaid"
                                                      : item.type === "til"
                                                      ? "Still earned"
                                                      : "Paid"}
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                                {item.absent &&
                                                item.partialAbsent ? (
                                                  <p className="tabLate">
                                                    {dateStringer.formatMinsDurationToHours(
                                                      item.partialAbsentDuration /
                                                        1000 /
                                                        60,
                                                      false
                                                    )}{" "}
                                                    absence -{" "}
                                                    {item.unpaidAbsent
                                                      ? item.type === "til"
                                                        ? "Not earned"
                                                        : "Unpaid"
                                                      : item.type === "til"
                                                      ? "Still earned"
                                                      : "Paid"}
                                                  </p>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div> */}
                                          </div>
                                        );
                                      })}
                                </div>
                              ) : (
                                <div className="dashTabHours ">
                                  {lates.length === 0 &&
                                  !userLoading &&
                                  !hoursLoading ? (
                                    <p className="noDashHours">
                                      No late instances
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {lates.map((item, i) => {
                                    return (
                                      <div
                                        className={`dataTabUserShift dashItem ${
                                          i === lates.length - 1 &&
                                          lates.length !== 1
                                            ? "noBorder"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          memoVals.setShowEditShiftModal(
                                            (x) => {
                                              return {
                                                itemID:
                                                  item.shiftID || item.tilID,
                                                itemType: item.type,

                                                itemIsNew: false,
                                                userID: selectedUser,
                                              };
                                            }
                                          );
                                        }}
                                      >
                                        <div className="dataTabUserShiftTop">
                                          <div className="dataTabUserShiftLeft">
                                            <div className="dataTabBlock dataTabBlockFirst x21989111342">
                                              <p className="dataTabBlockTitle">
                                                Type
                                              </p>
                                              <p
                                                className={`dataTabBlockValue`}
                                              >
                                                {item.type === "til"
                                                  ? "Shift in lieu"
                                                  : item.type === "shift"
                                                  ? "Shift"
                                                  : "Overtime"}
                                              </p>
                                            </div>
                                            <div className="dataTabBlock dataTabTimesBlock x18191x">
                                              <p
                                                className={`dataTabBlockValueTimes ${
                                                  item.startDs === item.endDs
                                                    ? "sameDataPushDown"
                                                    : ""
                                                } dataTabBlockValueTimesTimes`}
                                              >
                                                <img
                                                  src={clock}
                                                  className="dataTabClockIcon"
                                                  alt="Times"
                                                />
                                                {`${dateStringer.dsToTimeStrip(
                                                  item.startDs
                                                )} - ${dateStringer.dsToTimeStrip(
                                                  item.endDs
                                                )}`}
                                              </p>

                                              <p
                                                className={`dataTabBlockValueTimes ${
                                                  item.startDs === item.endDs
                                                    ? "none"
                                                    : ""
                                                }`}
                                              >
                                                <img
                                                  src={cal}
                                                  className="dataTabClockIcon"
                                                  alt="Times"
                                                />
                                                {dateStringer.printedDateFromDs(
                                                  item.endDs,
                                                  false
                                                )}{" "}
                                              </p>
                                            </div>
                                            <div className="dataTabBlock dataTabDurMins">
                                              <p className="dataTabBlockTitle">
                                                Late start
                                              </p>
                                              <p
                                                className={`dataTabBlockValue dataTabBlockValueDurMins colorBa0000`}
                                              >
                                                {dateStringer.dsToTimeStrip(
                                                  item.lateStartDs
                                                )}
                                              </p>
                                            </div>
                                          </div>{" "}
                                          <div className="dataTabUserShiftRight">
                                            <div className="dataTabBlock flexEnd2">
                                              <p className="dataTabBlockTitle textAlignRight">
                                                Late cost
                                              </p>
                                              <p className="dataTabBlockValue textAlignRight">
                                                {memoVals.currencySymbol}
                                                {item.cost === 0 ||
                                                item.type === "til"
                                                  ? ""
                                                  : item.cost <= 0
                                                  ? ""
                                                  : "+"}
                                                {Number(
                                                  item.cost
                                                    ? item.cost.toFixed(2)
                                                    : 0
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className={`dataTabUserShiftBottom ${
                                            item.reqID
                                              ? "dataTabUserShiftBottomReq"
                                              : ""
                                          }`}
                                        >
                                          <div className="tabRow">
                                            {item.lateDurMins ? (
                                              <p className="tabLate">
                                                {dateStringer.formatMinsDurationToHours(
                                                  item.lateDurMins,
                                                  true
                                                )}{" "}
                                                late -{" "}
                                                {item.unpaidLate
                                                  ? item.type === "til"
                                                    ? "Not earned"
                                                    : "Unpaid"
                                                  : item.type === "til"
                                                  ? "Still earned"
                                                  : "Paid"}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          ) : (
                            ""
                          )}

                          {subPage === "documents" ? (
                            <div
                              className={`userDashRight noScroll dashRightMob100w`}
                            >
                              <div
                                className={`avgMpwDashRow x10910 absPage1Height docsTopBar`}
                              >
                                <div className="userDashDetailsLeftRow docSashLeftRow noBorder">
                                  <div
                                    className={
                                      loadingFiles ? "" : "x1093901111a"
                                    }
                                  >
                                    {loadingFiles ? (
                                      ""
                                    ) : (
                                      // <img
                                      //   src={horizontalBalls}
                                      //   alt="Loading user"
                                      //   className="dashUserLoadingimg docsLoadingDash"
                                      // />
                                      <div
                                        className={`dashMenuFilesLeft`}
                                        onClick={() => {
                                          console.log({
                                            inFolderID,
                                            inFolderName,
                                          });
                                        }}
                                      >
                                        {totBytes ? (
                                          <p className="dashDocsSizeMb">
                                            {dateStringer.bytes(totBytes)}
                                          </p>
                                        ) : (
                                          <p className="dashDocsSizeMb">
                                            /{" "}
                                            {dateStringer.shorten(
                                              inFolderName,
                                              33
                                            ) || ""}
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </div>

                                  <div className="newFileNewFolderWrap">
                                    {" "}
                                    <p
                                      className={`uploadDocDashBtn ${
                                        countBytes(files, true) > 2147483648
                                          ? "invis"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        setShowUploadModal(true);
                                        if (uploadLoading) {
                                          setUploadLoading(false);
                                        }
                                      }}
                                    >
                                      Upload file
                                    </p>
                                    {!inFolderID ? (
                                      <img
                                        src={folder}
                                        className="newUserFolder"
                                        alt="Create new folder"
                                        onClick={() => {
                                          setNewFolderNameValue("");
                                          // let existing = files.filter((x) => {
                                          //   return x.new;
                                          // });

                                          // if (!existing[0]) {
                                          let id = new Date().getTime();
                                          setFiles([
                                            {
                                              folder: true,
                                              fileName: "",
                                              new: true,
                                              folderID: id,
                                            },
                                            ...files,
                                          ]);
                                          setRenameFolderID({
                                            show: true,
                                            name: "",
                                            new: true,
                                            folderID: id,
                                            visible: false,
                                          });
                                          // }
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              {subPage === "documents" ? (
                                loadingFiles ? (
                                  <img
                                    src={horizontalBalls}
                                    alt="Loading absence"
                                    className="loadingDashAbsImg"
                                  />
                                ) : (
                                  <div
                                    className={`dashUserDocsGrid dashUserDocsList ${
                                      memoVals.device.ios
                                        ? "dashUserDocsListIos"
                                        : ""
                                    }`}
                                    onDragOver={handleDragOver}
                                    // onDrop={(event) =>
                                    //   // handleDrop(event, { folderID: "" })
                                    // } // Assuming root folder ID is 'root'
                                  >
                                    {!loadingFiles && inFolderID ? (
                                      <div
                                        // root item
                                        className="backFolderItem"
                                        onDragOver={handleDragOver}
                                        onDrop={(event) =>
                                          handleDrop(event, {
                                            folderID: "",
                                            upRoot: true,
                                            folderName: "Root directory",
                                          })
                                        } // Assuming root folder ID is 'root'
                                        onTouchMove={handleTouchMove}
                                        onClick={() => {
                                          setLoadingFiles(true);
                                          axios
                                            .post(
                                              `${serverURL}/get-dash-files`,
                                              {
                                                userID: selectedUser,
                                                // folderID: file.folderID,
                                                fromFolderID: inFolderID,
                                              },

                                              {
                                                withCredentials: true,
                                                credentials: "include",
                                              }
                                            )
                                            .then((response) => {
                                              if (
                                                response.data.message ===
                                                "success"
                                              ) {
                                                // if (response.data.intoParent) {
                                                // console.log(
                                                //   "x1 Changing inFolderID from ",
                                                //   inFolderID,
                                                //   " to ",
                                                //   parentFolderID
                                                // );

                                                // setInFolderID("");

                                                // // if (
                                                // //   !response.data.currFolder
                                                // // ) {
                                                // //   console.log(
                                                // //     "x2 Changing inFolderID from ",
                                                // //     inFolderID,
                                                // //     " to NOTHING"
                                                // //   );
                                                // //   setInFolderID("");
                                                // // }
                                                // setTotBytes(0);
                                                // setInFolderName(
                                                //   response.data.currFolder
                                                // );
                                                // } else {
                                                setTotBytes(
                                                  response.data.bytes
                                                );
                                                setInFolderName(
                                                  "Root directory"
                                                );
                                                setInFolderID("");
                                                // }

                                                setFiles(response.data.files);
                                                // setParentFolderID("");
                                                // setParentFolderName("");

                                                setLoadingFiles(false);
                                              }
                                            })
                                            .catch((err) => {
                                              console.error(err);
                                            });
                                        }}
                                      >
                                        <img
                                          src={up}
                                          className="folderBack "
                                          alt="Back"
                                        />
                                        <p className="backFolderName">
                                          {"Root directory"}
                                        </p>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {/* files array here */}
                                    {files
                                      .sort((a, b) => {
                                        // Check if folderID is "self_"
                                        if (a.folderID === "self_") return -1; // "self_" comes first
                                        if (b.folderID === "self_") return 1; // "self_" comes first

                                        // Sort folder items by fileName
                                        if (a.folder && b.folder) {
                                          return a.fileName.localeCompare(
                                            b.fileName
                                          );
                                        }

                                        // Sort non-folder items by fileName
                                        if (!a.folder && !b.folder) {
                                          return a.fileName.localeCompare(
                                            b.fileName
                                          );
                                        }

                                        // If one is a folder and the other is not, the folder comes first
                                        return a.folder ? -1 : 1;
                                      })
                                      .map((file) => {
                                        return (
                                          <div
                                            className={`userDashFileItem ${
                                              file.folder && file.new
                                                ? "newFolderPopIn none"
                                                : ""
                                            } ${
                                              file.folder ? `folderItem` : ""
                                            } draggable`}
                                            key={file.fileID || file.folderID}
                                            onMouseLeave={() => {
                                              if (hoveredFolderID) {
                                                setHoveredFolderID("");
                                              }
                                            }}
                                            onMouseEnter={() => {
                                              if (file.folder && !file.new) {
                                                setHoveredFolderID(
                                                  file.folderID
                                                );
                                              }
                                            }}
                                            draggable
                                            onDragStart={(event) =>
                                              handleDragStart(event, file)
                                            }
                                            // desktop
                                            onDrop={(event) => {
                                              if (file.folder && !inFolderID) {
                                                handleDrop(event, file);
                                              }
                                            }}
                                            onDragOver={(event) => {
                                              if (file.folder && !inFolderID) {
                                                handleDragOver(event);
                                              }
                                            }}
                                            style={
                                              {
                                                // border: "1px solid black",
                                                // margin: "5px",
                                                // padding: "5px",
                                              }
                                            }
                                          >
                                            {file.folder ? (
                                              <p
                                                className={`dashFileIconBtn fileIconDelete folderDeleteBtn noBorder_ ${
                                                  file.new ||
                                                  renameFolderID !==
                                                    file.folderID
                                                    ? "invis"
                                                    : ""
                                                }`}
                                                onClick={() => {
                                                  setSureDeleteFile({
                                                    show: true,
                                                    fileName: file.fileName,
                                                    folderID: file.folder
                                                      ? file.folderID
                                                      : "",
                                                  });
                                                }}
                                              >
                                                <img
                                                  src={bin1}
                                                  alt="Delete folder"
                                                  className="deleteFolderBinImg"
                                                />
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            <img
                                              draggable={false} // Prevent the image from being draggable
                                              // onDragStart={(e) =>
                                              //   e.preventDefault()
                                              // } // Prevent default drag behavior for images
                                              src={
                                                file.folder
                                                  ? file.employeeVis
                                                    ? file.folderID === "self_"
                                                      ? folderIconSelf
                                                      : folderIcon
                                                    : folderHidden
                                                  : getFileImg(file.type)
                                              }
                                              className={`dashUserFileIcon ${
                                                file.folder
                                                  ? "folderImg"
                                                  : file.employeeVis
                                                  ? ""
                                                  : "opac04_"
                                              } ${
                                                file.folder &&
                                                renameFolderID !== file.folderID
                                                  ? "folderIconNonRename"
                                                  : "folderIconRename"
                                              } ${
                                                !memoVals.mob &&
                                                hoveredFolderID ===
                                                  file.folderID &&
                                                file.folder
                                                  ? "folderImgHovered"
                                                  : ""
                                              }`}
                                              alt={file.type}
                                              onClick={() => {
                                                if (file.folder) {
                                                  console.log(
                                                    "x3 Changing inFolderID from ",
                                                    inFolderID,
                                                    " to ",
                                                    file.folderID
                                                  );
                                                  setInFolderID(file.folderID);
                                                  setLoadingFiles(true);
                                                  axios
                                                    .post(
                                                      `${serverURL}/get-dash-files`,
                                                      {
                                                        userID: selectedUser,
                                                        folderID: file.folderID,
                                                        fromFolderID:
                                                          inFolderID,
                                                      },

                                                      {
                                                        withCredentials: true,
                                                        credentials: "include",
                                                      }
                                                    )
                                                    .then((response) => {
                                                      if (
                                                        response.data
                                                          .message === "success"
                                                      ) {
                                                        // if (
                                                        //   !response.data
                                                        //     .currFolder
                                                        // ) {
                                                        //   console.log(
                                                        //     "x4 Changing inFolderID from ",
                                                        //     inFolderID,
                                                        //     " to NOTHING"
                                                        //   );
                                                        //   setInFolderID("");
                                                        // }

                                                        setTotBytes(0);
                                                        setInFolderName(
                                                          response.data
                                                            .currFolder
                                                        );
                                                        setFiles(
                                                          response.data.files
                                                        );

                                                        setLoadingFiles(false);
                                                      }
                                                    })
                                                    .catch((err) => {
                                                      console.error(err);
                                                    });
                                                } else {
                                                  setIndicate({
                                                    show: true,
                                                    message:
                                                      "Downloading - please wait",
                                                    colour: "orange",
                                                    duration: 4000,
                                                  });
                                                  // - - - - - - - - - - - - -
                                                  axios({
                                                    method: "get",
                                                    url: `${serverURL}/download-file/${file.fileName}`,
                                                    responseType: "blob", // Set the responseType to 'blob' to handle binary data
                                                  })
                                                    .then((response) => {
                                                      // Create a URL for the blob data
                                                      const blobUrl =
                                                        window.URL.createObjectURL(
                                                          response.data
                                                        );

                                                      // Create a temporary link element
                                                      const link =
                                                        document.createElement(
                                                          "a"
                                                        );
                                                      link.href = blobUrl;

                                                      // Set the download attribute to specify the file name
                                                      link.download =
                                                        file.fileName;
                                                      // "your-file-name.jpg"; // Replace 'your-file-name.jpg' with the desired file name

                                                      // Append the link to the body
                                                      document.body.appendChild(
                                                        link
                                                      );

                                                      // Trigger the download by programmatically clicking on the link
                                                      link.click();

                                                      // Clean up: remove the temporary link
                                                      document.body.removeChild(
                                                        link
                                                      );
                                                      setIndicate({
                                                        show: false,
                                                      });
                                                    })
                                                    .catch((error) => {
                                                      // Handle errors
                                                      console.error(
                                                        "Error downloading file:",
                                                        error
                                                      );
                                                    });
                                                }
                                              }}
                                            />
                                            {renameFolderID === file.folderID &&
                                            file.folder ? (
                                              <input
                                                type="text"
                                                ref={folderNameInput}
                                                className="folderNameInput"
                                                value={newFolderNameValue}
                                                placeholder={
                                                  file.new
                                                    ? "New folder"
                                                    : file.fileName
                                                }
                                                onKeyPress={(e) => {
                                                  enterFct(e);
                                                }}
                                                onChange={(e) => {
                                                  setNewFolderNameValue(
                                                    e.target.value
                                                  );
                                                }}
                                              ></input>
                                            ) : (
                                              <p
                                                className={`dashUserFileName ${
                                                  file.folder
                                                    ? "noEllipsis"
                                                    : "fileNameBox"
                                                }`}
                                              >
                                                {dateStringer.shorten(
                                                  dateStringer.removeTsFromFileName(
                                                    file.fileName
                                                  ),
                                                  18
                                                )}
                                                {!file.folder &&
                                                  dateStringer.getExt(
                                                    file.fileName
                                                  )}{" "}
                                              </p>
                                            )}
                                            {!file.folder ? (
                                              <p className="filesize">
                                                {dateStringer.bytes(file.bytes)}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                            {renameFolderID !== file.folderID &&
                                              file.folder && (
                                                <p className="dashUserFileName fileBytes">
                                                  {file.folder
                                                    ? `${file.qty || 0} file${
                                                        file.qty === 1
                                                          ? ""
                                                          : "s"
                                                      }`
                                                    : dateStringer.bytes(
                                                        file.bytes
                                                      )}
                                                </p>
                                              )}
                                            {file.employeeVis || file.folder ? (
                                              ""
                                            ) : (
                                              <img
                                                src={privateIcon}
                                                alt="Not visible to employee"
                                                className="privateFileIcon"
                                              />
                                            )}
                                            <img
                                              src={threeGreyDots}
                                              alt="Edit folder name"
                                              className={`editFolterNameImg ${
                                                !file.employeeVis &&
                                                !file.folder
                                                  ? "editFolterNameImgPriv"
                                                  : ""
                                              }`}
                                              onClick={() => {
                                                if (file.folder) {
                                                  setRenameFolderID(
                                                    {
                                                      show: true,
                                                      folderID: file.folderID,
                                                      name: file.fileName,
                                                      visible: file.employeeVis,
                                                    }
                                                    // file.folderID
                                                  );
                                                } else {
                                                  // is a file
                                                  setRenameFolderID(
                                                    {
                                                      show: true,
                                                      fileID: file.fileID,
                                                      name: file.fileName,
                                                      visible: file.employeeVis,
                                                    }
                                                    // file.folderID
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                        );
                                      })}
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}

                          {subPage === "payroll" ? (
                            <div className="userDashRight userDashRightPayRoll">
                              <DashboardPayRoll userID={selectedUser} />
                            </div>
                          ) : (
                            ""
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : dashPage === "allowances" ? (
              <div
                className={`dashboardMasterMain  ${
                  memoVals.mob ? "dashMasterMob" : ""
                } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""}`}
              >
                <DashboardAllowances
                  availableYears={availableYears}
                  setAvailableYears={setAvailableYears}
                  dashPage={dashPage}
                  setDashPage={setDashPage}
                />
              </div>
            ) : dashPage === "toil" ? (
              <div
                className={`dashboardMasterMain  ${
                  memoVals.mob ? "dashMasterMob" : ""
                } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""}`}
              >
                <DashboardTOIL
                  availableYears={availableYears}
                  setAvailableYears={setAvailableYears}
                  dashPage={dashPage}
                  setDashPage={setDashPage}
                />{" "}
              </div>
            ) : dashPage === "documents" ? (
              <div
                className={`dashboardMasterMain  ${
                  memoVals.mob ? "dashMasterMob" : ""
                } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""}`}
              >
                <DashboardDocs
                  users={users}
                  setUsers={setUsers}
                  countBytes={countBytes}
                  setShowUploadModal={setShowUploadModal}
                  showUploadModal={showUploadModal}
                />{" "}
              </div>
            ) : (
              <div
                className={`dashboardMasterMain  ${
                  memoVals.mob ? "dashMasterMob" : ""
                } ${
                  window.innerWidth <= 1140 && !memoVals.mob
                    ? "midContentDash"
                    : ""
                } ${
                  window.innerWidth > 1140 ? "dashboardMasterMainDashFull" : ""
                }`}
              >
                <DashboardDash
                  users={users}
                  setUsers={setUsers}
                  countBytes={countBytes}
                  setShowUploadModal={setShowUploadModal}
                  showUploadModal={showUploadModal}
                />{" "}
              </div>
            )}
          </div>
        </div>
      </div>
      <Navbar />
      <MgrAccountNavbar />

      {showUploadModal ? (
        <div
          className="editProPicUnderlay"
          onClick={() => {
            setShowUploadModal(false);
            if (exceedSizeLimit) {
              setExceedSizeLimit(false);
            }
          }}
        >
          <div
            className="editProPicModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="editProPicModalHeader x2989248423">
              <img
                src={close}
                alt="Close edit profile photo modal"
                className="closeEditProPicModalIcon"
                onClick={() => {
                  setShowUploadModal(false);
                  if (exceedSizeLimit) {
                    setExceedSizeLimit(false);
                  }
                }}
              />
              Upload a file
            </div>
            <div className="editProPicModalContentBox">
              {uploadLoading ? (
                <p className="waitForProPic">Uploading...</p>
              ) : (
                <input
                  type="file"
                  title=" "
                  id="avatar"
                  name="avatar"
                  className="proPicChangeBtnMyAcc"
                  // accept="image/png, image/jpeg"
                  onChange={async (e) => {
                    let file = e.target.files[0];
                    console.log({ file });

                    if (file && file.size > 50000000) {
                      setExceedSizeLimit(true);
                    } else {
                      setExceedSizeLimit(false);
                      setUploadLoading(true);

                      // console.log("target: ", e.target.files);

                      try {
                        const formData = new FormData();
                        formData.append("file", file);
                        formData.append("name", file.name);
                        formData.append("type", file.type);

                        formData.append("userID", selectedUser);
                        formData.append(
                          "nowDs",
                          dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          )
                        );

                        const response = await axios.post(
                          `${serverURL}/upload-dash-file`,
                          formData,
                          {
                            withCredentials: true,
                            credentials: "include",
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          }
                        );

                        if (response.data.message.includes("exceed")) {
                          setExceedSizeLimit(true);
                        }
                        if (response.data.message === "success") {
                          setFiles([...files, response.data.newFile]);
                          setShowUploadModal(false);
                          // Handle success
                          console.log("File uploaded successfully");
                        }
                        if (response.data.message === "2GB limit exceeded") {
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "2GB employee limit exceeded",
                              colour: "red",
                              duration: 4000,
                            };
                          });
                        }
                      } catch (error) {
                        // Handle error
                        console.error("Error uploading file:", error);
                      } finally {
                        setUploadLoading(false);
                      }
                    }
                  }}
                />
              )}

              {!uploadLoading ? (
                <p className="uploadDisclaim">
                  By uploading files to FlowRota, you acknowledge that files are
                  stored in a public cloud and are accessible via a link. Whilst
                  FlowRota does not share links to anyone other than the
                  specific FlowRota user, please note that these files are not
                  encrypted and may be accessible to anyone with the link
                  without authentication. FlowRota uses Google Cloud for its
                  storage and assumes no responsibility for the availability or
                  security of these files once uploaded. We recommend exercising
                  caution when sharing sensitive information through FlowRota's
                  file uploads, and refrain from uploading highly confidential
                  information.{" "}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="editProPicModalFooter">
              <p className={`max25mb ${!exceedSizeLimit ? "invis" : ""}`}>
                Max 50MB file size limit
              </p>
              {/* <p
                className="removeProPicBtn"
                onClick={() => {
                  setShowEditProPicModal(false);

                  axios
                    .post(
                      `${serverURL}/upload-profile-picture`,
                      {
                        userID: memoVals.checkUserState.userID,
                        remove: true,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setState({ profPicUrl: response.data.url });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Remove
              </p> */}
              <p
                className="closeProPicModalBtn"
                onClick={() => {
                  setShowUploadModal(false);
                  if (exceedSizeLimit) {
                    setExceedSizeLimit(false);
                  }
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {emailAlreadyExists ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              The email you have used is already associated with another
              account.
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setEmailAlreadyExists(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {sureDeleteFile && sureDeleteFile.show ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            setSureDeleteFile({ show: false });
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              Are you sure you want to permanently delete this{" "}
              {sureDeleteFile.folderID ? "folder" : "file"}?
              <br />
              <br />
              <span className="sureDeleteFilenameSpan">
                {sureDeleteFile.fileName}
              </span>
              {sureDeleteFile.folderID ? <br /> : ""}
              {sureDeleteFile.folderID ? <br /> : ""}
              {sureDeleteFile.folderID
                ? "Any files inside this folder will be deleted."
                : ""}
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  let fn = sureDeleteFile.fileName;
                  setSureDeleteFile({ show: false });
                  setRenameFolderID({ show: false });
                  axios
                    .post(
                      `${serverURL}/delete-dash-file`,
                      {
                        fileName: fn,
                        folderID: sureDeleteFile.folderID
                          ? sureDeleteFile.folderID
                          : false,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setFiles(
                          sureDeleteFile.folderID
                            ? files.filter((x) => {
                                return (
                                  !x.folderID ||
                                  x.folderID !== sureDeleteFile.folderID
                                );
                              })
                            : files.filter((x) => {
                                return x.fileName !== fn;
                              })
                        );
                      }
                    });
                }}
              >
                Yes{" "}
                {/* <img src={popup} alt="Info" className="addLeaveModalInfoPopUp" /> */}
              </p>{" "}
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDeleteFile({ show: false });
                  setRenameFolderID({ show: false });
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {renameFolderID.show ? (
        <div
          className={`overlapsRenewalModalUnderlay ${
            sureDeleteFile.show ? "invis" : ""
          }`}
          onClick={() => {
            setRenameFolderID({ show: false });
            setNewFolderNameValue("");
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              {renameFolderID.fileID
                ? "File"
                : renameFolderID.new
                ? "New folder"
                : "Folder settings"}
            </p>
            <p
              className={`overlapsRenTxt folFilName ${
                renameFolderID.new ? "none" : ""
              }`}
            >
              {renameFolderID.fileID
                ? renameFolderID.name
                : renameFolderID.new
                ? ""
                : renameFolderID.name}
            </p>
            {renameFolderID.folderID && (
              <p className="editFolderRowTitle">Folder name</p>
            )}
            {renameFolderID.folderID && (
              <input
                type="text"
                className="editFolderNameInput"
                placeholder={renameFolderID.name}
                value={newFolderNameValue}
                ref={folderNameInput}
                onChange={(e) => {
                  setNewFolderNameValue(e.target.value);
                }}
              ></input>
            )}
            <div
              className="editFolderRow"
              onClick={() => {
                setRenameFolderID({
                  show: true,
                  visible: !renameFolderID.visible,
                  visChanged: true,
                  name: renameFolderID.name,
                  folderID: renameFolderID.folderID || "",
                  fileID: renameFolderID.fileID || "",
                  new: renameFolderID.new,
                });
              }}
            >
              Visible to {state.fName}
              {/* KNOB BEGIN */}
              <div
                className={`editDayFxSchedKnob ${
                  memoVals.mobModal ? "allowSwapsTeamKnob" : ""
                }`}
              >
                <div className="knobContainer allowSwapsKnobMyRota marginRight3 x10s00">
                  <div
                    className={`switchKnob ${
                      renameFolderID.visible
                        ? "knobOn allowSwapsKnobOn"
                        : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg allowSwapsKnobBg"></div>
                </div>
              </div>
              {/*  KNOB END */}
            </div>
            {!renameFolderID.folderID ? (
              <div
                className={`editFolderRow noHovFold ${
                  !folders[0] ? "invis" : ""
                }`}
              >
                Move file to folder
                <select
                  className="moveToFolderDrop"
                  onChange={(e) => {
                    setMoveToFolder(e.target.value);
                  }}
                >
                  <option key="--" value="">
                    Select folder
                  </option>

                  {inFolderName && inFolderName !== "Root directory" ? (
                    <option value="root">Root directory</option>
                  ) : (
                    ""
                  )}
                  {folders.map((folder) => {
                    if (folder.folderName !== inFolderName) {
                      return (
                        <option key={folder.folderID}>
                          {folder.folderName}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
            ) : (
              ""
            )}
            {!renameFolderID.new && (
              <p
                className="delFolBtn"
                onClick={() => {
                  setSureDeleteFile({
                    show: true,
                    fileName: renameFolderID.name,
                    folderID: renameFolderID.folderID || "",
                    fileID: renameFolderID.fileID || "",
                  });
                }}
              >
                Delete {renameFolderID.folderID ? "folder" : "file"}
              </p>
            )}
            {/* <div className="editFolderRow"> */}
            {/* </div> */}
            {/* <p className="overlapsRenTxt">
              {renameFolderID.name}

              {renameFolderID.folderID}
            </p> */}
            <div className="areYouModalBtnsDiv">
              <p
                className={`areYouSureModalYesBtn ${
                  renameFolderID.new && !newFolderNameValue
                    ? "disable opac04"
                    : newFolderNameValue || renameFolderID.name
                    ? ""
                    : "disable opac04"
                }`}
                onClick={() => {
                  // console.log({ newFolderNameValue }, renameFolderID.name);
                  console.log({ renameFolderID });
                  if (renameFolderID.fileID && renameFolderID.visChanged) {
                    axios
                      .post(
                        `${serverURL}/toggle-file-visibility`,
                        {
                          fileID: renameFolderID.fileID,

                          data: renameFolderID.visible,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          let newArr = [];
                          files.map((f) => {
                            if (
                              f.fileID &&
                              f.fileID === renameFolderID.fileID
                            ) {
                              let newObj = f;
                              newObj.employeeVis = renameFolderID.visible;
                              newArr.push(newObj);
                            } else {
                              newArr.push(f);
                            }
                          });

                          setFiles(newArr);
                        }
                      });
                  }
                  if (renameFolderID.fileID) {
                    // move folder here
                    if (moveToFolder) {
                      let folderFilt = folders.filter((x) => {
                        return x.folderName === moveToFolder;
                      });
                      if (folderFilt[0] || moveToFolder === "root") {
                        axios
                          .post(
                            `${serverURL}/move-file-or-folder`,
                            {
                              fileID: renameFolderID.fileID,
                              // folderID: folderFilt[0].folderID,
                              intoFolderID:
                                moveToFolder === "root"
                                  ? ""
                                  : folderFilt[0].folderID,
                              visible: renameFolderID.visible,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                              // cancelToken: cancelSource1.token,
                            }
                          )
                          .then((response1) => {
                            if (response1.data.message === "success") {
                              axios
                                .post(
                                  `${serverURL}/get-dash-files`,
                                  {
                                    userID: selectedUser,
                                    folderID: response1.data.currentFolderID,
                                    // fromFolderID: inFolderID,
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (response.data.message === "success") {
                                    setFiles(response.data.files);
                                    setLoadingFiles(false);
                                    setTotBytes(response.data.bytes);
                                  }
                                });
                            } else {
                              console.log("Error moving file or folder");
                            }
                          });
                      }
                    }
                  } else {
                    // dorian
                    if (renameFolderID.new) {
                      axios
                        .post(
                          `${serverURL}/new-folder`,
                          {
                            name: newFolderNameValue,
                            userID: selectedUser,
                            parentFolderID: inFolderID ? inFolderID : "",
                            visible: renameFolderID.visible,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            let newArr = [];
                            files.forEach((x) => {
                              if (x.new) {
                                newArr.push({
                                  folderID: response.data.folderID,
                                  fileName: response.data.name,
                                  folder: true,
                                  employeeVis: renameFolderID.visible,
                                });
                              } else {
                                newArr.push(x);
                              }
                            });

                            setFiles(newArr);
                          }
                        });
                    } else {
                      axios
                        .post(
                          `${serverURL}/edit-folder-name`,
                          {
                            folderID: renameFolderID.folderID,
                            fileID: renameFolderID.fileID,
                            name: newFolderNameValue || renameFolderID.name,
                            visible: renameFolderID.visible,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            let newFiles = [];
                            files.forEach((x) => {
                              if (
                                !x.folder &&
                                renameFolderID.fileID &&
                                renameFolderID.fileID === x.fileID
                              ) {
                                newFiles.push({
                                  folder: false,
                                  fileID: x.fileID,
                                  fileName: x.fileName,
                                  employeeVis: renameFolderID.visible,
                                  ts: x.ts,
                                  ds: x.ds,
                                  folderID: x.folderID,
                                  type: x.type,
                                  bytes: x.bytes,
                                });
                              } else if (
                                x.folder &&
                                renameFolderID.folderID === x.folderID
                              ) {
                                newFiles.push({
                                  folder: true,
                                  folderID: x.folderID,
                                  fileName:
                                    newFolderNameValue || renameFolderID.name,
                                  qty: x.qty,
                                  employeeVis: renameFolderID.visible,
                                });
                              } else {
                                newFiles.push(x);
                              }
                            });
                            setFiles(newFiles);
                            setRenameFolderID({ show: false });
                            setNewFolderNameValue("");
                          }
                        });
                    }
                  }
                  setRenameFolderID({ show: false });
                  setRenameFolderID({ show: false });
                  setMoveToFolder("");
                }}
              >
                Save{" "}
                {/* <img src={popup} alt="Info" className="addLeaveModalInfoPopUp" /> */}
              </p>{" "}
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setNewFolderNameValue("");
                  setRenameFolderID({ show: false });
                  setMoveToFolder("");
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default Dashboard;
