import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  Fragment,
} from "react";
import { NavLink } from "react-router-dom";
// ios done 7 aug 24
// cleaned wed 24 jul 23
import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";

import axios from "axios";
import serverURL from "../serverURL";
import close from "../img/modals/close.svg";
import allowArr from "../img/general/allowArr.svg";
import greenTick from "../img/general/greenTick.svg";
import popup from "../img/general/popup.svg";
import whiteGo from "../img/general/whiteGo.svg";

import plane2 from "../img/general/plane2.svg";
import plane from "../img/general/plane.svg";
import desktopBurger from "../img/general/desktopBurger.svg";
import rotaDur from "../img/general/rotaDur.svg";
import rotaDate from "../img/general/rotaDate.svg";
import rotaClock from "../img/general/rotaClock.svg";
import whiteTick from "../img/general/whiteTick.svg";
import rotaClockCard from "../img/general/rotaClockCard.svg";
import rotaTeam from "../img/general/rotaTeam.svg";

import sun from "../img/general/sun.svg";
import profilePicturePlaceholder from "../img/general/profilePicturePlaceholder.svg";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";
import { DataContext } from "../contexts/DataContext";
import appURL from "../appURL";

const NotifReqs = ({}) => {
  const { mobModal, mob } = useContext(StyleContext);
  const { setCheckUserState, checkUserState } = useContext(UserContext);
  const {
    countData,
    setCountData,
    setShowReqMgrModal,
    setIndicate,
    setShowEditUser,
    updateTabData,
    setUpdateTabData,
    setClockOnID,
  } = useContext(DataContext);

  let [approving, setApproving] = useState("");

  const memoVals = useMemo(
    () => ({
      setShowReqMgrModal,
      mob,
      checkUserState,
      setIndicate,
      setShowEditUser,
      updateTabData,
      setUpdateTabData,
      setClockOnID,
    }),
    [
      setShowReqMgrModal,
      mob,
      checkUserState,
      setIndicate,
      setShowEditUser,
      updateTabData,
      setUpdateTabData,
      setClockOnID,
    ]
  );

  let [blockApprove, setBlockApprove] = useState("");
  let [itemsArr, setItemsArr] = useState([]);
  let [loading, setLoading] = useState(true);
  let [group, setGroup] = useState("leave"); // leave, hours, changes, swaps, absence
  const cancelSource1 = axios.CancelToken.source();

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    axios
      .post(
        `${serverURL}/get-mgr-requests`,
        {
          group,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setItemsArr(response.data.output);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [group]);
  // master return
  return (
    <div className="notifReqsContentWrapper">
      <div className="notifReqsShoulder">
        <div className="notifReqsMenu">
          <p
            className={`notifReqsMenuItem ${
              group === "leave" ? "notifReqsMenuItemSelected" : ""
            }`}
            onClick={() => {
              setGroup("leave");
            }}
          >
            Leave
          </p>
          {countData.leaveReqs > 0 && (
            <p className="notifReqsMenuQty">
              {countData.leaveReqs > 9 ? "9+" : countData.leaveReqs}
            </p>
          )}
          <div className="notifReqsMenuItemSplit"></div>
          <p
            className={`notifReqsMenuItem ${
              group === "hours" ? "notifReqsMenuItemSelected" : ""
            }`}
            onClick={() => {
              setGroup("hours");
            }}
          >
            Hours
          </p>{" "}
          {countData.clocks +
            countData.loggedShifts +
            countData.timesheets +
            countData.declined >
            0 && (
            <p className="notifReqsMenuQty">
              {countData.clocks +
                countData.loggedShifts +
                countData.timesheets +
                countData.declined >
              9
                ? "9+"
                : countData.clocks +
                  countData.loggedShifts +
                  countData.timesheets +
                  countData.declined}
            </p>
          )}
          <div className="notifReqsMenuItemSplit"></div>
          <p
            className={`notifReqsMenuItem ${
              group === "changes" ? "notifReqsMenuItemSelected" : ""
            }`}
            onClick={() => {
              setGroup("changes");
            }}
          >
            Changes
          </p>{" "}
          {countData.ttrs + countData.drops + countData.shiftEdits > 0 && (
            <p className="notifReqsMenuQty">
              {countData.ttrs + countData.drops + countData.shiftEdits > 9
                ? "9+"
                : countData.ttrs + countData.drops + countData.shiftEdits}
            </p>
          )}
          <div className="notifReqsMenuItemSplit"></div>
          <p
            className={`notifReqsMenuItem ${
              group === "swaps" ? "notifReqsMenuItemSelected" : ""
            }`}
            onClick={() => {
              setGroup("swaps");
            }}
          >
            Swaps
          </p>{" "}
          {countData.staffSwaps > 0 && (
            <p className="notifReqsMenuQty">
              {countData.staffSwaps > 9 ? "9+" : countData.staffSwaps}
            </p>
          )}
          <div className="notifReqsMenuItemSplit"></div>
          <p
            className={`notifReqsMenuItem ${
              group === "absence" ? "notifReqsMenuItemSelected" : ""
            }`}
            onClick={() => {
              setGroup("absence");
            }}
          >
            Absence
          </p>{" "}
          {countData.forms + countData.selfLoggedAbsence > 0 && (
            <p className="notifReqsMenuQty">
              {countData.forms + countData.selfLoggedAbsence > 9
                ? "9+"
                : countData.forms + countData.selfLoggedAbsence}
            </p>
          )}{" "}
        </div>
        <p></p>
      </div>
      <div className="notifReqsContentBody">
        {loading ? (
          <img
            src={horizontalBalls}
            alt="Loading"
            className="notifReqsLoadingImg"
          />
        ) : !itemsArr || (Array.isArray(itemsArr) && itemsArr.length === 0) ? (
          <p className="noMgrReqsTxtStrip">
            {group === "leave"
              ? "No outstanding leave requests"
              : group === "swaps"
              ? "No outstanding shift swap requests"
              : group === "changes"
              ? "No shift change requests"
              : group === "absence"
              ? "No newly logged absence or submitted absence forms"
              : group === "hours"
              ? "No newly logged shifts, timesheets, clock cards, or declines"
              : ""}
          </p>
        ) : (
          itemsArr.map((item, i) => {
            if (group === "leave" && item.reqID && item.leaveTypeName) {
              return (
                <div key={i} className="reqUnitParentNotif">
                  <div
                    onClick={() => {
                      memoVals.setShowReqMgrModal((x) => item.reqID);
                    }}
                    className={`mgrReqItem mgrReqItemNotifReq pend ${
                      memoVals.mob ? "borderRadius10x" : ""
                    } leaveReqItem`}
                  >
                    {" "}
                    <div className="unsubmittedTsBlob annLeaveBlob"></div>
                    <div className="reqPlaneDiv">
                      <img
                        src={item.annLeave ? plane2 : plane}
                        alt="Requested leave"
                        className={`reqPlaneIcon ${
                          memoVals.mob ? "reqPlaneIconMob" : ""
                        }`}
                      />
                    </div>
                    <div className="reqColBarDiv divPend"></div>
                    <div
                      className={`mgrReqItemCol2 ${
                        memoVals.mob ? "mgrReqItemCol2Mob" : ""
                      }`}
                    >
                      <div className="mgrReqTopRow">
                        <div className="mgrReqName mgrReqName2">
                          {/* ------ */}
                          <div
                            className={`mgrReqDateStrip pendDateDiv x29898988444`}
                          >
                            <div
                              className={`mgrReqDayLengthSpan reqsLeaveNameAboveCalIcons `}
                            >
                              <img
                                src={desktopBurger}
                                alt="Request duration"
                                className="rotaSunReqs leaveNameBurger"
                              />
                              {item.leaveTypeName}
                            </div>

                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan `}
                            >
                              <img
                                src={sun}
                                alt="Request duration"
                                className="rotaSunReqs"
                              />
                              {item.days} day
                              {item.days !== 1 ? "s" : ""}{" "}
                              <span className="colour00aaff">requested</span>
                            </div>
                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan upcomingMgrReqsSpanDur `}
                            >
                              <img
                                src={rotaDur}
                                alt="Request duration"
                                className="rotaSunReqs"
                              />
                              {/* {item.ongoing ? "Ongoing" : durationString()} */}
                              {dateStringer.formatMinsDurationToHours(
                                item.contractedMins
                              )}
                              {item.excessMins && (
                                <span className="notifReqExcMinsSpan">
                                  {" "}
                                  +{" "}
                                  {dateStringer.formatMinsDurationToHours(
                                    item.excessMins
                                  )}
                                </span>
                              )}
                            </div>
                          </div>
                          {/* - - - - - - - - - - - - - - - - - - - - - - - - - */}
                        </div>
                        <div className="absTimelineVal absTimelineValReqs">
                          <div
                            className={`absCalDiv ${
                              item.startDs.substr(0, 11) ===
                              item.endDs.substr(0, 11)
                                ? "invis"
                                : ""
                            }`}
                          >
                            <div className="absCalDivUpper">
                              <div className="absDivCalNip"></div>
                              <div className="absDivCalNip"></div>
                            </div>
                            <div
                              className={`absCalDivLower ${
                                1 + 1 === 2
                                  ? "absCalDivLowerAllTime"
                                  : "absCalDivLowerAllTime"
                              }`}
                            >
                              <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                                {parseInt(item.startDs.substr(9, 2))}
                              </p>
                              <p className="absCalDivLowerMonth absCalDivLowerMonthReqs">
                                {dateStringer.getMonthThreeLettersFromDs(
                                  item.startDs
                                )}
                              </p>

                              <p className="absCalDivLowerYear">
                                {item.startDs.substr(1, 4)}
                              </p>
                            </div>
                          </div>
                          <div
                            className={`absCanDivSplitter ${
                              item.startDs === item.endDs ? "invis" : ""
                            }`}
                          ></div>
                          <div className={`absCalDiv`}>
                            <div className="absCalDivUpper">
                              <div className="absDivCalNip"></div>
                              <div className="absDivCalNip"></div>
                            </div>
                            <div
                              className={`absCalDivLower ${
                                1 + 1 === 2
                                  ? "absCalDivLowerAllTime"
                                  : "absCalDivLowerAllTime"
                              }`}
                            >
                              <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                                {parseInt(item.endDs.substr(9, 2))}
                              </p>
                              <p className="absCalDivLowerMonth absCalDivLowerMonthReqs">
                                {dateStringer.getMonthThreeLettersFromDs(
                                  item.endDs
                                )}
                              </p>{" "}
                              <p className="absCalDivLowerYear">
                                {item.startDs.substr(1, 4)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`mgrReqNameTab mgrReqNameTab2 pendClockName x34986498542`}
                    onClick={() => {
                      memoVals.setShowEditUser((x) => {
                        return { userID: item.userID, allows: true };
                      });
                    }}
                  >
                    <img
                      src={item.profPicUrl || profilePicturePlaceholder}
                      alt={item.fName}
                      className="itemNameTabImg"
                    />{" "}
                    {item.fName} {item.lName}
                    {item.you ? <span className="youSpan">(You)</span> : ""}
                  </div>
                  {/* - - - - - - - - - - - - - - - -  */}

                  <div
                    className={`mgrClockSubBar ${
                      memoVals.mob ? "mgrClockSubBarMob" : ""
                    } pendClockSub notifReqSubBarReq`}
                  >
                    <div
                      className={`clocksSchedSubLeft ${
                        memoVals.mob ? "font12px" : ""
                      }`}
                    >
                      <p className="mgrNotifDsMade">
                        <span
                          className={
                            item.daysOrHours === "days" && item.tot === 366
                              ? "reqAllowPositiveGreen"
                              : item.daysOrHours === "hours"
                              ? item.remaining < item.contractedMins
                                ? "reqAllowNegativeRed"
                                : "reqAllowPositiveGreen"
                              : item.daysOrHours === "days"
                              ? item.remaining < item.days
                                ? "reqAllowNegativeRed"
                                : "reqAllowPositiveGreen"
                              : ""
                          }
                        >
                          {item.daysOrHours === "days" && item.tot === 366
                            ? "Unlimited"
                            : item.daysOrHours === "days"
                            ? item.remaining
                            : dateStringer.formatMinsDurationToHours(
                                item.remaining || 0
                              )}{" "}
                          {item.daysOrHours === "days" && item.tot !== 366
                            ? "day"
                            : ""}
                          {item.remaining === 1 ||
                          item.daysOrHours === "hours" ||
                          (item.daysOrHours === "days" && item.tot === 366)
                            ? ""
                            : "s"}{" "}
                        </span>
                        remaining
                      </p>
                    </div>
                    <div
                      className={`clocksSchedSubRight  ${
                        memoVals.mob ? "flexEnd" : ""
                      }`}
                    >
                      {/* {!approveLoading && !clock.approved && !clock.declined && ( */}
                      <p
                        className={`approveClock x2978457 ${
                          item.blockApprove
                            ? "unableToApproveOwn greyColours"
                            : ""
                        } ${approving === item.reqID ? "disabledApprove" : ""}`}
                        onClick={() => {
                          if (item.blockApprove) {
                            setBlockApprove("req");
                          } else {
                            if (approving === item.reqID) {
                            } else {
                              setApproving(item.reqID);
                              // approve request here

                              axios
                                .post(
                                  `${serverURL}/approve-request`,
                                  {
                                    reqID: item.reqID,
                                    approvedBy: `${memoVals.checkUserState.fName} ${memoVals.checkUserState.lName}`,
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (response.data.message === "success") {
                                    // setTimeout(() => {

                                    setCountData({
                                      leaveReqs: countData.leaveReqs - 1,
                                    });
                                    setIndicate((x) => {
                                      return {
                                        show: true,
                                        message: `${dateStringer.possession(
                                          item.fName
                                        )} leave request approved`,
                                        colour: "green",
                                        duration: 4000,
                                      };
                                    });

                                    setTimeout(() => {
                                      setApproving("");
                                      setItemsArr(
                                        itemsArr.filter((x) => {
                                          return x.reqID !== item.reqID;
                                        })
                                      );
                                    }, 1);

                                    memoVals.setUpdateTabData(
                                      (x) => !memoVals.updateTabData
                                    );
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });
                            }
                          }
                        }}
                      >
                        Approve{" "}
                        <img
                          src={whiteTick}
                          alt="Approve clock card"
                          className="approveCCtickimg"
                        />
                      </p>
                    </div>
                  </div>
                  {/* - - - - - - - - - - - - - - - -  */}
                </div>
              );
            }

            if (group === "hours" && item.on && item.off) {
              return (
                <div key={i} className="reqUnitParentNotif">
                  <div
                    onClick={() => {
                      memoVals.setClockOnID((x) => {
                        return {
                          clockOnID: item.clockOnID,
                          isMgr: true,
                        };
                      });
                    }}
                    className={`mgrReqItem mgrReqItemNotifReq pend ${
                      memoVals.mob ? "borderRadius10x" : ""
                    } leaveReqItem ccNotifItem`}
                  >
                    {" "}
                    <div className="unsubmittedTsBlob annLeaveBlob ccMgrNotifBlob"></div>
                    <div className="reqPlaneDiv">
                      <img
                        src={rotaClockCard}
                        alt="Requested leave"
                        className={`reqPlaneIcon ${
                          memoVals.mob ? "reqPlaneIconMob" : ""
                        }`}
                      />
                    </div>
                    <div className="reqColBarDiv divPend"></div>
                    <div
                      className={`mgrReqItemCol2 ${
                        memoVals.mob ? "mgrReqItemCol2Mob" : ""
                      }`}
                    >
                      <div className="mgrReqTopRow">
                        <div className="mgrReqName mgrReqName2">
                          {/* ------ */}
                          <div
                            className={`mgrReqDateStrip pendDateDiv x29898988444 x29898988444233`}
                          >
                            <div
                              className={`mgrReqDayLengthSpan reqsLeaveNameAboveCalIcons `}
                            >
                              <img
                                src={desktopBurger}
                                alt="Request duration"
                                className="rotaSunReqs leaveNameBurger"
                              />
                              {item.type === "shift"
                                ? "Clocked shift"
                                : item.type === "overtime"
                                ? "Clocked overtime"
                                : "Clocked overtime (in lieu)"}
                            </div>
                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan `}
                            >
                              <img
                                src={rotaDate}
                                alt="Request duration"
                                className="rotaSunReqs x4985982567876"
                              />

                              {dateStringer.printedDateFromDs(
                                item.startDs,
                                true
                              )}
                            </div>
                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan upcomingMgrReqsSpanDur`}
                            >
                              <img
                                src={rotaDur}
                                alt="Request duration"
                                className="rotaSunReqs"
                              />
                              {dateStringer.formatMinsDurationToHours(
                                item.clockedDurMins
                              )}
                              {/* <span className="colour00aaff">requested</span> */}
                            </div>

                            <div
                              className={`mgrReqDayLengthSpan upcomingMgrReqsSpan upcomingMgrReqsSpanDur bbcada`}
                            >
                              <img
                                src={rotaTeam}
                                alt="Request team"
                                className="rotaSunReqs x4985982567876"
                              />

                              {dateStringer.shorten(item.teamName, 22)}
                            </div>
                          </div>
                          {/* - - - - - - - - - - - - - - - - - - - - - - - - - */}
                        </div>
                        <div className="absTimelineVal ccDivTimeline">
                          <div className="ccDiv">
                            {/* <div className="ccDivUpper">
                              <div className="ccDivUpperNip"></div>
                              <div className="ccDivUpperNip ccDivUpperNip2"></div>
                            </div> */}
                            <div className="ccDivMid">
                              <div className="ccDivTs">
                                <p className="ccDivTitle">On</p>

                                <p>
                                  {" "}
                                  {dateStringer.dsToTimeStrip(
                                    item.startDs,
                                    true
                                  )}
                                </p>
                              </div>
                            </div>
                            {/* <div className="ccDivLegs">
                              <div className="ccDivLeg"></div>
                              <div className="ccDivLeg ccDivLeg2"></div>
                            </div> */}
                          </div>
                          <div
                            className={`absCanDivSplitter ccMgrSplitter`}
                          ></div>

                          <div className="ccDiv ccDiv2">
                            {/* <div className="ccDivUpper">
                              <div className="ccDivUpperNip"></div>
                              <div className="ccDivUpperNip ccDivUpperNip2"></div>
                            </div> */}
                            <div className="ccDivMid">
                              <div className="ccDivTs">
                                <p className="ccDivTitle ccDivTitle2">Off</p>

                                <p>
                                  {" "}
                                  {dateStringer.dsToTimeStrip(item.endDs, true)}
                                </p>
                              </div>
                            </div>
                            {/* <div className="ccDivLegs">
                              <div className="ccDivLeg"></div>
                              <div className="ccDivLeg ccDivLeg2"></div>
                            </div> */}
                          </div>
                          {item.tmw && <p className="ccTmw">+{item.tmw}d</p>}
                          {/*  */}
                          {/*  */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`mgrReqNameTab mgrReqNameTab2 pendClockName x34986498542 x435397859383`}
                    onClick={() => {
                      memoVals.setShowEditUser((x) => {
                        return { userID: item.userID, allows: true };
                      });
                    }}
                  >
                    <img
                      src={item.profPicUrl || profilePicturePlaceholder}
                      alt={item.fName}
                      className="itemNameTabImg"
                    />{" "}
                    {item.fName} {item.lName}
                    {item.you ? <span className="youSpan">(You)</span> : ""}
                  </div>
                  {/* - - - - - - - - - - - - - - - -  */}

                  <div
                    className={`mgrClockSubBar ${
                      memoVals.mob ? "mgrClockSubBarMob" : ""
                    } pendClockSub notifReqSubBarReq`}
                  >
                    <div
                      className={`clocksSchedSubLeft ${
                        memoVals.mob ? "font12px" : ""
                      }`}
                    >
                      {item.assocStartDs ? (
                        <div className="mgrNotifDsMade">
                          <p className="mgrNotifSchedTitle">Scheduled:</p>
                          <p className="mgrNotifSchedMain">
                            {dateStringer.dsToTimeStrip(item.assocStartDs)} -{" "}
                            {dateStringer.dsToTimeStrip(item.assocEndDs)}
                          </p>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                    <div
                      className={`clocksSchedSubRight  ${
                        memoVals.mob ? "flexEnd" : ""
                      }`}
                    >
                      {/* {!approveLoading && !clock.approved && !clock.declined && ( */}
                      <p
                        className={`approveClock x2978457 ${
                          item.blockApprove
                            ? "unableToApproveOwn greyColours"
                            : ""
                        } ${approving === item.reqID ? "disabledApprove" : ""}`}
                        onClick={() => {
                          if (item.blockApprove) {
                            setBlockApprove("clock");
                          } else {
                          }
                        }}
                      >
                        Approve{" "}
                        <img
                          src={whiteTick}
                          alt="Approve clock card"
                          className="approveCCtickimg"
                        />
                      </p>
                    </div>
                  </div>
                  {/* - - - - - - - - - - - - - - - -  */}
                </div>
              );
            }
          })
        )}
      </div>
      {blockApprove ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              You cannot approve your own{" "}
              {blockApprove === "req"
                ? "requests"
                : blockApprove === "clock"
                ? "clock cards"
                : ""}
              . <br />
              <br />
              Only another manager from your primary team or a FlowRota admin
              can approve them.
              <br />
              <br />
              If you require permission to approve your own{" "}
              {blockApprove === "req"
                ? "requests"
                : blockApprove === "clock"
                ? "clock cards"
                : ""}
              , a FlowRota admin can grant it by following{" "}
              <a
                href="https://flowrota.com/support/self-approve-permissions"
                target="_blank"
                className="colour00aaff"
              >
                this guide
              </a>
              .
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setBlockApprove("");
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default NotifReqs;
