import { type } from "os";
import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  Fragment,
} from "react";

function validateDateString(str) {
  // Regular expression to match the format Y2024M00D01H00M00
  const regex =
    /^[A-Za-z]+\d{4}[A-Za-z]+\d{2}[A-Za-z]+\d{2}[A-Za-z]+\d{2}[A-Za-z]+\d{2}$/;

  // Test the input string against the regular expression
  return regex.test(str);
}

function isValidDateFormat(dateString) {
  // Regular expression to match the format yyyy-mm-dd
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  // Test the input string against the regular expression
  return regex.test(dateString);
}

let createStringFromTimestamp = (ts) => {
  if (!ts || typeof ts !== "number") {
    return "Y1970M00D01H00M00";
  }
  let dateObj = new Date(ts);
  let y = dateObj.getFullYear();
  let m = dateObj.getMonth();
  let d = dateObj.getDate();
  let hr = dateObj.getHours();
  let mn = dateObj.getMinutes();

  let formatM = (mo) => {
    if (JSON.stringify(mo).length === 1) {
      return `0${mo}`;
    } else {
      return mo;
    }
  };
  let formatD = (da) => {
    if (JSON.stringify(da).length === 1) {
      return `0${da}`;
    } else {
      return da;
    }
  };
  let formatH = (ho) => {
    if (JSON.stringify(ho).length === 1) {
      return `0${ho}`;
    } else {
      return ho;
    }
  };

  let formatMin = (mi) => {
    if (JSON.stringify(mi).length === 1) {
      return `0${mi}`;
    } else {
      return mi;
    }
  };
  if (ts) {
    return `Y${y}M${formatM(m)}D${formatD(d)}H${formatH(hr)}M${formatMin(mn)}`;
  } else {
    return ``;
  }
};

let createTimestampFromString = (string) => {
  if (
    string &&
    typeof string === "string" &&
    string[0] === "Y" &&
    validateDateString(string)
  ) {
    let y = parseInt(string.substr(1, 4));
    let m = parseInt(string.substr(6, 2));
    let d = parseInt(string.substr(9, 2));
    let hr = parseInt(string.substr(12, 2));
    let mn = parseInt(string.substr(15, 2));
    let dateObj = new Date(y, m, d, hr, mn, 0, 0);

    return dateObj.getTime();
  } else {
    return 0;
  }
};

let createStringFromDateInput = (val) => {
  if (val && typeof val === "string" && isValidDateFormat(val)) {
    let year = val.substr(0, 4);
    let month = parseInt(val.substr(5, 2)) - 1;
    let prependMonthWith0 = (num) => {
      if (num < 10) {
        return `0${num}`;
      } else {
        return JSON.stringify(num);
      }
    };
    month = prependMonthWith0(month);
    let day = prependMonthWith0(parseInt(val.substr(8, 2)));

    return `Y${year}M${month}D${day}H00M00`;
  } else {
    return "Y1970M00D01H00M00";
  }
};

let createDateInputFromTimestamp = (ts) => {
  if (typeof ts !== "number") {
    return "1970-01-01";
  }
  const date = new Date(ts);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

let createDateInputFromDateString = (val) => {
  if (val && val[0] === "Y" && validateDateString(val)) {
    let year = val.substr(1, 4);
    let month = val.substr(6, 2);
    let date = val.substr(9, 2);

    let incrementMonth = (m) => {
      let newM = parseInt(m) + 1;
      if (newM < 10) {
        return `0${newM}`;
      } else return newM;
    };

    return `${year}-${incrementMonth(month)}-${date}`;
  } else {
    return "1970-01-01";
  }
};

let createShortStripFromDateString = (ds, abbreviateYr) => {
  if (ds && ds[0] === "Y" && validateDateString(ds)) {
    // returns format: 1 Jan 2021
    let date = parseInt(ds.substr(9, 2));
    let month = parseInt(ds.substr(6, 2));
    let m = "";
    if (month === 0) {
      m = "Jan";
    }
    if (month === 1) {
      m = "Feb";
    }
    if (month === 2) {
      m = "Mar";
    }
    if (month === 3) {
      m = "Apr";
    }
    if (month === 4) {
      m = "May";
    }
    if (month === 5) {
      m = "Jun";
    }
    if (month === 6) {
      m = "Jul";
    }
    if (month === 7) {
      m = "Aug";
    }
    if (month === 8) {
      m = "Sep";
    }
    if (month === 9) {
      m = "Oct";
    }
    if (month === 10) {
      m = "Nov";
    }
    if (month === 11) {
      m = "Dec";
    }

    return `${date} ${m} ${
      abbreviateYr ? `'${ds.substr(3, 2)}` : `${ds.substr(1, 4)}`
    }`;
  } else {
    return "";
  }
};

// fct 3 on batch tab sheet: Gets the the weekDs of a ts. Returns datestring of the monday of the ts's week at midnight
let getWeekDs = (ts) => {
  if (!ts || typeof ts !== "number") {
    return "Y1970M00D01H00M00";
  }
  let dateObj = new Date(ts);

  if (dateObj.getDay() === 1) {
  } else {
    dateObj.setDate(dateObj.getDate() - 1);
    if (dateObj.getDay() === 1) {
    } else {
      dateObj.setDate(dateObj.getDate() - 1);
      if (dateObj.getDay() === 1) {
      } else {
        dateObj.setDate(dateObj.getDate() - 1);
        if (dateObj.getDay() === 1) {
        } else {
          dateObj.setDate(dateObj.getDate() - 1);
          if (dateObj.getDay() === 1) {
          } else {
            dateObj.setDate(dateObj.getDate() - 1);
            if (dateObj.getDay() === 1) {
            } else {
              dateObj.setDate(dateObj.getDate() - 1);
              if (dateObj.getDay() === 1) {
              }
            }
          }
        }
      }
    }
  }
};

let tsToHHMM = (ts) => {
  if (!ts || typeof ts !== "number") {
    return "00:00";
  }
  let dateObj = new Date(ts);

  let prepend0 = (val) => {
    if (val < 10) {
      return `0${val}`;
    } else {
      return val;
    }
  };

  return `${prepend0(dateObj.getHours())}:${prepend0(dateObj.getMinutes())}`;
};

let HHMMToMsToday = (hhmm, plus24h, dsToOverrideWith) => {
  if (!hhmm || typeof hhmm !== "string") {
    return 0;
  }
  if (hhmm.substr(2, 1) !== ":") {
    return 0;
  }
  let dateObj = new Date();
  if (
    dsToOverrideWith &&
    dsToOverrideWith[0] === "Y" &&
    validateDateString(dsToOverrideWith)
  ) {
    dateObj.setMonth(parseInt(dsToOverrideWith.substr(6, 2)));
    dateObj.setDate(parseInt(dsToOverrideWith.substr(9, 2)));
    dateObj.setFullYear(parseInt(dsToOverrideWith.substr(1, 4)));
  }
  dateObj.setHours(hhmm.substr(0, 2), hhmm.substr(3, 2), 0, 0);

  if (plus24h) {
    dateObj.getDate(dateObj.getDate() + 1);
  }

  return dateObj.getTime();
};

let dateAndMonth = (ds) => {
  if (ds && ds[0] === "Y" && validateDateString(ds)) {
    let date = parseInt(ds.substr(9, 2));
    let month = parseInt(ds.substr(6, 2));
    // let year = parseInt(ds.substr(1, 4));

    let dateObj = new Date(createTimestampFromString(ds));

    let monthString = "Jan";
    if (month === 1) {
      monthString = "Feb";
    }
    if (month === 2) {
      monthString = "Mar";
    }
    if (month === 3) {
      monthString = "Apr";
    }
    if (month === 4) {
      monthString = "May";
    }
    if (month === 5) {
      monthString = "Jun";
    }
    if (month === 6) {
      monthString = "Jul";
    }
    if (month === 7) {
      monthString = "Aug";
    }
    if (month === 8) {
      monthString = "Sep";
    }
    if (month === 9) {
      monthString = "Oct";
    }
    if (month === 10) {
      monthString = "Nov";
    }
    if (month === 11) {
      monthString = "Dec";
    }
    // console.log(dateObj);
    // return dateObj;
    return `${date} ${monthString}`;
  } else {
    return "Y1970M00D00H00M00";
  }
};

let dsToTimeStrip = (ds, use24format) => {
  // Y2022M03D22H09M30 to 9.30am
  if (ds && ds[0] === "Y" && validateDateString(ds)) {
    if (use24format) {
      let hours = ds.substr(12, 2);
      let mins = ds.substr(15, 2);

      return `${hours}:${mins}`;
    } else {
      let hours = parseInt(ds.substr(12, 2));
      let mins = parseInt(ds.substr(15, 2));

      let value = "";

      if (mins === 0) {
        if (hours < 12) {
          value = `${hours === 0 ? "12" : hours}am`;
        } else {
          if (hours > 12) {
            hours -= 12;
          }
          value = `${hours}pm`;
        }
      } else {
        // mins in not 0
        let minsFormatted = mins;
        if (mins < 10) {
          minsFormatted = `0${mins}`;
        }
        if (hours < 12) {
          let hoursFormatted = hours;
          if (hours === 0) {
            hoursFormatted = 12;
          }

          value = `${hoursFormatted}.${minsFormatted}am`;
        } else {
          if (hours > 12) {
            hours -= 12;
          }
          value = `${hours}.${minsFormatted}pm`;
        }
      }

      return value;
    }
  } else {
    return "Y1970M00D01H00M00";
  }
};

let printedDateWithTimeFromDs = (ds, printTodayIfToday, avoidMonthAndYear) => {
  if (ds && ds[0] === "Y" && validateDateString(ds)) {
    let date = parseInt(ds.substr(9, 2));
    let month = parseInt(ds.substr(6, 2));
    let year = parseInt(ds.substr(1, 4));
    let hours = parseInt(ds.substr(12, 2));
    let mins = parseInt(ds.substr(15, 2));

    if (hours < 10) {
      hours = `0${hours}`;
    }

    if (mins < 10) {
      mins = `0${mins}`;
    }

    let dateObj = new Date();
    dateObj.setFullYear(year);
    dateObj.setMonth(month);
    dateObj.setDate(date);
    dateObj.setHours(parseInt(hours), parseInt(mins), 0, 0);

    let day = "";
    if (dateObj.getDay() === 1) {
      day = "Mon";
    }
    if (dateObj.getDay() === 2) {
      day = "Tue";
    }
    if (dateObj.getDay() === 3) {
      day = "Wed";
    }
    if (dateObj.getDay() === 4) {
      day = "Thu";
    }
    if (dateObj.getDay() === 5) {
      day = "Fri";
    }
    if (dateObj.getDay() === 6) {
      day = "Sat";
    }
    if (dateObj.getDay() === 0) {
      day = "Sun";
    }

    let monthString = "Jan";
    if (month === 1) {
      monthString = "Feb";
    }
    if (month === 2) {
      monthString = "Mar";
    }
    if (month === 3) {
      monthString = "Apr";
    }
    if (month === 4) {
      monthString = "May";
    }
    if (month === 5) {
      monthString = "Jun";
    }
    if (month === 6) {
      monthString = "Jul";
    }
    if (month === 7) {
      monthString = "Aug";
    }
    if (month === 8) {
      monthString = "Sep";
    }
    if (month === 9) {
      monthString = "Oct";
    }
    if (month === 10) {
      monthString = "Nov";
    }
    if (month === 11) {
      monthString = "Dec";
    }

    let hour = hours;
    let min = mins;

    let time = "";
    if (mins === "00") {
      console.log({ hours });
      if (hours < 12) {
        time = `${parseInt(hours === "00" ? 12 : hours)}am`;
      } else {
        time = `${parseInt(hours) - 12}pm`;
      }
    } else {
      if (hours <= 12) {
        time = `${parseInt(hours === "00" ? 12 : hours)}.${mins}am`;
      } else {
        time = `${parseInt(hours) - 12}.${mins}pm`;
      }
    }
    let dayStrip = `, ${day} ${date} ${monthString}`;

    if (avoidMonthAndYear) {
      dayStrip = `, ${day}`;
    }
    if (printTodayIfToday) {
      let todayDs = createStringFromTimestamp(new Date().getTime());
      let todayDs00 = todayDs.substr(0, 11);
      let ds00 = ds.substr(0, 11);
      if (todayDs00 === ds00) {
        dayStrip = " today";
      }
    }

    return `${dsToTimeStrip(ds)}${dayStrip}`;
  } else {
    return "Y1970M00D01H00M00";
  }
};

let printedDateWithYrFromDs = (ds, longDayName, dontShowYear, longMonth) => {
  if (ds && validateDateString(ds)) {
    let date = parseInt(ds.substr(9, 2));
    let month = parseInt(ds.substr(6, 2));
    let year = parseInt(ds.substr(1, 4));

    let dateObj = new Date();
    dateObj.setFullYear(year);
    dateObj.setMonth(month);
    dateObj.setDate(date);
    dateObj.setHours(0, 0, 0, 0);

    let day = "";
    if (dateObj.getDay() === 1) {
      day = longDayName ? "Monday" : "Mon";
    }
    if (dateObj.getDay() === 2) {
      day = longDayName ? "Tuesday" : "Tue";
    }
    if (dateObj.getDay() === 3) {
      day = longDayName ? "Wednesday" : "Wed";
    }
    if (dateObj.getDay() === 4) {
      day = longDayName ? "Thursday" : "Thu";
    }
    if (dateObj.getDay() === 5) {
      day = longDayName ? "Friday" : "Fri";
    }
    if (dateObj.getDay() === 6) {
      day = longDayName ? "Saturday" : "Sat";
    }
    if (dateObj.getDay() === 0) {
      day = longDayName ? "Sunday" : "Sun";
    }

    let monthString = longMonth ? "January" : "Jan";
    if (month === 1) {
      monthString = longMonth ? "February" : "Feb";
    }
    if (month === 2) {
      monthString = longMonth ? "March" : "Mar";
    }
    if (month === 3) {
      monthString = longMonth ? "April" : "Apr";
    }
    if (month === 4) {
      monthString = longMonth ? "May" : "May";
    }
    if (month === 5) {
      monthString = longMonth ? "June" : "Jun";
    }
    if (month === 6) {
      monthString = longMonth ? "July" : "Jul";
    }
    if (month === 7) {
      monthString = longMonth ? "August" : "Aug";
    }
    if (month === 8) {
      monthString = longMonth ? "September" : "Sep";
    }
    if (month === 9) {
      monthString = longMonth ? "October" : "Oct";
    }
    if (month === 10) {
      monthString = longMonth ? "November" : "Nov";
    }
    if (month === 11) {
      monthString = longMonth ? "December" : "Dec";
    }

    return `${day} ${date} ${monthString}${dontShowYear ? "" : " "}${
      dontShowYear ? "" : ds.substr(1, 4)
    }`;
  } else {
    return "Y1970M00D01H00M00";
  }
};

let printedDateWithYrFromDWithoutDay = (ds, fullYear) => {
  if (ds && validateDateString(ds)) {
    let date = parseInt(ds.substr(9, 2));
    let month = parseInt(ds.substr(6, 2));
    let year = parseInt(ds.substr(1, 4));

    let dateObj = new Date();
    dateObj.setFullYear(year);
    dateObj.setMonth(month);
    dateObj.setDate(date);
    dateObj.setHours(0, 0, 0, 0);

    let monthString = "Jan";
    if (month === 1) {
      monthString = "Feb";
    }
    if (month === 2) {
      monthString = "Mar";
    }
    if (month === 3) {
      monthString = "Apr";
    }
    if (month === 4) {
      monthString = "May";
    }
    if (month === 5) {
      monthString = "Jun";
    }
    if (month === 6) {
      monthString = "Jul";
    }
    if (month === 7) {
      monthString = "Aug";
    }
    if (month === 8) {
      monthString = "Sep";
    }
    if (month === 9) {
      monthString = "Oct";
    }
    if (month === 10) {
      monthString = "Nov";
    }
    if (month === 11) {
      monthString = "Dec";
    }

    if (fullYear) {
      return `${date} ${monthString} ${ds.substr(3, 4)}`;
    } else {
      return `${date} ${monthString} '${ds.substr(3, 2)}`;
    }
  } else {
    return "Y1970M00D01H00M00";
  }
};

let formatMinsDurationToHours = (m, shorten, allowMinus) => {
  let mins = parseInt(m);
  if (!mins || typeof mins !== "number") {
    return "0m";
  }
  if (mins < 0 && !allowMinus) {
    mins = Math.abs(mins);
  }
  if (mins === 1) {
    return shorten ? "1 min" : "1 minute";
  }
  if (mins < 0 && !allowMinus) {
    return `${Math.round(mins)}m`;
    let mins2 = Math.abs(mins);
    let hours = Math.floor(mins2 / 60);
    let m = mins2 % 60;

    let hString = shorten ? "h" : "hr";
    let mString = "m";

    if (m === 0) {
      mString = "";
      m = "";
    } else if (m === 1) {
      mString = "m";
    }

    if (hours === 0) {
      hString = "";
      hours = "";
      mString = shorten ? "m" : "mins";
    } else if (hours === 1) {
      hString = shorten ? "h" : "hr";
    }

    if (!m && hours > 1) {
      hString = shorten ? "h" : " hours";
    } else if (!m && hours === 1) {
      hString = shorten ? "h" : " hour";
    }

    return `-${hours}${hString}${hString && mString ? " " : ""}${
      m === "" ? m : Math.round(m)
    }${!hString && mins2 < 60 ? " " : ""}${mString}`;
  } else {
    if (mins === 0) {
      return `0m`;
    }

    if (mins > 0 && mins < 1) {
      return `${Math.round(mins * 60)}s`;
    }
    if (mins > 0 && mins < 60 && typeof mins === "number") {
      return `${mins.toFixed(0)}m`;
    }

    let hours = Math.floor(mins / 60);
    let m = mins % 60;

    let hString = shorten ? "h" : "hr";
    let mString = "m";

    if (m === 0) {
      mString = "";
      m = "";
    } else if (m === 1) {
      mString = "m";
    }

    if (hours === 0) {
      hString = "";
      hours = "";
      mString = shorten ? "m" : "mins";
    } else if (hours === 1) {
      hString = shorten ? "h" : "hr";
    }

    if (!m && hours > 1) {
      hString = shorten ? "h" : " hours";
    } else if (!m && hours === 1) {
      hString = shorten ? "h" : " hour";
    }

    return `${hours}${hString}${hString && mString ? " " : ""}${
      m === "" ? m : Math.abs(Math.round(m))
    }${!hString && mins < 60 ? " " : ""}${mString}`;
  }

  return 0;
};

let createDatePeriod = (start, end) => {
  if (start && end && validateDateString(start) && validateDateString(end)) {
    let startDate = parseInt(start.substr(9, 2));
    let startMonth = parseInt(start.substr(6, 2));
    let endDate = parseInt(end.substr(9, 2));
    let endMonth = parseInt(end.substr(6, 2));
    let yr = `'${JSON.stringify(parseInt(end.substr(1, 4))).substr(2, 2)}`;
    let startMonthOutput = "";
    if (startMonth === 0) {
      startMonthOutput = "Jan";
    } else if (startMonth === 1) {
      startMonthOutput = "Feb";
    } else if (startMonth === 2) {
      startMonthOutput = "Mar";
    } else if (startMonth === 3) {
      startMonthOutput = "Apr";
    } else if (startMonth === 4) {
      startMonthOutput = "May";
    } else if (startMonth === 5) {
      startMonthOutput = "Jun";
    } else if (startMonth === 6) {
      startMonthOutput = "Jul";
    } else if (startMonth === 7) {
      startMonthOutput = "Aug";
    } else if (startMonth === 8) {
      startMonthOutput = "Sep";
    } else if (startMonth === 9) {
      startMonthOutput = "Oct";
    } else if (startMonth === 10) {
      startMonthOutput = "Nov";
    } else if (startMonth === 11) {
      startMonthOutput = "Dec";
    }

    let endMonthOutput = "";
    if (endMonth === 0) {
      endMonthOutput = "Jan";
    } else if (endMonth === 1) {
      endMonthOutput = "Feb";
    } else if (endMonth === 2) {
      endMonthOutput = "Mar";
    } else if (endMonth === 3) {
      endMonthOutput = "Apr";
    } else if (endMonth === 4) {
      endMonthOutput = "May";
    } else if (endMonth === 5) {
      endMonthOutput = "Jun";
    } else if (endMonth === 6) {
      endMonthOutput = "Jul";
    } else if (endMonth === 7) {
      endMonthOutput = "Aug";
    } else if (endMonth === 8) {
      endMonthOutput = "Sep";
    } else if (endMonth === 9) {
      endMonthOutput = "Oct";
    } else if (endMonth === 10) {
      endMonthOutput = "Nov";
    } else if (endMonth === 11) {
      endMonthOutput = "Dec";
    }

    if (startMonthOutput !== endMonthOutput) {
      return `${startDate} ${startMonthOutput} - ${endDate} ${endMonthOutput} ${yr}`;
    } else if (startMonthOutput === endMonthOutput && startDate === endDate) {
      return `${startDate} ${endMonthOutput} ${yr}`;
    } else {
      return `${startDate} - ${endDate} ${endMonthOutput} ${yr}`;
    }
  } else {
    return "No dates";
  }
};

let printedDayWithYrFromTs = (ts) => {
  if (!ts || typeof ts !== "number") {
    return "-";
  }
  let date = new Date(ts);
  if (date.getDay() === 0) {
    return "Sun";
  }
  if (date.getDay() === 1) {
    return "Mon";
  }
  if (date.getDay() === 2) {
    return "Tue";
  }
  if (date.getDay() === 3) {
    return "Wed";
  }
  if (date.getDay() === 4) {
    return "Thu";
  }
  if (date.getDay() === 5) {
    return "Fri";
  }
  if (date.getDay() === 6) {
    return "Sat";
  }
};

let nameInitials = (val) => {
  if (val && validateDateString(val)) {
    let newString = "";
    let spaceAt = 99999;

    for (var i = 0; i < val.length; i++) {
      if (val[i] === " ") {
        newString += val[i];
        spaceAt = i;
      } else {
        if (i <= spaceAt + 1) {
          newString += val[i];
        }
      }
    }
    return newString;
  } else {
    return "";
  }
};

let convert24hrTimeStripToAmPm = (hhmm) => {
  if (!hhmm || typeof hhmm !== "string" || hhmm.length !== 5) {
    return "";
  }

  // Split the input time into hours and minutes
  let [hours, minutes] = hhmm.split(":").map(Number);
  // Determine whether it's AM or PM
  let period = hours >= 12 ? "pm" : "am";

  // Convert 24-hour time to 12-hour time, where 0 or 12 represents 12
  hours = hours % 12 || 12; // Convert "0" or "12" to "12" for midnight/noon

  // If minutes are '00', return only the hour and period
  if (minutes === 0) {
    return `${hours}${period}`;
  }

  // Ensure minutes are always two digits
  minutes = minutes.toString().padStart(2, "0");

  // Return the formatted time with minutes
  return `${hours}.${minutes}${period}`;
};
let generateTilShiftString = (
  start,
  end,
  oldStart,
  oldEnd,
  elipse,
  div,
  type,
  inlineTil,
  amPmFormat
) => {
  // start = start time of til
  // end = end time of til
  // oldStart = shift start
  // oldEnd = shift end
  // elipse = if shift is split, return & instead of ...

  if (start && typeof start !== "string" && start.length !== 5) {
    return "";
  }
  if (end && typeof end !== "string" && end.length !== 5) {
    return "";
  }
  if (oldStart && typeof oldStart !== "string" && oldStart.length !== 5) {
    return "";
  }
  if (oldEnd && typeof oldEnd !== "string" && oldEnd.length !== 5) {
    return "";
  }

  if (oldEnd === end && oldStart === start) {
    // return `${start} - ${end}`;
    if (inlineTil) {
      return `Whole ${type || "shiftX"}`;
    } else {
      return `Whole ${type || "shiftX"} off as TOIL`;
    }
  }

  if (!start || !end) {
    return `${amPmFormat ? convert24hrTimeStripToAmPm(oldStart) : oldStart} - ${
      amPmFormat ? convert24hrTimeStripToAmPm(oldEnd) : oldEnd
    }`;
  }

  if (oldEnd === end && oldStart !== start) {
    let dateObj = new Date();
    let dsFromDateObj = createStringFromTimestamp(dateObj.getTime());
    let dsFormatted = `${dsFromDateObj.substr(0, 11)}H${start.substr(
      0,
      2
    )}M${start.substr(3, 2)}`;
    let backToTs = createTimestampFromString(dsFormatted) - 60000;
    // let backToDs = dateStringer.createStringFromTimestamp(backToTs);
    let resultEnd = tsToHHMM(backToTs);

    return `${amPmFormat ? convert24hrTimeStripToAmPm(oldStart) : oldStart} - ${
      amPmFormat ? convert24hrTimeStripToAmPm(resultEnd) : resultEnd
    }`;
  }

  if (oldEnd === end && oldStart === start) {
    return `${amPmFormat ? convert24hrTimeStripToAmPm(oldStart) : oldStart} - ${
      amPmFormat ? convert24hrTimeStripToAmPm(oldEnd) : oldEnd
    }`;
  }

  if (oldEnd !== end && oldStart === start) {
    let dateObj = new Date();
    let dsFromDateObj = createStringFromTimestamp(dateObj.getTime());
    let dsFormatted = `${dsFromDateObj.substr(0, 11)}H${end.substr(
      0,
      2
    )}M${end.substr(3, 2)}`;
    let backToTs = createTimestampFromString(dsFormatted) + 60000;
    // let backToDs = dateStringer.createStringFromTimestamp(backToTs);
    let resultStart = tsToHHMM(backToTs);

    return `${
      amPmFormat ? convert24hrTimeStripToAmPm(resultStart) : resultStart
    } - ${amPmFormat ? convert24hrTimeStripToAmPm(oldEnd) : oldEnd}`;
  }

  if (oldEnd !== end && oldStart !== start) {
    // is middle

    // first shift start
    let dateObj1 = new Date();
    let dsFromDateObj1 = createStringFromTimestamp(dateObj1.getTime());
    let dsFormatted1 = `${dsFromDateObj1.substr(0, 11)}H${start.substr(
      0,
      2
    )}M${start.substr(3, 2)}`;
    let backToTs1 = createTimestampFromString(dsFormatted1);
    // let backToDs = dateStringer.createStringFromTimestamp(backToTs);
    let resultEnd1 = tsToHHMM(backToTs1);

    // second shift start
    let dateObj2 = new Date();
    let dsFromDateObj2 = createStringFromTimestamp(dateObj2.getTime());
    let dsFormatted2 = `${dsFromDateObj2.substr(0, 11)}H${end.substr(
      0,
      2
    )}M${end.substr(3, 2)}`;
    let backToTs2 = createTimestampFromString(dsFormatted2);
    // let backToDs = dateStringer.createStringFromTimestamp(backToTs);
    let resultEnd2 = tsToHHMM(backToTs2);

    return `${amPmFormat ? convert24hrTimeStripToAmPm(oldStart) : oldStart} - ${
      amPmFormat ? convert24hrTimeStripToAmPm(resultEnd1) : resultEnd1
    } ${elipse ? "..." : `${div || ""}`} ${
      amPmFormat ? convert24hrTimeStripToAmPm(resultEnd2) : resultEnd2
    } - ${amPmFormat ? convert24hrTimeStripToAmPm(oldEnd) : oldEnd}`;
  }
};

let tsToDayAndDate = (ts, fullDayString) => {
  if (ts && typeof ts === "number") {
    let dateObj = new Date(ts);
    let day = fullDayString ? "Sunday" : "Sun";
    if (dateObj.getDay() === 1) {
      day = fullDayString ? "Monday" : "Mon";
    }
    if (dateObj.getDay() === 2) {
      day = fullDayString ? "Tuesday" : "Tue";
    }
    if (dateObj.getDay() === 3) {
      day = fullDayString ? "Wednesday" : "Wed";
    }
    if (dateObj.getDay() === 4) {
      day = fullDayString ? "Thursday" : "Thu";
    }
    if (dateObj.getDay() === 5) {
      day = fullDayString ? "Friday" : "Fri";
    }
    if (dateObj.getDay() === 6) {
      day = fullDayString ? "Saturday" : "Sat";
    }

    return `${day} ${dateObj.getDate()}`;
  } else {
    return "";
  }
};

// let tsToHHMM = (ts) => {
//   let dateObj = new Date(ts);
//   let h = dateObj.getHours();
//   let m = dateObj.getMinutes();

//   let prepend0 = (val) => {
//     if(val < 10) {
//       return `0${val}`
//     } else return val
//   }

//   return `${prepend0(h)}:${prepend0(m)}`

// }

// old -- returns minutes after days

let convertMinsToDays = (totMins, dailyMins, decimalise) => {
  if (typeof totMins !== "number" || typeof dailyMins !== "number") {
    return "";
  }
  if (!decimalise) {
    // will return something like 4 days, 5hrs
    // decimalise below will send 4.75 days
    let days = totMins / dailyMins;
    let surplus = totMins % dailyMins;

    if (!dailyMins) {
      return formatMinsDurationToHours(totMins);
    }

    if (!totMins) {
      return "None";
    }

    if (dailyMins > totMins) {
      if (totMins === dailyMins / 2) {
        return `0.5 day`;
      } else {
        return `${Math.round(totMins)} minutes`;
      }
    }

    if (surplus === 0 && days <= 1) {
      return `${days} day`;
    }

    if (surplus === 0 && days > 1) {
      return `${Math.floor(days)} days`;
    }

    if (surplus > 0 && days > 0) {
      if (surplus === dailyMins / 2) {
        return `${Math.floor(days)}.5 days`;
      } else {
        if (surplus > 59) {
          let hrs = Math.floor(surplus / 60);
          let mns = surplus % 60;
          if (mns === 0) {
            return `${Math.floor(days)}d, ${hrs}hr`;
          }
          return `${Math.floor(days)}d, ${hrs}h, ${Math.round(mns)}m`;
        }
      }
      return `${Math.floor(days)}d, ${Math.round(surplus)}m`;
    }

    if (surplus > 0 && days === 0) {
      return;
    }
  } else {
    let days = totMins / dailyMins;
    let surplus = totMins % dailyMins;

    if (totMins < 0) {
      let absVal = Math.abs(totMins);
      console.log({ absVal });
      return `-${formatMinsDurationToHours(absVal)}`;
    }
    if (!dailyMins) {
      return formatMinsDurationToHours(totMins);
    }

    if (!totMins) {
      return "None";
    }

    if (dailyMins > totMins) {
      if (totMins === dailyMins / 2) {
        return `0.5 day`;
      } else {
        return `${Math.round(totMins)} minutes`;
      }
    }

    if (surplus === 0 && days <= 1) {
      return `${days} day`;
    }

    if (surplus === 0 && days > 1) {
      return `${Math.floor(days)} days`;
    }

    if (surplus > 0 && days > 0) {
      if (surplus === dailyMins / 2) {
        return `${Math.floor(days)}.5 days`;
      } else {
        // if (surplus > 59) {
        // if surplus is within 25% and 74% of dailyMins, add 0.5 to days
        // if surplus is <25% of dailyMins, add 0.25 to days
        // if surplus is  ?75% of dailyMins, add 1 day

        let surplusIsWhatPercentOfDailyMins = (surplus / dailyMins) * 100;
        days = Math.floor(days);
        // return `${surplusIsWhatPercentOfDailyMins}%`;
        if (
          surplusIsWhatPercentOfDailyMins > 13 &&
          surplusIsWhatPercentOfDailyMins < 33
        ) {
          days += 0.25;
        }

        if (
          surplusIsWhatPercentOfDailyMins >= 33 &&
          surplusIsWhatPercentOfDailyMins <= 70
        ) {
          days += 0.5;
        }

        if (
          surplusIsWhatPercentOfDailyMins > 70 &&
          surplusIsWhatPercentOfDailyMins < 88
        ) {
          days += 0.75;
        }

        if (surplusIsWhatPercentOfDailyMins > 88) {
          days += 1;
        }
        return `${days} day${days === 1 ? "" : "s"}`;

        let hrs = Math.floor(surplus / 60);
        let mns = surplus % 60;
        if (mns === 0) {
          return `${Math.floor(days)}d, ${hrs}hr`;
        }
        return `${Math.floor(days)}d, ${hrs}h, ${Math.round(mns)}m`;
        // }
      }
      return `${Math.floor(days)}d, ${Math.round(surplus)}m`;
    }

    if (surplus > 0 && days === 0) {
      return;
    }
  }
};
let convertDayNumberToDayString = (val) => {
  let num = parseInt(val);
  if (isNaN(num)) {
    return "";
  }
  if (num === 0) {
    return "Sunday";
  }
  if (num === 1) {
    return "Monday";
  }
  if (num === 2) {
    return "Tuesday";
  }
  if (num === 3) {
    return "Wednesday";
  }
  if (num === 4) {
    return "Thursday";
  }
  if (num === 5) {
    return "Friday";
  }
  if (num === 6) {
    return "Saturday";
  }
};

let convertDayNumberToDayStringShort = (val) => {
  let num = parseInt(val);
  if (isNaN(num)) {
    return "";
  }
  if (num === 0) {
    return "Sun";
  }
  if (num === 1) {
    return "Mon";
  }
  if (num === 2) {
    return "Tue";
  }
  if (num === 3) {
    return "Wed";
  }
  if (num === 4) {
    return "Thu";
  }
  if (num === 5) {
    return "Fri";
  }
  if (num === 6) {
    return "Sat";
  }
};

let formatMinsDurationToShortHours = (mins) => {
  if (typeof mins !== "number") {
    return "";
  }
  if (mins < 0) {
    let mins2 = Math.abs(mins);
    let hours = Math.floor(mins2 / 60);
    let m = mins2 % 60;

    let hString = "hr";
    let mString = "m";

    if (m === 0) {
      mString = "";
      m = "";
    } else if (m === 1) {
      mString = "m";
    }

    if (hours === 0) {
      hString = "";
      hours = "";
      mString = "m";
    } else if (hours === 1) {
      hString = "h";
    }

    if (!m && hours > 1) {
      hString = "h";
    } else if (!m && hours === 1) {
      hString = "  h";
    }

    return `-${hours}${hString}${hString && mString ? " " : ""}${
      m === "" ? m : Math.round(m)
    }${!hString && mins2 < 60 ? "" : ""}${mString}`;
  } else {
    if (mins === 0) {
      return `0m`;
    }

    let hours = Math.floor(mins / 60);
    let m = mins % 60;

    let hString = "h";
    let mString = "m";

    if (m === 0) {
      mString = "";
      m = "";
    } else if (m === 1) {
      mString = "m";
    }

    if (hours === 0) {
      hString = "";
      hours = "";
      mString = "m";
    } else if (hours === 1) {
      hString = "h";
    }

    if (!m && hours > 1) {
      hString = "h";
    } else if (!m && hours === 1) {
      hString = "h";
    }

    return `${hours}${hString}${hString && mString ? " " : ""}${
      m === "" ? m : Math.round(m)
    }${!hString && mins < 60 ? "" : ""}${mString}`;
  }
};

let getCurrMonWeek = () => {
  let getCurrMonWeekTs = () => {
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    if (now.getDay() !== 1) {
      now.setDate(now.getDate() - 1);
      if (now.getDay() !== 1) {
        now.setDate(now.getDate() - 1);
        if (now.getDay() !== 1) {
          now.setDate(now.getDate() - 1);
          if (now.getDay() !== 1) {
            now.setDate(now.getDate() - 1);
            if (now.getDay() !== 1) {
              now.setDate(now.getDate() - 1);
              if (now.getDay() !== 1) {
                now.setDate(now.getDate() - 1);
              }
            }
          }
        }
      }
    }

    return now.getTime();
  };

  return createStringFromTimestamp(getCurrMonWeekTs());
};

let convertDsToDateInput = (ds) => {
  if (ds && ds[0] === "Y" && validateDateString(ds)) {
    let year = ds.substr(1, 4);
    let m = parseInt(ds.substr(6, 2));
    let month = m + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = ds.substr(9, 2);
    return `${year}-${month}-${day}`;
  } else {
    return "";
  }
};

let printMonthAndYearFromMy = (month, longMonth, longYear) => {
  if (typeof month !== "string" || month.length !== 4) {
    return "";
  }
  let formatMonth = "Jan";
  let formatYear = longYear ? `20${month.substr(2, 2)}` : month.substr(2, 2);
  if (month.substr(0, 2) === "00") {
    formatMonth = longMonth ? "January" : "Jan";
  }
  if (month.substr(0, 2) === "01") {
    formatMonth = longMonth ? "February" : "Feb";
  }
  if (month.substr(0, 2) === "02") {
    formatMonth = longMonth ? "March" : "Mar";
  }
  if (month.substr(0, 2) === "03") {
    formatMonth = longMonth ? "April" : "Apr";
  }
  if (month.substr(0, 2) === "04") {
    formatMonth = longMonth ? "May" : "May";
  }
  if (month.substr(0, 2) === "05") {
    formatMonth = longMonth ? "June" : "Jun";
  }
  if (month.substr(0, 2) === "06") {
    formatMonth = longMonth ? "July" : "Jul";
  }
  if (month.substr(0, 2) === "07") {
    formatMonth = longMonth ? "August" : "Aug";
  }
  if (month.substr(0, 2) === "08") {
    formatMonth = longMonth ? "September" : "Sep";
  }
  if (month.substr(0, 2) === "09") {
    formatMonth = longMonth ? "October" : "Oct";
  }
  if (month.substr(0, 2) === "10") {
    formatMonth = longMonth ? "November" : "Nov";
  }
  if (month.substr(0, 2) === "11") {
    formatMonth = longMonth ? "December" : "Dec";
  }

  return `${formatMonth} ${formatYear}`;
  // <option key={month} value={month}>
  //   {formatMonth} {formatYear}
  // </option>
};

let printMonthFromMy = (my) => {
  if (!my || typeof my !== "string" || my.length !== 2) {
    return "";
  }
  if (my === "00") {
    return "January";
  }

  if (my === "01") {
    return "February";
  }

  if (my === "02") {
    return "March";
  }

  if (my === "03") {
    return "April";
  }
  if (my === "04") {
    return "May";
  }

  if (my === "05") {
    return "June";
  }

  if (my === "06") {
    return "July";
  }

  if (my === "07") {
    return "August";
  }

  if (my === "08") {
    return "September";
  }

  if (my === "09") {
    return "October";
  }

  if (my === "10") {
    return "November";
  }
  if (my === "11") {
    return "December";
  }
};

let getMonthThreeLettersFromDs = (ds, getFull) => {
  if (ds && ds[0] === "Y" && validateDateString(ds)) {
    if (ds.substr(6, 2) === "00") {
      return getFull ? "January" : "Jan";
    }

    if (ds.substr(6, 2) === "01") {
      return getFull ? "Febraury" : "Feb";
    }

    if (ds.substr(6, 2) === "02") {
      return getFull ? "March" : "Mar";
    }

    if (ds.substr(6, 2) === "03") {
      return getFull ? "April" : "Apr";
    }

    if (ds.substr(6, 2) === "04") {
      return getFull ? "May" : "May";
    }

    if (ds.substr(6, 2) === "05") {
      return getFull ? "June" : "Jun";
    }

    if (ds.substr(6, 2) === "06") {
      return getFull ? "July" : "Jul";
    }

    if (ds.substr(6, 2) === "07") {
      return getFull ? "August" : "Aug";
    }

    if (ds.substr(6, 2) === "08") {
      return getFull ? "September" : "Sep";
    }

    if (ds.substr(6, 2) === "09") {
      return getFull ? "October" : "Oct";
    }

    if (ds.substr(6, 2) === "10") {
      return getFull ? "November" : "Nov";
    }
    if (ds.substr(6, 2) === "11") {
      return getFull ? "December" : "Dec";
    }
  } else {
    return "";
  }
};

let nameFormat = (val) => {
  if (val && typeof val === "string") {
    let output = "";
    let spaceFound = false;
    let firstCharacterOfSurnameAdded = false;
    for (let i = 0; i < val.length; i++) {
      if (!firstCharacterOfSurnameAdded) {
        if (spaceFound && !firstCharacterOfSurnameAdded) {
          firstCharacterOfSurnameAdded = true;
        }
        output += val[i];
        if (val[i] === " ") {
          spaceFound = true;
        }
      }
    }

    return output;
  } else {
    return "";
  }
};

let getMonWeekOfDs = (ds) => {
  if (!validateDateString(ds) || !ds) {
    return "";
  }
  let dateObj = new Date(createTimestampFromString(ds));
  if (dateObj.getDay() !== 1) {
    dateObj.setDate(dateObj.getDate() - 1);
  }
  if (dateObj.getDay() !== 1) {
    dateObj.setDate(dateObj.getDate() - 1);
  }
  if (dateObj.getDay() !== 1) {
    dateObj.setDate(dateObj.getDate() - 1);
  }
  if (dateObj.getDay() !== 1) {
    dateObj.setDate(dateObj.getDate() - 1);
  }
  if (dateObj.getDay() !== 1) {
    dateObj.setDate(dateObj.getDate() - 1);
  }
  if (dateObj.getDay() !== 1) {
    dateObj.setDate(dateObj.getDate() - 1);
  }

  dateObj.setHours(0, 0, 0, 0);
  return createStringFromTimestamp(dateObj.getTime());
};

let calcMinsBetweenTwoDateStrings = (ds1, ds2) => {
  if (!validateDateString(ds1) || !validateDateString(ds2)) {
    return "";
  }
  if (
    ds1 &&
    ds1[0] === "Y" &&
    ds1[1] === "2" &&
    ds2 &&
    ds2[0] === "Y" &&
    ds2[1] === "2"
  ) {
    let dateObj1 = new Date(createTimestampFromString(ds1));
    let dateObj2 = new Date(createTimestampFromString(ds2));

    let msDiff = dateObj2.getTime() - dateObj1.getTime();
    let mins = msDiff / 1000 / 60;
    return mins;
  } else {
    return "";
  }
};

let convertMetresToKm = (num, dontCap10) => {
  let m = parseFloat(num);
  if (isNaN(m)) {
    return "";
  }
  if (typeof m === "number") {
    if (m < 999) {
      return `${m.toFixed(0)}m`;
    } else {
      if (m > 9999 && !dontCap10) {
        return `>10km`;
      } else {
        return `${(m / 1000).toFixed(1)}km`;
      }
    }
  } else {
    return `${m}m`;
  }
};

let possession = (val) => {
  if (typeof val !== "string") {
    return "";
  }
  if (val) {
    if (val === "your" || val === "Your") {
      return val;
    }
    return `${val}${val[val.length - 1] === "s" ? "'" : "'s"}`;
  } else {
    return "";
  }
};

let initial = (val) => {
  if (typeof val !== "string") {
    return "";
  }
  if (val) {
    const [firstName, lastName] = val.split(" ");

    // extract the first letter of the last name
    if (lastName) {
      const lastNameInitial = lastName.charAt(0);

      // combine the first name and last name initial into a formatted string
      const formattedName = `${firstName} ${lastNameInitial}`;

      // return the formatted name
      return formattedName;
    }
  }
};

let shorten = (value, max) => {
  let valueFinal = value;
  let maxFinal = max;
  if (isNaN(maxFinal)) {
    return "";
  }

  if (typeof valueFinal !== "string") {
    return "";
  }

  if (valueFinal && maxFinal) {
    if (valueFinal.length > maxFinal) {
      if (
        valueFinal.substr(0, maxFinal - 3)[
          valueFinal.substr(0, maxFinal - 3).length - 1
        ] === " "
      ) {
        maxFinal += 1;
      }

      return `${valueFinal.substr(0, maxFinal - 3)}...`;
    } else {
      return value;
    }
  }
};

let printMonthAndYearFromDs = (ds, shortenMonth, shortenYear) => {
  if (ds && validateDateString(ds)) {
    let dateObj = new Date(createTimestampFromString(ds));

    let month;

    if (dateObj.getMonth() === 0) {
      month = shortenMonth ? "Jan" : "January";
    }

    if (dateObj.getMonth() === 1) {
      month = shortenMonth ? "Feb" : "February";
    }

    if (dateObj.getMonth() === 2) {
      month = shortenMonth ? "Mar" : "March";
    }

    if (dateObj.getMonth() === 3) {
      month = shortenMonth ? "Apr" : "April";
    }

    if (dateObj.getMonth() === 4) {
      month = shortenMonth ? "May" : "May";
    }

    if (dateObj.getMonth() === 5) {
      month = shortenMonth ? "Jun" : "June";
    }

    if (dateObj.getMonth() === 6) {
      month = shortenMonth ? "Jul" : "July";
    }

    if (dateObj.getMonth() === 7) {
      month = shortenMonth ? "Aug" : "August";
    }
    if (dateObj.getMonth() === 8) {
      month = shortenMonth ? "Sep" : "September";
    }
    if (dateObj.getMonth() === 9) {
      month = shortenMonth ? "Oct" : "October";
    }
    if (dateObj.getMonth() === 10) {
      month = shortenMonth ? "Nov" : "November";
    }
    if (dateObj.getMonth() === 11) {
      month = shortenMonth ? "Dec" : "December";
    }

    let year = shortenYear ? `${ds.substr(3, 2)}` : `${ds.substr(1, 4)}`;

    return `${month} ${year}`;
  } else {
    return "";
  }
};
let list = (items) => {
  if (!Array.isArray(items)) {
    return "";
  }
  const length = items.length;

  if (length === 0) {
    return "";
  }

  if (length === 1) {
    return items[0];
  }

  if (length === 2) {
    return `${items[0]} and ${items[1]}`;
  }

  const lastItem = items[length - 1];
  const penultimateItem = items[length - 2];
  const remainingItems = items.slice(0, length - 2);
  const joinedItems = remainingItems.join(", ");

  return `${joinedItems}, ${penultimateItem} & ${lastItem}`;
};

let nth = (num) => {
  if (!num) {
    return "";
  }
  let dateStrip = JSON.stringify(num);
  return dateStrip[dateStrip.length - 1] === "1"
    ? dateStrip[dateStrip.length - 2] === "1"
      ? "th"
      : "st"
    : dateStrip[dateStrip.length - 1] === "2"
    ? "nd"
    : dateStrip === "13"
    ? "th"
    : dateStrip[dateStrip.length - 1] === "3"
    ? "rd"
    : "th";
};

let calcDaysBetweenTwoDs = (startDs, endDs) => {
  if (!validateDateString(endDs) || !validateDateString(startDs)) {
    return 0;
  }

  let endDsFormatted = `${endDs.substr(0, 11)}H23M59`;

  let startObj = new Date(createTimestampFromString(startDs));
  let endObj = new Date(createTimestampFromString(endDsFormatted));

  return Math.round((endObj.getTime() - startObj.getTime()) / 86400000);
};

let calcMinsBetweenTwoTimes = (start, end, breakMins) => {
  if (!start || !end || typeof start !== "number" || typeof end !== "number") {
    return 0;
  }
  if (start.length !== 5 || end.length !== 5) {
    return 0;
  }
  if (start.substr(2, 1) !== ":" || end.substr(2, 1) !== ":") {
    return 0;
  }

  let startTs = HHMMToMsToday(start);
  let endTs = HHMMToMsToday(end);

  if (endTs <= startTs) {
    let endObj = new Date(endTs);
    endObj.setDate(endObj.getDate() + 1);
    endTs = endObj.getTime();
  }

  let minutesBetween = (endTs - startTs) / 1000 / 60;
  let minutesAfterBreak =
    minutesBetween - (typeof breakMins === "number" ? breakMins : 0);

  return minutesAfterBreak;
};

let nd = (lastNum) => {
  //   if(lastNum && lastNum.length === 17) {
  // lastNum =

  const date = parseInt(lastNum);
  if (isNaN(date)) {
    return "";
  }

  // Handle special cases for 11, 12, and 13
  if (date >= 11 && date <= 13) {
    return "th";
  }

  // Determine the suffix based on the last digit
  switch (date % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

let printedDateFromDs = (ds, withYear, full, suffix, shortenYr, withoutDay) => {
  // suffix is the span classname if passed
  if (ds && validateDateString(ds)) {
    let date = parseInt(ds.substr(9, 2));
    let month = parseInt(ds.substr(6, 2));
    // let year = parseInt(ds.substr(1, 4));

    let dateObj = new Date(createTimestampFromString(ds));

    let day = "";
    if (dateObj.getDay() === 1) {
      day = full ? "Monday" : "Mon";
    }
    if (dateObj.getDay() === 2) {
      day = full ? "Tuesday" : "Tue";
    }
    if (dateObj.getDay() === 3) {
      day = full ? "Wednesday" : "Wed";
    }
    if (dateObj.getDay() === 4) {
      day = full ? "Thursday" : "Thu";
    }
    if (dateObj.getDay() === 5) {
      day = full ? "Friday" : "Fri";
    }
    if (dateObj.getDay() === 6) {
      day = full ? "Saturday" : "Sat";
    }
    if (dateObj.getDay() === 0) {
      day = full ? "Sunday" : "Sun";
    }

    let monthString = full ? "January" : "Jan";
    if (month === 1) {
      monthString = full ? "February" : "Feb";
    }
    if (month === 2) {
      monthString = full ? "March" : "Mar";
    }
    if (month === 3) {
      monthString = full ? "April" : "Apr";
    }
    if (month === 4) {
      monthString = full ? "May" : "May";
    }
    if (month === 5) {
      monthString = full ? "June" : "Jun";
    }
    if (month === 6) {
      monthString = full ? "July" : "Jul";
    }
    if (month === 7) {
      monthString = full ? "August" : "Aug";
    }
    if (month === 8) {
      monthString = full ? "September" : "Sep";
    }
    if (month === 9) {
      monthString = full ? "October" : "Oct";
    }
    if (month === 10) {
      monthString = full ? "November" : "Nov";
    }
    if (month === 11) {
      monthString = full ? "December" : "Dec";
    }
    // console.log(dateObj);
    // return dateObj;
    if (suffix) {
      return (
        <>
          {day} {date}
          <span className={suffix}>{nd(date)}</span> {monthString}{" "}
          {withYear
            ? shortenYr
              ? ` '${JSON.stringify(dateObj.getFullYear()).substr(2, 2)}`
              : ` ${dateObj.getFullYear()}`
            : ""}
        </>
        // <>{`${day}<span className={suffix}>${nd(
        //   day
        // )}</span> ${date} ${monthString}${
        //   withYear ? ` ${dateObj.getFullYear()}` : ""
        // }`}</>
      );
    } else {
      if (withoutDay) {
        return `${date} ${monthString}${
          withYear
            ? shortenYr
              ? ` '${JSON.stringify(dateObj.getFullYear()).substr(2, 2)}`
              : ` ${dateObj.getFullYear()}`
            : ""
        }`;
      }
      return `${day} ${date} ${monthString}${
        withYear
          ? shortenYr
            ? ` '${JSON.stringify(dateObj.getFullYear()).substr(2, 2)}`
            : ` ${dateObj.getFullYear()}`
          : ""
      }`;
    }
  } else {
    return "Y1970M00D01H00M00";
  }
};

let getTsFromMy = (my) => {
  // Check if the input 'my' is a valid string with 4 characters
  if (typeof my === "string" && my.length === 4) {
    // Extract the month and year from the input 'my'
    const month = parseInt(my.slice(0, 2));
    const year = parseInt(my.slice(2, 4));

    // Check if the month and year are valid
    if (!isNaN(month) && !isNaN(year)) {
      // Create a Date object starting on the first day of the specified month and year
      const date = new Date(year + 2000, month, 1);

      // Return the timestamp of the Date object
      return date.getTime();
    }
  }

  // If the input is invalid or parsing fails, return null or an error message as desired
  return 0; // You can change this to an error message if needed
};

let getMyFromTs = (ts) => {
  if (ts && typeof ts === "number") {
    let prepend0 = (val) => {
      if (val < 10) {
        return `0${val}`;
      } else {
        return val;
      }
    };
    let dateObj = new Date(ts);
    return `${prepend0(dateObj.getMonth())}${JSON.stringify(
      dateObj.getFullYear()
    ).substr(2, 2)}`;
  } else {
    return "";
  }
};
let secsToString = (seconds) => {
  if (isNaN(seconds)) {
    return "";
  }
  if (seconds <= 0) {
    return `${seconds}s`;
  }
  if (typeof seconds !== "number" || isNaN(seconds) || seconds < 0) {
    return "";
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const timeParts = [];
  if (hours > 0) {
    timeParts.push(`${hours}hr`);
  }
  if (minutes > 0) {
    timeParts.push(`${minutes}m`);
  }
  if (remainingSeconds > 0) {
    timeParts.push(`${Math.round(remainingSeconds)}s`);
  }

  if (!timeParts[0]) {
    return "0s";
  } else {
    return timeParts.join(", ");
  }
};

let hhmmFromDs = (ds) => {
  if (!validateDateString(ds)) {
    return "00:00";
  }
  if (ds && ds.length === 17) {
    return `${ds.substr(12, 2)}:${ds.substr(15, 2)}`;
  } else {
    return "00:00";
  }
};

let listify = (names, fl, firstNameOnly) => {
  // if fl is true, the array is objects (fName: james, lName: wheldon) etc
  if (!Array.isArray(names)) {
    return "";
  }
  if (fl) {
    if (firstNameOnly) {
      const firstNames = names.map((obj) => obj.fName);

      if (names[0]) {
        if (names.length === 0) {
          return "";
        } else if (names.length === 1) {
          return names[0].fName;
        } else if (names.length === 2) {
          return names[0].fName + " and " + names[1].fName;
        } else {
          const last = firstNames.pop();
          return firstNames.join(", ") + ", and " + last;
        }
      } else {
        return "";
      }
    } else {
      const fullNames = names.map((obj) => `${obj.fName} ${obj.lName}`);

      if (fullNames[0]) {
        if (fullNames.length === 0) {
          return "";
        } else if (fullNames.length === 1) {
          return fullNames[0];
        } else if (fullNames.length === 2) {
          return fullNames[0] + " and " + names[1].fName;
        } else {
          const last = fullNames.pop();
          return fullNames.join(", ") + ", and " + last;
        }
      } else {
        return "";
      }
    }
  } else {
    if (names[0]) {
      if (names.length === 0) {
        return "";
      } else if (names.length === 1) {
        return names[0];
      } else if (names.length === 2) {
        return names[0] + " and " + names[1];
      } else {
        const last = names.pop();
        return names.join(", ") + ", and " + last;
      }
    } else {
      return "";
    }
  }
};

let generateUniqueID = () => {
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  let uniqueID = "";

  // Generate the first part (8 characters)
  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    uniqueID += characters[randomIndex];
  }

  uniqueID += "-";

  // Generate the second part (4 characters)
  for (let i = 0; i < 4; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    uniqueID += characters[randomIndex];
  }

  uniqueID += "-";

  // Generate the third part (4 characters)
  for (let i = 0; i < 4; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    uniqueID += characters[randomIndex];
  }

  uniqueID += "-";

  // Generate the fourth part (4 characters)
  for (let i = 0; i < 4; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    uniqueID += characters[randomIndex];
  }

  uniqueID += "-";

  // Generate the fifth part (12 characters)
  for (let i = 0; i < 12; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    uniqueID += characters[randomIndex];
  }

  return uniqueID;
};

let sanitize = (htmlString) => {
  if (typeof htmlString !== "string") {
    return "";
  }
  // Create a new DOMParser
  const parser = new DOMParser();

  // Parse the HTML string to create a DOM document
  const doc = parser.parseFromString(htmlString, "text/html");

  // Remove <script> and <iframe> elements
  const scriptElements = doc.querySelectorAll("script");
  const iframeElements = doc.querySelectorAll("iframe");

  scriptElements.forEach((element) => {
    element.parentNode.removeChild(element);
  });

  iframeElements.forEach((element) => {
    element.parentNode.removeChild(element);
  });

  // Serialize the sanitized document back to an HTML string
  let sanitizedHTML = new XMLSerializer().serializeToString(doc);

  // Replace 'classname' with 'className'
  sanitizedHTML = sanitizedHTML.replace(/classname/g, "class");

  return sanitizedHTML;
};

let pluraliseNoun = (noun) => {
  // Check if the noun ends with -s, -ss, -sh, -ch, -x
  if (typeof noun !== "string") {
    return "";
  }
  if (
    noun.endsWith("s") ||
    noun.endsWith("ss") ||
    noun.endsWith("sh") ||
    noun.endsWith("ch") ||
    noun.endsWith("x")
  ) {
    return noun + "es";
  }

  // Check if the noun ends with -is
  if (noun.endsWith("is")) {
    // Assume that nouns ending in -is form their plural by changing -is to -es
    return noun.slice(0, -2) + "es";
  }

  // Check if the noun ends with -us
  if (noun.endsWith("us")) {
    // Assume that nouns ending in -us form their plural by changing -us to -uses
    return noun.slice(0, -2) + "uses";
  }

  // Check if the noun ends with -os
  if (noun.endsWith("os")) {
    // Assume that nouns ending in -os form their plural by changing -os to -oses
    return noun.slice(0, -2) + "oses";
  }

  // Check if the noun ends with -man
  if (noun.endsWith("man")) {
    // Assume that nouns ending in -man form their plural by changing -man to -men
    return noun.slice(0, -3) + "men";
  }

  // Check if the noun ends with -woman
  if (noun.endsWith("woman")) {
    // Assume that nouns ending in -woman form their plural by changing -woman to -women
    return noun.slice(0, -5) + "women";
  }

  if (noun.endsWith("manager")) {
    // Assume that nouns ending in -man form their plural by changing -man to -men
    return noun.slice(0, -7) + "management";
  }

  if (noun.endsWith("Manager")) {
    // Assume that nouns ending in -man form their plural by changing -man to -men
    return noun.slice(0, -7) + "Management";
  }

  // Default case: add 's' to the noun
  return noun + "s";
};

let convertAnnLeavePercentToWeeks = (percent) => {
  let val = parseFloat(percent);
  if (typeof val !== "number") {
    return 0;
  } else {
    let arr = [
      { percent: 5.051, weeks: 2.5 },
      { percent: 5.263, weeks: 2.6 },
      { percent: 5.477, weeks: 2.7 },
      { percent: 5.691, weeks: 2.8 },
      { percent: 5.906, weeks: 2.9 },
      { percent: 6.122, weeks: 3 },
      { percent: 6.339, weeks: 3.1 },
      { percent: 6.557, weeks: 3.2 },
      { percent: 6.776, weeks: 3.3 },
      { percent: 6.996, weeks: 3.4 },
      { percent: 7.216, weeks: 3.5 },
      { percent: 7.438, weeks: 3.6 },
      { percent: 7.66, weeks: 3.7 },
      { percent: 7.884, weeks: 3.8 },
      { percent: 8.108, weeks: 3.9 },
      { percent: 8.333, weeks: 4 },
      { percent: 8.559, weeks: 4.1 },
      { percent: 8.787, weeks: 4.2 },
      { percent: 9.015, weeks: 4.3 },
      { percent: 9.244, weeks: 4.4 },
      { percent: 9.474, weeks: 4.5 },
      { percent: 9.705, weeks: 4.6 },
      { percent: 9.937, weeks: 4.7 },
      { percent: 10.169, weeks: 4.8 },
      { percent: 10.403, weeks: 4.9 },
      { percent: 10.638, weeks: 5 },
      { percent: 10.874, weeks: 5.1 },
      { percent: 11.111, weeks: 5.2 },
      { percent: 11.349, weeks: 5.3 },
      { percent: 11.588, weeks: 5.4 },
      { percent: 11.828, weeks: 5.5 },
      { percent: 12.069, weeks: 5.6 },
      { percent: 12.311, weeks: 5.7 },
      { percent: 12.554, weeks: 5.8 },
      { percent: 12.798, weeks: 5.9 },
      { percent: 13.043, weeks: 6 },
      { percent: 13.29, weeks: 6.1 },
      { percent: 13.537, weeks: 6.2 },
      { percent: 13.786, weeks: 6.3 },
      { percent: 14.035, weeks: 6.4 },
      { percent: 14.286, weeks: 6.5 },
      { percent: 14.537, weeks: 6.6 },
      { percent: 14.79, weeks: 6.7 },
      { percent: 15.044, weeks: 6.8 },
      { percent: 15.299, weeks: 6.9 },
      { percent: 15.556, weeks: 7 },
      { percent: 15.813, weeks: 7.1 },
      { percent: 16.071, weeks: 7.2 },
      { percent: 16.331, weeks: 7.3 },
      { percent: 16.592, weeks: 7.4 },
      { percent: 16.854, weeks: 7.5 },
      { percent: 17.117, weeks: 7.6 },
      { percent: 17.381, weeks: 7.7 },
      { percent: 17.647, weeks: 7.8 },
      { percent: 17.914, weeks: 7.9 },
      { percent: 18.182, weeks: 8 },
      { percent: 18.451, weeks: 8.1 },
      { percent: 18.721, weeks: 8.2 },
      { percent: 18.993, weeks: 8.3 },
      { percent: 19.266, weeks: 8.4 },
      { percent: 19.54, weeks: 8.5 },
      { percent: 19.816, weeks: 8.6 },
      { percent: 20.092, weeks: 8.7 },
      { percent: 20.37, weeks: 8.8 },
      { percent: 20.65, weeks: 8.9 },
      { percent: 20.93, weeks: 9 },
      { percent: 21.212, weeks: 9.1 },
      { percent: 21.495, weeks: 9.2 },
      { percent: 21.78, weeks: 9.3 },
      { percent: 22.066, weeks: 9.4 },
      { percent: 22.353, weeks: 9.5 },
      { percent: 22.642, weeks: 9.6 },
      { percent: 22.931, weeks: 9.7 },
      { percent: 23.223, weeks: 9.8 },
      { percent: 23.515, weeks: 9.9 },
      { percent: 23.81, weeks: 10 },
      { percent: 24.105, weeks: 10.1 },
      { percent: 24.402, weeks: 10.2 },
      { percent: 24.7, weeks: 10.3 },
      { percent: 25.0, weeks: 10.4 },
      { percent: 25.301, weeks: 10.5 },
      { percent: 25.604, weeks: 10.6 },
      { percent: 25.908, weeks: 10.7 },
      { percent: 26.214, weeks: 10.8 },
      { percent: 26.521, weeks: 10.9 },
      { percent: 26.829, weeks: 11 },
      { percent: 27.139, weeks: 11.1 },
      { percent: 27.451, weeks: 11.2 },
      { percent: 27.764, weeks: 11.3 },
      { percent: 28.079, weeks: 11.4 },
      { percent: 28.395, weeks: 11.5 },
      { percent: 28.713, weeks: 11.6 },
      { percent: 29.032, weeks: 11.7 },
      { percent: 29.353, weeks: 11.8 },
      { percent: 29.676, weeks: 11.9 },
      { percent: 30, weeks: 12 },
      { percent: 30.326, weeks: 12.1 },
      { percent: 30.653, weeks: 12.2 },
      { percent: 30.982, weeks: 12.3 },
      { percent: 31.313, weeks: 12.4 },
      { percent: 31.646, weeks: 12.5 },
      { percent: 31.98, weeks: 12.6 },
      { percent: 32.316, weeks: 12.7 },
      { percent: 32.653, weeks: 12.8 },
      { percent: 32.992, weeks: 12.9 },
      { percent: 33.333, weeks: 13 },
      { percent: 33.676, weeks: 13.1 },
      { percent: 34.021, weeks: 13.2 },
      { percent: 34.367, weeks: 13.3 },
    ];

    let arrFilt = arr.filter((x) => {
      return x.percent === val;
    });

    if (arrFilt[0]) {
      return arrFilt[0].weeks;
    }
  }
};

let getExt = (fileName) => {
  if (typeof fileName !== "string" || !fileName) {
    return "";
  }
  if (fileName) {
    const lastDotIndex = fileName.lastIndexOf(".");
    if (lastDotIndex !== -1) {
      return fileName.substring(lastDotIndex);
    }
    return ""; // Return empty string if there's no dot in the fileName
  }
};

let removeTsFromFileName = (fileName) => {
  // Find the index of the last underscore
  if (typeof fileName !== "string" || !fileName) {
    return "";
  }
  if (fileName) {
    const lastIndex = fileName.lastIndexOf("_");

    // If underscore is found, remove it along with the following digits
    if (lastIndex !== -1) {
      return fileName.substring(0, lastIndex);
    } else {
      // If no underscore found, return the original string
      return fileName;
    }
  } else return "";
};

let bytes = (bytes) => {
  if (typeof bytes === "number") {
    if (bytes < 1000) {
      return bytes + " bytes";
    } else if (bytes < 1000000) {
      return (bytes / 1000).toFixed(2) + " KB";
    } else {
      return (bytes / 1000000).toFixed(2) + " MB";
    }
  } else {
    return "Unknown size";
  }
};

let getThreeDayLettersFromDs = (ds, getFull) => {
  if (!validateDateString(ds)) {
    return "Y1970M00D01H00M00";
  } else {
    let dateObj = new Date(createTimestampFromString(ds));
    if (dateObj.getDay() === 0) {
      return getFull ? "Sunday" : "Sun";
    }
    if (dateObj.getDay() === 1) {
      return getFull ? "Monday" : "Mon";
    }
    if (dateObj.getDay() === 2) {
      return getFull ? "Tuesday" : "Tue";
    }
    if (dateObj.getDay() === 3) {
      return getFull ? "Wednesday" : "Wed";
    }
    if (dateObj.getDay() === 4) {
      return getFull ? "Thursday" : "Thu";
    }
    if (dateObj.getDay() === 5) {
      return getFull ? "Friday" : "Fri";
    }
    if (dateObj.getDay() === 6) {
      return getFull ? "Saturday" : "Sat";
    }
  }
};

let convertRotaDsIndexToDayNum = (ind) => {
  if (typeof ind !== "number") {
    return 0;
  }
  if (ind === 0) {
    return 1;
  }
  if (ind === 1) {
    return 2;
  }
  if (ind === 2) {
    return 3;
  }
  if (ind === 3) {
    return 4;
  }
  if (ind === 4) {
    return 5;
  }
  if (ind === 5) {
    return 6;
  }
  if (ind === 6) {
    return 0;
  }
};

let roundToNearestHalf = (num) => {
  if (typeof num !== "number") {
    return 0;
  }
  // Round the number to the nearest 0.5
  const rounded = Math.round(num * 2) / 2;

  // If the rounded number is an integer, return it
  if (Number.isInteger(rounded)) {
    return rounded;
  } else {
    // If the rounded number has decimal 0.5, return it
    if (rounded - Math.floor(rounded) === 0.5) {
      return rounded;
    } else {
      // Otherwise, return the whole integer part of the rounded number
      return Math.floor(rounded);
    }
  }
};

let cleanParam = (str) => {
  if (typeof str === "boolean") {
    return str;
  }
  if (str && typeof str !== "string") {
    return str;
  }
  if (str && typeof str === "string") {
    return str.replace(/\?utm_source=brevo/g, "");
  } else {
    return "";
  }
};

let getMonthStartAndEnd = (ts) => {
  if (!ts || typeof ts !== "number") {
    return false;
  }
  let now = new Date(ts);
  now.setDate(1);
  now.setHours(0, 0, 0, 0);
  let now2 = new Date(ts);

  // Move to the first day of the next month
  let nextMonth = new Date(now2.getFullYear(), now2.getMonth() + 1, 1);

  // Move one day back to get the last day of the current month
  let lastDayOfCurrentMonth = new Date(nextMonth - 1);

  // Set the time to 01:00 AM
  lastDayOfCurrentMonth.setHours(0, 0, 0, 0);

  return {
    firstDayTs: now.getTime(),
    lastDayTs: lastDayOfCurrentMonth.getTime(),
  };
};

let formatCost = (cost) => {
  const absCost = Math.abs(cost);
  function removeTrailingZeroes(str) {
    if (str.endsWith(".00")) {
      return str.slice(0, -3);
    }
    return str;
  }

  return absCost % 1 === 0
    ? removeTrailingZeroes(absCost.toString())
    : removeTrailingZeroes(absCost.toFixed(2));
};

let nowDs = () => {
  return createStringFromTimestamp(new Date().getTime());
};

let validateDs = (ds) => {
  if (!ds) {
    return false;
  } else if (typeof ds !== "string") {
    return false;
  } else if (typeof ds === "string" && ds.length !== 17) {
    return false;
  } else if (
    ds[0] !== "Y" ||
    ds[5] !== "M" ||
    ds[8] !== "D" ||
    ds[11] !== "H" ||
    ds[14] !== "M"
  ) {
    return false;
  } else {
    let yr = parseInt(`${ds.substr(1, 4)}`);
    let m = parseInt(`${ds.substr(6, 2)}`);
    let d = parseInt(`${ds.substr(9, 2)}`);

    if (isNaN(yr) || isNaN(m) || isNaN(d)) {
      return false;
    }

    if (yr < 1900 || yr > 2100) {
      return false;
    }

    if (m < 0 || m > 11) {
      return false;
    }

    if (d < 0 || d > 31) {
      return false;
    }

    if (m === 1 && d > 29) {
      return false;
    }

    if (m === 3 && d > 30) {
      return false;
    }

    if (m === 5 && d > 30) {
      return false;
    }

    if (m === 8 && d > 30) {
      return false;
    }
    if (m === 10 && d > 30) {
      return false;
    }

    return true;
  }
};
export default {
  validateDs: validateDs,
  nowDs: nowDs,
  formatCost: formatCost,
  getMonthStartAndEnd: getMonthStartAndEnd,
  cleanParam: cleanParam,
  roundToNearestHalf: roundToNearestHalf,
  convertRotaDsIndexToDayNum: convertRotaDsIndexToDayNum,
  getThreeDayLettersFromDs: getThreeDayLettersFromDs,
  getExt: getExt,
  bytes: bytes,
  removeTsFromFileName: removeTsFromFileName,
  convertAnnLeavePercentToWeeks: convertAnnLeavePercentToWeeks,
  pluraliseNoun: pluraliseNoun,
  sanitize: sanitize,
  generateUniqueID: generateUniqueID,
  listify: listify,
  hhmmFromDs: hhmmFromDs,
  secsToString: secsToString,
  getMyFromTs: getMyFromTs,
  getTsFromMy: getTsFromMy,
  nd: nd,
  calcMinsBetweenTwoTimes: calcMinsBetweenTwoTimes,
  calcDaysBetweenTwoDs: calcDaysBetweenTwoDs,
  nth: nth,
  list: list,
  shorten: shorten,
  createStringFromTimestamp: createStringFromTimestamp,
  createTimestampFromString: createTimestampFromString,
  createStringFromDateInput: createStringFromDateInput,
  createDateInputFromDateString: createDateInputFromDateString,
  createShortStripFromDateString: createShortStripFromDateString,
  getWeekDs: getWeekDs,
  tsToHHMM: tsToHHMM,
  HHMMToMsToday: HHMMToMsToday,
  printedDateFromDs: printedDateFromDs,
  formatMinsDurationToHours: formatMinsDurationToHours,
  createDatePeriod: createDatePeriod,
  printedDateWithYrFromDs: printedDateWithYrFromDs,
  printedDayWithYrFromTs: printedDayWithYrFromTs,
  nameInitials: nameInitials,
  generateTilShiftString: generateTilShiftString,
  tsToDayAndDate: tsToDayAndDate,
  convertMinsToDays: convertMinsToDays,
  convertDayNumberToDayString: convertDayNumberToDayString,
  convertDayNumberToDayStringShort: convertDayNumberToDayStringShort,
  printedDateWithTimeFromDs: printedDateWithTimeFromDs,
  dsToTimeStrip: dsToTimeStrip,
  printedDateWithYrFromDWithoutDay: printedDateWithYrFromDWithoutDay,
  formatMinsDurationToShortHours: formatMinsDurationToShortHours,
  getCurrMonWeek: getCurrMonWeek,
  convertDsToDateInput: convertDsToDateInput,
  printMonthFromMy: printMonthFromMy,
  getMonthThreeLettersFromDs: getMonthThreeLettersFromDs,
  nameFormat: nameFormat,
  getMonWeekOfDs: getMonWeekOfDs,
  dateAndMonth: dateAndMonth,
  printMonthAndYearFromMy: printMonthAndYearFromMy,
  calcMinsBetweenTwoDateStrings: calcMinsBetweenTwoDateStrings,
  convertMetresToKm: convertMetresToKm,
  possession: possession,
  initial: initial,
  printMonthAndYearFromDs: printMonthAndYearFromDs,
  createDateInputFromTimestamp: createDateInputFromTimestamp,
};

// default export {
//   createStringFromTimestamp,
//   createTimestampFromString,
//   createStringFromDateInput,
//   createDateInputFromDateString,
//   createShortStripFromDateString,
//   getWeekDs,
//   tsToHHMM,
//   HHMMToMsToday,
//   printedDateFromDs,
//   formatMinsDurationToHours,
//   createDatePeriod,
//   printedDateWithYrFromDs,
//   printedDayWithYrFromTs,
//   nameInitials,
//   generateTilShiftString,
//   tsToDayAndDate,
//   convertMinsToDays,
//   convertDayNumberToDayString,
//   convertDayNumberToDayStringShort,
//   printedDateWithTimeFromDs,
//   dsToTimeStrip,
//   printedDateWithYrFromDWithoutDay,
//   formatMinsDurationToShortHours,
//   getCurrMonWeek,
//   convertDsToDateInput,
//   printMonthFromMy,
//   getMonthThreeLettersFromDs,
//   nameFormat,
//   getMonWeekOfDs,
//   dateAndMonth,
//   printMonthAndYearFromMy,
//   calcMinsBetweenTwoDateStrings,
//   convertMetresToKm,
//   possession,
//   initial,
// };
