import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  Fragment,
} from "react";
// clean ups done in use effects 22 July 2023
// params cleared up 10 sep 23
// images preloaded 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import "../../styles/leaveRequests.css";
import { NavLink } from "react-router-dom";

import dateStringer from "../../tools/dateStringer";

import CheckUser from "../../tools/CheckUser";

import { DataContext } from "../../contexts/DataContext";
import { UserContext } from "../../contexts/UserContext";
import { RequestContext } from "../../contexts/RequestContext";
import { CalendarContext } from "../../contexts/CalendarContext";
import { StyleContext } from "../../contexts/StyleContext";

import horizontalBalls from "../../img/loaders/horizontalBalls.svg";

import whiteAddThin from "../../img/general/whiteAddThin.svg";

import Navbar from "../Navbar";
import serverURL from "../../serverURL";

import whiteGo from "../../img/general/whiteGo.svg";
import popup from "../../img/general/popup.svg";
import absentShiftIcon from "../../img/general/absentShiftIcon.svg";
import greenTick from "../../img/general/greenTick.svg";
import darkRedCross from "../../img/general/darkRedCross.svg";
import close from "../../img/general/navyClose.svg";
import magni from "../../img/general/magniWhite.svg";
import popup3 from "../../img/general/popup3.svg";
import bulkTick from "../../img/general/bulkTick.svg";
import blueLoader from "../../img/general/blueLoader.gif";
import whitecross from "../../img/general/whitecross.svg";
import filterWhite from "../../img/general/filterWhite.svg";
import checkboxInstall1 from "../../img/general/checkboxInstall1.svg";
import checkboxInstall2 from "../../img/general/checkboxInstall2.svg";
import commentRota from "../../img/general/commentRota.svg";
import downArr from "../../img/general/downArr.svg";
import arrGrey from "../../img/general/arrGrey.svg";
import plane2 from "../../img/general/plane2.svg";
import plane from "../../img/general/plane.svg";
import doubleTick from "../../img/general/doubleTick.svg";
import lightBlueQuestionMark from "../../img/general/lightBlueQuestionMark.svg";
import teamIcon from "../../img/general/teamIcon2.svg";

import CalendarSubBar from "../calendar/CalendarSubBar";
import MyReqsDataBox from "../requests/MyReqsDataBox";
import TopBar from "../../components/TopBar";
import TeamCalendarInlet from "./TeamCalendarInlet";

const Calendar = ({
  showLimitChecker,
  showViewColleague,
  teamCal,
  messages,
}) => {
  CheckUser(false, false, "Calendar.js");
  const {
    setShowUserReqFromNav, //
    setSeeUserID, //
    setShowMyShiftModal, //
    setPage, //
    calendarPage, //
    setCalendarPage, //
    setShowDayCommentModal, //
    device, //
    setShowAddAvailModal, //
    updateCalAvail, //
    setShowUserOpenShiftModal, //
    setShowMessages, //
    goToLimits, //
    acceptedShiftID, //
    setAcceptedShiftID, //
    declinedShiftID, //
    setDeclinedShiftID, //
    selectedDesktop, //
    setSelectedDesktop, //
    setShowClockOnModal,
  } = useContext(DataContext);
  const { setCalPage } = useContext(CalendarContext);
  const { setShowReqModal } = useContext(RequestContext);
  const { mob, full, width, mobModal, fullButNarrow, wide } =
    useContext(StyleContext);
  const { checkUserState, currencySymbol } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      setShowUserReqFromNav,
      setSeeUserID,
      setShowMyShiftModal,
      setPage,
      calendarPage,
      setCalendarPage,
      setShowDayCommentModal,
      device,
      setShowAddAvailModal,
      updateCalAvail,
      setShowUserOpenShiftModal,
      setShowMessages,
      goToLimits,
      acceptedShiftID,
      setAcceptedShiftID,
      declinedShiftID,
      setDeclinedShiftID,
      selectedDesktop,
      setSelectedDesktop, //
      setCalPage, //
      setShowReqModal, //
      mob, //
      full, //
      width, //
      mobModal, //
      fullButNarrow, //
      wide, //
      checkUserState,
      currencySymbol,
      setShowClockOnModal,
    }),
    [
      checkUserState,
      setShowUserReqFromNav,
      setSeeUserID,
      setShowMyShiftModal,
      setPage,
      calendarPage,
      setCalendarPage,
      setShowDayCommentModal,
      device,
      setShowAddAvailModal,
      updateCalAvail,
      setShowUserOpenShiftModal,
      setShowMessages,
      goToLimits,
      acceptedShiftID,
      setAcceptedShiftID,
      declinedShiftID,
      setDeclinedShiftID,
      selectedDesktop,
      setSelectedDesktop, //
      setCalPage, //
      setShowReqModal, //
      mob, //
      full, //
      width, //
      mobModal, //
      fullButNarrow, //
      wide, //
      currencySymbol,
      setShowClockOnModal,
    ]
  );

  let [loading, setLoading] = useState(showViewColleague ? false : true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [clockedOnShiftID, setClockedOnShiftID] = useState("");

  useEffect(() => {
    memoVals.setSelectedDesktop((x) => "calendar");
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (
      params &&
      dateStringer.cleanParam(params.userID) &&
      window.location.href.includes("messages")
    ) {
      setShowMessages(dateStringer.cleanParam(params.userID));

      window.history.pushState({}, document.title, window.location.pathname);
    }
    if (params && params.clock) {
      memoVals.setShowClockOnModal((x) => true);
      window.history.pushState({}, document.title, window.location.pathname);
    }

    if (params && params.comments) {
      memoVals.setShowDayCommentModal((x) => {
        return {
          show: true,
          isMgr: mgrOf.includes(dateStringer.cleanParam(params.teamID)),
          teamID: dateStringer.cleanParam(params.teamID),
          ds: dateStringer.cleanParam(params.ds),
        };
      });
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  useEffect(() => {
    console.log({ dataLoaded, imagesLoaded });
    if (dataLoaded && imagesLoaded === 21) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      {/* Previously added images */}
      {/* ... */}

      {/* Additional images */}
      <img
        src={lightBlueQuestionMark}
        alt="White Go"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteGo}
        alt="White Go"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={doubleTick}
        alt="White Go"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup}
        alt="Popup"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={absentShiftIcon}
        alt="Absent Shift Icon"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greenTick}
        alt="Green Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={darkRedCross}
        alt="Dark Red Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={magni}
        alt="Magni White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup3}
        alt="Popup 3"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={bulkTick}
        alt="Bulk Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={blueLoader}
        alt="Blue Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitecross}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={filterWhite}
        alt="Filter White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkboxInstall1}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkboxInstall2}
        alt="Checkbox Install 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={commentRota}
        alt="Comment Rota"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={downArr}
        alt="Down Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={arrGrey}
        alt="Arrow Grey"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={plane2}
        alt="Plane 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={plane}
        alt="Plane"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [months, setMonths] = useState([]);
  let [showDayComments, setShowDayComments] = useState([]);

  let [limitsArr, setLimitsArr] = useState([]);
  let [exceedsNoLimits, setExceedsNoLimits] = useState(false);
  let [showLimitsModal, setShowLimitsModal] = useState(false);
  let [showFilter, setShowFilter] = useState(false);

  const targetRef = useRef(null);
  const containerRef = useRef(null);
  let nullRef = useRef();
  document.title = "FlowRota - My calendar";

  const handleScroll = () => {
    if (containerRef.current && targetRef.current) {
      const scrollTop = containerRef.current.scrollTop;
      const targetOffset = targetRef.current.offsetTop;
      const position = targetOffset - scrollTop;
      // containerRef.current.scrollTop =
      //   position - (memoVals.calendarPage === "myCal" ? 80 : 130);

      containerRef.current.scrollTo({
        top: position - (memoVals.calendarPage === "myCal" ? 80 : 130),
        behavior: "smooth", // Smooth scroll behavior
      });
    }
  };

  let [showDay, setShowDay] = useState({
    show: false,
    items: [],
  });

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0, // Scroll to the top of the div
        behavior: "smooth", // Smooth scroll behavior
      });
    }
  }, [memoVals.calendarPage]);

  let [showShifts, setShowShifts] = useState(true);
  let [showTils, setShowTils] = useState(false);
  let [showOvertimes, setShowOvertimes] = useState(false);
  let [showReqs, setShowReqs] = useState(true);
  let [showAvail, setShowAvail] = useState(true);
  let [showAbsences, setShowAbsences] = useState(false);
  let [myCalMonthsArr, setMyCalMonthsArr] = useState([]); // {my, ds, string}

  useEffect(() => {
    // cleaning not needed
    if (memoVals.calendarPage === "myCal") {
      setShowReqs(true);
      setShowOvertimes(true);
      setShowTils(true);
      setShowShifts(true);
      setShowAbsences(true);
    }
  }, [memoVals.calendarPage]);

  let [selectedMyCalDs, setSelectedMyCalDs] = useState("");
  let [myTeamTeams, setMyTeamTeams] = useState([]);
  let [selectedMyTeam, setSelectedMyTeam] = useState("");
  let [collCalWorkingToday, setCollCalWorkingToday] = useState(false);
  let [currMonthDs, setCurrMonthDs] = useState("");

  let [showOpenDs, setShowOpenDs] = useState([]); // array of ds to show opens

  useEffect(() => {
    // cleaning not needed

    if (!selectedMyCalDs) {
      let dateObj = new Date();
      dateObj.setDate(1);
      dateObj.setHours(0, 0, 0, 0);
      let ds = dateStringer.createStringFromTimestamp(dateObj.getTime());
      setSelectedMyCalDs(ds);
      setCurrMonthDs(ds);
    }
    if (!loading) {
      handleScroll();
    }
  }, [loading, months]);

  useEffect(() => {
    // cleaning not needed

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && dateStringer.cleanParam(params.reqID)) {
      memoVals.setShowUserReqFromNav(
        `*${dateStringer.cleanParam(params.reqID)}`
      );
      // clear the params
      window.history.pushState({}, document.title, window.location.pathname);
    }

    if (messages && params && dateStringer.cleanParam(params.user)) {
      memoVals.setShowMessages(
        (x) => `${dateStringer.cleanParam(params.user)}`
      );
      // clear the params
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  let [selectedYear, setSelectedYear] = useState(
    memoVals.checkUserState.currClientYear
  );
  let [yearsArr, setYearsArr] = useState([selectedYear]);

  let [showNewReqBtn, setShowNewReqBtn] = useState(false);
  let todayMidnightDateObj = new Date();
  todayMidnightDateObj.setHours(0, 0, 0, 0);

  useEffect(() => {
    // cleaning not needed

    if (showDay.ds) {
      let today00 = new Date();
      today00.setHours(0, 0, 0, 0);
      if (
        dateStringer.createTimestampFromString(showDay.ds) >=
          today00.getTime() &&
        memoVals.checkUserState.userID === selectedUserID
      ) {
        setShowNewReqBtn(true);
      } else {
        setShowNewReqBtn(false);
      }
    }
  }, [showDay]);

  let [startDateInput, setStartDateInput] = useState(
    dateStringer.createDateInputFromDateString(
      dateStringer.createStringFromTimestamp(new Date().getTime())
    )
  );
  let [endDateInput, setEndDateInput] = useState(
    dateStringer.createDateInputFromDateString(
      dateStringer.createStringFromTimestamp(new Date().getTime())
    )
  );

  // Define the event handler function
  const handleLeaveCheckStartInputFocus = (evt) => {
    try {
      if (evt.target.getAttribute("type") === "date") {
        evt.target.showPicker();
      }
    } catch (error) {
      console.error("Error in handleLeaveCheckStartInputFocus:", error);
    }
  };

  // UseEffect for leaveCheckStartInput
  useEffect(() => {
    let leaveCheckStartInput;
    try {
      leaveCheckStartInput = document.getElementById("leaveCheckStartInput");

      if (leaveCheckStartInput && !memoVals.mob) {
        leaveCheckStartInput.addEventListener(
          "focus",
          handleLeaveCheckStartInputFocus
        );
      }
    } catch (error) {
      console.error("Error in setting up leaveCheckStartInput:", error);
    }

    return () => {
      try {
        if (leaveCheckStartInput) {
          leaveCheckStartInput.removeEventListener(
            "focus",
            handleLeaveCheckStartInputFocus
          );
        }
      } catch (error) {
        console.error(
          "Error in removing event listener for leaveCheckStartInput:",
          error
        );
      }
    };
  }, [memoVals.calendarPage]);

  // UseEffect for leaveCheckEndInput
  useEffect(() => {
    let leaveCheckEndInput;
    try {
      leaveCheckEndInput = document.getElementById("leaveCheckEndInput");

      if (leaveCheckEndInput && !memoVals.mob) {
        leaveCheckEndInput.addEventListener(
          "focus",
          handleLeaveCheckStartInputFocus
        );
      }
    } catch (error) {
      console.error("Error in setting up leaveCheckEndInput:", error);
    }

    return () => {
      try {
        if (leaveCheckEndInput) {
          leaveCheckEndInput.removeEventListener(
            "focus",
            handleLeaveCheckStartInputFocus
          );
        }
      } catch (error) {
        console.error(
          "Error in removing event listener for leaveCheckEndInput:",
          error
        );
      }
    };
  }, [memoVals.calendarPage]);

  let [showSideModal, setShowSideModal] = useState(false);
  let [allUsers, setAllUsers] = useState([]);
  let [selUserTeamName, setSelUserTeamName] = useState("");
  let [selectedUserID, setSelectedUserID] = useState("");
  let [selectedUserFName, setSelectedUserFName] = useState("");

  // For team calendar only:
  let [selectedTeam, setSelectedTeam] = useState("");
  let nowObj = new Date();
  let [selectedMy, setSelectedMy] = useState(
    `${nowObj.getMonth() < 10 ? "0" : ""}${nowObj.getMonth()}${JSON.stringify(
      nowObj.getFullYear()
    ).substr(2, 2)}`
  );

  let [currMy, setCurrMy] = useState(
    `${nowObj.getMonth() < 10 ? "0" : ""}${nowObj.getMonth()}${JSON.stringify(
      nowObj.getFullYear()
    ).substr(2, 2)}`
  );
  let [myArr, setMyArr] = useState([]);
  let [showClosedDay, setShowClosedDay] = useState("");

  let generateMys = myArr.map((my) => {
    return (
      <option value={my} key={my}>
        {dateStringer.printMonthAndYearFromMy(
          my,
          window.innerWidth > 440 &&
            (window.innerWidth < 1000 || window.innerWidth > 1090)
            ? true
            : false,
          window.innerWidth > 440 &&
            (window.innerWidth < 1000 || window.innerWidth > 1090)
            ? true
            : false
        )}
      </option>
    );
  });

  // -  - - - - - - - - - -  end

  let [myCalArr, setMyCalArr] = useState([]);
  let formatTag = (tag) => {
    if (tag && tag.length > 11) {
      return `${tag.substr(0, 10)}...`;
    } else return tag;
  };

  let generateMyCal = useMemo(() => {
    return myCalArr.map((ds, i) => {
      let dateObj = new Date(ds.ts);
      let availFilt = ds.items.filter((item) => {
        return item.type === "avail";
      });

      let nonAvailFilt = ds.items.filter((item) => {
        return (
          item.type !== "avail" &&
          item.type !== "specialDay" &&
          item.type !== "publicHol"
        );
      });

      let noItems = nonAvailFilt.length === 0;

      let opensFilt = ds.items.filter((item) => {
        return item.type === "open";
      });
      let openShiftsQty = opensFilt.length;

      let noAvailProvided = !availFilt[0];

      let blank = false;
      let nonAvailItems = !showAvail
        ? []
        : ds.items.filter((x) => {
            return x.type !== "avail";
          });
      if (ds.closed && !nonAvailItems[0]) {
        let shiftsFilt = ds.items.filter((x) => {
          return x.type === "shift";
        });
        let overtimesFilt = ds.items.filter((x) => {
          return x.type === "overtime";
        });
        let tilsFilt = ds.items.filter((x) => {
          return x.type === "til";
        });
        let reqsFilt = ds.items.filter((x) => {
          return x.type === "leave";
        });
        let absFilt = ds.items.filter((x) => {
          return x.type === "absence";
        });
        if (
          !absFilt[0] &&
          !reqsFilt[0] &&
          !overtimesFilt[0] &&
          !tilsFilt[0] &&
          !shiftsFilt[0]
        ) {
          blank = true;
        }
      }

      let claimed = false;
      ds.items.forEach((item) => {
        if (item.type === "open" && item.claimed) {
          claimed = true;
        }
      });

      let isPholSpecDay = false;
      ds.items.forEach((item) => {
        if (item.type === "publicHol" || item.type === "specialDay") {
          isPholSpecDay = true;
        }
      });

      let generateSpecPHols = ds.items
        .filter((x) => {
          return x.type === "publicHol" || x.type === "specialDay";
        })
        .map((xx) => {
          return (
            <div
              className={`myCalItemAvailRow specPHolRow ${
                memoVals.mob ? "noBorder" : ""
              }`}
            >
              {/* <div className="noAvailSubmitted"> */}
              <div className="availItemIllus fontSize12">{xx.title}</div>
              {/* <p></p> */}
              {/* </div> */}
            </div>
          );
        });

      return (
        <div className={`myCalItem ${blank ? "myCalDsClosed_" : ""}`}>
          <div
            className={`myCalItemDs ${blank ? "myCalDsClosed" : ""} ${
              i === 0 ? "myCalItemDsFirst" : ""
            }`}
          >
            {ds.today ? (
              <NavLink to="/rota?today=true">
                <p className="calDsTodayTxt">Today</p>{" "}
              </NavLink>
            ) : (
              ""
            )}
            <p className={`myCalItemDsDay ${ds.today ? "colour00aaff" : ""}`}>
              {dateStringer.convertDayNumberToDayStringShort(dateObj.getDay())}
            </p>
            <p className={`myCalItemDsNum ${ds.today ? "colour00aaff" : ""}`}>
              {parseInt(ds.ds.substr(9, 2))}
            </p>

            {ds.comments > 0 ? (
              <p
                className="commentsCal"
                onClick={() => {
                  if (ds.commentObjects.length === 1) {
                    memoVals.setShowDayCommentModal((x) => {
                      return {
                        show: true,
                        isMgr: mgrOf.includes(ds.commentObjects[0].teamID),
                        teamID: ds.commentObjects[0].teamID,
                        ds: ds.commentObjects[0].ds,
                      };
                    });
                  } else {
                    setShowDayComments(ds.commentObjects);
                  }
                }}
              >
                <img
                  src={commentRota}
                  alt="Day comments"
                  className="calDayCommentImg"
                />{" "}
                {/* {ds.comments === 1 ? "" : "x"}
                {ds.comments > 1 ? ds.comments : ""} */}
              </p>
            ) : (
              ""
            )}
          </div>
          <div className={`myCalItemList`}>
            {ds.payDay && (
              <div
                className={`myCalItemAvailRow ${
                  memoVals.mob ? "noBorder" : ""
                } x24982498231`}
              >
                <div className="noAvailSubmitted x3289284922">
                  <p className="availItemIllus calPayDayTxt">
                    <span className="payDayCurrSpan">
                      {memoVals.currencySymbol}
                    </span>
                    Pay day
                  </p>
                </div>
              </div>
            )}
            {ds.highlightedComments && ds.highlightedComments[0] ? (
              <div className="highlightedCommRow">
                {ds.highlightedComments.map((c, i2) => {
                  return (
                    <div
                      className={`highlightComCalRow ${
                        i2 !== 0 ? "x28181132" : ""
                      } ${i === 0 ? "marginTop0" : ""}`}
                      onClick={() => {
                        memoVals.setShowDayCommentModal((x) => {
                          return {
                            show: true,
                            isMgr: mgrOf.includes(c.teamID),
                            teamID: c.teamID,
                            ds: c.ds,
                          };
                        });
                      }}
                    >
                      <p className="highCalExclaim">!</p>
                      <div className="highlightCalComRowCentre">
                        <div className="highlightRow1">
                          {c.fName} {c.lName[0]} in {c.teamName}:
                        </div>
                        <div className="highlightRow2">{c.comment}</div>
                      </div>
                      <p className="highCalExclaim">!</p>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            {isPholSpecDay ? generateSpecPHols : ""}
            {noAvailProvided && showAvail ? (
              <div
                className={`myCalItemAvailRow ${
                  memoVals.mob ? "noBorder" : ""
                }`}
                onClick={() => {
                  let today = new Date(ds.ts);
                  today.setHours(0, 0, 0, 0);
                  memoVals.setShowAddAvailModal((x) => {
                    return {
                      monWeek: dateStringer.getMonWeekOfDs(
                        dateStringer.createStringFromTimestamp(today.getTime())
                      ),
                      loadTeamID: "loadPRIMARY",
                      openedFromCal: true,
                    };
                  });
                }}
              >
                <div className="noAvailSubmitted">
                  <div className="availItemIllus">No availabilty submitted</div>
                  <p></p>
                </div>
              </div>
            ) : (
              ds.items.map((item) => {
                if (showAvail) {
                  if (item.type === "avail") {
                    return (
                      <div
                        className={`myCalItemAvailRow ${
                          blank && item.unavailable ? "greyScale" : ""
                        } ${memoVals.mob ? "noBorder" : ""}`}
                        onClick={() => {
                          memoVals.setShowAddAvailModal((x) => {
                            return {
                              monWeek: item.monWeek,
                              loadTeamID: item.teamID,
                              openedFromCal: true,
                            };
                          });
                        }}
                      >
                        <div
                          className={
                            item.unavailable ? "unavailItemCal" : "availItemCal"
                          }
                        >
                          <div className="availItemIllus">
                            {" "}
                            <img
                              src={item.unavailable ? darkRedCross : greenTick}
                              className="myCalAvailItemImg"
                              alt="Availability"
                            />{" "}
                            {item.unavailable ? (
                              <p className="myCalUnvailTxt">
                                Unavailable to{" "}
                                {dateStringer.shorten(item.teamName, 34)}
                              </p>
                            ) : (
                              <p className="myCalUnvailTxt">
                                Available to{" "}
                                {dateStringer.shorten(item.teamName, 34)}
                              </p>
                            )}
                          </div>
                          <div className="availItemTimeStrip">
                            {!item.unavailable && item.startDs1 ? (
                              <p className="availItemTimeStripRow">
                                {item.startDs1} - {item.endDs1}
                              </p>
                            ) : (
                              ""
                            )}
                            {!item.unavailable && item.startDs2 ? (
                              <p className="availItemTimeStripRow">
                                & {item.startDs2} - {item.endDs2}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                }
              })
            )}
            {openShiftsQty > 0 ? (
              <div
                className={`myCalOpensAvailBtn ${
                  memoVals.mob ? "myCalOpensAvailBtnMob" : ""
                }`}
                onClick={() => {
                  if (!showOpenDs.includes(ds.ds)) {
                    setShowOpenDs([...showOpenDs, ds.ds]);
                  } else {
                    let newArr = [];
                    showOpenDs.forEach((d) => {
                      if (d !== ds.ds) {
                        newArr.push(d);
                      }
                    });
                    setShowOpenDs(newArr);
                  }
                }}
              >
                <div className="myCalOpenDiv">
                  <div
                    className={`myCalOpenLight ${
                      claimed ? "claimedLight2" : ""
                    }`}
                  ></div>
                  <p className="myCalOpenTxtLeft">
                    {openShiftsQty} open shift{openShiftsQty === 1 ? "" : "s"}{" "}
                    available
                  </p>
                </div>
                <img
                  src={downArr}
                  alt="Open shifts"
                  className={`openDownToggle ${
                    !showOpenDs.includes(ds.ds) ? "rotateDownArr" : ""
                  }`}
                />
              </div>
            ) : (
              ""
            )}
            {showOpenDs.includes(ds.ds) ? (
              <div className="myCalOpensArr">
                {ds.items.map((item) => {
                  if (item.type === "open") {
                    return (
                      <div
                        className={`myCalItemAvailRow itemBoxShadow openCalItem ${
                          memoVals.mob ? "noBorder" : ""
                        }`}
                        onClick={() => {
                          // open open modal here
                          memoVals.setShowUserOpenShiftModal(item.openID);
                        }}
                      >
                        <div className="myCalShiftInner">
                          <div className="availItemShiftLeft">
                            <div
                              className={`myCalColourLightGreen  ${
                                item.ghost ? "ghostColourLight" : ""
                              } orangeBg ${
                                item.claimed ? "claimedLight2" : ""
                              }`}
                              onClick={() => {
                                console.log({ item });
                              }}
                            ></div>

                            <div
                              className={`myCalShiftTypeAndTags ${
                                item.tags && item.tags[0] ? "marginLeft5" : ""
                              }`}
                            >
                              {" "}
                              <p className="myCalShiftTitle">
                                Open{" "}
                                {item.openType === "til"
                                  ? "overtime (in lieu)"
                                  : item.openType}
                                {item.notes && item.notes[0] ? (
                                  <img
                                    src={commentRota}
                                    alt="Comments"
                                    className="myCalItemNote"
                                  />
                                ) : (
                                  ""
                                )}
                              </p>
                              {item.tags && item.tags[0] ? (
                                <div className="calItemTagsContainer x197378124">
                                  <p
                                    className={`${
                                      item.tags && item.tags.length > 0
                                        ? "pTagTxt marginLeft0"
                                        : "none"
                                    }`}
                                  >
                                    {formatTag(item.tags[0])}
                                  </p>
                                  <p
                                    className={`${
                                      item.tags.length > 1
                                        ? "severalTagsTxt"
                                        : "none"
                                    }`}
                                  >
                                    +{item.tags.length - 1}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="availItemShiftRight">
                            {/* <p className="availItemShiftTimesTeamName">
                          {item.teamName}
                        </p> */}
                            {item.claimed ? (
                              <p className="openClaimed">Awaiting decision</p>
                            ) : (
                              ""
                            )}
                            <p className="availItemShiftTimesTop">
                              {dateStringer.dsToTimeStrip(item.startDs, true)} -{" "}
                              {dateStringer.dsToTimeStrip(item.endDs, true)}
                            </p>
                            <p className="availItemShiftTimesLower">
                              {dateStringer.shorten(
                                item.teamName,
                                !full ? 18 : 28
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            ) : (
              ""
            )}{" "}
            {ds.items.map((item) => {
              if (
                (item.type === "leave" && showReqs) ||
                (item.type === "absence" && showAbsences)
              ) {
                return (
                  <div
                    className={`myCalItemAvailRow itemBoxShadow ${
                      item.type === "leave" && item.pending
                        ? "pendingCalItem"
                        : ""
                    } ${memoVals.mob ? "noBorder" : ""}`}
                    onClick={() => {
                      // open user leave modal here
                      memoVals.setShowUserReqFromNav(item.reqID);
                    }}
                  >
                    <div
                      className={`myCalLeaveInner ${
                        item.type === "absence" ? "absInnerFull" : ""
                      } ${
                        item.reqStartHalf
                          ? `myCalReqLastHalfOff${
                              item.type === "absence" ? "Abs" : ""
                            }`
                          : item.reqEndHalf
                          ? `myCalReqFirstHalfOff${
                              item.type === "absence" ? "Abs" : ""
                            }`
                          : ""
                      }`}
                    >
                      <div className="availItemShiftLeft">
                        {/* <div className="myCalColourLightGreen"></div> */}
                        {item.annLeave ? (
                          <img
                            src={plane2}
                            alt="Annual leave"
                            className="annLeavePlaneIcon"
                          />
                        ) : (
                          ""
                        )}

                        <div className="myCalShiftTypeAndTags">
                          <p className="myCalShiftTitle">{item.name}</p>

                          <div className="calItemTagsContainer">
                            <p className="severalTagsTxt marginLeftMinus5">
                              {item.reqStartHalf
                                ? "Last half off"
                                : item.reqEndHalf
                                ? "First half off"
                                : "Full day off"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="availItemShiftRight">
                        {/* <p className="availItemShiftTimesTeamName">
                          {item.teamName}
                        </p> */}
                        <p className="availItemShiftTimesTop">
                          {dateStringer.formatMinsDurationToHours(item.durMins)}
                        </p>
                        {item.pending ? (
                          <p className="availItemShiftTimesLower">
                            Awaiting approval
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            {ds.items.map((item) => {
              if (
                (showShifts && item.type === "shift") ||
                (showOvertimes && item.type === "overtime") ||
                (showTils && item.type === "til")
              ) {
                return (
                  <Fragment>
                    <div
                      className={`myCalItemAvailRow itemBoxShadow ${
                        memoVals.mob ? "noBorder" : ""
                      }`}
                      onClick={() => {
                        memoVals.setShowMyShiftModal((x) => {
                          return {
                            unitType: item.type,
                            unitID:
                              item.type === "shift" ? item.shiftID : item.tilID,
                            otherUser: false,
                          };
                        });
                      }}
                    >
                      <div className="myCalShiftInner">
                        <div className="availItemShiftLeft">
                          <div
                            className={`myCalColourLightGreen ${
                              item.ghost ? "ghostColourLight" : ""
                            } ${
                              item.absent && !item.selfLoggedAbsence
                                ? "myCalColourLightRed"
                                : ""
                            } ${
                              !item.absent && item.selfLoggedAbsence
                                ? "slaColourLight"
                                : ""
                            }`}
                          ></div>

                          <div
                            className={`myCalShiftTypeAndTags ${
                              item.tags && item.tags[0] ? "marginLeft5" : ""
                            }`}
                          >
                            {" "}
                            <p
                              className={`myCalShiftTitle shiftTilMyCalTitle ${
                                item.tags && item.tags[0] ? "paddingLeft10" : ""
                              }`}
                            >
                              {item.ghost
                                ? "Paid day off"
                                : item.type === "til"
                                ? "Overtime (in lieu)"
                                : item.type === "shift"
                                ? "Shift"
                                : "Overtime"}{" "}
                              {item.notes && item.notes[0] ? (
                                <img
                                  src={commentRota}
                                  alt="Comments"
                                  className="myCalItemNote"
                                />
                              ) : (
                                ""
                              )}
                              {item.shiftID &&
                              item.shiftID === clockedOnShiftID &&
                              clockedOnShiftID ? (
                                <span className="calClockedOnSpan">
                                  clocked on
                                </span>
                              ) : (
                                ""
                              )}
                            </p>
                            {item.tags && item.tags[0] ? (
                              <div className="calItemTagsContainer">
                                <p
                                  className={`${
                                    item.tags && item.tags.length > 0
                                      ? "pTagTxt marginLeft0"
                                      : "none"
                                  }`}
                                >
                                  {formatTag(item.tags[0])}
                                </p>
                                <p
                                  className={`${
                                    item.tags.length > 1
                                      ? "severalTagsTxt"
                                      : "none"
                                  }`}
                                >
                                  +{item.tags.length - 1}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="calRightSecForAccept">
                          <div className="availItemShiftRight">
                            {/* <p className="availItemShiftTimesTeamName">
                          {item.teamName}
                        </p> */}

                            {item.ghost ? (
                              <p className="availItemShiftTimesTop">
                                {dateStringer.formatMinsDurationToHours(
                                  item.durMins
                                )}
                              </p>
                            ) : item.tilApproved ? (
                              <p
                                className={`availItemShiftTimesTop ${
                                  dateStringer.generateTilShiftString(
                                    item.tilStart,
                                    item.tilEnd,
                                    `${item.startDs.substr(
                                      12,
                                      2
                                    )}:${item.startDs.substr(15, 2)}`,
                                    `${item.endDs.substr(
                                      12,
                                      2
                                    )}:${item.endDs.substr(15, 2)}`,
                                    false
                                  ).length > 18
                                    ? "x328929849289822"
                                    : ""
                                }`}
                              >
                                {dateStringer.generateTilShiftString(
                                  item.tilStart,
                                  item.tilEnd,
                                  `${item.startDs.substr(
                                    12,
                                    2
                                  )}:${item.startDs.substr(15, 2)}`,
                                  `${item.endDs.substr(
                                    12,
                                    2
                                  )}:${item.endDs.substr(15, 2)}`,
                                  false,
                                  false,
                                  item.type
                                )}
                              </p>
                            ) : (
                              <p className="availItemShiftTimesTop">
                                {dateStringer.dsToTimeStrip(item.startDs, true)}{" "}
                                - {dateStringer.dsToTimeStrip(item.endDs, true)}
                              </p>
                            )}
                            <p className="availItemShiftTimesLower">
                              {item.ghostName ||
                                dateStringer.shorten(
                                  item.teamName,
                                  !full ? 18 : 28
                                )}
                            </p>
                          </div>
                          {item.requireAccept ? (
                            <div className="calReqAcceptDiv">
                              {item.selfLoggedAbsence && !item.absent ? (
                                <p className="slaExclaim">!</p>
                              ) : item.absent ? (
                                <img
                                  src={darkRedCross}
                                  alt="Accepted"
                                  className="calAcceptIconImg"
                                />
                              ) : item.accepted ? (
                                <img
                                  src={doubleTick}
                                  alt="Accepted"
                                  className="calAcceptIconImg"
                                />
                              ) : (
                                <img
                                  src={lightBlueQuestionMark}
                                  alt="Accepted"
                                  className="calNotAcceptIconImg"
                                />
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    {item.clockable && (
                      <div
                        className={`calClockOnRow ${
                          item.clockedOn ? "calClockOnRowClockedOn" : ""
                        }`}
                      >
                        <p
                          className="clockOnCalBtn blueColours"
                          onClick={() => {
                            memoVals.setShowClockOnModal((x) => true);
                          }}
                        >
                          {item.clockedOn ? "Clocked on" : "Clock on now"}{" "}
                          <img
                            src={popup}
                            alt="Clock on now"
                            className="calClockPop"
                          />
                        </p>
                      </div>
                    )}
                  </Fragment>
                );
              }
            })}
            {noItems ? <p className="calNoItemsTxt">No shifts</p> : ""}
          </div>
        </div>
      );
    });
  }, [
    myCalArr,
    showFilter,
    showShifts,
    showOvertimes,
    showTils,
    showAbsences,
    showAvail,
    showReqs,
    showOpenDs,
  ]);

  useEffect(() => {
    let newArr = [];
    myCalArr.forEach((x) => {
      let itemsArr = [];

      x.items.forEach((xx) => {
        if (memoVals.acceptedShiftID) {
          if (xx.shiftID && xx.shiftID === memoVals.acceptedShiftID) {
            let newItem = xx;
            newItem["accepted"] = true;
            itemsArr.push(newItem);
          } else if (xx.tilID && xx.tilID === memoVals.acceptedShiftID) {
            let newItem = xx;
            newItem["accepted"] = true;
            itemsArr.push(newItem);
          } else {
            itemsArr.push(xx);
          }
        } else if (memoVals.declinedShiftID) {
          if (xx.shiftID && xx.shiftID === memoVals.declinedShiftID) {
          } else if (xx.tilID && xx.tilID === memoVals.declinedShiftID) {
          } else {
            itemsArr.push(xx);
          }
        } else {
          itemsArr.push(xx);
        }
      });

      let newObj = {
        commentObjects: x.commentObjects,
        comments: x.comments,
        closed: x.closed,
        ts: x.ts,
        today: x.today,
        ds: x.ds,
        items: itemsArr,
      };

      newArr.push(newObj);
    });

    setMyCalArr(newArr);

    if (memoVals.acceptedShiftID) {
      memoVals.setAcceptedShiftID((x) => "");
    }

    if (memoVals.declinedShiftID) {
      memoVals.setDeclinedShiftID((x) => "");
    }
  }, [memoVals.acceptedShiftID, memoVals.declinedShiftID]);

  useEffect(() => {
    // clean not needed
    if (memoVals.calendarPage === "myCal") {
      axios
        .post(
          `${serverURL}/get-month-cal`,
          {
            monthDs: selectedMyCalDs,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },

          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            if (dataLoaded) {
              setDataLoaded(false);
            }
            setMgrOf(response.data.mgrOf || []);
            setDataLoaded(true);
            setClockedOnShiftID(response.data.clockedOnShiftID);
            setMyCalArr(response.data.output);
            setShowAvail(response.data.showAvailOnMyCal);
            if (memoVals.selectedDesktop !== "calendar") {
              memoVals.setSelectedDesktop((x) => "calendar");
            }
            console.log("resp: ", response.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [selectedMyCalDs, memoVals.updateCalAvail]);

  useEffect(() => {
    if (memoVals.goToLimits) {
      memoVals.setCalendarPage((x) => "check");
      memoVals.setPage((x) => "limit-checker");
      setDataLoaded(false);
    } else {
      memoVals.setCalendarPage((x) =>
        teamCal
          ? "team"
          : showLimitChecker
          ? "check"
          : showViewColleague
          ? "teamCal"
          : "myCal"
      );
    }
  }, []);

  let [exceeds1Yr, setExceeds1Yr] = useState(false);
  useEffect(() => {
    // clean not needed
    if (limitCheckLoading) {
      setLimitCheckLoading(false);
    }
    if (limitsArr[0]) {
      setLimitsArr([]);
    }
    setExceedsNoLimits(false);
  }, [memoVals.calendarPage]);

  let [limitCheckLoading, setLimitCheckLoading] = useState(false);

  useEffect(() => {
    // clean not needed
    if (!selUserTeamName) {
      let usersFilt = allUsers.filter((item) => {
        return item.userID === selectedUserID;
      });

      if (usersFilt[0]) {
        setSelUserTeamName(usersFilt[0].teamName);
      }
    }
  }, [selectedUserID]);

  let generateShowDay = useMemo(() => {
    if (showDay.show && showDay.arr[0]) {
      let pHol = showDay.arr.filter((item) => {
        return item.publicHol;
      });

      let specDay = showDay.arr.filter((item) => {
        return item.specialDay;
      });

      let shifts = showDay.arr.filter((item) => {
        return item.shift;
      });

      let tils = showDay.arr.filter((item) => {
        return item.til;
      });

      let leave = showDay.arr.filter((item) => {
        return item.reqID && !item.absence;
      });

      let absence = showDay.arr.filter((item) => {
        return item.reqID && item.absence;
      });

      return (
        <div className="showDayList">
          {pHol[0] ? (
            <div className="showDayListPHolSDay">
              <p
                className={`pHolSDayTitle colourWhite lineHeight18 ${
                  pHol[0].pHolName.length > 32 ? "font12" : ""
                }`}
              >
                {pHol[0].pHolName}
              </p>

              {pHol[0].ghostShiftID ? (
                <div className="pHolSpecDayRightWithGhostDiv">
                  <p className="showDayPholClosed">
                    <span className="pHolSdayClosedSpan">
                      {pHol[0].closed || (specDay[0] && specDay[0].closed)
                        ? "Company is closed"
                        : "Company is open"}
                    </span>
                  </p>
                  {memoVals.calendarPage === "myCal" ||
                  memoVals.calendarPage === "teamCal" ? (
                    <p
                      className="viewPholGhost border143051"
                      onClick={() => {
                        memoVals.setShowMyShiftModal((x) => {
                          return {
                            unitType: "shift",
                            unitID: pHol[0].ghostShiftID,
                            // otherUser: shift.userID !== memoVals.checkUserState.userID,
                          };
                        });
                      }}
                    >
                      View
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <p className="showDayPholClosed">
                  {pHol[0].closed || (specDay[0] && specDay[0].closed)
                    ? "Company is closed"
                    : "Company is open"}
                </p>
              )}
            </div>
          ) : (
            ""
          )}

          {specDay[0] ? (
            <div className="showDayListPHolSDay">
              <p
                className={`pHolSDayTitle colourWhite lineHeight18 ${
                  specDay[0].specDayName.length > 32 ? "font12" : ""
                }`}
              >
                {specDay[0].specDayName}
              </p>

              {specDay[0].ghostShiftID ? (
                <div className="pHolSpecDayRightWithGhostDiv">
                  <p className="showDayPholClosed">
                    <span className="pHolSdayClosedSpan">
                      {specDay[0].closed
                        ? "Company is closed"
                        : "Company is open"}
                    </span>
                  </p>
                  {memoVals.calendarPage === "myCal" ||
                  memoVals.calendarPage === "teamCal" ? (
                    <p
                      className="viewPholGhost border143051"
                      onClick={() => {
                        memoVals.setShowMyShiftModal((x) => {
                          return {
                            unitType: "shift",
                            unitID: specDay[0].ghostShiftID,
                          };
                        });
                      }}
                    >
                      View
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <p className="showDayPholClosed">
                  {specDay[0].closed ? "Company is closed" : "Company is open"}
                </p>
              )}
            </div>
          ) : (
            ""
          )}
          {shifts[0]
            ? shifts.map((shift) => {
                return (
                  <div className="showDayShiftUnit">
                    {" "}
                    <p className={`pHolSDayTitle`}>
                      {shift.ghost ? "Paid day off" : "Shift"}
                      <br />
                      {shift.ghost ? (
                        <span className="showDayShiftTimes">
                          {dateStringer.formatMinsDurationToHours(
                            shift.durMins
                          )}
                        </span>
                      ) : (
                        <span className="showDayShiftTimes">
                          {dateStringer.dsToTimeStrip(shift.start)} -{" "}
                          {dateStringer.dsToTimeStrip(shift.end)}
                        </span>
                      )}
                      <br />
                      {shift.ghost ? (
                        ""
                      ) : (
                        <span className="showDayTeamName">
                          {shift.teamName}
                        </span>
                      )}
                    </p>
                    <div className="pHolSpecDayRightWithGhostDiv">
                      {/* {memoVals.calendarPage === "myCal" ? ( */}
                      <p
                        className="viewPholGhost"
                        onClick={() => {
                          memoVals.setShowMyShiftModal((x) => {
                            return {
                              unitType: "shift",
                              unitID: shift.shiftID,
                              otherUser:
                                shift.userID !== memoVals.checkUserState.userID,
                            };
                          });
                        }}
                      >
                        View
                      </p>
                      {/* ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                );
              })
            : ""}

          {tils[0]
            ? tils.map((til) => {
                return (
                  <div className="showDayShiftUnit tilUnitShowDay">
                    {" "}
                    <p
                      className={`pHolSDayTitle ${
                        til.overtime ? "" : "tilTxtSize"
                      }`}
                    >
                      {til.overtime ? "Overtime" : "Overtime (in lieu)"}
                      <br />
                      <span className="showDayShiftTimes">
                        {dateStringer.dsToTimeStrip(til.start)} -{" "}
                        {dateStringer.dsToTimeStrip(til.end)}
                      </span>
                      <br />
                      <span className="showDayTeamName">{til.teamName}</span>
                    </p>
                    <div className="pHolSpecDayRightWithGhostDiv">
                      {/* {memoVals.calendarPage === "myCal" ? ( */}
                      <p
                        className="viewPholGhost"
                        onClick={() => {
                          memoVals.setShowMyShiftModal((x) => {
                            return {
                              unitType: til.overtime ? "overtime" : "til",
                              unitID: til.tilID,
                              otherUser:
                                til.userID !== memoVals.checkUserState.userID,
                            };
                          });
                        }}
                      >
                        View
                      </p>
                      {/* ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                );
              })
            : ""}

          {leave[0]
            ? leave.map((l) => {
                return (
                  <div className="showDayShiftUnit leaveShowDayUnit">
                    {" "}
                    <div className={`pHolSDayTitle `}>
                      <div className="showDayImgTitleWrap">
                        <img
                          src={l.leaveName === "Annual leave" ? plane2 : plane}
                          alt="Holiday"
                          className="inlineDayUnitImg"
                        />
                        {dateStringer.shorten(l.leaveName, 18)}
                      </div>
                      <br />
                      {l.half ? (
                        <span className="halfLeaveShowDay">Half day</span>
                      ) : (
                        ""
                      )}{" "}
                      <span className="showDayShiftTimes">
                        {dateStringer.formatMinsDurationToHours(l.durMins)}
                      </span>
                    </div>
                    <div className="pHolSpecDayRightWithGhostDiv">
                      {memoVals.calendarPage === "myCal" ||
                      memoVals.calendarPage === "teamCal" ? (
                        <p
                          className="viewPholGhost"
                          onClick={() => {
                            // open req using l.reqID
                            memoVals.setShowUserReqFromNav(l.reqID);
                          }}
                        >
                          View
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })
            : ""}

          {absence[0]
            ? absence.map((abs) => {
                return (
                  <div className="showDayShiftUnit absLeaveShowDayUnit">
                    {" "}
                    <p className={`pHolSDayTitle `}>
                      <div className="showDayImgTitleWrap">
                        <img
                          src={absentShiftIcon}
                          alt="Holiday"
                          className="inlineDayUnitImg"
                        />
                        {dateStringer.shorten(abs.absName, 18)}
                      </div>
                      {/* {abs.absName} */}
                      <br />
                      {abs.half ? (
                        <span className="halfLeaveShowDay">Half day</span>
                      ) : (
                        ""
                      )}{" "}
                      <span className="showDayShiftTimes">
                        {dateStringer.formatMinsDurationToHours(abs.durMins)}
                      </span>
                    </p>
                    <div className="pHolSpecDayRightWithGhostDiv">
                      {memoVals.calendarPage === "myCal" ||
                      memoVals.calendarPage === "teamCal" ? (
                        <p
                          className="viewPholGhost"
                          onClick={() => {
                            // open req using l.reqID
                            memoVals.setShowUserReqFromNav(abs.reqID);
                          }}
                        >
                          View
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      );
    }

    if (showDay.arr && showDay.arr.length === 0) {
      return <p className="showDayNone">Nothing scheduled for this day</p>;
    }
  }, [showDay]);

  useEffect(() => {
    // clean not needed
    memoVals.setPage((x) => "calendar");
    memoVals.setCalPage((x) => "calendar");
  }, []);

  let renderSideModal = () => {
    if (showSideModal) {
      return (
        <div
          className="reqStatsUnderlay"
          onClick={() => {
            setShowSideModal(false);
          }}
        >
          <MyReqsDataBox
            modal={true}
            profileSideBox={true}
            selectedYear={selectedYear}
            setSelectedYearParent={setSelectedYear}
            myCalPage={true}
            setShowAllows={setShowSideModal}
          />
        </div>
      );
    }
  };

  let [minMaxDs, setMinMaxDs] = useState({ minDs: "", maxDs: "" });
  let [closedDays, setClosedDays] = useState([]);
  let [types, setTypes] = useState([]);

  let [mgrOf, setMgrOf] = useState([]);
  let [myTeamName, setMyTeamName] = useState("");
  let [limitsDisabled, setLimitsDisabled] = useState(false);

  useEffect(() => {
    // cleaned with gpt
    const cancelSource1 = axios.CancelToken.source();
    const cancelSource2 = axios.CancelToken.source();
    const cancelSource3 = axios.CancelToken.source();
    const cancelSource4 = axios.CancelToken.source();
    const cancelSource5 = axios.CancelToken.source();

    if (!yearsArr[1]) {
      axios
        .post(
          `${serverURL}/get-available-years`,
          {
            userOnly: true,
            getMyArr: true,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token, // Pass the cancel token to the request
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setYearsArr(response.data.availableYears || []);
            setSelectedYear(response.data.currentYear);
            let arr = [];
            // let my1 = dateStringer.printMonthAndYearFromMy(my)
            if (response.data.my1 && response.data.my2) {
              let startObj = new Date();
              startObj.setFullYear(
                parseInt(`20${response.data.my1.substr(2, 2)}`)
              );
              startObj.setMonth(parseInt(`${response.data.my1.substr(0, 2)}`));
              startObj.setDate(1);
              startObj.setHours(0, 0, 0, 0);

              let endObj = new Date();
              endObj.setFullYear(
                parseInt(`20${response.data.my2.substr(2, 2)}`)
              );
              endObj.setMonth(parseInt(`${response.data.my2.substr(0, 2)}`));
              endObj.setDate(1);
              endObj.setHours(0, 0, 0, 0);

              let currMonth = new Date();
              currMonth.setDate(1);
              currMonth.setHours(0, 0, 0, 0);
              let dateObj = new Date(startObj.getTime());
              while (dateObj.getTime() < endObj.getTime()) {
                dateObj.setMonth(dateObj.getMonth() + 1);
                if (dateObj.getTime() >= currMonth.getTime()) {
                  arr.push(
                    dateStringer.createStringFromTimestamp(dateObj.getTime())
                  );
                }
              }
              setMyCalMonthsArr(arr);
              console.log({ arr });
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (memoVals.calendarPage === "team") {
      // get my teams
      axios
        .post(
          `${serverURL}/get-user-teams`,
          {
            userID: memoVals.checkUserState.userID,
            getUserTeamsOnly: true,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource2.token, // Pass the cancel token to the request
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setMyTeamTeams(response.data.partOfTeams);
            setSelectedMyTeam(response.data.primaryTeamID);
            if (dataLoaded) {
              setDataLoaded(false);
            }
            setDataLoaded(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      // jamie
    }

    // if (memoVals.calendarPage === "myCal") {
    //   axios
    //     .post(
    //       `${serverURL}/get-user-cal`,
    //       {
    //         year: selectedYear,
    //         nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
    //       },

    //       {
    //         withCredentials: true,
    //         credentials: "include",
    //         cancelToken: cancelSource3.token, // Pass the cancel token to the request
    //       }
    //     )
    //     .then((response) => {
    //       if (response.data.message === "success") {
    //         setMinMaxDs({
    //           minDs: response.data.minDs,
    //           maxDs: response.data.maxDs,
    //         });
    //         setTypes([
    //           ...response.data.customLeaveTypes,
    //           ...response.data.absenceTypes,
    //         ]);
    //         setMonths(response.data.output);
    //         setClosedDays(response.data.closedDaysArr || []);
    //         if (dataLoaded) {
    //           setDataLoaded(false);
    //         }
    //         setDataLoaded(true);
    //       }
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     });
    // }

    if (memoVals.calendarPage === "teamCal") {
      if (!allUsers[0]) {
        axios
          .post(
            `${serverURL}/get-all-users`,
            {
              dontGetTimelineIssues: true,
              myCal: true,
              includeSelf: true,
            },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource4.token, // Pass the cancel token to the request
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setAllUsers(response.data.usersArray);

              let usersFiltMe = response.data.usersArray.filter((item) => {
                return item.you;
              });

              console.log({ usersFiltMe });

              if (usersFiltMe[0]) {
                setSelectedUserID(usersFiltMe[0].userID);
                setSelUserTeamName(usersFiltMe[0].teamName);

                setSelectedUserFName("Your");
              } else {
                setSelectedUserID(memoVals.checkUserState.userID);

                setSelectedUserFName(memoVals.checkUserState.fName);
              }

              if (dataLoaded) {
                setDataLoaded(false);
              }
              setDataLoaded(true);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }

      if (selectedUserID) {
        axios
          .post(
            `${serverURL}/get-user-cal`,
            {
              year: selectedYear,
              nowDs: dateStringer.createStringFromTimestamp(
                new Date().getTime()
              ),
              userID: selectedUserID,
              areTheyWorkingNow: true,
            },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource5.token, // Pass the cancel token to the request
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setMinMaxDs({
                minDs: response.data.minDs,
                maxDs: response.data.maxDs,
              });
              setTypes([
                ...response.data.customLeaveTypes,
                ...response.data.absenceTypes,
              ]);
              setMonths(response.data.output);
              setClosedDays(response.data.closedDaysArr || []);
              if (dataLoaded) {
                setDataLoaded(false);
              }
              setDataLoaded(true);
              setCollCalWorkingToday(response.data.workingToday); // {startDs, endDs, or false}
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }

    if (memoVals.calendarPage === "check") {
      axios
        .post(
          `${serverURL}/initialise-leave-check`,
          {
            someData: 1,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource5.token, // Pass the cancel token to the request
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            console.log(response.data);
            setMyTeamName(response.data.teamName);
            setLimitsDisabled(response.data.limitDisabled);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
      cancelSource2.cancel("Component unmounted");
      cancelSource3.cancel("Component unmounted");
      cancelSource4.cancel("Component unmounted");
      cancelSource5.cancel("Component unmounted");
    };
  }, [selectedYear, memoVals.calendarPage, selectedUserID]);

  let generateYearsArr = useMemo(() => {
    yearsArr.sort();
    return (
      yearsArr &&
      yearsArr.map((year) => {
        return (
          <option key={year} value={year}>
            {year}
          </option>
        );
      })
    );
  }, [yearsArr]);

  let generateMyCalMonths = useMemo(() => {
    return (
      myCalMonthsArr &&
      myCalMonthsArr.map((ds) => {
        return (
          <option key={ds} value={ds}>
            {dateStringer.printMonthAndYearFromDs(ds, false)}
          </option>
        );
      })
    );
  }, [myCalMonthsArr]);

  useEffect(() => {
    // cleaned with GPT
    const handlePopstate = (event) => {
      window.history.pushState(null, document.title, window.location.href);
      // code here
      if (showSideModal) {
        setShowSideModal(false);
      }
    };

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [showSideModal]);

  let generateCal = months.map((month) => {
    return (
      <div
        className={`monthCalUnit ${
          !memoVals.mob && !memoVals.full ? "midCalUnit" : ""
        } ${memoVals.full ? "fullCalUnit" : ""} ${
          month.current ? `${memoVals.mob ? "currMcalMob" : "currMcal"}` : ""
        }`}
      >
        <div className="monthCalUnitHeader">
          <p
            className={`monthCalUnitHeaderTitle ${
              memoVals.mob ? "font20" : ""
            } ${month.current ? "monthCalHeaderTitleCurr" : ""}`}
            ref={month.current ? targetRef : nullRef}
          >
            {month.month}
          </p>
        </div>
        <div className="monthCalDayLetters">
          <p
            className={`monthCalDayLtr ${
              closedDays.includes(1) ? "closedCalLtr" : ""
            }`}
          >
            M
          </p>
          <p
            className={`monthCalDayLtr ${
              closedDays.includes(2) ? "closedCalLtr" : ""
            }`}
          >
            T
          </p>
          <p
            className={`monthCalDayLtr ${
              closedDays.includes(3) ? "closedCalLtr" : ""
            }`}
          >
            W
          </p>
          <p
            className={`monthCalDayLtr ${
              closedDays.includes(4) ? "closedCalLtr" : ""
            }`}
          >
            T
          </p>
          <p
            className={`monthCalDayLtr ${
              closedDays.includes(5) ? "closedCalLtr" : ""
            }`}
          >
            F
          </p>
          <p
            className={`monthCalDayLtr ${
              closedDays.includes(6) ? "closedCalLtr" : ""
            }`}
          >
            S
          </p>
          <p
            className={`monthCalDayLtr ${
              closedDays.includes(0) ? "closedCalLtr" : ""
            }`}
          >
            S
          </p>
        </div>
        <div className="snake">
          {month.snake.map((item, i) => {
            let closedDay = false;
            if (closedDays.includes(1)) {
              if (
                i === 0 ||
                i === 7 ||
                i === 14 ||
                i === 21 ||
                i === 28 ||
                i === 35
              ) {
                closedDay = true;
              }
            }

            if (closedDays.includes(2)) {
              if (
                i === 1 ||
                i === 8 ||
                i === 15 ||
                i === 22 ||
                i === 29 ||
                i === 36
              ) {
                closedDay = true;
              }
            }

            if (closedDays.includes(3)) {
              if (
                i === 2 ||
                i === 9 ||
                i === 16 ||
                i === 23 ||
                i === 30 ||
                i === 37
              ) {
                closedDay = true;
              }
            }

            if (closedDays.includes(4)) {
              if (
                i === 3 ||
                i === 10 ||
                i === 17 ||
                i === 24 ||
                i === 31 ||
                i === 38
              ) {
                closedDay = true;
              }
            }

            if (closedDays.includes(5)) {
              if (
                i === 4 ||
                i === 11 ||
                i === 18 ||
                i === 25 ||
                i === 32 ||
                i === 39
              ) {
                closedDay = true;
              }
            }

            if (closedDays.includes(6)) {
              if (
                i === 5 ||
                i === 12 ||
                i === 19 ||
                i === 26 ||
                i === 33 ||
                i === 40
              ) {
                closedDay = true;
              }
            }

            if (closedDays.includes(0)) {
              if (
                i === 6 ||
                i === 13 ||
                i === 20 ||
                i === 27 ||
                i === 34 ||
                i === 41
              ) {
                closedDay = true;
              }
            }

            if (!item.void) {
              return (
                <div
                  className={`snakeItem ${
                    !memoVals.mob ? "nonMobSnakItem" : ""
                  } ${
                    closedDay
                      ? `closedCalLtr ${
                          item.post || item.pre ? "closedCalLtrPost" : ""
                        }`
                      : ""
                  } ${item.post || item.pre ? "nullSnake" : ""} ${
                    memoVals.width >= 1000 && memoVals.width <= 1139
                      ? "forceCalTxtSmall"
                      : ""
                  } ${
                    item.today &&
                    !item.absence &&
                    !item.leave &&
                    !item.pHolName &&
                    !item.specDayName
                      ? "todayCalSnake"
                      : ""
                  }`}
                  onClick={() => {
                    if (!item.pre && !item.post) {
                      setShowDay({
                        show: true,
                        arr: [],
                        ds: item.ds,
                        loading: true,
                      });
                      axios
                        .post(
                          `${serverURL}/get-snake-day-info`,
                          {
                            ds: item.ds,
                            userID:
                              memoVals.calendarPage === "teamCal" &&
                              selectedUserID
                                ? selectedUserID
                                : "",
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            console.log(response.data);
                            setShowDay({
                              show: true,
                              arr: response.data.outputArr,
                              ds: item.ds,
                              loading: false,
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                >
                  {item.leave || item.absence ? (
                    <div
                      className={`snakeItemLeave ${
                        memoVals.mob ? "snakeItemLeaveMob" : ""
                      } ${item.absence ? "snakeAbsenceColours" : ""} ${
                        item.firstHalf
                          ? item.absence
                            ? "snakeLastHalfOffAbs"
                            : "snakeLastHalfOff"
                          : ""
                      } ${
                        item.lastHalf
                          ? item.absence
                            ? "snakeFirstHalfOffAbs"
                            : "snakeFirstHalfOff"
                          : ""
                      } ${item.today ? "todayCalSnake" : ""}`}
                    >
                      {item.num}
                    </div>
                  ) : item.pHolName || item.specDayName ? (
                    <div
                      className={`pHolNameSnakeItem ${
                        item.specDayName ? "specDayNameSnakeItem" : ""
                      } ${item.today ? "todayCalSnake" : ""}`}
                    >
                      {item.num}
                    </div>
                  ) : (
                    item.num
                  )}
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  });

  // myCal, teamCal

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  useEffect(() => {
    setModalPosition({ bottom: "0%", left: "0%" });
  }, [showDay]);

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 50) {
      setShowDay({ show: false });
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  useEffect(() => {
    if (!loading) {
      // check if stylesheet calendar loaded properly using the class myRequestsHeaderLeftTitle in calendar.css which is colour: #143051
      setTimeout(() => {
        // let element = document.createElement("div");
        // element.className = "myRequestsHeaderLeftTitle";

        // document.body.appendChild(element);
        // const computedStyle = getComputedStyle(element);
        // console.log({ computedStyle });
        // if (computedStyle.color === "#143051") {
        // } else {
        //   console.log("Stylesheet not applied correctly");
        //   // location.reload(true);
        // }

        // document.body.removeChild(element);
        let element = document.getElementsByClassName("headerTitleSize")[0];

        if (element) {
          let computedStyle = getComputedStyle(element);
          let color = computedStyle.color;
          if (color !== "rgb(20, 48, 82)") {
            console.log(
              "Colour not loaded from headerTitleSize in calendar.css -- reloading page"
            );
            location.reload(true);
          }
          // console.log("Color:", color, { computedStyle });
        }
      }, 3000);
    }
  }, [loading]);

  // MASTER RETURN
  return (
    <div className="reqsPageHolder">
      <div className="reqsPage">
        <div
          className={`reqsPageUpperContainer ${
            !memoVals.mob ? "pageLeft240pxDesktop" : "noBorderBottom"
          }`}
        >
          {!memoVals.mob ? <TopBar /> : ""}
          {/* Reqs page header here */}

          <div className="shiftsHeaderContentContainer">
            <div
              className={`shiftsHeaderLeft ${
                memoVals.wide ? "wideTitle" : ""
              } `}
            >
              <div
                className={`myRequestsHeaderLeftTitle ${
                  !memoVals.mob ? "headerTitleSize myReqsTitle2" : ""
                } ${memoVals.mob ? "myRequestsHeaderLeftTitleMob" : ""}`}
                onClick={() => {}}
              >
                My calendar
              </div>

              <div
                className={`calendarHeaderMenuRow ${
                  memoVals.mob ? "calendarHeaderMenuRowMob" : ""
                }`}
              >
                {" "}
                {/* <NavLink to="/calendar"> */}
                <div
                  className={`shiftHeaderMenuItem ${
                    memoVals.calendarPage === "myCal"
                      ? `shiftHeaderMenuItemSelect`
                      : ""
                  }`}
                  onClick={() => {
                    if (memoVals.calendarPage === "myCal") {
                      // nil
                    } else {
                      memoVals.setCalendarPage((x) => "myCal");
                      memoVals.setPage((x) => "calendar");
                      setDataLoaded(false);
                    }
                  }}
                >
                  <p className="headerMenuTopRow">My calendar</p>

                  <p
                    className={`headerMenuBottomRow ${
                      memoVals.calendarPage === "myCal" ? "none" : "x198389191"
                    } `}
                  ></p>
                  <p
                    className={`headerMenuBottomRow ${
                      memoVals.calendarPage === "myCal" ? "" : "none"
                    }  `}
                  ></p>
                </div>{" "}
                {/* </NavLink> */} <div className="subHeadSplit"></div>
                {/* <div className="shifHeaderMenuSplitter"></div>{" "} */}
                {/* <NavLink to="/calendar/view-colleague"> */}
                <div
                  className={`shiftHeaderMenuItem ${
                    memoVals.calendarPage === "teamCal"
                      ? `shiftHeaderMenuItemSelect`
                      : ""
                  }`}
                  onClick={() => {
                    if (memoVals.calendarPage === "teamCal") {
                      // nil
                    } else {
                      memoVals.setCalendarPage((x) => "teamCal");
                      memoVals.setPage((x) => "view-colleague");
                      setDataLoaded(false);

                      setSelectedUserID(memoVals.checkUserState.userID);
                      let usersFilt = allUsers.filter((item) => {
                        return item.userID === memoVals.checkUserState.userID;
                      });

                      if (usersFilt[0]) {
                        setSelUserTeamName(usersFilt[0].teamName);
                      }
                      setCollCalWorkingToday(false);
                      // setLoading(false);
                    }
                  }}
                >
                  <p className="headerMenuTopRow">Leave chart</p>

                  <p
                    className={`headerMenuBottomRow ${
                      memoVals.calendarPage === "teamCal"
                        ? "none"
                        : "x198389191"
                    } `}
                  ></p>
                  <p
                    className={`headerMenuBottomRow ${
                      memoVals.calendarPage === "teamCal" ? "" : "none"
                    }  `}
                  ></p>
                </div>{" "}
                <div className="subHeadSplit"></div>
                <div
                  className={`shiftHeaderMenuItem ${
                    memoVals.calendarPage === "team"
                      ? `shiftHeaderMenuItemSelect`
                      : ""
                  }`}
                  onClick={() => {
                    if (memoVals.calendarPage === "team") {
                      // nil
                    } else {
                      memoVals.setCalendarPage((x) => "team");
                      memoVals.setPage((x) => "team-calendar");
                      setDataLoaded(false);
                    }
                  }}
                >
                  <p className="headerMenuTopRow">My team</p>

                  <p
                    className={`headerMenuBottomRow ${
                      memoVals.calendarPage === "team" ? "none" : "x198389191"
                    } `}
                  ></p>
                  <p
                    className={`headerMenuBottomRow ${
                      memoVals.calendarPage === "team" ? "" : "none"
                    }  `}
                  ></p>
                </div>{" "}
                <div className="subHeadSplit"></div>
                {/* </NavLink> */}
                {/* <NavLink to="/calendar/limit-checker"> */}{" "}
                <div
                  className={`shiftHeaderMenuItem ${
                    memoVals.calendarPage === "check"
                      ? `shiftHeaderMenuItemSelect`
                      : ""
                  }`}
                  onClick={() => {
                    if (memoVals.calendarPage === "check") {
                      // nil
                    } else {
                      memoVals.setCalendarPage((x) => "check");
                      memoVals.setPage((x) => "limit-checker");
                      setDataLoaded(false);
                    }
                  }}
                >
                  <p className="headerMenuTopRow">Limits</p>

                  <p
                    className={`headerMenuBottomRow ${
                      memoVals.calendarPage === "check" ? "none" : "x198389191"
                    } `}
                  ></p>
                  <p
                    className={`headerMenuBottomRow ${
                      memoVals.calendarPage === "check" ? "" : "none"
                    }  `}
                  ></p>
                </div>
                {/* </NavLink> */}
              </div>
            </div>

            {memoVals.checkUserState.permissions < 3 ||
            memoVals.checkUserState.indiOnly ? (
              <NavLink
                to="/staff-calendar"
                // to={`${!shiftsPage.includes("team") ? "/team-hours" : "/hours"}`}
                onClick={() => {
                  memoVals.setCalPage((x) => "staff-calendar");
                  console.log("changing calpage to staff-calendar");
                }}
              >
                <div
                  className={`staffHoursBtn staffReqsBtn minWidth164 pinkColours`}
                >
                  <p className="teamHrsBtn">
                    {memoVals.full || !memoVals.full
                      ? "Staff calendar"
                      : "Staff"}
                  </p>
                  <img src={whiteGo} alt="Team" className="whiteGoImg" />
                </div>
              </NavLink>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          className={`requestPageParent ${
            !memoVals.mob ? "pageLeft240pxDesktop borderLeftGrey" : ""
          } `}
        >
          {" "}
          <div
            className={`desktopMaster ${memoVals.mob ? "desktopWhiteBg" : ""}`}
          >
            <div
              className={`desktopMasterMain  ${
                memoVals.mob ? "mob100Witdth" : ""
              } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""}`}
            >
              <div
                className={`requestsContainer ${
                  memoVals.mob
                    ? "mobReqsContainerFullWidth mobReqsContainerFullWidthUserCal"
                    : ""
                } `}
              >
                {/* requests container starts */}

                {memoVals.calendarPage !== "check" ? (
                  <div
                    className={`reqsPageFilterHolder ${
                      !memoVals.mob ? "borderRightGrey" : "noBorderRight"
                    } ${loading ? "noBorder" : ""} `}
                  >
                    <div
                      className={`reqPageFilterBarMy ${
                        calendarPage === "team" ||
                        (calendarPage === "teamCal" && memoVals.mob)
                          ? "reqPageFilterBarMyMyTeamMobBorderBottom"
                          : ""
                      }`}
                    >
                      <div
                        className={`myAbsencesFiltBar ${
                          memoVals.mob ? "mobMyReqsFilterbar x282388298342" : ""
                        } ${
                          memoVals.calendarPage === "team" && memoVals.mob
                            ? "mobMyReqsFilterbarMobCal"
                            : ""
                        } ${
                          !memoVals.full && !memoVals.mob ? "minWidth312_" : ""
                        }`}
                      >
                        {memoVals.calendarPage === "team" ? (
                          <div
                            className={`teamSchedFilterBarLeft marginLeft0 ${
                              window.innerWidth > 440 &&
                              (window.innerWidth < 1000 ||
                                window.innerWidth > 1090)
                                ? "xxx198294"
                                : ""
                            }`}
                          >
                            {!memoVals.mob ? (
                              <img
                                src={arrGrey}
                                alt="Left"
                                className={`leftMonWeek ${
                                  selectedMy === myArr[0] || loading
                                    ? "fadeSelMonWeekBtn"
                                    : ""
                                } ${
                                  memoVals.width < 1012 && memoVals.full
                                    ? "none"
                                    : ""
                                }`}
                                onClick={() => {
                                  if (selectedMy !== myArr[0]) {
                                    let ind = myArr.indexOf(selectedMy);
                                    setSelectedMy(myArr[ind - 1]);
                                  }
                                }}
                              />
                            ) : (
                              ""
                            )}
                            <select
                              className={`monthDropdown ${
                                memoVals.mob ? "monthDropdownMobXh" : ""
                              } ${
                                window.innerWidth > 440 &&
                                (window.innerWidth < 1000 ||
                                  window.innerWidth > 1090)
                                  ? "x92020121113"
                                  : ""
                              } ${selectedMy === currMy ? "colour00aaff" : ""}`}
                              value={selectedMy}
                              onChange={(e) => {
                                setSelectedMy(e.target.value);
                              }}
                            >
                              {generateMys}
                            </select>{" "}
                            {!memoVals.mob ? (
                              <img
                                src={arrGrey}
                                alt="Right"
                                className={`rightMonWeek ${
                                  selectedMy === myArr[myArr.length - 1] ||
                                  loading
                                    ? "fadeSelMonWeekBtn"
                                    : ""
                                }`}
                                onClick={() => {
                                  if (selectedMy !== myArr[myArr.length - 1]) {
                                    let ind = myArr.indexOf(selectedMy);
                                    setSelectedMy(myArr[ind + 1]);
                                  }
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        ) : memoVals.calendarPage === "myCal" ? (
                          <div
                            className={`myCalDropAndRightBtnContainer ${
                              memoVals.mob ? "marginTop8" : ""
                            }`}
                          >
                            <select
                              className={`myReqsYearDropdown ${
                                memoVals.mob ? "myReqsYearDropdownMob" : ""
                              } myCalMonthsDropSize ${
                                currMonthDs === selectedMyCalDs
                                  ? "colour00aaff"
                                  : ""
                              }`}
                              value={selectedMyCalDs}
                              onChange={(e) => {
                                if (e.target.value) {
                                  setLoading(true);
                                  setSelectedMyCalDs(e.target.value);
                                  setShowOpenDs([]);
                                }
                              }}
                            >
                              {generateMyCalMonths}
                            </select>{" "}
                            <img
                              src={arrGrey}
                              alt="Right"
                              className={`rightMonWeek ${
                                memoVals.mob ? "rightMonWeekMob" : ""
                              } ${
                                loading
                                  ? "fadeSelMonWeekBtn"
                                  : selectedMyCalDs ===
                                    myCalMonthsArr[myCalMonthsArr.length - 1]
                                  ? "none"
                                  : ""
                              }`}
                              onClick={() => {
                                // code here
                                if (
                                  selectedMyCalDs !==
                                  myCalMonthsArr[myCalMonthsArr.length - 1]
                                ) {
                                  let dateObj = new Date(
                                    dateStringer.createTimestampFromString(
                                      selectedMyCalDs
                                    )
                                  );
                                  dateObj.setMonth(dateObj.getMonth() + 1);
                                  setLoading(true);
                                  setSelectedMyCalDs(
                                    dateStringer.createStringFromTimestamp(
                                      dateObj.getTime()
                                    )
                                  );
                                  setShowOpenDs([]);
                                }
                              }}
                            />{" "}
                          </div>
                        ) : (
                          <select
                            className={`myReqsYearDropdown ${
                              memoVals.mob ? "myReqsYearDropdownMobCal" : ""
                            } ${
                              selectedYear === new Date().getFullYear()
                                ? "colour0af"
                                : ""
                            }`}
                            value={selectedYear}
                            onChange={(e) => {
                              if (e.target.value) {
                                setLoading(true);
                                setSelectedYear(parseInt(e.target.value));
                              }
                            }}
                          >
                            {!yearsArr.includes(selectedYear) ? (
                              <option value="">--</option>
                            ) : (
                              ""
                            )}
                            {generateYearsArr}
                          </select>
                        )}

                        {memoVals.calendarPage === "myCal" ? (
                          // <p></p>
                          <div
                            className={`${
                              showFilter
                                ? "shiftTilFilterBtnActive"
                                : "shiftTilFilterBtn"
                            } marginTop0 ${
                              !showAvail && !showFilter ? "blueColours" : ""
                            }`}
                            onClick={() => {
                              setShowFilter(!showFilter);
                            }}
                          >
                            <img
                              src={filterWhite}
                              alt="Close filter"
                              className={`filtImg ${
                                !showFilter ? "x19831" : ""
                              }`}
                            />{" "}
                            <img
                              className={`${
                                showFilter ? "closeFilterImgTilShifts" : "none"
                              }`}
                              alt="close"
                              src={whitecross}
                            />
                            {!showAvail && !showFilter ? (
                              <p className="availFiltInactive"></p>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          // <p className="calDateStrip">
                          //   {dateStringer.printedDateFromDs(minMaxDs.minDs)} -{" "}
                          //   {dateStringer.printedDateFromDs(minMaxDs.maxDs)}
                          // </p>
                          ""
                        )}
                        {memoVals.full
                          ? ""
                          : memoVals.calendarPage === "myCal"
                          ? ""
                          : // (
                            //   <div
                            //     className={`myAbsencePerfWhiteBtn  ${
                            //       memoVals.mob ? "myAllowsWhiteBtnMob" : ""
                            //     }`}
                            //     onClick={() => {
                            //       setShowSideModal(true);
                            //     }}
                            //   >
                            //     Allowances
                            //     <img
                            //       src={popup2}
                            //       alt="Staff rota"
                            //       className="availPopupImg"
                            //     />
                            //   </div>
                            // )
                            ""}

                        {memoVals.calendarPage === "teamCal" ? (
                          <select
                            className={`collCalUserDropdown ${
                              selectedUserID !== memoVals.checkUserState.userID
                                ? "highlightSelCollDrop"
                                : "youTeamCalDropper"
                            } ${memoVals.mob ? "collCalUserDropdownMob" : ""}`}
                            value={selectedUserID}
                            onChange={(e) => {
                              setLoading(true);
                              setSelectedUserID(e.target.value);
                              let usersFilt = allUsers.filter((item) => {
                                return item.userID === e.target.value;
                              });

                              if (usersFilt[0]) {
                                setSelectedUserFName(usersFilt[0].fName);
                              }

                              // go through the data, find todaya and see if selectedUserID is working today
                              // daisy
                            }}
                          >
                            {/* <option value="" key="_"></option> */}
                            {allUsers &&
                              allUsers[0] &&
                              allUsers
                                .sort((a, b) => a.fName.localeCompare(b.fName))
                                .map((user) => {
                                  // if (user.userID !== memoVals.checkUserState.userID) {
                                  return (
                                    <option
                                      value={user.userID}
                                      key={user.userID}
                                    >
                                      {user.userID ===
                                      memoVals.checkUserState.userID
                                        ? "You"
                                        : user.fName}{" "}
                                      {user.userID ===
                                      memoVals.checkUserState.userID
                                        ? ""
                                        : user.lName}
                                    </option>
                                  );
                                  // }
                                })}
                          </select>
                        ) : (
                          ""
                        )}

                        {memoVals.calendarPage === "team" ? (
                          <select
                            className={`collCalUserDropdown ${
                              !selectedUserID ? "highlightSelCollDrop_" : ""
                            } ${memoVals.mob ? "collCalUserDropdownMob2" : ""}`}
                            value={selectedMyTeam}
                            onChange={(e) => {
                              if (e.target.value !== "") {
                                setLoading(true);
                                setSelectedMyTeam(e.target.value);
                              }

                              // go through the data, find todaya and see if selectedUserID is working today
                              // daisy
                            }}
                          >
                            {" "}
                            <option value="" key="_">
                              -- Select a team
                            </option>
                            {myTeamTeams &&
                              myTeamTeams[0] &&
                              myTeamTeams
                                .sort((a, b) =>
                                  a.teamName.localeCompare(b.teamName)
                                )
                                .map((team) => {
                                  return (
                                    <option
                                      value={team.teamID}
                                      key={team.teamID}
                                    >
                                      {selectedMyTeam === team.teamID &&
                                      team.teamName &&
                                      team.teamName.length > 25
                                        ? dateStringer.shorten(
                                            team.teamName,
                                            22
                                          )
                                        : team.teamName}
                                    </option>
                                  );
                                })}
                          </select>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {memoVals.calendarPage !== "check" ? (
                  <div
                    className={`Container borderRightGrey  ${
                      loading || !memoVals.full ? "noBorder" : ""
                    }`}
                  >
                    {memoVals.calendarPage !== "team" &&
                    !loading &&
                    (memoVals.calendarPage === "myCal" || !selectedUserID) ? (
                      <div
                        className={`reqFilterSplitter ${
                          memoVals.mob ? "reqFilterSplitterMob" : ""
                        }`}
                      ></div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}

                {memoVals.calendarPage === "teamCal" &&
                selectedUserID &&
                !loading ? (
                  <div
                    className={`collCalUserInfoDiv ${
                      memoVals.mob ? "collCalUserInfoDivMob" : ""
                    }`}
                  >
                    <div className="colCalInfoLeft">
                      <p className="collCalTeamTitle">
                        {selectedUserFName === "Your"
                          ? "Your"
                          : dateStringer.possession(selectedUserFName)}{" "}
                        primary team
                      </p>
                      <p className="collCalTeamValue">
                        {dateStringer.shorten(
                          selUserTeamName,
                          memoVals.fullButNarrow || memoVals.mob ? 21 : 99
                        )}
                      </p>
                    </div>
                    <div className="colCalInfoRight">
                      <p
                        className="viewCollCalBtn"
                        onClick={() => {
                          memoVals.setSeeUserID((x) => {
                            return {
                              userID: selectedUserID,
                              openedFromUserRota: false,
                            };
                          });
                        }}
                      >
                        Weekly schedule
                        <img
                          src={popup}
                          alt="Weekly schedule"
                          className="wklySchedPopUp"
                        />
                      </p>
                      {collCalWorkingToday[0] ? (
                        <div
                          className="collCalWorkingToday"
                          onClick={() => {
                            let today00 = new Date();
                            today00.setHours(0, 0, 0, 0);
                            let todayDs =
                              dateStringer.createStringFromTimestamp(
                                today00.getTime()
                              );
                            setShowDay({
                              show: true,
                              arr: [],
                              ds: todayDs,
                              loading: true,
                            });
                            axios
                              .post(
                                `${serverURL}/get-snake-day-info`,
                                {
                                  ds: todayDs,
                                  userID:
                                    memoVals.calendarPage === "teamCal" &&
                                    selectedUserID
                                      ? selectedUserID
                                      : "",
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  setShowDay({
                                    show: true,
                                    arr: response.data.outputArr,
                                    ds: todayDs,
                                    loading: false,
                                  });
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }}
                        >
                          Working today:{" "}
                          <span className="workBetTodaySpan">
                            {dateStringer.dsToTimeStrip(
                              collCalWorkingToday[0].startDs
                            )}{" "}
                            -{" "}
                            {dateStringer.dsToTimeStrip(
                              collCalWorkingToday[0].endDs
                            )}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {memoVals.calendarPage === "myCal" && showFilter && !loading ? (
                  <div
                    className={`shiftTilFilterHolder removeFlex borderRightGrey ${
                      memoVals.mob ? "hurdydddd" : ""
                    }`}
                  >
                    <div
                      className={`${
                        showFilter
                          ? "shfitTilFilterBtnsContainer x2894895834 borderRightNone"
                          : "none"
                      }`}
                    >
                      <div
                        className={`shiftsOrTilFilter ${
                          showAvail
                            ? ""
                            : "unselectedShiftOrTil availBtn_ blueColours"
                        }`}
                        onClick={() => {
                          setShowAvail(!showAvail);

                          axios
                            .post(
                              `${serverURL}/toggle-show-avail-on-user-cal`,
                              {
                                data: !showAvail,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setShowAvail(response.data.data);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      >
                        Availability{" "}
                        <img
                          src={showAvail ? checkboxInstall2 : checkboxInstall1}
                          alt="Select"
                          className="shiftTilSelectImg"
                        />
                      </div>
                      <div
                        className={`shiftsOrTilFilter ${
                          showReqs ? "" : "unselectedShiftOrTil"
                        }`}
                        onClick={() => {
                          setShowReqs(!showReqs);
                        }}
                      >
                        Leave{" "}
                        <img
                          src={showReqs ? checkboxInstall2 : checkboxInstall1}
                          alt="Select"
                          className="shiftTilSelectImg"
                        />
                      </div>
                      <div
                        className={`shiftsOrTilFilter ${
                          showAbsences ? "" : "unselectedShiftOrTil"
                        }`}
                        onClick={() => {
                          setShowAbsences(!showAbsences);
                        }}
                      >
                        Absence{" "}
                        <img
                          src={
                            showAbsences ? checkboxInstall2 : checkboxInstall1
                          }
                          alt="Select"
                          className="shiftTilSelectImg"
                        />
                      </div>
                    </div>
                    <div
                      className={`${
                        showFilter
                          ? "shfitTilFilterBtnsContainer x2894895834 borderRightNone"
                          : "none"
                      }`}
                    >
                      <div
                        className={`shiftsOrTilFilter ${
                          showShifts ? "" : "unselectedShiftOrTil"
                        }`}
                        onClick={() => {
                          setShowShifts(!showShifts);
                        }}
                      >
                        Shifts{" "}
                        <img
                          src={showShifts ? checkboxInstall2 : checkboxInstall1}
                          alt="Select"
                          className="shiftTilSelectImg"
                        />
                      </div>
                      <div
                        className={`shiftsOrTilFilter ${
                          showOvertimes ? "" : "unselectedShiftOrTil"
                        }`}
                        onClick={() => {
                          setShowOvertimes(!showOvertimes);
                        }}
                      >
                        Overtime{" "}
                        <img
                          src={
                            showOvertimes ? checkboxInstall2 : checkboxInstall1
                          }
                          alt="Select"
                          className="shiftTilSelectImg"
                        />
                      </div>
                      <div
                        className={`shiftsOrTilFilter ${
                          showTils ? "" : "unselectedShiftOrTil"
                        }`}
                        onClick={() => {
                          setShowTils(!showTils);
                        }}
                      >
                        Overtime (in lieu){" "}
                        <img
                          src={showTils ? checkboxInstall2 : checkboxInstall1}
                          alt="Select"
                          className="shiftTilSelectImg"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {memoVals.calendarPage === "myCal" && showFilter ? (
                  // <div className="filtSplitContainer borderRightGrey">
                  <div className="filterSplitter"></div>
                ) : (
                  // </div>
                  ""
                )}
                <div
                  ref={containerRef}
                  className={`showReqs ${
                    memoVals.mob ? "noBorder minW101pc" : ""
                  }  ${
                    memoVals.mob
                      ? `${
                          memoVals.calendarPage === "teamCal"
                            ? `absBoxColCalMob ${
                                memoVals.device.ios ? "absBoxColCalMobIos" : ""
                              }`
                            : `absBoxMob ${
                                memoVals.device.ios ? "absBoxMobIos" : ""
                              }  ${
                                memoVals.calendarPage === "team"
                                  ? memoVals.device.ios
                                    ? "x1384292498233Ios"
                                    : "x1384292498233"
                                  : ""
                              }`
                        }`
                      : `${
                          memoVals.calendarPage === "teamCal"
                            ? "absBoxColCal"
                            : memoVals.calendarPage === "team"
                            ? "absBoxTeamCal"
                            : "absBox"
                        }`
                  } ${!memoVals.mob && !memoVals.full ? "midCalHolder" : ""} ${
                    memoVals.full
                      ? `fullCalHolder ${
                          memoVals.calendarPage === "myCal" && showFilter
                            ? "showFilterCalBoxDesktop"
                            : ""
                        }`
                      : `${
                          memoVals.calendarPage === "myCal" &&
                          showFilter &&
                          memoVals.mob
                            ? `showFilterCalBoxMob ${
                                memoVals.device.ios
                                  ? "showFilterCalBoxMobIos"
                                  : ""
                              }`
                            : memoVals.calendarPage === "myCal" && showFilter
                            ? "showFilterCalBoxDesktop"
                            : ""
                        }`
                  } ${!memoVals.mob && loading ? "nonMobLoadingCal" : ""} ${
                    memoVals.calendarPage === "check"
                      ? `alignStartx ${
                          memoVals.device.ios ? "alignStartxIos" : ""
                        }`
                      : ""
                  } ${memoVals.device.vhIssue ? "vhIssue" : ""}`}
                >
                  {/* LOADING */}
                  {!loading &&
                  memoVals.calendarPage === "teamCal" &&
                  !selectedUserID ? (
                    <div className="noShiftsTxtContainer noUpcomingReqs selCollHolder">
                      <div className="noShiftsSideBar"></div>

                      <p className="noShiftsTxt">Select a colleague</p>

                      <div className="noShiftsSideBar"></div>
                    </div>
                  ) : (
                    ""
                  )}

                  {memoVals.calendarPage === "team" ? (
                    <div
                      className={`teamCalHolder ${
                        !memoVals.mob ? "marginLeft2pc" : ""
                      } `}
                    >
                      <TeamCalendarInlet
                        isMgr={false}
                        setSelectedTeam={setSelectedMyTeam}
                        setSelectedMy={setSelectedMy}
                        loading={loading}
                        selectedMy={selectedMy}
                        selectedTeam={selectedMyTeam}
                        mob={memoVals.mob}
                        setLoading={setLoading}
                        setMyArr={setMyArr}
                        myArr={myArr}
                        setShowDayCommentModal={setShowDayCommentModal}
                        selectedType={"allTypes"}
                        showClosedDay={showClosedDay}
                        setShowClosedDay={setShowClosedDay}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {memoVals.calendarPage === "check" ? (
                    <div className="checkParent">
                      {memoVals.calendarPage === "check" ? (
                        <p className="checkDivTitle yourPTeamIs">
                          <img
                            src={teamIcon}
                            alt="Primary team"
                            className="calPrimTeamIcon"
                          />
                          Your primary team is {myTeamName}
                        </p>
                      ) : (
                        ""
                      )}
                      {memoVals.calendarPage === "check" ? (
                        <div className="checkerDiv">
                          <p className="checkDivTitle">
                            Use this tool to find out if a period of leave you
                            are thinking of requesting might exceed your team
                            leave limits
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="imgPreloads">
                        <img src={popup3} alt="_" className="invis" />
                        <img src={bulkTick} alt="_" className="invis" />
                      </div>

                      {memoVals.calendarPage === "check" ? (
                        <div className="checkerDiv">
                          <p className="checkDivTitle">
                            {limitsDisabled
                              ? "There are no leave limits set for this team."
                              : "Leave limits are enabled for this team. Enter your start and end date below to find out whether or not the leave will exceed a limit."}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      {memoVals.calendarPage === "check" ? (
                        <div className="leaveCheckParent">
                          <div className="leaveCheckFlexUnit">
                            <p className="leaveCheckStartTitle">
                              First day of request
                            </p>
                            <input
                              type="date"
                              className={`limitCheckDateInput ${
                                memoVals.device.ios ? "iosDateInput" : ""
                              }`}
                              id="leaveCheckStartInput"
                              value={startDateInput}
                              min={dateStringer.createDateInputFromDateString(
                                dateStringer.createStringFromTimestamp(
                                  todayMidnightDateObj.getTime()
                                )
                              )}
                              onFocus={() => {
                                console.log("focus is on start date input");
                              }}
                              onChange={(e) => {
                                // daisy
                                setStartDateInput(e.target.value);
                                setEndDateInput(e.target.value);
                              }}
                            ></input>
                          </div>
                          <div className="leaveCheckFlexUnit">
                            <p className="leaveCheckEndTitle">
                              Last day of request
                            </p>
                            <input
                              type="date"
                              className={`limitCheckDateInput ${
                                memoVals.device.ios ? "iosDateInput" : ""
                              }`}
                              id="leaveCheckEndInput"
                              value={endDateInput}
                              min={startDateInput}
                              onFocus={() => {
                                console.log("focus is on end date input");
                              }}
                              onChange={(e) => {
                                // daisy
                                setEndDateInput(e.target.value);
                              }}
                            ></input>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {memoVals.calendarPage === "check" ? (
                        <div className="checkBtnAndResultDiv">
                          <div className="checkBtnResultLeft">
                            {limitCheckLoading ? (
                              <img
                                src={blueLoader}
                                alt="loading limits"
                                className="loadinglimBlueLoader"
                              />
                            ) : (
                              <p
                                className={`checkLimitBtn ${
                                  startDateInput &&
                                  startDateInput[0] === "2" &&
                                  endDateInput &&
                                  endDateInput[0] === "2" &&
                                  dateStringer.createTimestampFromString(
                                    dateStringer.createStringFromDateInput(
                                      startDateInput
                                    )
                                  ) <=
                                    dateStringer.createTimestampFromString(
                                      dateStringer.createStringFromDateInput(
                                        endDateInput
                                      )
                                    )
                                    ? ""
                                    : "invalidLimitDates"
                                }`}
                                onClick={() => {
                                  setLimitCheckLoading(true);
                                  setExceedsNoLimits(false);
                                  axios
                                    .post(
                                      `${serverURL}/get-leave-request-pre-data`,
                                      {
                                        userID: memoVals.checkUserState.userID,
                                        leaveTypeID: "annLeave",
                                        startDs:
                                          dateStringer.createStringFromDateInput(
                                            startDateInput
                                          ),
                                        endDs:
                                          dateStringer.createStringFromDateInput(
                                            endDateInput
                                          ),
                                        startHalf: false,
                                        endHalf: false,
                                        limitsOnly: true,
                                      },

                                      {
                                        withCredentials: true,
                                        credentials: "include",
                                      }
                                    )
                                    .then((response) => {
                                      if (response.data.message === "success") {
                                        setLimitsArr(response.data.limitsArr);
                                        if (!response.data.limitsArr[0]) {
                                          setExceedsNoLimits(true);
                                        }
                                        setLimitCheckLoading(false);
                                      }
                                      if (
                                        response.data.message.includes("1 year")
                                      ) {
                                        setExceeds1Yr(true);
                                        setLimitCheckLoading(false);
                                      }
                                    })
                                    .catch((err) => {
                                      console.error(err);
                                    });
                                }}
                              >
                                Check{" "}
                                <img
                                  src={magni}
                                  alt="Check limits"
                                  className="limitMagniImg"
                                />
                              </p>
                            )}
                          </div>

                          <div className="checkBtnResultRight">
                            {limitsArr[0] && !limitCheckLoading ? (
                              <p
                                className="checkExceedBtn"
                                onClick={() => {
                                  setShowLimitsModal(true);
                                }}
                              >
                                Exceeds limits on{" "}
                                {limitsArr && limitsArr.length} day
                                {limitsArr.length === 1 ? "" : "s"}{" "}
                                <img
                                  src={popup3}
                                  alt="View limits"
                                  className="exceedsLimitsPopUp3"
                                />
                              </p>
                            ) : exceedsNoLimits ? (
                              <p className="exceedsNoLimits">
                                This period exceeds no limits{" "}
                                <img
                                  src={bulkTick}
                                  alt="No limits exceeded"
                                  className="noExceedTickImg"
                                />
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {loading &&
                  memoVals.calendarPage !== "check" &&
                  memoVals.calendarPage !== "team" ? (
                    <img
                      src={horizontalBalls}
                      alt="loading"
                      className="blueLoadingReqs"
                    />
                  ) : memoVals.calendarPage === "myCal" ? (
                    <div
                      className={`genMyCal ${
                        memoVals.mob ? "genMyCalMob" : ""
                      }`}
                    >
                      {generateMyCal}
                    </div>
                  ) : selectedUserID && memoVals.calendarPage === "teamCal" ? (
                    generateCal
                  ) : (
                    ""
                  )}
                  {/* END OF LOADING */}
                </div>
              </div>
            </div>
            {/* Calendar sidebox here */}
            {memoVals.full ? (
              <div className="desktopMasterSecondary">
                <MyReqsDataBox
                  modal={false}
                  profileSideBox={true}
                  selectedYear={selectedYear}
                  setSelectedYearParent={setSelectedYear}
                  myCalPage={true}
                  setShowAllows={setShowSideModal}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* end of requests */}
        {renderSideModal()}
        {showLimitsModal && limitsArr[0] ? (
          <div
            className="reqStatsUnderlay"
            onClick={() => {
              setShowLimitsModal(false);
            }}
          >
            <div
              className="showDayModalBox"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="showDayModalHeader">
                <img
                  src={close}
                  alt="Close"
                  className="closeShowDayModalImg"
                  onClick={() => {
                    setShowLimitsModal(false);
                  }}
                />
                <p className="showDayHeaderTitle">Exceeded limits</p>
              </div>
              <div className="showDayModalBody">
                {limitsArr.map((lim, i) => {
                  return (
                    <div className="reqMakerLimitrRow">
                      <p className="limRowDs">
                        {dateStringer.printedDateFromDs(lim.ds)}
                      </p>
                      <div className="limRowFlexBox">
                        {lim.jtOrTeam === "jt" ? (
                          <div className="limRowLeft">
                            <p className="limRowTitle">Limit</p>
                            <p className="limRowValue limRowValue2">
                              {lim.maxOff}
                            </p>
                            {/* Limit of {lim.maxOff} {lim.jobTitle}
              {lim.jobTitle[lim.jobTitle.length - 1] === "s" || lim.maxOff === 1
                ? ""
                : "s"}{" "}
              off in {lim.teamName} */}
                          </div>
                        ) : (
                          <div className="limRowLeft">
                            <p className="limRowTitle">Limit</p>
                            <p className="limRowValue limRowValue2">
                              {lim.maxOff}
                            </p>

                            {/* Limit of {lim.maxOff} employees off in {lim.teamName} */}
                          </div>
                        )}

                        {lim.jtOrTeam === "jt" ? (
                          <div className="limRowMiddle">
                            <p className="limRowTitle">Already off</p>
                            <p className="limRowValue limRowValue2">
                              {lim.currOff}
                            </p>

                            {/* There {lim.maxOff === 1 ? "is" : "are"} currently already{" "}
              {lim.currOff} {lim.jobTitle}
              {lim.jobTitle[lim.jobTitle.length - 1] === "s" ||
              lim.currOff === 1
                ? ""
                : "s"}{" "}
              off already */}
                          </div>
                        ) : (
                          <div className="limRowMiddle">
                            <p className="limRowTitle">Already off</p>
                            <p className="limRowValue limRowValue2">
                              {lim.currOff}
                            </p>
                            {/* There {lim.currOff === 1 ? "is" : "are"} already {lim.currOff}{" "}
              employee{lim.currOff === 1 ? "" : "s"} off in {lim.teamName} */}
                          </div>
                        )}

                        {lim.jtOrTeam === "jt" ? (
                          <div className="limRowRight">
                            <p className="limRowTitle">Limit by</p>
                            <p className="limRowValue limRowValue2 minHeightFitContent">
                              {lim.jobTitle}
                              {lim.jobTitle[lim.jobTitle.length - 1] === "s"
                                ? "es"
                                : "s"}{" "}
                              in {lim.teamName}
                            </p>
                            {/* Limit of {lim.maxOff} {lim.jobTitle}
              {lim.jobTitle[lim.jobTitle.length - 1] === "s" || lim.maxOff === 1
                ? ""
                : "s"}{" "}
              off in {lim.teamName} */}
                          </div>
                        ) : (
                          <div className="limRowRight">
                            <p className="limRowTitle">Limit by</p>
                            <p className="limRowValueChecker limRowValue2">
                              All staff in {lim.teamName}
                            </p>

                            {/* Limit of {lim.maxOff} employees off in {lim.teamName} */}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="showDayModalFooter">
                {!showDay.loading && showNewReqBtn ? (
                  <p
                    className="showDayAddLeaveBtn"
                    onClick={() => {
                      memoVals.setShowReqModal((x) => showDay.ds);
                    }}
                  >
                    New request{" "}
                    <img
                      src={whiteAddThin}
                      alt="Add form"
                      className="whiteAddFormImg"
                    />
                  </p>
                ) : (
                  <p></p>
                )}
                <p
                  className="closeShowDayBtnTxt"
                  onClick={() => {
                    setShowLimitsModal(false);
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* swip here */}
        {showDay && showDay.show ? (
          <div
            className="reqStatsUnderlay"
            onClick={(e) => {
              e.stopPropagation();
              setShowDay({ show: false, arr: [], ds: "" });
            }}
          >
            <div
              className={`showDayModalBox ${
                memoVals.mobModal
                  ? `mobModalShoulder ${
                      memoVals.device.ios ? "showDayModalBoxIos" : ""
                    }`
                  : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={modalPosition}
              ref={modalRef}
            >
              {memoVals.mobModal && (
                <div className="modalSwiper modalSwiperViewUserDay"></div>
              )}
              <div
                className="showDayModalHeader"
                onTouchStart={handleDown}
                onTouchMove={memoVals.mobModal ? handleMove : null}
                onTouchEnd={memoVals.mobModal ? handleUp : null}
                onMouseDown={memoVals.mobModal ? handleDown : null}
                onMouseMove={memoVals.mobModal ? handleMove : null}
                onMouseUp={memoVals.mobModal ? handleUp : null}
              >
                <img
                  src={close}
                  alt="Close"
                  className="closeShowDayModalImg"
                  onClick={() => {
                    setShowDay({ show: false });
                  }}
                />
                <p className="showDayHeaderTitle">
                  {memoVals.calendarPage === "myCal"
                    ? dateStringer.printedDateWithYrFromDs(showDay.ds)
                    : `${dateStringer.possession(
                        selectedUserFName
                      )} day on ${dateStringer.printedDateFromDs(showDay.ds)}`}
                </p>
              </div>
              <div className="showDayModalBody">
                {showDay.loading ? (
                  <img
                    src={horizontalBalls}
                    alt="loading"
                    className="blueLoadingReqs"
                  />
                ) : (
                  generateShowDay
                )}
              </div>
              <div className="showDayModalFooter">
                {!showDay.loading && showNewReqBtn ? (
                  <p
                    className="showDayAddLeaveBtn"
                    onClick={() => {
                      memoVals.setShowReqModal((x) => showDay.ds);
                    }}
                  >
                    New request{" "}
                    <img
                      src={whiteAddThin}
                      alt="Add form"
                      className="whiteAddFormImg"
                    />
                  </p>
                ) : (
                  <p></p>
                )}
                <p
                  className="closeShowDayBtnTxt"
                  onClick={() => {
                    setShowDay({ show: false });
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {exceeds1Yr ? (
          <div
            className="addAbsenceModalUnderlay"
            onClick={() => {
              setExceeds1Yr(0);
            }}
          >
            {" "}
            <div
              className="formCreatedModal"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Sorry, your leave period must not exceed one year.
              <p
                className="formCreatedModalClose"
                onClick={() => {
                  setExceeds1Yr(0);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        ) : (
          ""
        )}{" "}
        {showDayComments[0] ? (
          <div
            className="addAbsenceModalUnderlay"
            onClick={() => {
              setShowDayComments([]);
            }}
          >
            {" "}
            <div
              className={`formCreatedModal ${
                memoVals.mobModal ? "mobModalShoulder slideUp " : ""
              } x61761g11`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              There are {showDayComments.length} teams with comments on{" "}
              {dateStringer.printedDateFromDs(showDayComments[0].ds)}.
              <br />
              <br />
              {showDayComments.map((dc) => {
                return (
                  <div
                    className="dayCommentListDiv"
                    onClick={() => {
                      setShowDayComments([]);
                      memoVals.setShowDayCommentModal((x) => {
                        return {
                          show: true,
                          isMgr: mgrOf.includes(dc.teamID),
                          teamID: dc.teamID,
                          ds: dc.ds,
                        };
                      });
                    }}
                  >
                    <p className="dayCommentListBtn">{dc.teamName}</p>
                    <p className="dayCommentListPreview">
                      <span className="comFn">{dc.fName}:</span> {dc.preview}
                    </p>
                  </div>
                );
              })}
              <div className="dayCommFooter">
                <p
                  className="formCreatedModalClose"
                  onClick={() => {
                    setShowDayComments([]);
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {showClosedDay ? (
          <div
            className="indiOnlyUnderlay"
            onClick={() => {
              setShowClosedDay("");
            }}
          >
            <div className="overlapsRenewalModalBox">
              <div className="overlapsRenTxt">{showClosedDay}</div>
              <p
                className="tempSavedCloseBtn"
                onClick={() => {
                  setShowClosedDay("");
                }}
              >
                Close
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
        <Navbar />
        {mob && <CalendarSubBar />}
      </div>

      {imgPreload}
      {!memoVals.checkUserState.validUser && (
        <div className="invalidUser">
          {" "}
          <img
            src={horizontalBalls}
            alt="loading"
            className="blueLoadingReqs invUsrLoad"
          />
        </div>
      )}
    </div>
  );
};

export default Calendar;
